import React, { useEffect, useState, useContext } from "react";
import 'chart.js/auto';
import { useNavigate } from "react-router-dom";
import { Doughnut } from "react-chartjs-2";
import { AlertContext } from "../../../common/alertContainer";
import { AuthTokenService } from "../../../../services";
import defaultTheme from "../../../../theme/theme";
import { getImpactSectorData } from "../micappitalEcoServices";
import ImpactSectorsStyle from "./impactSectorsStyle";
import ImpactSectorSwiper from "./impactSectorSwiper/impactSectorSwiper";
import Text from "../../../../theme/font";
import { ThemeProvider } from "styled-components";

const ImpactSectors = React.memo(() => {
  const theme = defaultTheme;
  const [chartData, setChartData] = useState([]);
  const [amount, setAmount] = useState([]);
  const [color, setColor] = useState([]);
  const [title, setTitle] = useState([]);
  const [iteratedAmount, setIteratedAmount] = useState([]);
  const [iteratedColor, setIteratedColor] = useState([]);
  const [iteratedTitle, setIteratedTitle] = useState([]);
  const [iteratedId, setIteratedId] = useState([]);
  const [sectorData, setSectorData] = useState([]);
  const [checkData, setCheckData] = useState(false);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const navigate = useNavigate();
  const { open } = useContext(AlertContext);

  useEffect(() => {
    setScreenWidth(window.innerWidth);
  }, []);

  const responseHandler = (response) => {
    const valueData = [];
    const colorData = [];
    const titleData = [];
    // eslint-disable-next-line array-callback-return
    response.map((value) => {
      const percentage = value.por.toFixed(2);
      setChartData(response);
      valueData.push(value.por);
      colorData.push(value.color);
      titleData.push(`${value.titulo}    \t${percentage}%`);
    });
    setAmount([...valueData]);
    setColor([...colorData]);
    setTitle([...titleData]);
  };
  const userId = AuthTokenService.getUserId();

  useEffect(() => {
    open({
      alertType: "spinner",
      open: true,
    });
    getImpactSectorData(userId)
      .then((response) => {
        open({
          alertType: "spinner",
          open: false,
        });
        if (response && response.data.data.ods) {
          setSectorData(response.data.data.ods);

          const primaryChartData = response.data.data.ods;

          if (primaryChartData && primaryChartData.length > 0) {
            responseHandler(primaryChartData);
          }
        } else {
          navigate("/conoceEco");
        }
      })
      .catch(() => {
        open({
          alertType: "spinner",
          open: false,
        });
        open({
          type: "error",
          title: "Error al enviar datos",
          alertType: "toast",
          open: true,
        });
      });
    return () => {
      setSectorData([]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const dataIterationHandler = (id) => {
    setCheckData(true);
    let iteratedvalueData = iteratedAmount;
    let iteratedcolorData = iteratedColor;
    let iteratedtitleData = iteratedTitle;
    let iteratedIdData = iteratedId;

    const checkingVariable = iteratedIdData.find((e) => e === id);

    if (!checkingVariable) {
      // eslint-disable-next-line array-callback-return
      chartData.map((data) => {
        if (data.idOds === id) {
          const percentage = data.por.toFixed(2);
          iteratedvalueData.push({ id: data.idOds, value: data.por });
          iteratedcolorData.push({ id: data.idOds, value: data.color });
          iteratedtitleData.push(`${data.titulo}    \t${percentage}%`);
        }
      });
      iteratedIdData.push(id);
      setIteratedId([...iteratedIdData]);
      setIteratedAmount([...iteratedvalueData]);
      setIteratedColor([...iteratedcolorData]);
      setIteratedTitle([...iteratedtitleData]);
    } else {
      if (iteratedAmount.length > 1) {
        // eslint-disable-next-line array-callback-return
        chartData.map((data) => {
          if (data.idOds === id) {
            const percentage = data.por.toFixed(2);

            setIteratedId(iteratedIdData.filter((e) => e !== data.idOds));
            setIteratedAmount(
              iteratedvalueData.filter((e) => e.id !== data.idOds)
            );
            setIteratedColor(
              iteratedcolorData.filter((e) => e.id !== data.idOds)
            );
            setIteratedTitle(
              iteratedtitleData.filter(
                (e) => e !== `${data.titulo}    \t${percentage}%`
              )
            );
          }
        });
      } else {
        setCheckData(false);
        setIteratedAmount([]);
        setIteratedColor([]);
        setIteratedTitle([]);
        setIteratedId([]);
        responseHandler(chartData);
      }
    }
  };
  const data = {
    datasets: [
      {
        data: checkData ? iteratedAmount.map((obj) => obj.value) : amount,
        backgroundColor: checkData
          ? iteratedColor.map((obj) => obj.value)
          : color,
      },
    ],
    labels: checkData ? iteratedTitle : title,
  };

  const options = {
    type: "doughnut",
    cutout: "80%",
    responsive: true,
    resizeDelay: 1,
    align: "left",
    radius: screenWidth <= 500 ? "80%" : "70%",
    innerRadius: "20%",
    borderWidth: 0,
    plugins: {
      legend: {
        display: true,
        position: screenWidth <= 1150 ? "bottom" : "right",
        labels: {
          color: "#3F3F3F",
          boxWidth: 20,
          padding: screenWidth <= 500 ? 5 : 20,
          font: {
            size:
              screenWidth >= 3000
                ? 26
                : screenWidth >= 2000
                ? 22
                : screenWidth >= 500
                ? 16
                : 12,
            family: "Asap",
          },
        },
        maintainAspectRatio: false,
        datalabels: {
          display: true,
        },
      },
    },
  };

  return (
    <ThemeProvider theme={theme}>
      <ImpactSectorsStyle>
        <div className="impact-sector-container box-container">
          <Text variant="titleFont" className="impact-sector-title">
            Sectores en los que estás ayudando
          </Text>
          <ImpactSectorSwiper
            data={sectorData}
            click={(e) => dataIterationHandler(e)}
          />
          <div className="impact-sector-doughnut-container">
            <Doughnut
              className="impact-sector-doughnut"
              data={data}
              options={options}
            />
          </div>
        </div>
      </ImpactSectorsStyle>
    </ThemeProvider>
  );
});

export default ImpactSectors;
