import styled  from "styled-components";

export const LoaderStyle = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  .loader_overlay {
    width: 100%;
    height: 100%;
    opacity: 0.5;
    background-color: #fff;
  }
  .loader {
    display: flex;
    width: 100vw;
    height: 100vh;
    justify-content: center;
    align-items: center;
  }
  img {
    width: 8rem;
    height: 8rem;
    @media (max-width: 450px) {
      width: 4rem;
      height: 4rem;
    }
  }
`;
export default LoaderStyle;
