import styled from "styled-components";

const AddFriendsStyle = styled.div`
  padding-right: 2rem;
  @media (max-width: 1040px) {
    padding: 0;
  }
  .add-friends-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 2%;
    padding: 1rem 0rem;
    @media (max-width: 1080px) {
      gap: 2rem;
      flex-direction: column;
      align-items: center;
    }

    .invite-section {
      width: calc(100% - 33%);
      padding: 2rem 2rem;
      gap: 2rem;
      @media (max-width: 1080px) {
        width: 100%;
      }
      @media (max-width: 621px) {
        padding: 1rem 1rem;
      }
    }
    .invited-section {
      width: calc(100% - 65%);
      padding: 1rem 1rem;
      min-width: 18.12rem;
      height: fit-content;
      max-height: fit-content;
      @media (max-width: 1080px) {
        width: 100%;
        max-width: 25rem;
      }
    }
  }
`;
export default AddFriendsStyle;
