import ImageConstants from "../constants/imageConstants/image_constants";

export const optionsData = [
  {
    src: ImageConstants.option1,
    title: "Sostenibilidad e impacto",
    description:
      "Analizamos los productos ISR que mejor se adecuen a tu perfil para que la inversión sea a medida.",
  },
  {
    src: ImageConstants.option2,
    title: "A tu medida",
    description:
      "Teniendo en cuenta tus preferencias, elaboramos una cartera exclusivamente para ti.",
  },
  {
    src: ImageConstants.option3,
    title: "Rentabilidad",
    description:
      "Sostenibilidad y rentabilidad ya van de la mano. Las empresas del futuro son aquellas que garantizan la sostenibilidad.",
  },
];

export const sectorIcons = [
  {
    src:ImageConstants.sector1
  },
  {
    src:ImageConstants.sector2
  },
  {
    src:ImageConstants.sector3
  },
 
  {
    src:ImageConstants.sector4
  },
  {
    src:ImageConstants.sector5
  },
  {
    src:ImageConstants.sector6
  },
  {
    src:ImageConstants.sector7
  },
  {
    src:ImageConstants.sector8
  },
  {
    src:ImageConstants.sector9
  },
  {
    src:ImageConstants.sector10
  },
  {
    src:ImageConstants.sector11
  },
  {
    src:ImageConstants.sector12
  },
  {
    src:ImageConstants.sector13
  },
  {
    src:ImageConstants.sector14
  },
  {
    src:ImageConstants.sector15
  },
  {
    src:ImageConstants.sector16
  },
  {
    src:ImageConstants.sector17
  },
  
]