import styled from "styled-components";

const DoubtsStyle = styled.div`
  margin-bottom: 1rem;
  margin-top: 4rem;
  @media (max-width: 991px) {
    margin-bottom: 3rem;
  }
  .title-doubts {
    color: ${(props) => props.theme.colors.fontColor.titleFontColor};
    font-weight: bold;
    margin-bottom: 3.25rem;
    margin-top: 4.5rem;
    width: 100%;
    text-align: center;
  }
  .padding-container {
    @media (max-width: 450px) {
      padding: 1rem;
    }
  }
  .doubts-container {
    @media (max-width: 991px) {
      gap: 2.5rem;
    }
  }
  .doubts-text {
    color: ${(props) => props.theme.colors.fontColor.descriptionFontColor};
    font-weight: bold;
    text-align: center;
    width: 100%;
  }
  .doubts-small-text {
    color: ${(props) => props.theme.colors.fontColor.descriptionFontColor};
    text-align: center;
    width: 100%;
  }
  .spacer-div {
    margin-bottom: 1.6rem;
    @media (max-width: 921px) {
      margin-bottom: 1rem;
    }
  }
  .button-container-doubts {
    width: 100%;
    text-align: center;
    align-items: center;
    margin-top: 1rem;
    margin-bottom: 1rem;
    @media (max-width: 921px) {
      display: flex;
    }
  }
  .doubts-flex-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }
  .contact-button {
    margin: auto;
    width: 11.25rem;
    max-width: 11.25rem;
    height: 2.68rem;
    padding: 10px 6px 12px 7px;
    border-radius: 1.34375rem;
    background-color: ${(props) =>
      props.theme.colors.button.background.whatsappButtonBackground};
    text-align: center;
    color: ${(props) => props.theme.colors.fontColor.titleFontColor};
    @media (max-width: 921px) {
      width: 100%;
      max-width: 11.25rem;
    }
  }
  .phone {
    color: ${(props) => props.theme.colors.fontColor.phoneNumberFontColor};
    margin-top: 1rem;
  }
  .whatsapp {
    background-color: ${(props) =>
      props.theme.colors.button.background.whatsappButtonBackground};
    color: ${(props) => props.theme.colors.fontColor.titleFontColor};
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .whatsapp:hover {
    cursor: pointer;
  }
  .llamada {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${(props) =>
      props.theme.colors.button.background.callButtonBackground};
    color: ${(props) => props.theme.colors.fontColor.titleFontColor};
  }
  .llamada:hover {
    cursor: pointer;
  }
  .contact-image {
    height: 4.37rem;
    margin-bottom: 1.25rem;
  }
`;
export default DoubtsStyle;
