// import Button from "../../../../components/button/button";
import NecessaryActionsStyle from "./necessayActonsStyle";
import Text from "../../../../theme/font";

const NecessaryActions = ({ data }) => {
  const optimizationData = data;

  return (
    <NecessaryActionsStyle>
      <div className="necessary-actions-container">
        <div className="header-container">
          <Text variant="titleFont" className="necessary-actions-heading">
            Acciones necesarias
          </Text>
          <div className="optimization-data">
            <Text variant="titleFont" className="optimization-percentage">
              {optimizationData.porcentaje}%
            </Text>
            <Text variant="extraSmall">Optimización</Text>
          </div>
        </div>
        <Text variant="titleFont" className="necessary-actions-description">
          {optimizationData.texto}
        </Text>
        {/* <div className="improvements-button-container"> */}
          {/* <Button className="improvements-button" value="Ver mejoras" /> */}
        {/* </div> */}
      </div>
    </NecessaryActionsStyle>
  );
};

export default NecessaryActions;
