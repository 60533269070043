import styled from "styled-components";

const LandingPageStyle = styled.div`
  padding: 1rem 1.7rem 2rem 0rem;
  @media (max-width: 1040px) {
    padding: 2rem 0rem;
  }

  .landing-page-container {
    display: flex;
    gap: 2%;
    @media (max-width: 1040px) {
      flex-direction: column;
    }

    .primary-section {
      width: calc(100% - 35%);
      display: flex;
      flex-direction: column;
      gap: 1.5rem;
      @media (max-width: 1040px) {
        width: 100%;
        padding-bottom: 1.5rem;
      }
      .banner-section {
        width: 100%;
      }
    }
    .secondary-section {
      display: flex;
      flex-direction: column;
      gap: 1.5rem;
      width: calc(100% - 67%);
      @media (max-width: 1040px) {
        width: 100%;
        display: flex;
        align-items: center;
      }
      .simulate-section {
        @media (max-width: 1040px) {
          max-width: 22rem;
        }
      }
      .all-in-one-section {
        @media (max-width: 1040px) {
          max-width: 22rem;
        }
      }
    }
  }
`;
export default LandingPageStyle;
