import styled from "styled-components";

const SingleNewsStyle = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  padding-right: 2rem;
  margin-bottom: 2rem;
  color: ${(props) => props.theme.colors.fontColor.newsTitle};
  @media (max-width: 1040px) {
    padding-right: 0rem;
  }
  @media (max-width: 450px) {
    margin: 3rem 0rem;
  }
  .newsTitle {
    font-weight: bold;
    @media (max-width: 1140px) {
      text-align: center;
    }
  }
  .newsContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 2rem;

    .news {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 60%;
      position: relative;
      @media (max-width: 730px) {
        width: 80%;
      }
      .totalNews {
        margin-top: 1.5rem;
      }
      .newsImage {
        display: flex;
        justify-content: center;
        align-items: center;
        .newsImg {
          width: 100%;
        }
      }
      .newsInfos {
        position: absolute;
        display: flex;
        flex-direction: column;
        gap: 1rem;
        top: 0;
        left: -15rem;
        @media (max-width: 1140px) {
          display: flex;
          position: unset;
          margin-bottom: 1rem;
        }

        .logo {
          width: 2.5rem;
          cursor: pointer;
          @media (max-width: 1140px) {
            width: 2rem;
          }
        }
        .newsWriter {
          .author {
            font-weight: bold;
          }
        }
      }
    }
  }

  .back-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2.1875rem;
    position: fixed;
    top: 2.3rem;
    left: 3rem;
    z-index: 1;
    cursor: pointer;
    @media (max-width: 1040px) {
      display: none;
    }

    img {
      width: 1.5625rem;
    }
  }
`;
export default SingleNewsStyle;
