import { GoalActionTypes } from "./goalActionType";

export const setDetails = (details) => {
    return{
        type: GoalActionTypes.SHOW_DETAILS,
        payload: details
    }
}

export const getObjective = (objectiveId) => {
    return{
        type: GoalActionTypes.GET_OBJECTIVE,
        payload: objectiveId
    }
}