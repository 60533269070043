import { useEffect, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { AlertContext } from "../../common/alertContainer";
import { ContainerContext } from "../../../components/navbarContainer/containerProvider";
import { AuthTokenService } from "../../../services";
import { cargarPosicion } from "../../investment/home/homeServices";
import { loginSuperCliente } from "./superCuentaServices";

const Supercuenta = () => {
  const { open } = useContext(AlertContext);
  const { search } = useLocation();
  const navigate = useNavigate();
  const { changeMenuStatus, changeNavBarStatus, changeAlertStatus } =
    useContext(ContainerContext);

  useEffect(() => {
    changeNavBarStatus(true);
    changeMenuStatus(true);
    changeAlertStatus(false);
    return () => {
      changeMenuStatus(false);
      changeNavBarStatus(false);
      changeAlertStatus(true);
    };
  });

  const tokenHandler = (newToken, idSuperCliente) => {
    if (newToken && idSuperCliente) {
      AuthTokenService.setToken({
        token: newToken,
        idSuperCliente,
      });
      const data = { idSuperCliente: idSuperCliente };
      loginSuperCliente(data)
        .then((result) => {
          const data = result?.data.datosSuperCliente;
          const { token } = result.data;
          const { idCliente, idClienteSeguros, nombre, apellidos, imagen } =
            data;
          const userId = idCliente ? idCliente : "";
          const clientInsuranceId = idClienteSeguros ? idClienteSeguros : "";
          const profileImage = imagen ? imagen : "";

          AuthTokenService.setToken({
            idCliente: userId,
            token,
            idSuperCliente,
            idClienteSeguros: clientInsuranceId,
            nombre,
            apellidos,
            imagen: profileImage,
          });
          if (idCliente) {
            cargarPosicion(idCliente)
              .then((result) => {
                if (result?.data) {
                  const valorData = result.data.data[0].valor;
                  if (valorData && valorData === "0") {
                    navigate("/propuesta");
                    open({
                      alertType: "spinner",
                      open: false,
                    });
                  } else {
                    navigate("/resumen");
                    open({
                      alertType: "spinner",
                      open: false,
                    });
                  }
                }
              })
              .catch(() => {
                open({
                  alertType: "spinner",
                  open: false,
                });
                open({
                  type: "error",
                  title: "Error al enviar datos",
                  alertType: "toast",
                  open: true,
                });
              });
          } else {
            navigate("/renderContainer");
          }
        })

        .catch(() => {
          open({
            alertType: "spinner",
            open: false,
          });
          open({
            type: "error",
            title: "Error al enviar datos",
            alertType: "toast",
            open: true,
          });
        });
    } else {
      open({
        type: "error",
        title: "No se puede acceder sin id y token",
        alertType: "toast",
        open: true,
      });
    }
  };

  useEffect(() => {
    open({
      alertType: "spinner",
      open: true,
    });
    const query = new URLSearchParams(search);
    const newToken = query.get("token");
    const idSuperCliente = query.get("idSuperCliente");
    setTimeout(() => {
      tokenHandler(newToken, idSuperCliente);
    }, 500);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <div className="supercuenta-container"></div>;
};

export default Supercuenta;
