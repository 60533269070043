import styled from "styled-components";

const NewMenuStyle = styled.div`
  padding: 0rem 0.5rem;
  .logo-container {
    padding: 0rem 0rem 4.7rem 1.5rem;
    @media (max-width: 1040px) {
      padding: 1.2rem 0rem 4.7rem 1.5rem;
    }
    .logo-icon {
      width: 3rem;
      @media (max-width: 540px) {
        width: 2.5rem;
      }
    }
  }
  .menu-items-container {
    width: 85%;
    .not-selected-container {
      display: flex;
      padding: 0.5rem;
      align-items: center;
    }
    .selected-container {
      border-radius: 0.5rem;
      background: ${(props) => props.theme.colors.background.defaultBackground};
      color: ${(props) => props.theme.colors.fontColor.titleFontColor};
      padding: 0.5rem;
      display: flex;
      align-items: center;
      cursor: pointer;
      @media (max-width: 1040px) {
        background: ${(props) => props.theme.colors.background.bodyBackground};
      }
    }
    .menu-option-icon {
      width: 1.8rem;
    }
    .seguro-icon {
      width: 1.8rem;
      height: 1.2rem;
    }
    .menu-item {
      padding-left: 0.6rem;
      cursor: pointer;
    }
  }
  .settings-container {
    margin-top: 2rem;
    .not-selected-container {
      display: flex;
      padding: 0.5rem;
      align-items: center;
    }
    .selected-container {
      border-radius: 0.5rem;
      background: ${(props) => props.theme.colors.background.defaultBackground};
      color: ${(props) => props.theme.colors.fontColor.titleFontColor};
      padding: 0.5rem;
      display: flex;
      align-items: center;
      cursor: pointer;
      @media (max-width: 1040px) {
        background: ${(props) => props.theme.colors.background.bodyBackground};
      }
    }
    .seguro-icon {
      width: 1.8rem;
      height: 1.2rem;
    }
    .menu-item {
      padding-left: 0.6rem;
      cursor: pointer;
    }
  }
`;
export default NewMenuStyle;
