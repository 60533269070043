import React, { useEffect, useState, useContext } from "react";
import { ComposableMap, Geographies, Geography } from "react-simple-maps";
import { AlertContext } from "../../../common/alertContainer";
import { AuthTokenService } from "../../../../services";
import defaultTheme from "../../../../theme/theme";
import { getEcoMapData } from "../../proposal/proposalServices";
import ImpactMapStyle from "./impactMapStyle";
import { ThemeProvider } from "styled-components";
import Text from "../../../../theme/font";

const ImpactMap = () => {
  const theme = defaultTheme;
  const [ecoMapData, setEcoMapData] = useState("");
  const { open } = useContext(AlertContext);
  const geoUrl = require("./ecoWorldMap.json");

  useEffect(() => {
    open({
      alertType: "spinner",
      open: true,
    });
    const userId = AuthTokenService.getUserId();
    getEcoMapData(userId)
      .then((result) => {
        open({
          alertType: "spinner",
          open: false,
        });
        setEcoMapData(result.data.paises);
      })
      .catch(() => {
        open({
          alertType: "spinner",
          open: false,
        });
        open({
          type: "error",
          title: "Error al enviar datos",
          alertType: "toast",
          open: true,
        });
      });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const checkHandler = (ecoEffectedArea) => {
    if (ecoEffectedArea) {
      return "#a8d115";
    } else {
      return "#ccc";
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <ImpactMapStyle>
        <div className="impact-map-section box-container">
          <div className="impact-map-container">
            <Text variant="titleFont" className="impact-map-title">
              ¿Dónde estás generando impacto?
            </Text>
            <div className="map-container">
              <ComposableMap>
                <Geographies geography={geoUrl}>
                  {({ geographies }) =>
                    geographies.map((geo) => {
                      const ecoEffectedArea =
                        ecoMapData.indexOf(geo.properties.name) !== -1;
                      return (
                        <Geography
                          key={geo.rsmKey}
                          geography={geo}
                          fill={checkHandler(ecoEffectedArea)}
                        />
                      );
                    })
                  }
                </Geographies>
              </ComposableMap>
            </div>
          </div>
          <div className="impact-map-labels">
            <div className="less-than-label">
              <div className="primary-label-color"></div>Lugares donde generas impacto
            </div>
            {/* <div className="between-label">
              <div className="secondary-label-color"></div>Entre un 1 y 5%
            </div> */}
            {/* <div className="more-than-label">
              <div className="teritiary-label-color"></div>Más de un 5%
            </div> */}
          </div>
        </div>
      </ImpactMapStyle>
    </ThemeProvider>
  );
};

export default ImpactMap;
