import jsPDF from "jspdf";
import ReactDOMServer from "react-dom/server";
import InsuranceLetterSectionStyle from "./insuranceLetterSectionStyle";
import Button from "../../../../components/button/button";
import InsuranceLetter from "../insuranceLetter/insuranceLetter";
import Text from "../../../../theme/font";

const InsuranceLetterSection = ({ data, micappitalData }) => {
  const insuranceLetterHandler = () => {
    const string = ReactDOMServer.renderToString(
      <InsuranceLetter data={data} micappitalData={micappitalData} />
    );
    const doc = new jsPDF("p", "px", "a4");
    doc.setFont("Inter-Regular", "normal");
    doc.setFontSize("10");
    doc.html(string, {
      callback: function (doc) {
        doc.save("Carta seguros cambio corredor");
      },
    });
  };

  return (
    <InsuranceLetterSectionStyle>
      <Text variant="titleFont" className="appointment-section-heading">
        ¿Quieres que te representemos ante la aseguradora?
      </Text>
      <Text className="appointment-section-description" variant="popUpText">
        Esto implica que te podemos ayudar a gestionar siniestros, renovar tus
        productos y optar a mejores tarifas. Además podrás acceder a tu póliza de manera más rápida.
        Para eso necesitamos que nos envíes esta carta firmada junto con tu DNI
      </Text>
      <Text variant="labelFont" className="appointment-button-container">
        <Button
          className="download-button"
          value="Carta de nombramiento"
          click={insuranceLetterHandler}
        />
      </Text>
 
    </InsuranceLetterSectionStyle>
  );
};

export default InsuranceLetterSection;
