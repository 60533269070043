import { useEffect, useState } from "react";
import { ThemeProvider } from "styled-components";
import AddbankPopUpStyle from "./addBankPopUpStyle";
import Button from "../../../../components/button/button";
import defaultTheme from "../../../../theme/theme";
import ImageConstants from "../../../../constants/imageConstants/image_constants";
import ScrollBar from "../../../../components/scrollbar/scrollbar";
import Text from "../../../../theme/font";

const AddBankPopUp = (props) => {
  const theme = defaultTheme;
  const [banks, setBanks] = useState(props.data.bancos && props.data.bancos); //if props.data.bancos is there then add it to the state
  const [bankValues, setBankValues] = useState(props.datas);

  useEffect(() => {
    setBanks(props.data.bancos && props.data.bancos);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const bankSelecterHandler = (id) => {
    const AddedBankValues = { ...bankValues }; //spread operator used to copy all data from bankvalues to AddedBankValues
    AddedBankValues[id] = !AddedBankValues[id];
    setBankValues({ ...AddedBankValues });
  };

  const bankSavingHandler = (props) => {
    props.submit(bankValues);
    props.submited();
  };

  return (
    <ThemeProvider theme={theme}>
      <AddbankPopUpStyle>
        <ScrollBar elementName={"card_header_container"} max_height={"100%"}>
          <div className="add-bank-container">
            <div className="add-bank-header">
              <Text className="add-bank-heading" variant="titleFont">
                Elige tus bancos
              </Text>
              <div className="button-container">
                <Button
                  className="save-button"
                  value="Guardar datos"
                  click={() => bankSavingHandler(props)}
                />
              </div>
            </div>
            <div className="select-bank-container">
              <Text
                className="select-bank-description"
                variant="popUpText"
                type="primary"
              >
                Selecciona tus bancos para que podamos trabajar.
              </Text>
              <div className="bank-options-container">
                <div className="bank-options">
                  {banks &&
                    banks.map((value) => {
                      return (
                        <div className="bank-icons" key={Math.random()}>
                          <img
                            className="bank-icon"
                            alt="x"
                            src={value.image}
                            onClick={() => bankSelecterHandler(value.idBanco)}
                          />
                          <img
                            alt="x"
                            className={
                              bankValues[value.idBanco] === true
                                ? "tick-icon"
                                : "not-selected"
                            }
                            src={ImageConstants.tick}
                          />
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
          </div>
        </ScrollBar>
      </AddbankPopUpStyle>
    </ThemeProvider>
  );
};

export default AddBankPopUp;
