import styled from "styled-components";
import dropDownArrow from "../../../../assets/img/settings/dropDownArrow.svg";

const TestPopUpStyle = styled.div`
  height: 31.25rem;
  border-radius: 1.5625rem;
  overflow: hidden;
  .test-container {
    width: 100%;
    border-radius: 1.5625rem;
    background-color: ${(props) =>
      props.theme.colors.background.defaultBackground};
    padding: 2rem 2rem 2rem 2rem;
    .test-header {
      display: flex;
      justify-content: space-between;
      @media (max-width: 680px) {
        flex-direction: column;
        gap: 2rem;
        align-items: center;
      }

      .test-heading {
        color: ${(props) => props.theme.colors.fontColor.titleFontColor};
        font-weight: bold;
      }
      .save-btn {
        font-weight: bold;
        width: 24%;
        width: 11.2rem;
        min-width: 8.7rem;
        .btn-div {
          height: 2.5rem;
          width: 11.25rem;
        }
      }
    }
    .test-description {
      font-weight: 400;
      padding: 2rem 0rem 1rem 0rem;
      font-style: italic;
      @media (max-width: 680px) {
        text-align: center;
      }
    }
    .form-field {
      width: 70%;
      padding-left: 0.5rem;
      @media (max-width: 835px) {
        width: 100%;
      }
      .label {
        font-weight: bold;
        padding: 1rem 0.5rem;
      }
      .term-field {
        .label {
          font-weight: bold;
          padding: 1rem 0.5rem;
        }
        .select-field-container {
          width: 100%;
          background-color: ${(props) =>
            props.theme.colors.background.inputFieldBackground};
          border-radius: 6rem;
          padding-right: 1rem;
          display: flex;
          align-items: center;
          .test-select-field {
            outline: none;
            border-radius: 6rem;
            height: 2rem;
            background-color: ${(props) =>
              props.theme.colors.background.inputFieldBackground};
            border: ${(props) =>
              props.variant === "error"
                ? "0.0625rem solid"`${props.theme.colors.fontColor.errorFontColor}`
                : "none"};
            color: ${(props) => props.theme.colors.text.fieldText};
            width: 100%;
            min-height: 2.7rem;
            font-weight: bold;
            background-position: 1rem;
            padding: 0rem 1.5rem;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            word-wrap: break-word;
            background: url(${dropDownArrow}) no-repeat;
            background-position: calc(100% - 0.75rem) center;
            -moz-appearance: none;
            -webkit-appearance: none;
            appearance: none;
          }
        }
      }
    }
  }
  .simplebar-scrollbar::before {
    background-color: ${(props) =>
      props.theme.colors.background.primaryCardBackground};
    border-radius: 0.4375rem;
  }

  .simplebar-vertical {
    width: 0.6875rem;
  }
`;
export default TestPopUpStyle;
