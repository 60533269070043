import styled from "styled-components";

const EcoTutorialStyle = styled.div`
  padding: 1.25rem;
  width: 100%;
  display: flex;
  gap: 2rem;
  justify-content: flex-start;
  align-items: flex-start;
  border-radius: 1rem;
  background-color: ${(props) =>
    props.theme.colors.background.defaultBackground};
  @media (max-width: 1140px) {
    flex-direction: column;
  }

  .panelVideoContainer {
    width: 50%;
    @media (max-width: 1140px) {
      width: 100%;
    }
    .videoTitle {
      color: ${(props) => props.theme.colors.fontColor.titleFontColor};
      font-weight: bold;
      margin-bottom: 1.25rem;
      margin-top: 1rem;
    }
    .video-responsive {
      overflow: hidden;
      padding-bottom: 56.25%;
      position: relative;
      height: 0;
    }

    .video-responsive iframe {
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
      position: absolute;
    }
  }

  .stepMainContainer {
    display: flex;
    flex-direction: column;
    width: 50%;
    color: ${(props) => props.theme.colors.background.overlayBackground};
    @media (max-width: 1140px) {
      width: 100%;
    }
    .stepHeader {
      color: ${(props) => props.theme.colors.fontColor.titleFontColor};
      font-weight: bold;
      margin-bottom: 1.25rem;
      margin-top: 1rem;
      @media (max-width: 1140px) {
        margin-bottom: 1.5rem;
      }
    }

    .stepContainer {
      display: flex;
      flex-direction: column;
      margin-bottom: 1.5rem;
      overflow: hidden;
      padding-right: 0.8rem;
      .stepDetails {
        font-weight: bold;
        display: flex;
        gap: 1rem;
        .stepCount {
          min-width: 3rem;
        }
      }
      .stepDescription {
        margin-top: 0.5rem;
      }
    }
    .seeMoreButtonContainer {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      .seeMoreButton {
        display: flex;
        justify-content: center;
        align-items: center;
        color: ${(props) => props.theme.colors.fontColor.titleFontColor};
        font-weight: bold;
        background-color: ${(props) =>
          props.theme.colors.background.inputFieldBackground};
        min-width: 10rem;
        min-height: 3rem;
        border-radius: 4rem;
        margin: 1rem 0rem;
        cursor: pointer;
      }
    }
  }
  .full-width {
    width: 100%;
  }
  .simplebar-scrollbar::before {
    background-color: ${(props) =>
      props.theme.colors.background.primaryCardBackground};
    border-radius: 0.4375rem;
  }

  .simplebar-vertical {
    width: 0.6875rem;
  }
`;
export default EcoTutorialStyle;
