import styled from "styled-components";

const FundPopUpStyle = styled.div`
  width: 100%;
  background-color: ${(props) =>
    props.theme.colors.background.defaultBackground};
  padding: 1.5rem 1rem;
  border-radius: 1.5625rem;
  @media (max-width: 660px) {
    padding: 0rem;
  }
  .investment-popup-container {
    overflow-x: hidden;
    padding: 1rem;
    .investment-popup-inner-container {
      .header-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        @media (max-width: 1310px) {
          flex-direction: column;
          justify-content: center;
          gap: 1rem;
        }
        @media (max-width: 940px) {
          flex-direction: row;
          justify-content: space-between;
        }
        @media (max-width: 660px) {
          flex-direction: column;
          justify-content: center;
        }
        .investment-popup-heading {
          color: ${(props) => props.theme.colors.fontColor.titleFontColor};
          max-height: 2rem !important;
          @media (max-width: 420px) {
            text-align: center;
          }
        }
        .date-field-container {
          padding-left: 0.4rem;
          .date-selector {
          }
          .error-container {
            color: ${(props) => props.theme.colors.fontColor.errorFontColor};
            padding-left: 1rem;
          }
        }
      }
      .investment-details {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        margin-top: 2rem;

        .investment-container {
          background-color: ${(props) =>
            props.theme.colors.background.defaultFieldBackground};
          color: ${(props) => props.theme.colors.fontColor.titleFontColor};
          border-radius: 1.5625rem;
          padding: 0.5rem 1rem;
          display: flex;
          flex-direction: column;
          gap: 0.2rem;
          .investment-title {
            font-weight: bold;
          }
          .ISIN-number {
            font-style: italic;
          }
          .amount-container {
            display: flex;
            justify-content: space-between;
            align-items: center;
            .amount-label {
              font-style: italic;
            }
            .amount-field-container {
              display: flex;
              align-items: center;
              justify-content: center;
              width: calc(100% - 40%);
              max-width: 10rem;
              .amount-field {
                background-color: ${(props) =>
                  props.theme.colors.background.defaultBackground};
                color: ${(props) =>
                  props.theme.colors.fontColor.titleFontColor};
                width: 100%;
                text-align: end;
                font-size: 1rem;
                font-weight: bold;
                border-radius: 6rem;
                padding-right: 1.5rem;
                min-height: 2rem;
                max-width: 10rem;
                display: flex;
                align-items: center;
                justify-content: flex-end;
                @media (max-width: 640px) {
                  font-size: 0.8rem;
                }
              }
            }
          }
        }
        .optimization-investment-container {
          background-color: ${(props) =>
            props.theme.colors.background.defaultFieldBackground};
          border-radius: 1.5625rem;
          padding: 1.5rem;
          display: flex;
          flex-direction: column;
          gap: 0.5rem;
          .withdraw-section {
            width: 100%;
            display: flex;
            flex-direction: column;
            color: ${(props) =>
              props.theme.colors.background.secondaryInfoMessage};
            .withdraw-heading {
            }
            .withdraw-details {
              display: flex;
              padding-left: 1.5rem;
              @media (max-width: 450px) {
                flex-direction: column;
                align-items: start;
                padding-left: 1rem;
                gap: 0.3rem;
              }
              .withdraw-name {
                flex: 1;
                @media (max-width: 450px) {
                  width: 100%;
                  text-align: start;
                  padding-left: 0.5rem;
                }
              }
              .withdraw-isin {
                flex: 2;
                padding-left: 0.3rem;
                @media (max-width: 450px) {
                  flex: 1;
                  width: 100%;
                  text-align: start;
                  padding-left: 0.5rem;
                }
              }
              .withdraw-amount-container {
                flex: 1;
                text-align: end;
                @media (max-width: 450px) {
                  width: 100%;
                  text-align: start;
                }
                .withdraw-amount {
                  padding-right: 1.5rem;
                  @media (max-width: 450px) {
                    padding: 0rem 0.5rem;
                  }
                }
              }
            }
          }
          .incoming-section {
            display: flex;
            flex-direction: column;
            color: ${(props) => props.theme.colors.fontColor.titleFontColor};
            .incoming-details {
              display: flex;
              padding-left: 1.5rem;
              @media (max-width: 450px) {
                flex-direction: column;
                align-items: start;
                padding-left: 1rem;
                gap: 0.3rem;
              }
              .incoming-name {
                flex: 1;
                @media (max-width: 450px) {
                  width: 100%;
                  text-align: start;
                  padding-left: 0.5rem;
                }
              }
              .incoming-isin {
                flex: 2;
                padding-left: 0.3rem;
                @media (max-width: 450px) {
                  flex: 1;
                  width: 100%;
                  text-align: start;
                  padding-left: 0.5rem;
                }
              }
              .incoming-amount {
                flex: 1;
                text-align: end;
                @media (max-width: 450px) {
                  width: 100%;
                  text-align: start;
                }
                .amount-field {
                  background-color: ${(props) =>
                    props.theme.colors.background.defaultBackground};
                  color: ${(props) =>
                    props.theme.colors.fontColor.titleFontColor};
                  width: 100%;
                  text-align: end;
                  font-size: 1rem;
                  font-weight: bold;
                  border-radius: 6rem;
                  padding-right: 1.5rem;
                  min-height: 2rem;
                  max-width: 10rem;
                  display: flex;
                  align-items: center;
                  justify-content: flex-end;
                  @media (max-width: 640px) {
                    font-size: 0.8rem;
                  }
                  @media (max-width: 450px) {
                    justify-content: start;
                    padding: 0rem 0.5rem;
                  }
                }
              }
            }
          }
        }
      }
      .button--container {
        margin-top: 2rem;
        display: flex;
        justify-content: end;
        .button-inner-container {
          max-width: 12rem;
          width: 100%;
        }
        .finalize-button {
          min-height: 3rem;
          width: 100%;
          font-size: 1.3rem;
          @media (max-width: 640px) {
            min-height: 2.5rem;
            font-size: 1rem;
          }
        }
      }
    }
  }
  .simplebar-scrollbar::before {
    background-color: ${(props) =>
      props.theme.colors.background.primaryCardBackground};
    border-radius: 0.4375rem;
  }

  .simplebar-vertical {
    width: 0.6875rem;
  }
`;
export default FundPopUpStyle;
