import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { AlertContext } from "../../../alertContainer";
import Button from "../../../../../components/button/button";
import { forgotPasswordSuperCliente } from "../../forgotPasswordServices";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import RecoverFormStyle from "./recoverFormStyle";
import TextField from "../../../../../components/textField/textField";
import Text from "../../../../../theme/font";

const RecoverForm = () => {
  const { open } = useContext(AlertContext);
  const navigate = useNavigate();

  const validate = Yup.object({
    email: Yup.string()
      .required("Contraseña necesaria")
      .matches(/^\S*$/, "El espacio en blanco no está permitido"),
  });

  const submitHandler = (values) => {
    open({
      alertType: "spinner",
      open: true,
    });
    const emailData = {
      email: values.email,
    };
    forgotPasswordSuperCliente(emailData)
      .then((response) => {
        open({
          alertType: "spinner",
          open: false,
        });
        open({
          type: "success",
          title: "Link enviado con éxito",
          alertType: "toast",
          open: true,
        });
      })
      .catch((error) => {
        open({
          alertType: "spinner",
          open: false,
        });
        open({
          type: "error",
          title: "El correo electrónico no existe",
          alertType: "toast",
          open: true,
        });
      });
  };

  const formValidationHandler = (conditionalValue, resultValue) => {
    let result = null;
    if (conditionalValue) {
      result = resultValue;
    }
    return result;
  };

  const variantHandler = (conditionalValue, resultValue) => {
    let variant = null;
    if (conditionalValue && resultValue) {
      variant = "error";
    } else {
      variant = "outlined";
    }
    return variant;
  };

  return (
    <RecoverFormStyle>
      <Text variant="extraLarge" className="form-heading">
        Recupera tu contraseña
      </Text>
      <Text variant="normal" className="form-description">
        Introduce tu email de Micappital y te enviaremos un
        <br /> link para resetear la contraseña
      </Text>
      <Formik
        initialValues={{ email: "" }}
        onSubmit={(values) => {
          submitHandler(values);
        }}
        validateOnBlur="true"
        validationSchema={validate}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleSubmit,
          submitForm,
        }) => (
          <Form>
            <div className="form-field">
              <MailOutlineIcon className="mail-icon" />
              <TextField
                autoFocused
                name="email"
                type="text"
                placeholder="Correo electrónico"
                autoComplete="new-password"
                handleChange={handleChange}
                value={values.email}
                variant={variantHandler(errors.email, touched.email)}
                error={formValidationHandler(
                  touched.email,
                  Boolean(errors.email)
                )}
                helperText={formValidationHandler(touched.email, errors.email)}
              />
            </div>
            <Text
              variant="smallFont"
              className="description"
              onClick={() => navigate("/")}
            >
              Inicia sesión
            </Text>
            <Text variant="titleFont" className="form-button">
              <Button
                className="send-button"
                variant="outlined"
                value="Enviar"
                click={submitForm}
              />
            </Text>
          </Form>
        )}
      </Formik>
    </RecoverFormStyle>
  );
};

export default RecoverForm;
