import React, { useEffect, useContext, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import "../../../../assets/css/Comun.css";
import { AuthTokenService } from "../../../../services";
import { cargarRecomendacionInicial } from "../proposalServices";
import defaultTheme from "../../../../theme/theme";
import { AlertContext } from "../../../common/alertContainer";
import FundChangePopUp from "./fundChangePopup/fundChangePopup";
import ImageConstants from "../../../../constants/imageConstants/image_constants";
import SummaryActionStyle from "./summaryActionsStyle";
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination } from "swiper";
import { Swiper, SwiperSlide } from 'swiper/react';
import { summaryContent } from "../../../../models/proposalSummary";
import Text from "../../../../theme/font";
import { checkMinimumBalance } from "../../ecoInvestment/ecoInsvestmentServices";

const SummaryActions = React.memo((props) => {
  const theme = defaultTheme;
  const [logo, setLogo] = useState("");
  const [amount, setAmount] = useState(0);
  const [show, setShow] = useState(false);
  const [active, setActive] = useState(true);
  const [videoData, setVideoData] = useState([]);
  const [minimumBalance, setMinimumBalance] = useState("");
  const { open } = useContext(AlertContext);
  const navigate = useNavigate();
  const swiperRef = useRef(null);

  const slideTo = (index) => swiperRef?.current?.swiper?.slideTo(index);

  const slideChangeHandler = (step) => {
    if (step === 1) {
      slideTo(0);
    } else if (step === 2) {
      slideTo(1);
    } else {
      slideTo(2);
    }
  };

  useEffect(() => {
    if (props?.video?.basicInfo) {
      setVideoData(props.video.basicInfo?.enlacevideo);
    }
  }, [props.video]);

  useEffect(() => {
    slideChangeHandler(props.step);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.step]);

  //for changing the steps when click on the button
  const nextStep = (mode) => {
    if (mode === "continuous") {
      props.clickNextStep();
    } else {
      if (active) {
        props.clickNextStep();
        setActive(false);
      }
    }
  };

  const formatter = (value) =>
    new Intl.NumberFormat("de", {
      style: "currency",
      currency: "EUR",
    }).format(value);

  useEffect(() => {
    open({
      alertType: "spinner",
      open: true,
    });
    const userId = AuthTokenService.getUserId();
    cargarRecomendacionInicial(userId)
      .then((result) => {
        open({
          alertType: "spinner",
          open: false,
        });
        if (result.statusText === "OK" && result.status === 200) {
          setLogo(result.data.banco.image);
          setAmount(result.data.importe);
        }
      })
      .catch(() => {
        open({
          alertType: "spinner",
          open: false,
        });
        open({
          type: "error",
          title: "Error al enviar datos",
          alertType: "toast",
          open: true,
        });
      });

    checkMinimumBalance(userId)
      .then((result) => {
        open({
          alertType: "spinner",
          open: false,
        });
        const data = result.data;
        setMinimumBalance(data?.importeMinimo);
      })
      .catch(() => {
        open({
          alertType: "spinner",
          open: false,
        });
        open({
          type: "error",
          title: "Error al enviar datos",
          alertType: "toast",
          open: true,
        });
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //for changing the title of summary according to the steps
  const renderTitle = (paso) => {
    switch (paso) {
      case 1:
        return (
          <div className="tituloResumen float">
            <Text variant="primaryFont" className="normal-title">
              1/3 Analiza tu propuesta
            </Text>
          </div>
        );
      case 2:
        return (
          <div className="tituloResumen float">
            <Text variant="primaryFont" className="normal-title">
              2/3 ¡Compra tus fondos!
            </Text>
          </div>
        );
      case 3:
        return (
          <div className="tituloResumen float">
            <Text variant="primaryFont" className="normal-title">
              3/3 Activa Micappital Connect
            </Text>
          </div>
        );
      default:
        return null;
    }
  };

  //for changing the text inside summary according to the steps
  const renderInitialText = (paso) => {
    switch (paso) {
      case 1:
        return (
          <div className="subtituloResumen">
            <Text variant="fieldFont" className="normal-title">
              Aquí te explicamos los productos que vas a comprar, los lugares en
              los que vas a estar invertido, la rentabilidad histórica de tu
              plan y &nbsp;
              <span className="alertText normal-title">
                te ayudaremos a comprar tu plan de inversión.
              </span>
            </Text>
          </div>
        );
      case 2:
        return (
          <div className="subtituloResumen">
            <Text className="invester-content normal-title" variant="fieldFont">
              El paso más importante y que te convertirá en un
              <span className="alertText" variant="fieldFont">
                &nbsp;inversor de Micappital&nbsp;
              </span>
              es que compres los fondos que te recomendamos. Te hemos hecho un
              vídeo explicativo para ayudarte en el proceso
            </Text>
          </div>
        );
      case 3:
        return (
          <div className="subtituloResumen">
            <Text variant="fieldFont" className="normal-title">
              Activa Micappital Connect para que el seguimiento de tu inversión
              sea&nbsp;
              <span className="alertText normal-title" variant="fieldFont">
                100% automático
              </span>
            </Text>
          </div>
        );
      default:
        return null;
    }
  };

  //for showing the changed amount inside the list
  const showHandler = (value, amount, list) => {
    setShow(value);
    if (amount) {
      setAmount(amount);
    }
    if (list) {
      props.listShow(list, amount);
    }
  };

  //for changing the steps when click on the dots
  const primaryClickHandler = (value) => {
    if (value !== 1 || props.check) {
      props.dotClick(value);
    }
  };

  //for scrolling to the video
  const scrollToVideo = () => {
    props.click();
  };

  const linkClickHandler = (title) => {
    if (title === "Cambiar cantidad") {
      setShow(!show);
    } else if (title === "Ver vídeo") {
      scrollToVideo();
    } else {
      // eslint-disable-next-line no-unused-expressions
      null;
    }
  };

  const swiperHandler = () => {
    return (
      <div className="summary-swiper-inner-container">
        <Swiper
          pagination={{
            dynamicBullets: true,
            clickable: true,
          }}
          modules={[Pagination]}
          className="mySwiper"
          onSlideChange={(e) => primaryClickHandler(e.activeIndex + 1)}
          ref={swiperRef}
        >
          {summaryContent &&
            summaryContent.map((post) => {
              return (
                <SwiperSlide key={Math.random()}>
                  <div className="tituloResumen float">
                    <Text variant="primaryFont" className="responsive-title">
                      {post.title}
                    </Text>
                  </div>
                  <div className="subtituloResumen">
                    <Text className="responsive-title" variant="fieldFont">
                      {post.content}
                    </Text>
                  </div>
                  <Text
                    variant="fieldFont"
                    className="cambiarText"
                    onClick={() => linkClickHandler(post.linkTitle)}
                  >
                    {videoData ? post.linkTitle : post.newLinkTitle}
                  </Text>
                </SwiperSlide>
              );
            })}
        </Swiper>
      </div>
    );
  };

  const renderhandler = () => {
    return (
      <div className="panelResumenContainer box-container">
        <div className="swiper-summary-container">{swiperHandler()}</div>
        <div className="content-container">
          {show && (
            <FundChangePopUp
              click={showHandler}
              minimumAmount={minimumBalance}
            />
          )}
          <div className="float">{renderTitle(props.step && props.step)}</div>
          <div className="float">
            {renderInitialText(props.step && props.step)}
          </div>
          <div className="responsive-investment-container">
            <div className="investment-details-container">
              <div className="fund-details">
                <img
                  className="circle-icon"
                  src={ImageConstants.walletIcon}
                  alt="x"
                />
                <div className="fund-info">
                  <div className="fund-title">
                    <Text variant="normal">Vas a invertir</Text>
                  </div>
                  <div className="fund-count">
                    <Text variant="titleFont" className="fund-field">
                      {formatter(amount)}
                    </Text>
                    <span
                      onClick={() => {
                        setShow(!show);
                      }}
                      className="cambiarText"
                    >
                      Cambiar
                    </span>
                  </div>
                </div>
              </div>
              <div className="bank-details">
                <img
                  className="circle-icon"
                  src={ImageConstants.bankIcon}
                  alt="x"
                />
                <div className="bank-info">
                  <div className="bank-title">
                    <Text variant="normal"> Desde tu banco</Text>
                  </div>
                  <div className="bank-img">
                    <img
                      className="avatarImgResumen selected"
                      src={logo}
                      alt="logo"
                    ></img>
                  </div>
                </div>
              </div>
              <div className="help-details">
                <img
                  className="circle-icon"
                  src={ImageConstants.videoIcon}
                  alt="x"
                />
                <div className="help-info">
                  <div className="help-title">
                    <Text variant="normal">¿Cómo realizo mi inversión?</Text>
                  </div>
                  <div className="help-tutorial">
                    <Text variant="normal" onClick={scrollToVideo}>
                      {videoData ? "Ver vídeo tutorial" : "Ver receta tutorial"}
                    </Text>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row inforow button-div">
          <div
            className="red-button redbtn-div"
            onClick={() => nextStep("continuous")}
          >
            <Text variant="labelFont">Siguiente paso</Text>
          </div>
        </div>
        {props.step === 3 && (
          <Text className="skip-button" onClick={() => navigate("/resumen")}>
            Conectar más adelante
          </Text>
        )}
      </div>
    );
  };
  return (
    <ThemeProvider theme={theme}>
      <SummaryActionStyle>{renderhandler()}</SummaryActionStyle>
    </ThemeProvider>
  );
});

export default SummaryActions;
