import styled from "styled-components";

const GraphObjectivesStyle = styled.div`
  width: 100%;
  .graph-objective-container {
    width: 100%;
    .objectiveContainer {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      gap: 1.5rem;
      margin-top: 2rem;
      .objectiveCard {
        max-width: 16rem;
      }
    }
  }
  .graficoObjetivosContainer {
    padding: 1.875rem 1.125rem;
  }
  .header-container {
    display: flex;
    align-items: center;
    padding-bottom: 1.3rem;
    justify-content: space-between;
    flex-wrap: wrap;
    @media (max-width: 545px) {
      justify-content: center;
      gap: 1rem;
    }
  }
  .tituloGraficoObjetivos {
    font-weight: bold;
    color: ${(props) => props.theme.colors.fontColor.titleFontColor};
    padding-right: 1rem;
  }
  .top-text-container {
    display: flex;
    background-color: ${(props) =>
      props.theme.colors.background.objectiveBackground};
    padding: 0.3rem;
    border-radius: 0.5rem;
    align-items: center;
  }
  .walletImg {
    width: 2rem;
    margin-right: 1rem;
  }
  .top-text {
    color: ${(props) => props.theme.colors.fontColor.titleFontColor};
    margin-right: 1rem;
    font-weight: 500;
    @media (max-width: 331px) {
      margin-right: 0.5rem;
    }
  }
  .graficoObjetivosDatosContainer {
    height: 18.75rem;
    position: relative;
    .alert-container {
      display: flex;
      justify-content: center;
      width: 100%;
      position: absolute;
      top: 6rem;
      .alert-message {
        color: ${(props) => props.theme.colors.fontColor.titleFontColor};
        background-color: ${(props) =>
          props.theme.colors.button.background.navabarButtonBackground};
        padding: 1rem;
        border-radius: 1rem;
        font-weight: 500;
        max-width: 24rem;
        text-align: center;
        z-index: 1;
      }
    }
  }
  .total-objectives {
    display: flex;
    flex-wrap: wrap;
  }
`;
export default GraphObjectivesStyle;
