import { ThemeProvider } from "styled-components";
import AdviceSectionStyle from "./adviceSectionStyle";
import defaultTheme from "../../../../theme/theme";
import { optionsData} from "../../../../models/defaultPageArray"
import Text from "../../../../theme/font";

const AdviceSection = () => {
  const theme = defaultTheme;
  return (
    <ThemeProvider theme={theme}>
      <AdviceSectionStyle>
        <Text variant="titleFont" className="advice-container-heading">
          Invierte en lo que realmente importa.
          <br /> Utiliza tus ahorros para mejorar el mundo.
        </Text>
        <div className="investment-advice-container">
          {optionsData && optionsData.map((data) => {
            return (
              <div className="advice-options-container" key={Math.random()}>
                <div className="option-icon">
                  <img alt="x" src={data.src} />
                </div>
                <Text
                  variant="labelFont"
                  type="primary"
                  className="option-title"
                >
                  {data.title}
                </Text>
                <Text
                  variant="smallerText"
                  type="primary"
                  className="option-description"
                >
                  {data.description}
                </Text>
              </div>
            );
          })}
        </div>
        <Text variant="smallerText" type="primary" className="advice-container-description">
          Recuerda que estás invirtiendo, no donando. Como toda inversión,
          buscamos simpre generar rentabilidad, pero de manera sostenible.
        </Text>
      </AdviceSectionStyle>
    </ThemeProvider>
  );
};
export default AdviceSection;
