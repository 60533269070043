import styled from "styled-components";

const SettingsOptionsStyle = styled.div`
  width: 100%;
  height: 100%;
  @media (max-width: 1200px) {
    width: 11.87rem;
  }
  .card {
    width: 100%;
    height: 100%;
    background-color: ${(props) =>
      props.theme.colors.background.cardBackground};
    border: none;
    min-height: 16rem;
    display: flex;
    justify-content: flex-start;
    border-radius: 3.125rem;
    cursor: pointer;
    position: relative;
    padding-top: 1rem;
    .card-icon {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 1rem 0rem;
      img {
        height: 4.37rem;
      }
    }
    .title {
      font-weight: bold;
      text-align: center;
    }
    .card-content {
      font-style: italic;
      font-weight: 300;
      text-align: center;
      padding: 1rem 1rem;
      .banks-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        .bank-icon {
          height: 2.62rem;
        }
      }
    }
    .fill-icon {
      position: absolute;
      top: -0.3rem;
      right: 0rem;
      .tick-icon {
        height: 2.62rem;
      }
      .oval-icon {
        height: 3rem;
      }
    }
  }
`;

export default SettingsOptionsStyle;
