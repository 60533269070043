import { Request } from "../../../services";
import URL_CONSTANTS from "../../../constants/urlConstants/urlConstants";

export const getControlPanelData = (data) => {
  return Request.get(`${URL_CONSTANTS.get_control_panel_data}/${data}`);
};

export const getImpactSectorData = (data) => {
  return Request.get(`${URL_CONSTANTS.get_impact_sector_data}/${data}`);
};

export const getInvestedDetails = (data) => {
  return Request.get(`${URL_CONSTANTS.get_invested_details}/${data}`);
};

