import styled from "styled-components";

const ProcessSectionStyle = styled.div`
  padding: 1rem;
  .process-section-heading {
    color: ${(props) => props.theme.colors.fontColor.titleFontColor};
    font-weight: bold;
    margin-bottom: 1rem;
    @media (max-width: 540px) {
      text-align: center;
    }
  }
  .process-section-description {
    padding-left: 1rem;
    color: ${(props) => props.theme.colors.fontColor.objectiveFontColor};

    @media (max-width: 1040px) {
      padding-left: 0rem;
    }
    @media (max-width: 540px) {
      text-align: center;
    }
  }
  .call-button-container {
    margin-top: 1rem;
    .download-button {
      max-width: 10rem;
      font-weight: bold;
      .button-value {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        .call-icon {
          height: 1.4rem;
        }
      }
    }
  }
`;
export default ProcessSectionStyle;
