import { Request } from "../../../services";
import URL_CONSTANTS from "../../../constants/urlConstants/urlConstants";

export const forgotPassword = (data) => {
  return Request.post(URL_CONSTANTS.forgot_password, data);
};

export const resetPassword = (data) => {
  return Request.post(URL_CONSTANTS.reset_password, data);
};

export const checkLink = (data) => {
  return Request.post(URL_CONSTANTS.check_link, data);
};

export const forgotPasswordSuperCliente = (data) => {
  return Request.post(URL_CONSTANTS.forgot_password_superCliente, data);
};

export const resetPasswordSuperCliente = (data) => {
  return Request.post(URL_CONSTANTS.reset_password_superCliente, data);
};
