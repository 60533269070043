import styled from "styled-components";

const AlertMessageStyle = styled.div`
  .message-section {
    margin-bottom: 1rem;
    .message {
      display: flex;
      align-items: center;
      margin-top: 0.5rem;
      cursor: pointer;
      .alert-message-red {
        width: 100%;
        padding: 1rem;
        font-weight: 400;
        color: ${(props) => props.theme.colors.text.redAlert};
      }
      .alert-message-yelow {
        width: 100%;
        padding: 1rem;
        font-weight: 400;
        color: ${(props) => props.theme.colors.text.yelowAlert};
      }
      .message-close-icon-red {
        stroke: ${(props) => props.theme.colors.text.redAlert};
        stroke-width: 0.125rem;
        height: 1rem;
        cursor: pointer;
      }
      .message-close-icon-yelow {
        stroke: ${(props) => props.theme.colors.text.yelowAlert};
        stroke-width: 0.125rem;
        height: 1rem;
        cursor: pointer;
      }
    }
    .red-container {
      background-color: ${(props) =>
        props.theme.colors.background.secondaryInfoMessage};
      color: ${(props) =>
        props.theme.colors.text.redAlert};
      border-radius: 0.93rem;
    }
    .yellow-container {
      background-color: ${(props) =>
        props.theme.colors.background.teritiaryInfoMessage};
      color: ${(props) =>
        props.theme.colors.text.yelowAlert};
      border-radius: 0.93rem;
    }
  }
`;
export default AlertMessageStyle;
