import styled from "styled-components";

const CommissionPopUpStyle = styled.div`
  .commission-popup-container {
    width: 100%;
    background-color: ${(props) =>
      props.theme.colors.background.defaultBackground};
    padding: 2rem;
    border-radius: 1.5625rem;
    @media (max-width: 460px) {
      padding: 1rem;
    }
    .content-section {
      color: ${(props) => props.theme.colors.fontColor.titleFontColor};
      display: flex;
      flex-direction: column;
      gap: 0.6rem;
      .popup-heading {
        margin-bottom: 1.5rem;
        @media (max-width: 420px) {
          text-align: center;
        }
      }
    }
    .commission-details-section {
      color: ${(props) => props.theme.colors.fontColor.titleFontColor};
      margin-top: 2rem;
      display: flex;
      flex-direction: column;
      gap: 1rem;
      .commission-details-container {
        background-color: ${(props) =>
          props.theme.colors.background.defaultFieldBackground};
        display: flex;
        flex-direction: column;
        padding: 1rem;
        gap: 0.5rem;
        border-radius: 1.5rem;
        .won-commission {
          display: flex;
          justify-content: space-between;
          font-weight: bold;
          align-items: center;
          .won-commission-title {
            padding-right: 0.4rem;
          }
          .won-commission-amount {
          }
        }
        .commission {
          display: flex;
          justify-content: space-between;
          align-items: center;
          .commission-details {
            display: flex;

            width: calc(100% - 50%);
            max-width: 18.75rem;
            align-items: center;
            .commission-title {
              font-style: italic;
              flex: 1;
              padding-right: 0.4rem;
            }
            .commission-percentage {
            }
          }
          .commission-amount {
            width: calc(100% - 50%);
            text-align: end;
            @media (max-width: 450px) {
              margin-left: 0.5%;
            }
          }
        }
      }
    }
    .important-text-commission
    {
      font-weight:bold;
    }
    .total-commission-section {
      display: flex;
      justify-content: space-between;
      font-weight: bold;
      color: ${(props) => props.theme.colors.fontColor.titleFontColor};
      padding: 1rem;
      border-top: solid 0.0625rem
        ${(props) => props.theme.colors.background.outlineBorderColor};
      margin-top: 2rem;
    }
  }
`;
export default CommissionPopUpStyle;
