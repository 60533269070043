import React from "react";
import { PopupWidget } from "react-calendly";
import { ThemeProvider } from "styled-components";
import "../../../../assets/css/Comun.css";
import defaultTheme from "../../../../theme/theme";
import DoubtsStyle from "./doubtsStyle";
import ImageConstants from "../../../../constants/imageConstants/image_constants";
import Text from "../../../../theme/font";
import URL_CONSTANTS from "../../../../constants/urlConstants/urlConstants";

const Doubts = React.memo(({ props }) => {
  const theme = defaultTheme;
  const openWhatsapp = () => {
    window.open(URL_CONSTANTS.open_whatsapp, "_blank");
  };
  const openCalendly = () => {
    let url = URL_CONSTANTS.open_calendly;
    let prefill = "";
    let pageSettings = "";
    let utm = "";
    PopupWidget({ url, prefill, pageSettings, utm });
  };

  return (
    <ThemeProvider theme={theme}>
      <DoubtsStyle>
        <div className="row container-profitability padding-container">
          <div className="col-lg-12 col-12">
            <div className="row padding-container doubts-container">
              <div className="col-lg-6 col-12 text-center doubts-flex-container">
                <img
                  className="contact-image"
                  src={ImageConstants.whatsapp}
                  alt="x"
                ></img>
                <div className="float doubts-text asapText">
                  <Text variant="primaryFont">Mándanos un whatsapp</Text>
                </div>
                <div className="float doubts-small-text asapText">
                  <Text variant="normal">
                    De lunes a viernes de 08:30 a 20:00.
                  </Text>
                </div>
                <div className="float doubts-small-text phone ">
                  <Text variant="primaryFont">+ 34 693 039 289</Text>
                </div>
                <div className="button-container-doubts float">
                  <div
                    onClick={openWhatsapp}
                    className=" contact-button whatsapp "
                  >
                    Enviar Whatsapp
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-12 text-center doubts-flex-container">
                <img
                  className="contact-image"
                  src={ImageConstants.telefono}
                  alt="x"
                ></img>
                <div className="float doubts-text asapText">
                  <Text variant="primaryFont">¿Prefieres que te llamemos?</Text>
                </div>
                <div className="float doubts-small-text asapText">
                  <Text variant="normal">
                    Un asesor se pondrá en contacto contigo en la fecha y hora
                    acordadas para resolver tus dudas.
                  </Text>
                </div>
                <div className="button-container-doubts float">
                  <div
                    onClick={openCalendly}
                    className="contact-button llamada"
                  >
                    Ver disponibilidad
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </DoubtsStyle>
    </ThemeProvider>
  );
});

export default Doubts;
