import styled from "styled-components";
import defaultTheme from "../../theme/theme";
const theme = defaultTheme;

const InputText = styled.div`
  position: relative;
  input[type="number"]::-webkit-outer-spin-button,
  input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type="number"] {
    -moz-appearance: textfield;
  }
  input[type="date"]::-webkit-inner-spin-button,
  input[type="date"]::-webkit-calendar-picker-indicator {
    display: none;
    -webkit-appearance: none;
  }
  .input-field {
    outline: none;
    border-radius: 6rem;
    height: 2rem;
    background-color: ${(props) =>
      props.theme.colors.background.inputFieldBackground};
    border: ${(props) =>
      props.variant === "error"
        ? `1px solid ${props.theme.colors.fontColor.errorFontColor}`
        : "none"};
    color: ${(props) => props.theme.colors.text.fieldText};
    width: 100%;
    padding-left: 3.5rem;
    min-height: 2.7rem;
    font-weight: bold;
    ::-ms-reveal,
    ::-ms-clear {
      display: none;
    }
    ::placeholder {
      color: ${(props) => props.theme.colors.text.fieldText};
    }
  }
  .error-val {
    color: red;
    position: absolute;
    padding-left: 1.5rem;
  }
  .right-icon {
    position: absolute;
    top: 0.6rem;
    right: 1rem;
    color: ${(props) => props.theme.colors.icon.eyeIcon};
    cursor: pointer;
    @media (max-width: 768px) {
      top: 0.4rem;
    }
    img {
      height: 1.12rem;
    }
  }

  ${(props, form) => {
    // eslint-disable-next-line default-case
    switch (props.form) {
      case "primary":
        return `
        .input-field{
          outline: none;
    border-radius: 6rem;
    height: 2rem;
    background-color: ${theme.colors.background.inputFieldBackground};
    border: ${(props) =>
      props.variant === "error"
        ? `1px solid ${props.theme.colors.fontColor.errorFontColor}`
        : "none"};
      color: ${(props) => props.theme.colors.fontColor.primaryTextFieldColor};
    width: 100%;
    padding-left: 1.5rem;
    min-height: 2.7rem;
    font-weight: 400;
    ::-ms-reveal,
    ::-ms-clear {
      display: none;
    }
    ::placeholder {
      color: ${(props) => props.theme.colors.fontColor.primaryTextFieldColor};
    }
  }
        `;
      case "secondary":
        return `
          .input-field{
          outline: none;
    border-radius: 6rem;
    height: 2rem;
    background-color:  ${(props) =>
      props.theme.colors.fontColor.objectiveFontOpec};
    background-color:  ${(props) =>
      props.theme.colors.fontColor.objectiveFontOpec};
    border: ${(props) =>
      props.variant === "error"
        ? `1px solid ${props.theme.colors.fontColor.errorFontColor}`
        : "none"};
      color:${(props) => props.theme.colors.fontColor.investmentAmountFont};
      font-weight: bold;
    width: 100%;
    padding-left: 1.5rem;
    min-height: 2.7rem;
    font-weight: 400;
    ::-ms-reveal,
    ::-ms-clear {
      display: none;
    }
    ::placeholder {
      color: ${(props) => props.theme.colors.fontColor.investmentAmountFont}
      font-weight:bold;
    }
  }
    `;
      case "insurance-field":
        return `
        input[type=textarea] {
          ::-webkit-input-placeholder {     
            white-space:pre-line;     
  position:relative;
  top:-9px;
  @media(max-width:768px){
    position:static;
  }
  @media(max-width:345px){
    position:relative;
  }
}
    }
          .input-field{
            outline: none;
    background-color: ${theme.colors.background.defaultBackground};
    color: ${theme.colors.background.outlineBorderColor};
    padding-left:1rem;
    font-weight:400;
    ::-ms-reveal,
    ::-ms-clear {
      display: none;
    }
    ::placeholder {
      color: ${theme.colors.background.outlineBorderColor};
      font-weight:400;
    }
          }
          input:-internal-autofill-selected{
            background-color: ${theme.colors.background.defaultBackground} !important;

          }
      `;
    }
  }}
`;
export default InputText;
