import React from "react";
import InputText from "./textfieldStyle";
import Text from "../../theme/font";
import ImageConstants from "../../constants/imageConstants/image_constants";
import { useState } from "react";

const TextField = React.memo(
  ({
    type,
    placeholder,
    variant,
    handleChange,
    value,
    autoComplete,
    name,
    error,
    helperText,
    style,
    rightIcon,
    id,
    form,
    min,
    max,
    onKeyDown,
    autoFocused,
    onFocus,
    onBlur,
  }) => {
    const [inputType, setInputType] = useState(type);
    const [passwordVisible, setPasswordVisible] = useState(
      <img
        className="eyeClosed-img"
        src={ImageConstants.eyeClosedIcon}
        alt="X"
        onClick={() => rightIconClick("password")}
      />
    );

    const rightIconClick = (value) => {
      if (value === "password") {
        setInputType("text");
        setPasswordVisible(
          <img
            className="eyeOpen-img"
            src={ImageConstants.eyeOpenIcon}
            alt="X"
            onClick={() => rightIconClick("text")}
          />
        );
      } else {
        setInputType("password");
        setPasswordVisible(
          <img
            className="eyeClosed-img"
            src={ImageConstants.eyeClosedIcon}
            alt="X"
            onClick={() => rightIconClick("password")}
          />
        );
      }
    };

    const rightIconHnadler = () => {
      let icon = null;
      if (rightIcon) {
        icon = <div className="right-icon">{rightIcon}</div>;
      }
      if (!rightIcon && type === "password") {
        icon = <div className="right-icon">{passwordVisible}</div>;
      }
      return icon;
    };

    // const variantHandler = (text) => {
    //   const placeHolderText = text.length;
    //   if (placeHolderText > 16) {
    //     return "smallFont";
    //   } else {
    //     return "normal";
    //   }
    // };

    return (
      <Text variant="normal">
        <InputText variant={variant} form={form}>
          {rightIconHnadler()}
          <input
            className="input-field"
            type={inputType}
            placeholder={placeholder}
            onChange={handleChange}
            autoComplete={autoComplete}
            name={name}
            style={style}
            id={id}
            min={min}
            onWheel={(event) => event.currentTarget.blur()}
            max={max}
            onKeyDown={onKeyDown}
            autoFocus={autoFocused}
            onFocus={onFocus}
            onBlur={onBlur}
            value={value}
          />
          {error && (
            <Text variant="extraSmall">
              <div className="error-val">{helperText}</div>
            </Text>
          )}
        </InputText>
      </Text>
    );
  }
);
export default TextField;
