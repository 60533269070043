import styled from "styled-components";

const ImpactControlPanelStyle = styled.div`
  position: fixed;
  width: 30%;
  @media (max-width: 1300px) {
    width:29%;
  }
  @media (max-width: 1040px) {
    width: 100%;
    position: relative;
  }
  .impact-control-panel-container {
    padding: 2rem;
    min-width: 17.75rem;
    .impact-control-panel-title {
      padding-bottom: 2rem;
      font-weight: bold;
      color: ${(props) => props.theme.colors.fontColor.titleFontColor};
      @media (max-width: 620px) {
        text-align: center;
      }
    }
    .impact-control-panel-description {
      @media (max-width: 620px) {
        text-align: center;
      }
      span {
        color: ${(props) => props.theme.colors.fontColor.titleFontColor};
        font-weight: bold;
      }
    }
    .fund-evolution-container {
      padding: 2rem 0;
      gap: 0.5rem;
      display: flex;
      flex-direction: column;
      .fund-evolution-list {
        width: 100%;
        background-color: ${(props) =>
          props.theme.colors.background.defaultFieldBackground};
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 0.62rem 1rem;
        border-radius: 1rem;
        .fund-evolution-title {
          padding-right: 1rem;
          color: ${(props) => props.theme.colors.fontColor.titleFontColor};
        }
        .fund-evolution-values {
          color: ${(props) => props.theme.colors.fontColor.titleFontColor};
          font-weight: 700;
          word-break: break-word;
        }
      }
    }
    .fund-evolution-details {
      display: flex;
      justify-content: space-around;
      gap: 1rem;
      .eco-data {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 0.5rem;
        img {
          width: 2rem;
        }
        .eco-value {
          color: ${(props) => props.theme.colors.fontColor.titleFontColor};
        }
      }
    }
    .button-container {
      margin-top: 1.5rem;
      .see-impact-button {
        width: 100%;
        background-color: ${(props) =>
          props.theme.colors.fontColor.titleFontColor};
        max-height: 3rem;
      }
    }
  }
`;
export default ImpactControlPanelStyle;
