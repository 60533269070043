import ImageConstants from "../constants/imageConstants/image_constants";

const selectGoalArray = [
  {
    title: "coche",
    src: ImageConstants.Coche,
  },
  {
    title: "casa",
    src: ImageConstants.Casa,
  },
  {
    title: "hijos",
    src: ImageConstants.Hijos,
  },
  {
    title: "viaje",
    src: ImageConstants.Viaje,
  },
  {
    title: "ahorro",
    src: ImageConstants.Ahorro,
  },
  {
    title: "universidad",
    src: ImageConstants.Universidad,
  },
];
export default selectGoalArray;

export const monthArray = [
  {
    id: "0",
    label: "Mes",
    value: "00",
  },
  {
    id: "1",
    label: "enero",
    value: "01",
  },
  {
    id: "2",
    label: "febrero",
    value: "02",
  },
  {
    id: "3",
    label: "marzo",
    value: "03",
  },
  {
    id: "4",
    label: "abril ",
    value: "04",
  },
  {
    id: "5",
    label: "mayo",
    value: "05",
  },
  {
    id: "6",
    label: "junio",
    value: "06",
  },
  {
    id: "7",
    label: "julio",
    value: "07",
  },
  {
    id: "8",
    label: "agosto",
    value: "08",
  },
  {
    id: "9",
    label: "septiembre",
    value: "09",
  },
  {
    id: "10",
    label: "octubre",
    value: "10",
  },
  {
    id: "11",
    label: "noviembre",
    value: "11",
  },
  {
    id: "12",
    label: "diciembre",
    value: "12",
  },
];

export const yearArray = [
  {
    label: "Año",
    value: "00",
  },
  { label: "2020", value: "2020" },
  { label: "2021", value: "2021" },
  { label: "2022", value: "2022" },
  { label: "2023", value: "2023" },
  { label: "2024", value: "2024" },
  { label: "2025", value: "2025" },
  { label: "2026", value: "2026" },
  { label: "2027", value: "2027" },
  { label: "2028", value: "2028" },
  { label: "2029", value: "2029" },
  { label: "2030", value: "2030" },
  { label: "2031", value: "2031" },
  { label: "2032", value: "2032" },
  { label: "2033", value: "2033" },
  { label: "2034", value: "2034" },
  { label: "2035", value: "2035" },
  { label: "2036", value: "2036" },
  { label: "2037", value: "2037" },
  { label: "2038", value: "2038" },
  { label: "2039", value: "2039" },
  { label: "2040", value: "2040" },
  { label: "2041", value: "2041" },
  { label: "2042", value: "2042" },
];
