/* eslint-disable array-callback-return */
import React, { useEffect, useRef, useState } from "react";
import { Pagination, Navigation } from "swiper";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import defaultTheme from "../../../../../theme/theme";
import ImageConstants from "../../../../../constants/imageConstants/image_constants";
import ImpactSectorSwiperStyle from "./impactSectorSwiperStyle";
import { iterationvalues } from "../../../../../models/ecoSectionArray";
import { ThemeProvider } from "styled-components";

const Breakpoint = {
  // when window width is >= 320px
  320: {
    slidesPerView: 1,
    spaceBetween: 20,
  },
  // when window width is >= 360px
  390: {
    slidesPerView: 2,
    spaceBetween: 20,
  },
  // when window width is >= 450px
  480: {
    slidesPerView: 3,
    spaceBetween: 20,
  },
  // when window width is >= 620px
  620: {
    slidesPerView: 4,
    spaceBetween: 10,
  },
  // when window width is >= 850px
  850: {
    slidesPerView: 6,
    spaceBetween: 10,
  },
  // when window width is >= 1041px
  1041: {
    slidesPerView: 3,
    spaceBetween: 10,
  },
  // when window width is >= 1100px
  1100: {
    slidesPerView: 4,
    spaceBetween: 10,
  },
  // when window width is >= 1350px
  1350: {
    slidesPerView: 6,
    spaceBetween: 0,
  },
};

const useSwiperRef = () => {
  const ref = useRef(null);
  const wrapper = ref.current;

  return [wrapper, ref];
};

const ImpactSectorSwiper = ({ click, data }) => {
  const theme = defaultTheme;
  const [firstClick, setFirstClick] = useState(false);
  const [nextEl, nextElRef] = useSwiperRef();
  const [prevEl, prevElRef] = useSwiperRef();
  const [swiperData, setSwiperData] = useState([]);

  let iteratedTitle = iterationvalues;

  const pagination = {
    clickable: true,
  };

  const clickHandler = (id, title) => {
    if (firstClick) {
      if (iteratedTitle[title] === true) {
        let keys = Object.keys(iteratedTitle);
        let count = 0;

        keys.map((data) => {
          if (iteratedTitle[data] === true) {
            count = count + 1;
          }
        });
        if (count > 1) {
          iteratedTitle[title] = false;
        } else {
          keys.map((value) => {
            if (value !== title) {
              iteratedTitle[value] = true;
            }
          });
          setFirstClick(false);
        }
      } else {
        iteratedTitle[title] = true;
      }
    } else {
      const keys = Object.keys(iteratedTitle);
      keys.map((value) => {
        if (value !== title) {
          iteratedTitle[value] = false;
        }
      });
      setFirstClick(true);
    }
    click(id);
  };

  useEffect(() => {
    setSwiperData([...data]);
  }, [data]);

  return (
    <ThemeProvider theme={theme}>
      <ImpactSectorSwiperStyle>
        <img
          className="arrow-icon arrow-icon-left"
          src={ImageConstants.leftArrow}
          ref={prevElRef}
          alt="x"
        />
        <Swiper
          pagination={pagination}
          // loop= {true}
          modules={[Navigation, Pagination]}
          className="mySwiper"
          spaceBetween={0}
          breakpoints={Breakpoint}
          navigation={{
            prevEl,
            nextEl,
          }}
        >
          {swiperData &&
            swiperData.map((post) => {
              return (
                <SwiperSlide className="swiper-img" key={Math.random()}>
                  <div
                    className={
                      iteratedTitle[post.titulo] === true
                        ? "icon-container"
                        : "icon-container-not"
                    }
                    style={{ backgroundColor: post.color }}
                  >
                    <img
                      src={post.icono}
                      alt="x"
                      className={
                        iteratedTitle[post.titulo] === true
                          ? "swiper-icon icon-selected"
                          : "swiper-icon not-selected"
                      }
                      onClick={() => clickHandler(post.idOds, post.titulo)}
                    />
                  </div>
                </SwiperSlide>
              );
            })}
        </Swiper>

        <img
          alt="x"
          className="arrow-icon arrow-icon-right"
          src={ImageConstants.rightArrow}
          ref={nextElRef}
        />
      </ImpactSectorSwiperStyle>
    </ThemeProvider>
  );
};

export default ImpactSectorSwiper;
