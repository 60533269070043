import styled from "styled-components";

const AccountStyle = styled.div`
  padding-right: 2rem;
  @media (max-width: 1040px) {
    padding-right: 0rem;
  }
  
  //popup style start//
  .popUp-wrapper {
    position: fixed;
    z-index: 150;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    .popUp-overlay {
      width: 100%;
      height: 100%;
      background-color: ${(props) =>
        props.theme.colors.background.overlayBackground};
      opacity: 0.5;
    }
    .popUp-container {
      position: absolute;
      width: calc(100% - 33%);
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      height: auto;
      max-height: 100%;
      overflow: hidden;
      max-width: 58.12rem;
      border-radius: 1.5625rem;
      @media (max-width: 680px) {
        width: 85%;
      }
    }
  }
  .simplebar-scrollbar::before {
    background-color: ${(props) =>
      props.theme.colors.background.primaryCardBackground};
    border-radius: 0.4375rem;
  }

  .simplebar-vertical {
    width: 0.6875rem;
  }
  //popup style end//

  .content-box {
    display: flex;
    gap: 2%;
    width: 100%;
    padding: 1rem 0rem;
    @media (max-width: 1040px) {
      flex-direction: column;
      flex-flow: column-reverse;
      align-items: center;
      gap: 2rem;
      padding: 1rem 0 2rem 0;
    }
    .grid-box-wrapper {
      width: calc(100% - 36%);
      @media (max-width: 1040px) {
        width: 100%;
        display: flex;
        justify-content: center;
      }
      .grid-box {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(3, minmax(6.25rem, 1fr));
        grid-gap: 2.5rem;
        text-align: -webkit-center;
        width: 100%;
        justify-items: center;
        padding: 2rem 3rem;
        @media (max-width: 1410px) {
          grid-template-columns: repeat(2, minmax(6.25rem, 1fr));
        }
        @media (max-width: 1170px) {
          grid-template-columns: repeat(1, minmax(6.25rem, 1fr));
          padding: 2rem 0rem;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        }
        @media (max-width: 1040px) {
          max-width: 28.62rem;
        }
        @media (max-width: 500px) {
          padding: 1rem 1rem;
          min-width: 17.5rem;
        }
        @media (min-width: 1700px) {
          grid-template-columns: repeat(4, minmax(6.25rem, 1fr));
        }
        @media (min-width: 2100px) {
          grid-template-columns: repeat(4, minmax(6.25rem, 1fr));
          align-items: center;
        }
        @media (min-width: 2500px) {
          grid-template-columns: repeat(5, minmax(6.25rem, 1fr));
          align-items: center;
        }
      }
    }
    .profile-box-wrapper {
      width: calc(100% - 65%);
      @media (max-width: 1040px) {
        width: 100%;
        display: flex;
        justify-content: center;
      }
    }
  }
`;
export default AccountStyle;
