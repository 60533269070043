import React from "react";
import "../../../../../assets/css/Dudas.css";
import "../../../../../assets/css/Comun.css";
import "../../../../../assets/css/Video.css";

const VideoChanges = () => {
  var embedId = "Uq7cUyMpOC4";
  return (
    <div className="panelVideoContainer sombreado">
      <div className="row">
        <div className="col-lg-12 col-12">
          <div className="float titlechart asapText float">
            ¿Cómo realizar cambios en tu entidad bancaria?
          </div>
        </div>
      </div>
      <div className="row">
        <div className="video-responsive">
          <iframe
            width="853"
            height="480"
            src={`https://www.youtube.com/embed/${embedId}`}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title="Embedded youtube"
          />
        </div>
      </div>
    </div>
  );
};

export default VideoChanges;
