import styled from "styled-components";

const AddbankPopUpStyle = styled.div`
  height: 31.25rem;
  overflow: hidden;
  border-radius: 1.56rem;
  .add-bank-container {
    width: 100%;
    height: 100%;
    min-height: 31.25rem;
    background-color: ${(props) =>
      props.theme.colors.background.defaultBackground};
    padding: 2rem;
    border-radius: 1.56rem;
    .add-bank-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 2rem;
      @media (max-width: 600px) {
        flex-direction: column;
        gap: 2rem;
        align-items: center;
      }
      .add-bank-heading {
        color: ${(props) => props.theme.colors.fontColor.titleFontColor};
        font-weight: bold;
      }
      .button-container {
        .save-button {
          height: 2.5rem;
          width: 11.25rem;
        }
      }
    }
    .condition-container {
      padding-left: 2rem;
      @media (max-width: 740px) {
        padding-left: 0;
      }
      @media (max-width: 600px) {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
      .condition-description {
        font-weight: bold;
        padding-bottom: 1rem;
        @media (max-width: 740px) {
          text-align: center;
        }
      }
      .condition-buttons {
        display: flex;
        width: fit-content;
        gap: 1rem;
        @media (max-width: 740px) {
          width: 100%;
          justify-content: center;
          flex-direction: column;
        }
        .yes-button {
          height: 2.4rem;
          width: 11.25rem;
          background-color: ${(props) =>
            props.theme.colors.background.secondaryLoadingColor};
        }
        .selected-button {
          border: 0.312rem solid
            ${(props) => props.theme.colors.fontColor.titleFontColor};
        }
        .no-button {
          height: 2.4rem;
          width: 11.25rem;
          background-color: ${(props) =>
            props.theme.colors.background.primaryCardBackground};
        }
      }
    }
    .select-bank-container {
      padding-top: 1rem;
      padding-left: 2rem;
      @media (max-width: 740px) {
        padding-left: 0;
      }
      .select-bank-description {
        font-weight: bold;
        @media (max-width: 740px) {
          text-align: center;
        }
      }
      .bank-options-container {
        padding-top: 1rem;
        display: flex;
        @media (max-width: 740px) {
          justify-content: center;
        }
        .bank-options {
          display: grid;
          grid-template-columns: repeat(9, minmax(2.5rem, 1fr));
          grid-gap: 1.25rem;
          align-items: stretch;
          width: fit-content;
          @media (max-width: 1050px) {
            grid-template-columns: repeat(7, minmax(2.5rem, 1fr));
          }
          @media (max-width: 850px) {
            grid-template-columns: repeat(5, minmax(2.5rem, 1fr));
          }
          @media (max-width: 490px) {
            grid-template-columns: repeat(3, minmax(2.5rem, 1fr));
          }
          .bank-icons {
            position: relative;
            .bank-icon {
              height: 3rem;
              cursor: pointer;
            }
            .tick-icon {
              position: absolute;
              height: 1.37rem;
              left: 1.5rem;
              top: -0.5rem;
            }
            .not-selected {
              display: none;
            }
          }
        }
      }
    }
  }
  .simplebar-scrollbar::before {
    background-color: ${(props) =>
      props.theme.colors.background.primaryCardBackground};
    border-radius: 0.4375rem;
  }

  .simplebar-vertical {
    width: 0.6875rem;
  }
`;
export default AddbankPopUpStyle;
