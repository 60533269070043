import styled from "styled-components";

const GetEcoStyle = styled.div`
  display: flex;
  justify-content: center;
  .hazteEcoContainer {
    padding: 1.25rem;
    width: 100%;
    background: ${(props) => props.theme.gradient.ecoGradient};
    cursor: pointer;
    @media (max-width: 1040px) {
      max-width: 19.37rem;
    }
    @media (max-width: 400px) {
      max-width: 100%;
    }
  }
  .getEchoInnerContainer {
    text-align: center;
  }
  .getEchoImg {
    display: flex;
    align-items: center;
  }
  .hazteEcoImg {
    margin: 0 auto;
    width: 100%;
    min-width: 2.75rem;
    max-width: 4.37rem;
  }
  .getEchoText {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .hazteEcoTexto1 {
    color: ${(props) => props.theme.colors.fontColor.titleFontColor};
    margin-bottom: 0;
  }
  .hazteEcoTexto2 {
    color: ${(props) => props.theme.colors.fontColor.titleFontColor};
    font-weight: bold;
    margin-bottom: 0;
  }
  .enabled-eco-container {
    padding: 1.25rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    @media (max-width: 1040px) {
      max-width: 19.37rem;
    }
    @media (max-width: 400px) {
      max-width: 100%;
    }
    .enabled-eco-heading {
      color: ${(props) => props.theme.colors.fontColor.titleFontColor};
      font-weight: bold;
    }
    .invested-amount-container {
      .invested-amount-title {
        color: ${(props) => props.theme.colors.fontColor.descriptionFontColor};
        font-weight: 500;
        padding-bottom: 0.3rem;
      }
      .invested-amount-data {
        display: flex;
        align-items: center;
        .invested-amount-icon {
          height: 1.75rem;
        }
        .invested-amount {
          color: ${(props) =>
            props.theme.colors.fontColor.descriptionFontColor};
          font-weight: 500;
          padding-left: 0.5rem;
        }
      }
    }
    .cost-effect-container {
      .cost-effect-title {
        color: ${(props) => props.theme.colors.fontColor.descriptionFontColor};
        font-weight: 500;
        padding-bottom: 0.3rem;
      }
      .cost-effect-data {
        display: flex;
        align-items: center;
        .cost-effect-icon {
          max-height: 3.75rem;
        }
        .amount-container {
          padding-left: 0.5rem;
          font-weight: 500;
          .cost-effect-percentage {
            color: ${(props) => props.theme.colors.fontColor.titleFontColor};
          }
          .negative-cost-effect {
            color: ${(props) =>
              props.theme.colors.button.background.teritiaryButton};
          }
          .cost-effect-amount {
            color: ${(props) => props.theme.colors.fontColor.titleFontColor};
          }
        }
      }
    }
  }
`;
export default GetEcoStyle;
