import styled from "styled-components";

const EcoInvestmentsStyle = styled.div`
  @media (max-width: 1040px) {
    height: calc(var(--app-height) - 9rem);
  }
  .primary-alert-container {
    padding-right: 1.5rem;
    @media (max-width: 1040px) {
      display: none;
    }
  }
  .eco-investments-container {
    display: flex;
    width: 100%;
    gap: 2%;
    padding: 0rem 1.5rem 3rem 0rem;
    @media (max-width: 1040px) {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 0%;
      padding: 0rem;
      height: 100%;
    }
    @media (max-width: 345px) {
      padding: 2rem 0rem 0rem;
    }
    .popUp-wrpper {
      position: fixed;
      z-index: 150;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      .popUp-overlay {
        width: 100%;
        height: 100%;
        background-color: ${(props) =>
          props.theme.colors.background.overlayBackground};
        opacity: 0.5;
      }
      .popUp-container {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        height: auto;
        max-height: 100%;
        overflow: hidden;
        min-width: 50%;
        @media (max-width: 940px) {
          min-width: 85%;
        }
      }
    }
    .back-icon {
      width: 1.5625rem;
      height: 1.5625rem;
      position: fixed;
      z-index: 1;
      top: 2rem;
      left: 2rem;
      @media (max-width: 1040px) {
        display: none;
      }
      cursor: pointer;
      img {
        height: 100%;
        width: 100%;
      }
    }
    .investment-section {
      width: calc(100% - 37%);

      @media (max-width: 1040px) {
        padding: 1rem;
        width: 100%;
        overflow: scroll;
        overflow-x: hidden;
      }
      ::-webkit-scrollbar {
        width: 0.031rem;
        right: 0.031rem;
      }
      ::-webkit-scrollbar-track {
        box-shadow: inset 0 0 0.031rem transparent;
        border-radius: 0.625rem;
        right: 0.031rem;
      }
      ::-webkit-scrollbar-thumb {
        background: ${(props) =>
          props.theme.colors.background.scrollbarBackground};
        border-radius: 0.62rem;
        right: 0.031rem;
      }
      ::-webkit-scrollbar-thumb:hover {
        background: ${(props) =>
          props.theme.colors.background.scrollbarHoverbackground};
      }
      .secondary-alert-container {
        @media (min-width: 1040px) {
          display: none;
        }
      }
      .investment-inner-section {
        height: 100%;
        width: 100%;

        display: flex;
        flex-direction: column;
        gap: 2rem;
        @media (max-width: 1040px) {
          height: 100%;
        }
        .eco-tutorial-section {
          padding-bottom: 1rem;
        }
      }
    }
    .proposal-section {
      width: calc(100% - 65%);
      @media (max-width: 1040px) {
        width: 100%;
      }
    }
  }
`;
export default EcoInvestmentsStyle;
