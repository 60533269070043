import React from "react";
import ImageConstants from "../../constants/imageConstants/image_constants";
import ListItemStyle from "./listItemStyle";
import Text from "../../theme/font";

const parentClassNameValues = {
  withIcon: "list-item-container",
  normal: "value-field",
  home: "fondo reponsiveFondo",
  eco: "invested-list",
  proposal: "dataFund-container",
  settings: "fund-evolution-list",
  ecoPanel: "fund-evolution-list",
};

const primaryClassNameValues = {
  withIcon: "primary-data",
  normal: "field",
  home: "fondoNombre col-sm-7 float",
  eco: "invest-info",
  proposal: "fund-name",
  settings: "fund-evolution-title",
  ecoPanel: "fund-evolution-title",
};
const secondaryClassNameValues = {
  withIcon: "secondary-data",
  normal: "value",
  home: "fondoISIN col-sm-3 float",
  eco: "invested-amount",
  proposal: "fund-ISIN",
  settings: "fund-evolution-values",
  ecoPanel: "fund-evolution-values",
};

const teritioryClassNameValues = {
  withIcon: "teritiory-data",
  home: "fondoImporte col-sm-2 float",
  proposal: "fund-amount",
};

const ListItem = React.memo(
  ({ primary, secondary, teritioary, subData, variant, showImage }) => {
    const iconHandler = (data) => {
      if (data === "Activado") {
        return (
          <img className="tick-icon" alt="x" src={ImageConstants.ovalTick} />
        );
      } else {
        return (
          <img className="cross-icon" alt="x" src={ImageConstants.ovalCross} />
        );
      }
    };

    const amountHanlder = (data) => {
      if (data === "Activado") {
        return "50€";
      } else {
        return "0€";
      }
    };

    const formato = (x, signo = false) => {
      var number = new Intl.NumberFormat("de-DE", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(x);
      if (signo && x > 0) {
        return "+" + number;
      } else return number;
    };

    const amountConverter = (value) => {
      let convertedString = "";
      if (value) {
        convertedString = value.toLocaleString();
      }
      return convertedString;
    };

    const upperCaseHandler = (value) => {
      let changedValue = "";
      if (value) {
        changedValue = value.charAt(0).toUpperCase() + value.slice(1);
        return changedValue;
      } else {
        return changedValue;
      }
    };

    const primaryHandler = () => {
      switch (variant) {
        case "withIcon": {
          return primary;
        }
        case "normal": {
          return (
            <div>
              <Text className="value-name" variant="fieldFont">
                {upperCaseHandler(primary)}
              </Text>
              <Text className="date" variant="smallFont">
                {subData}
              </Text>
            </div>
          );
        }
        case "home": {
          if (showImage) {
            return (
              <div className="home-name-container">
                <img
                  className="ticImgRound  float"
                  alt="x"
                  src={ImageConstants.TicRound}
                />
                <Text variant="fieldFont">{primary}</Text>
              </div>
            );
          } else {
            return <Text variant="fieldFont">{primary}</Text>;
          }
        }
        case "eco": {
          return (
            <div>
              <Text variant="fieldFont" className="invested-title">
                {primary}
              </Text>
              <Text variant="smallFont" className="invest-code">
                {subData}
              </Text>
            </div>
          );
        }
        case "proposal": {
          return primary ? primary : null;
        }
        case "settings": {
          return primary;
        }
        case "ecoPanel": {
          return primary;
        }
        default: {
          return null;
        }
      }
    };

    const secondaryHandler = () => {
      switch (variant) {
        case "withIcon": {
          return iconHandler(secondary);
        }
        case "normal": {
          return secondary ? `${formato(secondary)}€` : "";
        }
        case "home": {
          return secondary;
        }
        case "eco": {
          return `${formato(secondary)} €`;
        }
        case "proposal": {
          return secondary ? secondary : null;
        }
        case "settings": {
          return `${secondary} ${subData ? subData : ""}`;
        }
        case "ecoPanel": {
          return <div>{`${amountConverter(secondary)} ${subData}`}</div>;
        }
        default: {
          return null;
        }
      }
    };

    const teritioryHandler = () => {
      switch (variant) {
        case "withIcon": {
          return `${amountHanlder(secondary)}`;
        }
        case "home": {
          const teritioryAmount = `${
            teritioary !== 0 ? formato(teritioary) : 0
          }`;
          return `${teritioryAmount} €`;
        }

        case "proposal": {
          return teritioary ? `${formato(teritioary)}€` : null;
        }
        default: {
          return null;
        }
      }
    };

    return (
      <ListItemStyle>
        <div className={parentClassNameValues[variant]}>
          <div
            className={
              primaryClassNameValues[variant]
                ? primaryClassNameValues[variant]
                : "no-data"
            }
          >
            {(variant === "home" || variant === "proposal") && (
              <Text className="proposal-fund-title">Nombre</Text>
            )}
            <div>{primaryHandler()}</div>
          </div>
          <div
            className={
              secondaryClassNameValues[variant]
                ? secondary && secondary.length > 18
                  ? `${secondaryClassNameValues[variant]} small-text`
                  : secondaryClassNameValues[variant]
                : "no-data"
            }
          >
            {(variant === "home" || variant === "proposal") && (
              <Text className="proposal-fund-title">Isin</Text>
            )}
            <div>{secondaryHandler()}</div>
          </div>

          {variant === "home" || variant === "proposal" ? (
            <div
              className={
                teritioryClassNameValues[variant]
                  ? teritioryClassNameValues[variant]
                  : "no-data"
              }
            >
              <div className="importe-container">
                <Text className="proposal-fund-title">Importe</Text>
                <div>{teritioryHandler()}</div>
              </div>
            </div>
          ) : (
            <div
              className={
                teritioryClassNameValues[variant]
                  ? teritioryClassNameValues[variant]
                  : "no-data"
              }
            >
              <div>{teritioryHandler()}</div>
            </div>
          )}
        </div>
      </ListItemStyle>
    );
  }
);

export default ListItem;
