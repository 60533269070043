import { useEffect, useContext, useState } from "react";
import { useLocation } from "react-router-dom";
import { AlertContext } from "../../common/alertContainer";
import { AuthTokenService } from "../../../services";
import InsuranceLetterSection from "./insuranceLetterSection/insuranceLetterSection";
import CancelLetterSection from "./cancelLetterSection/cancelLetterSection";
import { ContainerContext } from "../../../components/navbarContainer/containerProvider";
import DocumentSection from "./documentsSection/documentsSection";
import { getInsuranceDetails } from "../insuranceServices";
import {
  GetCancelLetter,
  GetInsuranceLetter,
  GetInsurancePolicy,
} from "./insuranceDetailsServices";
import InsuranceDetailsStyle from "./insuranceDetailsStyle";
import ProcessSection from "./processSection/processSection";
import SelectedInsurance from "./selectedInsurance/selectedInsurance";


const InsuranceDetails = () => {
  const { changeAlertStatus, pageNameHandler, headerTitleHandler } =
    useContext(ContainerContext);
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const insuranceId = query.get("id");
  const [totalData, setTotalData] = useState([]);
  const [cancelLetterData, setCancelLetterData] = useState({});
  const [insuranceLetterData, setInsuranceLetterData] = useState({});
  const [micappitalData, setMicappitalData] = useState({});
  const [policyData, setPolicyData] = useState({});
  const idClienteSeguros = AuthTokenService.getIdClienteSeguros();
  const { open } = useContext(AlertContext);

  useEffect(() => {
    open({
      alertType: "spinner",
      open: false,
    });
    getInsuranceDetails(insuranceId, idClienteSeguros)
      .then((result) => {
        open({
          alertType: "spinner",
          open: false,
        });
        setTotalData(result?.data?.seguro);
      })
      .catch((error) => {
        open({
          alertType: "spinner",
          open: false,
        });
        open({
          type: "error",
          title: "Error al enviar datos",
          alertType: "toast",
          open: true,
        });
      });

    GetInsurancePolicy(idClienteSeguros, insuranceId)
      .then((result) => {
        open({
          alertType: "spinner",
          open: false,
        });
        const data = result?.data?.poliza;
        setPolicyData(data);
      })
      .catch((error) => {
        open({
          alertType: "spinner",
          open: false,
        });
        open({
          type: "error",
          title: "Error al enviar datos",
          alertType: "toast",
          open: true,
        });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    open({
      alertType: "spinner",
      open: false,
    });
    GetCancelLetter(idClienteSeguros, insuranceId)
      .then((result) => {
        open({
          alertType: "spinner",
          open: false,
        });

        const { datosCartaBaja } = result?.data;
        setCancelLetterData(datosCartaBaja);
      })
      .catch((error) => {
        open({
          alertType: "spinner",
          open: false,
        });
        open({
          type: "error",
          title: "Error al enviar datos",
          alertType: "toast",
          open: true,
        });
      });
    GetInsuranceLetter(idClienteSeguros, insuranceId)
      .then((result) => {
        open({
          alertType: "spinner",
          open: false,
        });
        const { datosCartaBaja } = result?.data;
        const { datosMicappital } = datosCartaBaja;
        setInsuranceLetterData(datosCartaBaja);
        setMicappitalData(datosMicappital);
      })
      .catch((error) => {
        open({
          alertType: "spinner",
          open: false,
        });
        open({
          type: "error",
          title: "Error al enviar datos",
          alertType: "toast",
          open: true,
        });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    changeAlertStatus(false);
    toogleNavBar();
    return () => {
      changeAlertStatus(true);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toogleNavBar = () => {
    pageNameHandler("Resumen");
    headerTitleHandler("Seguros");
  };

  return (
    <InsuranceDetailsStyle>
      <div className="insurance-details-container">
        <div className="primary-section">
          <div className="selected-insurance-section box-container">
            <SelectedInsurance data={totalData} policyData={policyData} />
          </div>
          <div className="box-container">
            <DocumentSection policyData={policyData} />
          </div>
          {totalData.asesorado === "0" && (
            <div className="policy-section box-container">
              <InsuranceLetterSection
                data={insuranceLetterData}
                micappitalData={micappitalData}
              />
            </div>
          )}
          <div className="cancel-letter-section box-container">
            <CancelLetterSection data={cancelLetterData} />
          </div>
        </div>

        <div className="secondary-section ">
          <div className="optimization-section box-container">
            <ProcessSection />
          </div>
        </div>
      </div>
    </InsuranceDetailsStyle>
  );
};
export default InsuranceDetails;
