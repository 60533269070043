import styled from "styled-components";

const EvolutionStyle = styled.div`
  .panelEvolucionContainer {
    padding: 1.25rem;
    .growthContainer {
      width: 100%;
      min-height: 6rem;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 1.5rem;
      margin-top: 1rem;
      @media (max-width: 1140px) {
        flex-direction: column;
      }
      @media (max-width: 1040px) {
        flex-direction: row;
      }
      @media (max-width: 680px) {
        flex-direction: column;
      }
      .mainText {
        font-weight: bold;
      }
      .depositBox {
        display: flex;
        justify-content: space-around;
        align-items: center;
        min-height: 5rem;
        min-width: 17rem;
        background-color: ${(props) =>
          props.theme.colors.background.primaryCardBackground};
        color: ${(props) => props.theme.colors.fontColor.defaultFontColor};
        border-radius: 1rem;
        position: relative;
        padding: 0.3rem;
        gap: 0.5rem;

        .depositIconContainer {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          width: 30%;
          margin-left: 1rem;

          img {
            width: 2.5rem;
          }
        }
        .depositTextContainer {
          display: flex;
          flex: 1;
          flex-direction: column;
          justify-content: space-around;
          align-items: flex-start;
          width: fit-content;

          .depositText {
            display: flex;
            align-items: baseline;
          }
        }
        .arrowRight {
          width: 0;
          height: 0;
          border-top: 0.7rem solid transparent;
          border-bottom: 0.7rem solid transparent;
          border-left: 0.7rem solid
            ${(props) => props.theme.colors.fontColor.titleFontColor};
          position: absolute;
          right: -0.7rem;
          z-index: -1;
          @media (max-width: 1140px) {
            display: none;
          }
          @media (max-width: 1040px) {
            display: block;
          }
          @media (max-width: 680px) {
            display: none;
          }
        }
        .arrowBottom {
          display: none;
          width: 0;
          height: 0;
          border-left: 0.7rem solid transparent;
          border-right: 0.7rem solid transparent;
          border-top: 0.7rem solid
            ${(props) => props.theme.colors.fontColor.titleFontColor};
          position: absolute;
          bottom: -0.7rem;
          z-index: -1;
          @media (max-width: 1140px) {
            display: block;
          }
          @media (max-width: 1040px) {
            display: none;
          }
          @media (max-width: 680px) {
            display: block;
          }
        }
      }
      .growthBox {
        display: flex;
        justify-content: space-around;
        align-items: center;
        min-height: 5rem;
        min-width: 17rem;
        background-color: ${(props) =>
          props.theme.colors.background.defaultFieldBackground};
        border-radius: 1rem;
        color: ${(props) =>
          props.theme.colors.background.primaryCardBackground};
        padding: 0.3rem;
        gap: 0.5rem;
        .incrementText {
          display: flex;
          align-items: baseline;
        }
        .rentaText {
          display: flex;
          align-items: baseline;
          gap: 0.2rem;
          .percentageText {
            font-weight: bold;
          }
        }
        .leftTexts {
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          align-items: flex-start;
          padding-left: 1.5rem;
          flex: 1;
          width: fit-content;
        }
        .verticalLine {
          border-left: 1px solid
            ${(props) => props.theme.colors.background.primaryCardBackground};
          height: 3rem;
          width: fit-content;
        }
        .rightTexts {
          color: ${(props) => props.theme.colors.text.fieldText};
          width: 40%;
          padding-left: 0.5rem;
        }
      }
    }
    .graphLabelContainer {
      width: 100%;
      min-height: 4rem;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 3rem;
      color: ${(props) => props.theme.colors.fontColor.objectiveFontColor};
      @media (max-width: 450px) {
        gap: 1rem;
      }
      .labelDeposite {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 1rem;
        .labelSquare {
          width: 0.9rem;
          height: 0.9rem;
          background-color: ${(props) =>
            props.theme.colors.fontColor.titleFontColor};
        }
      }

      .labelMiCap {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 1rem;
        .miLabelSquare {
          width: 0.9rem;
          height: 0.9rem;
          background-color: ${(props) =>
            props.theme.colors.background.primaryCardBackground};
        }
      }
    }
  }
  .titlechart {
    color: ${(props) => props.theme.colors.fontColor.titleFontColor};
    font-weight: bold;
    margin-bottom: 1.25rem;
    margin-top: 1rem;
    @media (max-width: 600px) {
      text-align: center;
      width: 100%;
    }
  }
`;
export default EvolutionStyle;
