import React from "react";
import { useNavigate } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import defaultTheme from "../../../../theme/theme";
import InviteFriendsStyle from "./inviteFriendsStyle";
import Text from "../../../../theme/font";

const InviteFriends = () => {
  const theme = defaultTheme;
  const navigate = useNavigate();

  const addFriendsNavHandler = () => {
    navigate("/amigos");
  };

  return (
    <ThemeProvider theme={theme}>
      <InviteFriendsStyle>
        <div className="box-container invitaAmigosContainer">
          <div className="inviteFriendsSection">
            <div className="col-sm-4 invitaAmigosMensaje">
              <div className="invitaAmigosTexto1">
                <Text variant="smallerText">Consigue</Text>
              </div>
              <div className="invitaAmigos">
                <Text variant="extraLarge">50€</Text>
              </div>
            </div>
            <div className="col-sm-8 invitaAmigosMensaje2">
              <div className="invitaAmigosTexto2">
                <Text variant="primaryFont">
                  Por cada amigo que traigas a Micappital
                </Text>
              </div>
            </div>
          </div>

          <div className="buttonContainer">
            <Text
              variant="fieldFont"
              className="button"
              onClick={() => addFriendsNavHandler()}
            >
              Invítalos ahora
            </Text>
          </div>
        </div>
      </InviteFriendsStyle>
    </ThemeProvider>
  );
};

export default InviteFriends;
