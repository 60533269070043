import { Request } from "../../../services";
import URL_CONSTANTS from "../../../constants/urlConstants/urlConstants";

export const patchDatos = (data) => {
  return Request.patch(URL_CONSTANTS.client_settings, data);
};
export const getDatos = (data) => {
  return Request.get(URL_CONSTANTS.client_settings + "/" + data);
};
export const getBankData = (data) => {
  return Request.get(URL_CONSTANTS.get_bank + "/" + data);
};
export const postProfilePic = (data) => {
  return Request.post(URL_CONSTANTS.post_pic, data, {
    headers: { "Content-Type": "multipart/form-data" },
  });
};
export const getCardDetails = (data) => {
  return Request.get(URL_CONSTANTS.get_card_details + "/" + data);
};
export const postCardResponse = (data) => {
  return Request.post(URL_CONSTANTS.post_card_response, data);
};

export const getTestData = (data) => {
  return Request.get(`${URL_CONSTANTS.test_api}${data}`);
};

export const postTestData = (data) => {
  return Request.patch(URL_CONSTANTS.post_test_data, data);
};

export const postDocumentImage = (data) => {
  return Request.post(URL_CONSTANTS.postDocumentImg, data, {
    headers: { "Content-Type": "multipart/form-data" },
  });
};

export const getCommissionData = (data) => {
  return Request.get(URL_CONSTANTS.getCommissionData + "/" + data);
};

export const getProfileImage = (data) => {
  return Request.get(URL_CONSTANTS.get_profile_image + "/" + data);
};

export const uploadGeneralProfileImage = (data) => {
  return Request.post(URL_CONSTANTS.upload_image, data, {
    headers: { "Content-Type": "multipart/form-data" },
  });
};

export const patchGeneralData = (data) => {
  return Request.patch(URL_CONSTANTS.patch_settings, data);
};

export const getGeneralData = (data) => {
  return Request.get(URL_CONSTANTS.get_settings_superCliente + "/" + data);
};
