import styled from "styled-components";

const SupportSectionStyle = styled.div`
  padding: 2rem 1rem;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  .support-section-heading {
    font-weight: bold;
    padding-left: 0.6rem;
    color: ${(props) => props.theme.colors.fontColor.titleFontColor};
    @media (max-width: 540px) {
      text-align: center;
    }
  }
  .support-section-container {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    gap: 1rem;
    justify-content: space-around;
    .support-options-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      min-height: 22rem;
      min-width: 13rem;
      flex: 0 1 30%;
      padding: 3rem 1rem;
      background-color: ${(props) =>
        props.theme.colors.background.optionsCardBackground};
      border-radius: 2.18rem;
      .option-icon {
        margin-bottom: 2rem;
        img {
          width: 4.75rem;
          height: 5rem;
        }
      }
      .option-title {
        font-weight: 500;
        text-align: center;
        padding-bottom: 1rem;
        color: ${(props) => props.theme.colors.fontColor.descriptionFontColor};
      }
      .option-description {
        text-align: center;
        color: ${(props) => props.theme.colors.fontColor.descriptionFontColor};
      }
    }
  }
  .bottom-container {
    display: flex;
    align-items: center;
    @media (max-width: 600px) {
      flex-direction: column;
    }
    .support-section-description {
      flex: 1 0 50%;
      padding: 0rem 0.6rem;
      @media (max-width: 600px) {
        padding: 1rem 0rem;
        text-align: center;
      }
    }
    .button-container {
      flex: 1 0 50%;
      width: 100%;
      .begin-button {
        width: 100%;
        height: 3rem;
        max-width: 28rem;
      }
    }
  }
`;
export default SupportSectionStyle;
