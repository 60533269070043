import { interestOptions } from "../../../../models/insurancelandingPage";
import InterestSectionStyle from "./interestSectionStyle";
import Text from "../../../../theme/font";

const InterestSection = () => {
  return (
    <InterestSectionStyle>
      <div className="interest-container">
        <Text variant="mediumFont" className="interest-heading">
        Sin conflictos de intereses
        </Text>
        <div className="interest-option-container">
          {interestOptions &&
            interestOptions.map((data) => {
              return (
                <div className="interest-option-container" key={Math.random()}>
                  <div className="interest-option-icon">
                    <img alt="x" src={data.src} className="option-icon" />
                  </div>
                  <Text
                    variant="fieldFont"
                    type="primary"
                    className="interest-option-title"
                  >
                    {data.title}
                  </Text>
                  <Text
                    variant="smallFont"
                    type="primary"
                    className="interest-option-description"
                  >
                    {data.description}
                  </Text>
                </div>
              );
            })}
        </div>
      </div>
    </InterestSectionStyle>
  );
};

export default InterestSection;
