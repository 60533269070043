import styled from "styled-components";

const SideBarStyle = styled.div`
  height: 1.562rem;
  width: 1.562rem;
  position: fixed;
  .bm-overlay {
    top: 0;
    left: 0;
  }
  .bm-burger-button {
    height: 1.562rem;
    width: 1.562rem;
    position: fixed;
    .bm-icon {
      height: 2rem;
      width: 2rem;
    }
  }
  .image {
    height: 2rem;
    width: 2rem;
    z-index: 1000;
  }
  .bm-menu-wrap {
    position: fixed;
    top: 0;
    left: 0;
    width: 12rem !important;
    .bm-menu {
      background: ${(props) => props.theme.colors.background.defaultBackground};
      overflow: hidden !important;
      @media (max-width: 1040px) {
        overflow: visible !important;
      }
    }
    .bm-cross-button {
      height: 1.25rem;
      width: 1.25rem;
      .bm-cross {
        background: ${(props) => props.theme.colors.fontColor.titleFontColor};
      }
    }
  }
`;
export default SideBarStyle;
