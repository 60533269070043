import styled from "styled-components";

const DiversificationStyle = styled.div`
  .panelDiversificacionContainer {
    padding: 2rem 1.25rem;
    .rowStyle {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      .map-labels {
        display: flex;
        justify-content: center;
        gap: 0.5rem;
        align-items: center;
        font-weight: 600;
        @media (max-width: 1380px) {
          flex-direction: column;
          align-items: flex-start;
        }
        @media (max-width: 1040px) {
          flex-direction: row;
        }
        @media (max-width: 350px) {
          flex-direction: column;
        }

        .label-div {
          display: flex;
          align-items: center;
          gap: 0.5rem;
          .label-color {
            border-radius: 50%;
            height: 0.75rem;
            width: 0.75rem;
            flex-shrink: 0;
          }
          .primary-label-color {
            background-color: ${(props) =>
              props.theme.colors.background.primaryMapLabelColor};
          }
          .secondary-label-color {
            background-color: ${(props) =>
              props.theme.colors.background.secondaryMapLabelColor};
          }
        }
        .teritiary-label-color {
          background-color: ${(props) =>
            props.theme.colors.background.teritiaryMapLabelColor};
        }
      }
    }
    .more-information-text {
      text-align: center;
      cursor: pointer;
      color: ${(props) => props.theme.colors.fontColor.titleFontColor};
      text-decoration: underline;
      margin-top:1.5rem;
    }
  }
  .titlechart {
    color: ${(props) => props.theme.colors.fontColor.titleFontColor};
    font-weight: bold;
    margin-bottom: 1.25rem;
    margin-top: 1rem;
    display: flex;
    justify-content: flex-start;
    width: 100%;
    @media (max-width: 991px) {
      width: 100%;
      text-align: center;
      justify-content: center;
    }
  }
  .chart-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    @media (max-width: 991px) {
      padding-bottom: 3rem;
    }
  }
  .doughnut {
    width: fit-content;
    max-width: 14.8rem;
    max-height: 14.8rem;
  }

  .mapContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    svg {
      width: fit-content;
      height: fit-content;
      max-width: 19.7rem;
      @media (max-width: 1100px) {
        max-width: 18.7rem;
      }
    }
  }
`;

export default DiversificationStyle;
