import { useState, useEffect } from "react";
import { ThemeProvider } from "styled-components";
import { toast } from "react-toastify";
import defaultTheme from "../../../../theme/theme";
import ImageConstants from "../../../../constants/imageConstants/image_constants";
import InviteYourFriendsStyle from "./inviteYourFriendsStyle";
import { offerArray } from "../../../../models/addFriendsSectionArray";
import Text from "../../../../theme/font";

const InviteYourFriends = ({ data }) => {
  const theme = defaultTheme;
  const [referralLink, setReferralLink] = useState(data ? data.codigo : "");
  const [isCopied, setIsCopied] = useState(false);

  useEffect(() => {
    if (data && data.codigo) {
      setReferralLink(data.codigo);
    }
  }, [data]);

  const copyToClipboard = (value) => {
    const text = value;
    setIsCopied(true);
    navigator.clipboard.writeText(text);
    setTimeout(() => {
      setIsCopied(false);
    }, [1000]);
  };

  return (
    <ThemeProvider theme={theme}>
      <InviteYourFriendsStyle>
        <div className="invite-friends-container">
          <div className="add-friends-icon">
            <img alt="x" src={ImageConstants.addIcon} />
          </div>

          <Text variant="titleFont" className="invite-title">
            Micappital mejora día a día gracias a ti
          </Text>

          <Text variant="normal" type="primary" className="link-description">
            Por eso queremos recompensarte si nos ayudas. 50€ por cada amigo que invierta.
          </Text>

          <div className="link-container">
            <div className="invite-link">
              <Text variant="fieldFont" className="link-field">
                {referralLink}
              </Text>
            </div>
            {/* if isCopied is true then return <div className="copy-message">ISIN copiado</div> */}
            {isCopied &&
              toast.success("Código copiado", {
                position: "top-right",
                autoClose: 1000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                toastId: "",
              })}
            <div
              className="copy-link-icon"
              onClick={() => copyToClipboard(referralLink)}
            >
              <img alt="x" src={ImageConstants.copyIcon} />
            </div>
          </div>
        </div>

        <div className="offer-container">
          {offerArray.map((value) => {
            return (
              <div className="offer-data-container" key={Math.random()}>
                <div className="offer-icon">
                  <img alt="x" src={value.src} />
                </div>
                <Text variant="normal" className="offer-title">
                  {value.title}
                </Text>
                <Text
                  variant="normal"
                  type="primary"
                  className="offer-description"
                >
                  {value.content}
                </Text>
              </div>
            );
          })}
        </div>
      </InviteYourFriendsStyle>
    </ThemeProvider>
  );
};

export default InviteYourFriends;
