import { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AlertContext } from '../../container/common/alertContainer'
import AlertMessageStyle from "./alertMessageStyle.js";
import { AuthTokenService } from "../../services";
import CloseIcon from "@mui/icons-material/Close";
import { getAlertMessages } from "./alertMessageServices";
import {
  setConnectionData,
  setHideAlert,
  setHideSecondAlert,
} from "./alertMessageAction";
import Text from "../../theme/font";

const AlertMessage = () => {
  const { open } = useContext(AlertContext);
  const [alertMessageData, setAlertMessageData] = useState("");
  const alertData = useSelector((state) => state.alert);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    //setTimeout is used to get a delay, since API call is fetching with a delay of 3 seconds for some users. 
    setTimeout(() => {
      const userId = AuthTokenService.getUserId();
      if (userId) {
        open({
          alertType: "spinner",
          open: true,
        });
        getAlertMessages(userId)
          .then((response) => {
            open({
              alertType: "spinner",
              open: false,
            });
            setAlertMessageData(response?.data?.data);
          })
          .catch((error) => {
            open({
              alertType: "spinner",
              open: false,
            });
            open({
              type: "error",
              title: "Error al enviar datos",
              alertType: "toast",
              open: true,
            });
          });
      }
    }, 3000);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const closeAlertHandler = (values) => {
    if (values === "alertCard") {
      dispatch(setHideAlert(false));
    } else {
      dispatch(setHideSecondAlert(false));
    }
  };

  const clickHandler = () => {
    navigate("/ajustes");
    dispatch(setConnectionData(true));
  };

  return (
    <AlertMessageStyle>
      {Object.keys(alertMessageData).length > 0 && (
        <div className="message-section">
          {Object.keys(alertMessageData).map((values, i) => {
            return (
              ((alertData.hideData && values === "alertCard") ||
                (alertData.hideSecData &&
                  values === "micappitalConnectError")) && (
                <div
                  className={
                    alertMessageData[values].cardColor === "red"
                      ? "message red-container"
                      : "message yellow-container"
                  }
                  key={Math.random()}
                >
                  <Text
                    variant="normal"
                    className={
                      alertMessageData[values].cardColor === "red"
                        ? "alert-message-red"
                        : "alert-message-yelow"
                    }
                    onClick={() => {
                      values === "alertCard" && clickHandler();
                    }}
                  >
                    {alertMessageData[values].message}
                  </Text>
                  <CloseIcon
                    className={
                      alertMessageData[values].cardColor === "red"
                        ? "message-close-icon-red"
                        : "message-close-icon-yelow"
                    }
                    onClick={() => closeAlertHandler(values)}
                  />
                </div>
              )
            );
          })}
        </div>
      )}
    </AlertMessageStyle>
  );
};

export default AlertMessage;
