import React, { useEffect, useState, useContext } from "react";
import { ThemeProvider } from "styled-components";
import defaultTheme from "../../../../../theme/theme";
import ImageConstants from "../../../../../constants/imageConstants/image_constants";
import NewGoalInfo from "../subComponents/newGoalInfo/newGoalInfo";
import NewObjectiveStyle from "./newObjectiveStyle";
import SetGoal from "./setGoal/setGoal";
import { useNavigate } from "react-router-dom";
import { ContainerContext } from "../../../../../components/navbarContainer/containerProvider";

const NewObjective = () => {
  const [month, setMonth] = useState("");
  const [year, setYear] = useState("");
  const [amount, setAmount] = useState("");
  const [title, setTitle] = useState("-");
  const theme = defaultTheme;
  const navigate = useNavigate();
  const [menuHidden] = useState(true);
  const {
    changeMenuStatus,
    changeNavBarStatus,
    titleHandler,
    pageNameHandler,
    headerTitleHandler,
  } = useContext(ContainerContext);

  useEffect(() => {
    window.scrollTo(0, 0);
    changeNavBarStatus(false);
    changeMenuStatus(menuHidden);
    toogleNavBar();
    return () => {
      changeMenuStatus(!menuHidden);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toogleNavBar = () => {
    titleHandler("Nuevo objetivo");
    pageNameHandler("nuevoObjetivo");
    headerTitleHandler("");
  };

  const infoValueChange = (value, element) => {
    // eslint-disable-next-line default-case
    switch (element) {
      case "month": {
        setMonth(value);
        break;
      }

      case "year": {
        setYear(value);
        break;
      }
      case "amount": {
        setAmount(value);
        break;
      }
      case "title": {
        setTitle(value);
      }
    }
  };
  const onSubmitHandler = () => {
    navigate("/resumen");
  };
  const onReturnHandler = () => {
    navigate("/resumen");
  };

  return (
    <ThemeProvider theme={theme}>
      <NewObjectiveStyle>
        <div className="col-lg-12">
          <div className="row margin30 main-goal-container responsive-div-container">
            <div className="col-lg-8 main-goal-container">
              <SetGoal changeData={infoValueChange} />
            </div>
            <div className="col-lg-4 main-goal-container">
              <div className="col-lg-12 full-height">
                <NewGoalInfo
                  month={month}
                  year={year}
                  amount={amount}
                  title={title}
                  buttonValue="Cancelar"
                  submit={onSubmitHandler}
                  onCancel={onReturnHandler}
                  isEdit={false}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="back-icon" onClick={() => onReturnHandler()}>
          <img src={ImageConstants.arrowLeftIcon} alt="x" />
        </div>
      </NewObjectiveStyle>
    </ThemeProvider>
  );
};

export default NewObjective;
