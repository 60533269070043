import { useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { AlertContext } from "../../../alertContainer";
import Button from "../../../../../components/button/button";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import PasswordFormStyle from "./passwordFormStyle";
import { resetPasswordSuperCliente } from "../../forgotPasswordServices";
import TextField from "../../../../../components/textField/textField";
import Text from "../../../../../theme/font";

const PasswordForm = (props) => {
  const { open } = useContext(AlertContext);
  const params = useParams();
  const navigate = useNavigate();
  const validate = Yup.object({
    password: Yup.string()
      .required("Contraseña necesaria")
      .matches(/^\S*$/, "El espacio en blanco no está permitido"),
    confirmPassword: Yup.string()
      .required("Confirmar contraseña")
      .oneOf([Yup.ref("password"), null], "Las contraseñas no coinciden"),
  });

  const submitHandler = (values) => {
    open({
      alertType: "spinner",
      open: true,
    });
    const passwordData = {
      password: values.password,
      confirm_password: values.confirmPassword,
      hash: params.hash,
    };
    resetPasswordSuperCliente(passwordData)
      .then((response) => {
        open({
          alertType: "spinner",
          open: false,
        });
        open({
          type: "success",
          title: "Contraseña actualizada correctamente",
          alertType: "toast",
          open: true,
        });
        navigate("/login");
      })
      .catch((error) => {
        open({
          type: "error",
          title: "Error al enviar datos",
          alertType: "toast",
          open: true,
        });
      });
  };

  const variantHandler = (conditionalValue, resultValue) => {
    let variant = null;
    if (conditionalValue && resultValue) {
      variant = "error";
    } else {
      variant = "outlined";
    }
    return variant;
  };

  const formValidationHandler = (conditionalValue, resultValue) => {
    let result = null;
    if (conditionalValue) {
      result = resultValue;
    }
    return result;
  };

  return (
    <PasswordFormStyle>
      <Text variant="extraLarge" className="password-form-heading">
        Introduce tu nueva contraseña
      </Text>
      <Text variant="normal" className="password-form-description">
        Introduce tu nueva contraseña para resetearla
      </Text>
      <Formik
        initialValues={{
          password: "",
          confirmPassword: "",
        }}
        onSubmit={(values) => {
          submitHandler(values);
        }}
        validationSchema={validate}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleSubmit,
          submitForm,
        }) => (
          <Form onSubmit={handleSubmit}>
            <div className="password-form-field">
              <div className="password-form">
                <LockOutlinedIcon className="lock-icon" />
                <TextField
                  name="password"
                  placeholder="Contraseña"
                  autoComplete="new-password"
                  variant={variantHandler(errors.password, touched.password)}
                  value={values.password}
                  handleChange={handleChange}
                  type="password"
                  error={formValidationHandler(
                    touched.password,
                    Boolean(errors.password)
                  )}
                  helperText={formValidationHandler(
                    touched.password,
                    errors.password
                  )}
                />
              </div>
              <div className="password-form">
                <LockOutlinedIcon className="lock-icon" />
                <TextField
                  name="confirmPassword"
                  placeholder="Confirmar contraseña"
                  autoComplete="new-password"
                  variant={variantHandler(
                    errors.confirmPassword,
                    touched.confirmPassword
                  )}
                  value={values.confirmPassword}
                  handleChange={handleChange}
                  type="password"
                  error={formValidationHandler(
                    touched.confirmPassword,
                    Boolean(errors.confirmPassword)
                  )}
                  helperText={formValidationHandler(
                    touched.confirmPassword,
                    errors.confirmPassword
                  )}
                />
              </div>
            </div>
            <Text variant="titleFont" className="password-form-button">
              <Button
                className="reset-button"
                variant="outlined"
                value="Resetear"
                click={submitForm}
              />
            </Text>
          </Form>
        )}
      </Formik>
    </PasswordFormStyle>
  );
};

export default PasswordForm;
