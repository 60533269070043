import styled from "styled-components";

const NewsStyle = styled.div`
  @media (max-width: 1040px) {
    display: flex;
    justify-content: center;
  }
  .noticeContainer {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 1.5rem;
    padding: 1.25rem;
    width: 100%;
    @media (max-width: 1040px) {
      max-width: 19.37rem;
    }
    @media (max-width: 400px) {
      max-width: 100%;
    }
    .noticeTitle {
      font-weight: bold;
      color: ${(props) => props.theme.colors.fontColor.titleFontColor};
    }
    .newsList {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      gap: 1rem;
      color: ${(props) => props.theme.colors.fontColor.descriptionFontColor};
      .notifyImg {
        display: flex;
        .notifyIcon {
          width: 0.8rem;
        }
      }

      .newsDescription {
        text-align: start;
        flex: 1;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .readMore {
        text-decoration: underline;
        font-style: italic;
        cursor: pointer;
      }
      .updatedTime {
        font-style: italic;
      }
    }
    .viewAll {
      padding-left: 2rem;
      text-decoration: underline;
      font-style: italic;
      color: ${(props) => props.theme.colors.fontColor.descriptionFontColor};
      cursor: pointer;
    }
  }
`;
export default NewsStyle;
