import Button from "../../../../../../components/button/button";
import DeletePopUpStyle from "./deletePopUpStyle";
import Text from "../../../../../../theme/font";

const DeletePopUp = ({ onCancel, submit }) => {
  return (
    <DeletePopUpStyle>
      <div className="delete-pop-up-container">
        <div className="pop-up-header">
          <div className="pop-up-heading">
            <Text variant="titleFont">Confirmación</Text>
          </div>
        </div>
        <div className="pop-up-content-container">
          <div className="content-container">
            <Text variant="primaryFont">
              Confirma que quieres eliminar el objetivo
            </Text>
          </div>
          <div className="pop-up-btn">
            <div className="cancel-button">
              <Button
                value="Cancelar"
                className="pop-up-cancel"
                click={() => onCancel()}
              />
            </div>
            <div className="delete-button">
              <Button
                value="Eliminar"
                className="pop-up-delete"
                click={() => submit()}
              />
            </div>
          </div>
        </div>
      </div>
    </DeletePopUpStyle>
  );
};
export default DeletePopUp;
