import Button from "../../../../components/button/button";
import Text from "../../../../theme/font";
import DocumentSectionStyle from "./documentsSectionStyle";

const DocumentSection = ({ policyData }) => {
  const policyDetails = policyData && policyData;
  const policyUrl = policyDetails?.urlPoliza;

  const onButtonClick = () => {
    fetch(policyUrl).then((response) => {
      response.blob().then((blob) => {
        // Creating new object of PDF file
        const fileURL = window.URL.createObjectURL(blob);
        // Setting various property values
        let alink = document.createElement("a");
        alink.href = fileURL;
        alink.download = "documento de política";
        alink.click();
      });
    });
  };

  return (
    <DocumentSectionStyle>
      <Text variant="titleFont" className="document-section-heading">
        Documentos importantes
      </Text>
      <div className="button-container">
        <Text className="inner-button-container" variant="labelFont">
          <Button
            className="inner-button "
            value="Descargar póliza"
            click={() => onButtonClick()}
          />
        </Text>
        <Text className="inner-button-container" variant="labelFont">
          <Button className="inner-button " value="Descargar recibo" />
        </Text>
        {/* <Text className="inner-button-container" variant="labelFont">
          <Button className="inner-button " value="Descargar certificado" />
        </Text> */}
      </div>
    </DocumentSectionStyle>
  );
};

export default DocumentSection;
