import { useNavigate } from "react-router-dom";
import ImageConstants from "../../../../constants/imageConstants/image_constants";
import InsurancesStyle from "./insurancesStyle";
import Moment from "moment";
import ScrollBar from "../../../../components/scrollbar/scrollbar";
import Text from "../../../../theme/font";
import { useEffect, useState } from "react";

const Insurances = ({ data, check }) => {
  const navigate = useNavigate();
  const totalInsuranceData = data;
  const [sortedData, setSortedData] = useState([]);
  const windowWidth = window.innerWidth;

  useEffect(() => {
    const dataSeguros = data?.listaSeguros;
    dataSeguros &&
      dataSeguros.sort((a, b) =>
        a.fechaVencimiento > b.fechaVencimiento ? 1 : -1
      );
    setSortedData(dataSeguros);
  }, [data]);

  const dateConverter = (date) => {
    let convertedDate = Moment(date).format("MMMM YYYY");
    return convertedDate;
  };

  const daysCounter = (expiryDate) => {
    let date = new Date();
    let startDate = new Date(expiryDate);
    let timeDifference = startDate.getTime() - date.getTime();
    let dayMilliSeconds = 1000 * 60 * 60 * 24;
    let totalDays = Math.abs(timeDifference / dayMilliSeconds);
    totalDays = Math.floor(totalDays);

    if (totalDays < 60) {
      return (
        <Text variant="smallLabel" className="renewal-label">
          Seguro próximo a renovación
        </Text>
      );
    } else {
      return <Text></Text>;
    }
  };

  const formatter = (x) => {
    var number = new Intl.NumberFormat("de-DE", {
      minimumFractionDigits: 2, //Decimals for returns at the top of the chart
      maximumFractionDigits: 2,
    }).format(x);
    return number;
  };

  const titleHandler = () => {
    var active = 0;
    // eslint-disable-next-line array-callback-return
    sortedData &&
      // eslint-disable-next-line array-callback-return
      sortedData.map((list) => {
        if (list.activo !== "0") {
          active++;
        }
      });
    if (active > 1) {
      return ` Tus ${active} seguros`;
    } else {
      return "Tu seguro";
    }
  };

  return (
    <InsurancesStyle>
      <div className="insurances-container">
        <div className="header-container">
          <Text variant="titleFont" className="insurances-heading">
            {titleHandler()}
          </Text>
          <div className="total-insurance-amount">
            <Text className="total-amount" variant="button">
              {formatter(totalInsuranceData.precioTotal)}€
              <span className="amount-text">/año</span>
            </Text>
            {/* <Text className="label-text" variant="extraSmall">
              Estás ahorrando un 55%
            </Text> */}
          </div>
        </div>
        <div
          className="insurances-list-container"
          style={
            sortedData && sortedData.length > 2
              ? windowWidth && windowWidth < 1200
                ? { height: "24rem" }
                : { height: "fit-content" }
              : { height: "fit-content" }
          }
        >
          <ScrollBar elementName={"card_header_container"} max_height={"100%"}>
            <div className="insurances-list-inner-container">
              {sortedData &&
                // eslint-disable-next-line array-callback-return
                sortedData.map((list) => {
                  if (list.revisado !== "0" && list.activo !== "0") {
                    return (
                      <div
                        className={
                          list.asesorado === "1"
                            ? "insurances-list insurance-list-dateCount"
                            : "insurances-list"
                        }
                        key={Math.random()}
                        onClick={() =>
                          navigate(`/insuranceDetails?id=${list.idSeguro}`)
                        }
                      >
                        <div className="images-section">
                          <img
                            src={list.iconotipo}
                            className="insurance-icon"
                            alt="x"
                          />
                          {list.iconoaseguradora && (
                            <img
                              src={list.iconoaseguradora}
                              alt="x"
                              className="company-icon"
                            />
                          )}
                        </div>
                        <div className="insurances-details-section">
                          <Text
                            variant="primaryFont"
                            className="insurance-title"
                          >
                            {`Seguro de ${list.tipo}`}
                          </Text>
                          <Text className="insurance-type" variant="labelFont">
                            {list.alias}
                          </Text>
                          <Text className="insurance-type" variant="labelFont">
                            {list.aseguradora}
                          </Text>
                          <div className="insurance-date-container">
                            <img alt="x" src={ImageConstants.newCalendericon} />
                            <Text
                              className="insurance-date"
                              variant="popUpFont"
                            >
                              {dateConverter(list.fechaVencimiento)}
                            </Text>
                            {daysCounter(list.fechaVencimiento)}
                          </div>
                          <div className="micappital-optimization-section">
                            <img alt="x" src={ImageConstants.newLogoIcon} />
                            <div
                              variant="tableValue"
                              className={
                                list.asesorado === "1"
                                  ? "micappital-optimized-text"
                                  : "micappital-not-optimized-text"
                              }
                            >
                              {list.asesorado === "1"
                                ? "Seguro optimizado por Micappital"
                                : "Seguro no optimizado por Micappital"}
                            </div>
                          </div>
                          {/* <div className="optimization-details">
                      <img
                        alt="x"
                        className="optimization-icon"
                        src={imageHandler(list.optimization)}
                      />
                      <Text className={classNameHandler(list.optimization)}>
                        {list.optimizationText}
                      </Text>
                    </div> */}
                          {/* <div className="activation-section">
                      <img
                        alt="x"
                        className="activation-icon"
                        src={
                          list.activo === "1"
                            ? ImageConstants.greenToggle
                            : ImageConstants.defaultToggle
                        }
                      />
                      <Text
                        className={
                          list.activo === "1" ? "green-text" : "activation-text"
                        }
                      >
                        {list.notificationtext}
                      </Text>
                    </div> */}
                        </div>
                        <div className="insurances-amount-section">
                          <Text className="insurance-amount" variant="button">
                            {`${formatter(list.precio)}€`}
                            <span className="amount-text">/año</span>
                          </Text>

                          {/* <Text
                      className={labelColorHandler(list.optimization)}
                      variant="extraSmall"
                    >
                      {list.labelText}
                    </Text> */}
                        </div>
                      </div>
                    );
                  } else if (list.revisado === "0" && list.activo === "1") {
                    return (
                      <div className="insurances-list-inactive">
                        <div className="insurance-non-active">
                          <div className="images-section-non-active">
                            <div className="company-icon-non-active"></div>
                            <div className="company-name-icon-non-active"></div>
                          </div>
                          <div className="insurance-section-non-active">
                            <div className="insurance-name-non-active">
                              {`Seguro de ${list.tipo}`}
                            </div>
                            <div className="insurance-details-container-non-active">
                              <div className="insurance-details-non-active"></div>
                              <div className="insurance-details-non-active"></div>
                              <div className="insurance-details-non-active"></div>
                            </div>
                          </div>
                        </div>
                        <div className="insurance-info-container">
                          <div className="insurance-details-L-txt">
                            Procesando tu nuevo seguro
                          </div>
                          <div className="insurance-details-S-txt">
                            Este proceso puede tardar un par de días
                          </div>
                        </div>
                        <div className="insurance-amount-non-active"></div>
                      </div>
                    );
                  }
                })}
            </div>
          </ScrollBar>
        </div>

        <div className="bottom-description">
          <img
            alt="x"
            src={ImageConstants.newLogoIcon}
            className="bottom-logo"
          />
          <Text variant="smallerText" className="bottom-text">
            Seguro recomendado por Micappital
          </Text>
        </div>
      </div>
    </InsurancesStyle>
  );
};
export default Insurances;
