import Button from "../../../../components/button/button";
import Text from "../../../../theme/font";
import TutorialPopUpStyle from "./tutorialPopUpStyle";

const TutorialPopUp = (props) => {
  return (
    <TutorialPopUpStyle>
      <div className="tutorial-container">
        <div className="tutorial-header">
          <div className="tutorial-heading">
            <Text variant="titleFont">Tutorial</Text>
          </div>
          <div className="save-btn">
            <Button className="btn-div" value="Guardar datos" />
          </div>
        </div>
      </div>
    </TutorialPopUpStyle>
  );
};

export default TutorialPopUp;
