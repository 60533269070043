import AuthoritySectionStyle from "./authoritySectionStyle";
import ImageConstants from "../../../../constants/imageConstants/image_constants";
import Text from "../../../../theme/font";

const AuthoritySection = () => {
  return (
    <AuthoritySectionStyle>
      <div className="section-container">
        <Text variant="titleFont" className="authority-heading">
          Autorizados y regulados
        </Text>
        <div className="authority-image-container">
          <img
            src={ImageConstants.catalana}
            alt="x"
            className="authority-image"
          />
          <img
            src={ImageConstants.general}
            alt="x"
            className="authority-image"
          />
        </div>
        <Text variant="smallFont" className="bottom-text">
          Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam
          nonummy nibh euismod tincidunt ut. Lorem ipsum dolor sit amet,
          consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt
          ut
        </Text>
      </div>
    </AuthoritySectionStyle>
  );
};
export default AuthoritySection;
