import React from "react";
import NewGoalPanel from "../../subComponents/newGoalPanel/newGoalPanel";
import SetGoalStyle from "./setGoalStyle";
import { ThemeProvider } from "styled-components";
import DefineObjective from "../../subComponents/defineObjective/defineObjective";
import defaultTheme from "../../../../../../theme/theme";

const SetGoal = ({ changeData }) => {
  const theme = defaultTheme;

  const changeHandler = (values, element) => {
    changeData(values, element);
  };

  return (
    <ThemeProvider theme={theme}>
      <SetGoalStyle>
        <div className="set-goal-container box-container">
          <NewGoalPanel goal={false} changeData={changeHandler} />
          <DefineObjective changeData={changeHandler} />
        </div>
      </SetGoalStyle>
    </ThemeProvider>
  );
};

export default SetGoal;
