import ImageConstants from "../constants/imageConstants/image_constants";

export const connectArray = [
  {
    src: ImageConstants.connect1,
    title: "Tecnología Exclusiva",
    description:
      "Micappital Connect es la tecnología que nos permite supervisar diariamente el estado de tu inversión",
  },
  {
    src: ImageConstants.connect2,
    title: "Inversión optimizada",
    description:
      "Al estar conectados con tu banco, vamos a poder avisarte cuando haya que hacer cambios en tu inversión para que siempre estés en los productos correctos",
  },
  {
    src: ImageConstants.connect3,
    title: "Solo de consulta",
    description:
      "Micappital Connect solo nos permite consultar tu inversión, nunca podremos operar con tu cuenta",
  },
  {
    src: ImageConstants.connect4,
    title: "Máximo rendimiento",
    description:
      "Para sacar el máximo rendimiento a Micappital te recomendamos activar este servicio completamente gratuito y que va a mejorar notablemente tu experiencia como inversor",
  },
];
