import styled from "styled-components";

const LeftContainerStyle = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(100% - 50%);
  height: 100%;
  padding: 1rem;
  @media (max-width: 768px) {
    width: 100%;
    height: calc(100% - 75%);
  }
  .main-icon {
    height: 3.4375rem;
    width: 3.4375rem;
    padding: 1rem 0rem 0rem 1rem;
    @media (max-width: 768px) {
      width: 2.5rem;
      height: 2.5rem;
      padding: 0rem;
      position: absolute;
    }
  }
  .space-container {
    padding: 2.69rem 0rem;
    @media (max-width: 1040px) {
      padding: 0rem;
    }
  }
  .logIn-images {
    @media (min-width: 768px) {
      flex: 1;
    }
    .login-backgorund {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      @media (max-width: 768px) {
        height: auto;
        padding-top: 3rem;
      }
      .initial-image {
        width: 80%;
        height: 80%;
        @media (max-width: 860px) {
          width: 90%;
        }
        @media (max-width: 768px) {
          width: 65%;
        }
        @media (max-width: 768px) {
          width: 80%;
        }
      }
    }
  }
`;
export default LeftContainerStyle;
