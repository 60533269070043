import React from "react";
import Text from "../../../../theme/font";
import Button from "../../../../components/button/button";
import MicappitalConnectStyle from "./micappitalConnectStyle";
import { useNavigate } from "react-router-dom";
import { connectArray } from "../../../../models/miCappitalConnectArray";

const MicappitalConnect = ({url, connectedData}) => {
  const navigate = useNavigate();

  const connectHandler = () => {
    window.open(url);
  };

  return (
    <MicappitalConnectStyle>
      <div className="titleContainer">
        <Text variant="titleFont" className="title">
          Activa Micappital Connect
        </Text>
        <Text variant="normal" className="subTitle">
          La mejor forma de mantenerte al día con tu inversión. Al activarlo no
          tendrás que avisarnos cada vez que realices una operación.
        </Text>
      </div>
      <div className="dataMainContainer">
        {connectArray &&
          connectArray.map((data) => {
            return (
              <div className="dataSubContainer" key={Math.random()}>
                <div className="imageContainer">
                  <img src={data.src} alt="x" className="iconImage" />
                </div>
                <Text variant="labelFont" className="heading">
                  {data.title}
                </Text>
                <Text variant="smallFont" className="description">
                  {data.description}
                </Text>
              </div>
            );
          })}
        
      </div>
      {connectedData === "manual" && (
          <div className="buttonBox">
            <div className="connect-button">
              <Button
                className="micappital-connect-button"
                value="Activa Micappital Connect"
                variant="red"
                click={connectHandler}
              />
            </div>

            <Text className="skip-button" onClick={() => navigate("/resumen")}>
              Conectar más adelante
            </Text>
          </div>
        )}
    </MicappitalConnectStyle>
  );
};

export default MicappitalConnect;
