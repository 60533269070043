import styled from "styled-components";

const DefineObjectiveStyle = styled.div`
  width: 50%;
  @media (max-width: 620px) {
    width: 100%;
  }
  .goal-container {
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    @media (max-width: 620px) {
      width: 100%;
    }
    .new-goal-title {
      width: 100%;
      font-weight: bold;
      color: ${(props) => props.theme.colors.fontColor.titleFontColor};
      @media (max-width: 620px) {
        text-align: center;
      }
    }
    .field-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 90%;
      gap: 2rem;
      .amount-goal {
        width: 100%;
        gap: 1rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        @media (max-width: 620px) {
          align-items: center;
        }
        .amount-goal-title {
          color: ${(props) =>
            props.theme.colors.fontColor.descriptionFontColor};
          font-weight: bold;
        }
        .amount-goal-field {
          display: flex;
          align-items: center;
          width: 100%;
          min-height: 4rem;
          max-width: 13rem;
          background-color: ${(props) =>
            props.theme.colors.background.inputFieldBackground};
          border-radius: 0.625rem;
          padding: 0.3rem;
          .amount-input-field {
            display: flex;
            justify-content: center;
            color: ${(props) => props.theme.colors.text.fieldText};
            font-weight: bold;
          }
        }
        .date-field-container {
          display: flex;
          justify-content: center;
          gap: 1rem;
          @media (max-width: 1120px) {
            flex-direction: column;
          }
          @media (max-width: 991px) {
            flex-direction: row;
          }
          @media (max-width: 700px) {
            flex-direction: column;
          }
          @media (max-width: 621px) {
            flex-direction: row;
          }
          .primary-date-field {
            width: 60%;
            min-width: 8.12rem;
            z-index: 1;
            @media (max-width: 1120px) {
              width: 100%;
            }
            @media (max-width: 991px) {
              width: 60%;
            }
            @media (max-width: 700px) {
              width: 100%;
            }
            @media (max-width: 621px) {
              width: 60%;
            }
            #month-field {
              border-radius: 0.625rem;
              width: 100%;
              height: 4rem;
              display: flex;
              align-items: center;
              text-align: center !important;
              padding: 0 0.5rem 0 0.5rem;
              @media (max-width: 660px) {
                padding: 0 0.5rem 0 1.5rem;
              }
              @media (max-width: 620px) {
                padding: 0 0.5rem 0 2.5rem;
              }
              @media (max-width: 420px) {
                padding: 0 0.5rem 0 0.5rem;
              }
            }
          }
          .secondary-date-field {
            width: 40%;
            min-width: 8.12rem;
            @media (max-width: 1120px) {
              width: 100%;
            }
            @media (max-width: 991px) {
              width: 40%;
            }
            @media (max-width: 700px) {
              width: 100%;
            }
            @media (max-width: 621px) {
              width: 40%;
            }
            #year-field {
              border-radius: 0.625rem;
              height: 4rem;
              display: flex;
              align-items: center;
              padding: 0 1rem;
            }
          }
        }
      }
    }
  }
`;
export default DefineObjectiveStyle;
