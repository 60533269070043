import styled from "styled-components";

const DateSelectorStyle = styled.div`
  .MuiBox-root {
    outline: none;
    border-radius: 1.56rem;
    height: 2rem;
    background-color: ${(props) =>
      props.theme.colors.background.defaultFieldBackground};
    width: 100%;
    padding: 0rem 1rem 0rem 0rem;
    min-height: 2.7rem;
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    gap: 0.2rem;
    cursor: pointer;
    input {
      outline: none;
      border: none;
      background-color: ${(props) =>
        props.theme.colors.background.defaultFieldBackground};
      color: ${(props) => props.theme.colors.fontColor.titleFontColor};
      font-weight: 500;
      height: 100%;
      text-align: center;
      font-size: 1.1rem;
      text-decoration: underline;
      max-width: 11.875rem;
      border-radius: 1.56rem;
      cursor: pointer;
    }

    .MuiSvgIcon-root {
      color: ${(props) => props.theme.colors.background.primaryCardBackground};
      font-size: 1.1rem;
    }
    .css-vubbuv {
      color: ${(props) => props.theme.colors.background.primaryCardBackground};
      font-size: 1.1rem;
    }
  }
`;
export default DateSelectorStyle;
