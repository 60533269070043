import { useContext, useEffect, useState } from "react";
import { AlertContext } from "../../common/alertContainer";
import AllInOneSection from "./allInOneSection/allInOneSection";
import { AuthTokenService } from "../../../services";
import { ContainerContext } from "../../../components/navbarContainer/containerProvider";
import CompaniesSection from "./companiesSection/companiesSection";
import InusranceBanner from "./insuranceBanner/insuranceBanner";
import InsuranceReview from "./insuranceReview/insuranceReview";
import LandingPageStyle from "./landingPageStyle";
import SimulateSection from "./simulateSection/simulateSection";
import ProcessPopUp from "../processPopUp/processPopUp";
import { startNewInsurance } from "../insuranceServices";
import { useNavigate } from "react-router-dom";
import ClickInsurance from "../processPopUp/clickPopup/clickPopup";

const LandingPage = () => {
  const { changeAlertStatus, pageNameHandler, headerTitleHandler } =
    useContext(ContainerContext);
  const [showPopUp, setShowPopup] = useState(false);
  const [showClosePopup, setShowClosePopup] = useState(false);
  const { open } = useContext(AlertContext);
  const userId = AuthTokenService.getUserId();
  const idClienteSeguros = AuthTokenService.getIdClienteSeguros();
  const navigate = useNavigate();

  useEffect(() => {
    changeAlertStatus(false);
    toogleNavBar();
    return () => {
      changeAlertStatus(true);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!idClienteSeguros) {
      open({
        alertType: "spinner",
        open: true,
      });
      startNewInsurance(userId)
        .then((result) => {
          open({
            alertType: "spinner",
            open: false,
          });
          const data = result.data;
          if (data) {
            localStorage.setItem("idClienteSeguros", data.idClienteSeguros);
          }
        })
        .catch(() => {
          open({
            alertType: "spinner",
            open: false,
          });
          open({
            type: "error",
            title: "Error al enviar datos",
            alertType: "toast",
            open: true,
          });
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toogleNavBar = () => {
    pageNameHandler("Resumen");
    headerTitleHandler("Seguros");
  };

  //For closing the insurance popup
  const popupCloseHandler = () => {
    setShowPopup(false);
  };

  //Close the popup and redirect to insurance list section after submiting insurance
  const submitHandler = () => {
    popupCloseHandler();
    navigate("/insuranceList");
  };

  //For showing the confirmation popup
  const confirmPopUpHandler = () => {
    setShowClosePopup(true);
  };

  //For closing the confirmation popup
  const closePopUpHandler = () => {
    setShowClosePopup(false);
  };

  //For closing both popups
  const closeAllPopUps = () => {
    setShowClosePopup(false);
    setShowPopup(false);
  };

  const processPopupHandler = () => {
    return (
      <div className="process-popup-wrapper">
        <div
          className="popup-overlay"
          onClick={() => confirmPopUpHandler()}
        ></div>
        <div className="process-popup-container">
          {showPopUp && <ProcessPopUp click={submitHandler} />}
          {showClosePopup && (
            <div className="closing-popup-container">
              <ClickInsurance
                closeAllPopup={closeAllPopUps}
                closePopUp={closePopUpHandler}
              />
            </div>
          )}
        </div>
      </div>
    );
  };

  return (
    <LandingPageStyle>
      {showPopUp && (
        <div className="process-pop-section">{processPopupHandler()}</div>
      )}
      <div className="landing-page-container">
        <div className="primary-section">
          <div className="banner-section">
            <InusranceBanner showPopUp={setShowPopup} />
          </div>
          <div className="insurance-review-section box-container">
            <InsuranceReview />
          </div>
          <div className="companies-section box-container">
            <CompaniesSection />
          </div>
        </div>
        <div className="secondary-section">
          <div className="simulate-section box-container">
            <SimulateSection showPopUp={setShowPopup} />
          </div>
          <div className="all-in-one-section box-container">
            <AllInOneSection />
          </div>
        </div>
      </div>
    </LandingPageStyle>
  );
};

export default LandingPage;
