import React from "react";
// import "./../../assets/css/Loading.css";
import LoadingGif from "./../../assets/img/common/loader.gif";
import LoaderStyle from "../../container/common/alertContainer/spinner/spinnerStyle";

class Loading extends React.Component {
  // eslint-disable-next-line no-useless-constructor
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <LoaderStyle>
        <div className="loader_overlay">
          <div className="loader">
            <img src={LoadingGif} className="loaderImg" alt="X" />
          </div>
        </div>
      </LoaderStyle>
    );
  }
}

export default Loading;
