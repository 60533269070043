import styled from "styled-components";

const DateRangeStyle = styled.div`
  position: absolute;
  top: 4rem;
  right: 0rem;
  z-index: 2;
  @media (max-width: 620px) {
    right: -1rem;
  }
  @media (max-width: 350px) {
    right: -2rem;
  }
  .rdrDateRangePickerWrapper {
    .rdrDefinedRangesWrapper {
      display: none;
    }
    .rdrCalendarWrapper {
      font-size: 0.75rem;
      background-color: ${(props) =>
        props.theme.colors.background.defaultBackground};
      box-shadow: 0.5rem 0.5rem 1.87rem 0 rgb(11 57 59 / 18%);
      border-radius: 1rem;
      @media (max-width: 620px) {
        width: 16.5rem;
      }
      .rdrDateDisplayWrapper {
        z-index: 1;
        background-color: ${(props) =>
          props.theme.colors.button.background.navabarButtonBackground};
        border-top-left-radius: 0.62rem;
        border-top-right-radius: 0.62rem;
        .rdrDateDisplay {
          .rdrDateDisplayItemActive {
            border-color: ${(props) =>
              props.theme.colors.fontColor.titleFontColor};
          }
        }
      }
      .rdrMonthAndYearWrapper {
        background-color: ${(props) =>
          props.theme.colors.background.defaultBackground};
        z-index: 1;
        @media (max-width: 620px) {
          width: 100%;
        }
        .rdrNextPrevButton {
          background-color: ${(props) =>
            props.theme.colors.button.background.navabarButtonBackground};
        }
        .rdrPprevButton i {
          border-color: transparent rgb(44 120 124) transparent transparent;
        }
        .rdrNextButton i {
          border-color: transparent transparent transparent rgb(44 120 124);
        }

        .rdrMonthAndYearPickers {
          .rdrMonthPicker {
            select {
              color: ${(props) => props.theme.colors.fontColor.titleFontColor};
            }
            select:hover {
              background-color: ${(props) =>
                props.theme.colors.button.background.navabarButtonBackground};
            }
          }
          .rdrYearPicker {
            select {
              color: ${(props) => props.theme.colors.fontColor.titleFontColor};
            }
            select:hover {
              background-color: ${(props) =>
                props.theme.colors.button.background.navabarButtonBackground};
            }
          }
        }
      }
      .rdrMonths {
        background-color: #fff;
        z-index: 1;
        padding-top: 0.5rem;
        gap: 0.5rem;
        border-bottom-right-radius: 0.62rem;
        border-bottom-left-radius: 0.62rem;

        @media (max-width: 1300px) {
          flex-direction: column;
        }

        .rdrMonth {
          @media (max-width: 620px) {
            width: 100%;
          }
          .rdrMonthName {
            display: none;
          }
          .rdrWeekDays {
            .rdrWeekDay {
              color: ${(props) => props.theme.colors.fontColor.titleFontColor};
            }
          }
          .rdrDays {
            .rdrDay {
              .rdrInRange:hover {
              }
              .rdrDayNumber {
                span {
                  z-index: 1;
                  color: ${(props) =>
                    props.theme.colors.fontColor.titleFontColor};
                }
                span:hover {
                  font-weight: bold;
                }
              }
            }
            .rdrDayHovered {
              /* background-color: black; */
              .rdDayInPreview {
                /* background-color: black; */
              }
            }
            .rdrDayPassive .rdrDayNumber span {
              color: ${(props) =>
                props.theme.colors.fontColor.disabledFontColor};
            }
          }
          .rdrDays:focus-visible {
            outline: -webkit-focus-ring-color auto 1.25rem;
          }
        }
      }
    }
  }
  .rdrDayInPreview {
    /* border-top: 10px solid #9e9e9e;
    border-bottom: 10px solid #9e9e9e; */
    background-color: ${(props) =>
      props.theme.colors.button.background.navabarButtonBackground};
  }
  .rdrDayStartPreview {
    /* border-left: 10px solid #9e9e9e;
    border-top: 10px solid #9e9e9e;
    border-bottom: 10px solid #9e9e9e; */
    background-color: ${(props) =>
      props.theme.colors.button.background.navabarButtonBackground};
  }
  .rdrDayEndPreview {
    /* border-right: 10px solid #9e9e9e;
    border-top: 10px solid #9e9e9e;
    border-bottom: 10px solid #9e9e9e; */
    background-color: ${(props) =>
      props.theme.colors.button.background.navabarButtonBackground};
  }
  .rdrDayStartOfMonth,
  .rdrDayStartOfWeek {
    .rdrDayInPreview,
    .rdrDayEndPreview {
      /* left: 3px;
      border-top: 10px solid #9e9e9e;
      border-bottom: 10px solid #9e9e9e;
      border-left: 10px solid #9e9e9e; */
      background-color: ${(props) =>
        props.theme.colors.button.background.navabarButtonBackground};
    }
    .rdrDayEndPreview {
      /* border-right: 10px solid #9e9e9e; */
      background-color: ${(props) =>
        props.theme.colors.button.background.navabarButtonBackground};
    }
  }
  .rdrDayEndOfMonth,
  .rdrDayEndOfWeek {
    .rdrDayInPreview,
    .rdrDayStartPreview {
      /* border-top: 10px solid #9e9e9e;
      border-bottom: 10px solid #9e9e9e;
      border-right: 10px solid #9e9e9e; */
      background-color: ${(props) =>
        props.theme.colors.button.background.navabarButtonBackground};
    }
    .rdrDayStartPreview {
      /* border-left: 10px solid #9e9e9e; */
      background-color: ${(props) =>
        props.theme.colors.button.background.navabarButtonBackground};
    }
  }
`;
export default DateRangeStyle;
