import styled from "styled-components";

const InvestedSectionStyle = styled.div`
  .Invested-list-container {
    padding: 2rem 3rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    @media (max-width: 620px) {
      padding: 1rem;
    }
    .invested-list-title {
      font-weight: bold;
      color: ${(props) => props.theme.colors.fontColor.titleFontColor};
      @media (max-width: 780px) {
        text-align: center;
      }
    }
    .invested-list-details {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      .invested-list {
        width: 100%;
        background-color: ${(props) =>
          props.theme.colors.background.defaultFieldBackground};
        display: flex;
        justify-content: space-between;
        border-radius: 1.56rem;
        padding: 0.5rem 2rem;
        align-items: center;
        @media (max-width: 500px) {
          flex-direction: column;
        }
        .invest-info {
          .invested-title {
            font-weight: 500;
            color: ${(props) =>
              props.theme.colors.fontColor.descriptionFontColor};
            @media (max-width: 500px) {
              text-align: center;
            }
          }
          .invest-code {
            font-weight: 300;
            color: ${(props) =>
              props.theme.colors.fontColor.descriptionFontColor};
            @media (max-width: 500px) {
              text-align: center;
            }
          }
        }
        .invested-amount {
          font-weight: 700;
          color: ${(props) =>
            props.theme.colors.fontColor.descriptionFontColor};
        }
      }
      ::-webkit-scrollbar {
        width: 0.5rem;
        right: 0.312rem;
      }
      ::-webkit-scrollbar-track {
        box-shadow: inset 0 0 0.312rem transparent;
        border-radius: 0.62rem;
        right: 0.312rem;
      }
      ::-webkit-scrollbar-thumb {
        background: ${(props) =>
          props.theme.colors.background.scrollbarBackground};
        border-radius: 0.62rem;
        right: 0.312rem;
      }
      ::-webkit-scrollbar-thumb:hover {
        background: ${(props) =>
          props.theme.colors.background.scrollbarHoverbackground};
      }
    }
  }
`;
export default InvestedSectionStyle;
