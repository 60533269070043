import React, { useState } from "react";
import { slide as Slide } from "react-burger-menu";
import ImageConstants from "../../constants/imageConstants/image_constants";
import NewMenu from "../newMenu/newMenu";
import SideBarStyle from "./sidebarStyle";

const SideBar = React.memo(({ click, headerTitle }) => {
  const [isOpen, setOpen] = useState(false);

  //side menu closing fuctions
  const handleIsOpen = () => {
    setOpen(!isOpen);
    click();
  };

  const closeSideBar = () => {
    setOpen(false);
  };

  return (
    <SideBarStyle>
      <Slide
        pageWrapId={"page-wrap"}
        outerContainerId={"menu"}
        isOpen={isOpen}
        onOpen={handleIsOpen}
        onClose={handleIsOpen}
        customBurgerIcon={
          <img
            className="image"
            src={ImageConstants.hamburgerMenuIcon}
            alt="menu"
          />
        }
      >
        <NewMenu
          id="page-wrap"
          closeSideBar={closeSideBar}
          headerTitle={headerTitle}
        />
      </Slide>
    </SideBarStyle>
  );
});

export default SideBar;
