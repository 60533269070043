import ImageConstants from "../../../../constants/imageConstants/image_constants";
import Moment from "moment";
import SelectedInsuranceStyle from "./selectedInsuranceStyle";
import Text from "../../../../theme/font";

const SelectedInsurance = ({ data }) => {
  const insuranceDetails = data && data;

  const formatter = (x) => {
    var number = new Intl.NumberFormat("de-DE", {
      minimumFractionDigits: 2, //Decimals for returns at the top of the chart
      maximumFractionDigits: 2,
    }).format(x);
    return number;
  };

  const convertedDate = (date) => {
    let convertedDate = Moment(date).format(`DD/MM/YYYY`);
    return convertedDate;
  };

  return (
    <SelectedInsuranceStyle>
      <div
        className={
          insuranceDetails.asesorado === "1"
            ? "selected-insurance-container connected-insurance"
            : "selected-insurance-container"
        }
      >
        <div className="selected-insurance-header">
          <Text variant="titleFont" className="selected-insurance-heading">
            Detalles del seguro
          </Text>
          <Text className="insurance-amount" variant="button">
            {formatter(insuranceDetails?.precio)}€
            <span className="amount-text">/año</span>
          </Text>
        </div>
        <div className="insurance-details-section">
          <div className="insurance-icon-container">
            {insuranceDetails?.iconotipo && (
              <img
                className="insurance-icon"
                alt="x"
                src={insuranceDetails?.iconotipo}
              />
            )}
            {insuranceDetails?.iconoaseguradora && (
              <img
                alt="x"
                className="insurance-company-icon"
                src={insuranceDetails?.iconoaseguradora}
              />
            )}
          </div>
          <div className="insurance-data">
            <Text variant="primaryFont" className="insurance-name">
              {`Seguro de ${insuranceDetails?.tipo}`}
            </Text>
            {insuranceDetails?.poliza && (
              <Text variant="primaryFont" className="policy-id">
                {`Número de Póliza: #${insuranceDetails?.poliza}`}
              </Text>
            )}
            <Text variant="popUpFont" className="insurance-id">
              {insuranceDetails?.alias}
            </Text>
            <Text variant="popUpFont" className="bank-name">
              {insuranceDetails?.aseguradora}
            </Text>
            {/* <Text variant="popUpFont" className="insurance-type">
              Seguro a terceros plus
            </Text> */}
            <div className="date-container">
              <img alt="x" src={ImageConstants.newCalendericon} />
              <Text variant="popUpFont" className="insurance-date">
                {`Fecha de vencimiento:
                ${convertedDate(insuranceDetails?.fechaVencimiento)}`}
              </Text>
            </div>
            <div className="micappital-optimization-section">
              <img alt="x" src={ImageConstants.newLogoIcon} />
              <Text
                variant="tableValue"
                className={
                  insuranceDetails.asesorado === "1"
                    ? "micappital-optimized-text"
                    : "micappital-not-optimized-text"
                }
              >
                {insuranceDetails.asesorado === "1"
                  ? "Seguro optimizado por Micappital"
                  : "Seguro no optimizado por Micappital"}
              </Text>
            </div>
            {/* <div className="optimization-details">
              <img alt="x" src={ImageConstants.redCross} />
              <Text variant="smallerText" className="optimization-description">
                Seguro no optimizado - Hay mejor precio
              </Text>
            </div> */}
            {/* <div className="activation-section">
              <img alt="x" src={ImageConstants.defaultToggle} />
              <Text variant="smallerText" className="activation-description">
                Avisos automáticos desactivados
              </Text>
            </div> */}
          </div>
        </div>
        {/* <Text variant="labelFont" className="insurance-button-container">
          <Button value="Ver optimizaciones" className="optimization-button" />
        </Text> */}
      </div>
    </SelectedInsuranceStyle>
  );
};
export default SelectedInsurance;
