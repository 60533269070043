import ContractPopUpStyle from "./contractPopUpStyle";
import ScrollBar from "../../../../components/scrollbar/scrollbar";
import Text from "../../../../theme/font";

const ContractPopUp = (props) => {
  return (
    <ContractPopUpStyle>
      <ScrollBar elementName={"card_header_container"} max_height={"100%"}>
        <div className="contractPopUp-container">
          <div className="contractPopUp-header">
            <div className="popup-heading">
              <Text variant="titleFont">{props.data.title}</Text>
            </div>
          </div>
          <div className="contractPopUp-content">
            <div
              className="contract-content"
              dangerouslySetInnerHTML={{ __html: props.datas.contrato }}
            />
          </div>
        </div>
      </ScrollBar>
    </ContractPopUpStyle>
  );
};

export default ContractPopUp;
