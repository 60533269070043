import React from "react";
import { useNavigate } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import defaultTheme from "../../../../theme/theme";
import NewContributionStyle from "./newContributionStyle";
import Text from "../../../../theme/font";

const NewContribution = (props) => {
  const theme = defaultTheme;
  const navigate = useNavigate();

  const addInvestmentNavHandler = () => {
    navigate("/aportaciones");
  };
  return (
    <ThemeProvider theme={theme}>
      <NewContributionStyle>
        <div className="box-container nuevaAportacionContainer">
          <div className="nuevaAportacionText">
            <Text variant="fieldFont" type="primary">
              Aportar a tu inversión periódicamente es importante
            </Text>
          </div>
          <div className="nuevaAportacionText2">
            <Text variant="button" type="primary">
              ¿Quieres invertir más?
            </Text>
          </div>
          <div className="buttonContainer">
            <Text
              variant="fieldFont"
              className="button"
              onClick={() => addInvestmentNavHandler()}
            >
              Nueva inversión
            </Text>
          </div>
        </div>
      </NewContributionStyle>
    </ThemeProvider>
  );
};
export default NewContribution;
