import styled from "styled-components";

const ProposedChangesStyle = styled.div`
  background-color: ${(props) =>
    props.theme.colors.background.defaultBackground};

  border-radius: 1rem;
  padding: 1.5rem 2rem;
  position: fixed;
  width: 29%;
  @media (max-width: 1040px) {
    width: 100%;
    min-width: 18.75rem;
    position: relative;
    border-radius: 0;
    background-color: ${(props) =>
      props.theme.colors.background.bodyBackground};
  }
  .proposal-section-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 2rem;
    @media (max-width: 1040px) {
      align-items: center;
      gap: 1rem;
    }
    .dot-container {
      display: none;
      @media (max-width: 1040px) {
        width: 100%;
        display: flex;
        justify-content: center;
        gap: 1rem;
      }
      .color-dot {
        border-radius: 50%;
        height: 0.5rem;
        width: 0.5rem;
        background-color: ${(props) =>
          props.theme.colors.background.primaryCardBackground};
      }
      .select-dot {
        border-radius: 50%;
        background-color: ${(props) =>
          props.theme.colors.fontColor.objectiveFontOpec};
        height: 0.5rem;
        width: 0.5rem;
        cursor: pointer;
      }
    }

    .proposal-section-header {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 1rem;
      @media (max-width: 1040px) {
        text-align: center;
      }
      .proposal-section-heading {
        font-weight: bold;
        color: ${(props) => props.theme.colors.fontColor.titleFontColor};
      }
      .proposal-section-description {
        color: ${(props) => props.theme.colors.fontColor.descriptionFontColor};
        font-weight: 500;
      }
    }
    .investment-details-container {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      @media (max-width: 1040px) {
        display: none;
      }
    }
    .responsive-details-container {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      @media (min-width: 1040px) {
        display: none;
      }
    }
    .fund-details {
      display: flex;
      align-items: center;
      width: 100%;
      @media (max-width: 920px) {
        justify-content: start;
      }
      .fund-icon {
        padding-right: 1rem;
        img {
          height: 2.5rem;
          box-shadow: ${(props) => props.theme.boxShadow.imageBoxShadow};
          border-radius: 1.56rem;
        }
      }
      .fund-info {
        .fund-title {
          color: ${(props) =>
            props.theme.colors.fontColor.descriptionFontColor};
          font-weight: 500;
        }
        .fund-count {
          color: ${(props) =>
            props.theme.colors.fontColor.descriptionFontColor};
          font-weight: bold;
        }
      }
    }
    .bank-details {
      display: flex;
      align-items: center;
      width: 100%;
      @media (max-width: 920px) {
        justify-content: start;
      }
      .bank-icon {
        padding-right: 1rem;
        img {
          height: 2.5rem;
          box-shadow: ${(props) => props.theme.boxShadow.imageBoxShadow};
          border-radius: 1.56rem;
        }
      }
      .bank-info {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        .bank-img {
          .bank-logo {
            width: 2.5rem;
          }
        }
        .bank-title {
          color: ${(props) =>
            props.theme.colors.fontColor.descriptionFontColor};
          font-weight: 500;
        }
      }
    }
    .help-details {
      display: flex;
      align-items: center;
      width: 100%;
      @media (max-width: 920px) {
        justify-content: start;
      }
      .video-icon {
        padding-right: 1rem;
        img {
          height: 2.5rem;
          box-shadow: ${(props) => props.theme.boxShadow.imageBoxShadow};
          border-radius: 1.56rem;
        }
      }
      .help-info {
        .help-title {
          color: ${(props) =>
            props.theme.colors.fontColor.descriptionFontColor};
          font-weight: 500;
        }
        .help-tutorial {
          color: ${(props) => props.theme.colors.fontColor.titleFontColor};
          font-weight: bold;
          cursor: pointer;
        }
      }
    }

    .bought-btn {
      width: 100%;
      .btn-div {
        height: 2.7rem;
        width: 100%;
        max-width: 21rem;
        @media (max-width: 920px) {
          width: 55%;
          min-width: 15.62rem;
        }
      }
    }
  }
`;
export default ProposedChangesStyle;
