import React, { useEffect, useState, useContext } from "react";
import { AuthTokenService } from "../../../../services";
import { useNavigate } from "react-router-dom";
import { AlertContext } from "../../../common/alertContainer";
import defaultTheme from "../../../../theme/theme";
import { getInvestedDetails } from "../micappitalEcoServices";
import InvestedSectionStyle from "./investedSectionStyle";
import { ThemeProvider } from "styled-components";
import Text from "../../../../theme/font";
import List from "../../../../components/list/list";
import ListItem from "../../../../components/listItem/listItem";

const InvestedSection = React.memo(() => {
  const theme = defaultTheme;
  const [investedData, setInvestedData] = useState([]);
  const [fundData, setFundData] = useState([]);
  const { open } = useContext(AlertContext);
  const navigate = useNavigate();
  const userId = AuthTokenService.getUserId();

  const investmentHandler = () => {
    getInvestedDetails(userId)
      .then((response) => {
        open({
          alertType: "spinner",
          open: false,
        });
        if (response && response.data.data) {
          setInvestedData(response.data.data);
        } else {
          navigate("/conoceEco");
        }
      })
      .catch(() => {
        open({
          alertType: "spinner",
          open: false,
        });
        open({
          type: "error",
          title: "error encontrado",
          alertType: "toast",
          open: true,
        });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  useEffect(() => {
    if (userId) {
      investmentHandler();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  useEffect(() => {
    const combinedData = [];
    if (investedData) {
      // eslint-disable-next-line array-callback-return
      investedData.map((items) => {
        const dataItems = {
          primary: items.Nombre,
          secondary: items.valor,
          subData: items.ISIN,
        };
        combinedData.push(dataItems);
      });
      setFundData([...fundData, ...combinedData]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [investedData]);

  return (
    <ThemeProvider theme={theme}>
      <InvestedSectionStyle>
        <div className="Invested-list-container box-container">
          <Text variant="titleFont" className="invested-list-title">
            ¿Dónde estás invertido?
          </Text>
          <List data={fundData} limit={10}>
            <ListItem variant="eco" />
          </List>
        </div>
      </InvestedSectionStyle>
    </ThemeProvider>
  );
});

export default InvestedSection;
