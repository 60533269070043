import { useEffect, useState } from "react";
import Button from "../../../../components/button/button";
import ImageConstants from "../../../../constants/imageConstants/image_constants";
import ProposedChangesStyle from "./proposedChangeStyle";
import Text from "../../../../theme/font";

const ProposedChange = ({ data, click, popUpClick, connectData }) => {
  const [bankData, setBankData] = useState(data && data.info.banco);
  const [numberOfChanges, setNumberOfChanges] = useState(
    data && data.numberofchanges
  );
  const [primaryClick, setPrimaryClick] = useState(1);
  const videoData = data?.info?.banco?.enlacevideotraspaso;

  useEffect(() => {
    if (data && data.info.banco) {
      setBankData(data.info.banco);
    }
    if (data && data.numberofchanges) {
      setNumberOfChanges(data.numberofchanges);
    } else {
      setNumberOfChanges(0);
    }
  }, [data]);

  const primaryClickHandler = (value) => {
    setPrimaryClick(value);
  };

  const responsiveVideoScroll = () => {
    click();
  };

  const popUpClickHandler = () => {
    popUpClick();
  };

  const dotClickHandler = () => {
    return (
      <div className="dot-container">
        <div
          className={primaryClick === 1 ? "color-dot" : "select-dot "}
          onClick={() => primaryClickHandler(1)}
        ></div>
        <div
          className={primaryClick === 2 ? "color-dot" : "select-dot "}
          onClick={() => primaryClickHandler(2)}
        ></div>
        <div
          className={primaryClick === 3 ? "color-dot" : "select-dot "}
          onClick={() => primaryClickHandler(3)}
        ></div>
      </div>
    );
  };

  const responsiveDetailsHandler = () => {
    return (
      <div className="responsive-details-container">
        {primaryClick === 1 && (
          <div className="fund-details">
            <div className="fund-icon">
              <img src={ImageConstants.walletIcon} alt="x" />
            </div>
            <div className="fund-info">
              <Text className="fund-title" variant="normal">
                Vas a traspasar
              </Text>
              <div className="fund-count">
                <Text variant="titleFont">
                  {numberOfChanges && numberOfChanges > 1
                    ? `${numberOfChanges} fondos`
                    : `${numberOfChanges} fondo`}
                </Text>
              </div>
            </div>
          </div>
        )}
        {primaryClick === 2 && (
          <div className="bank-details">
            <div className="bank-icon">
              <img src={ImageConstants.bankIcon} alt="x" />
            </div>
            <div className="bank-info">
              <Text className="bank-title" variant="normal">
                En tu entidad bancaria
              </Text>

              <div className="bank-img">
                <img className="bank-logo" src={bankData.image} alt="x" />
              </div>
            </div>
          </div>
        )}
        {primaryClick === 3 && (
          <div className="help-details">
            <div className="video-icon">
              <img src={ImageConstants.tutorialIcon} alt="x" />
            </div>
            <div className="help-info">
              <div className="help-title">
                <Text variant="normal">¿Cómo realizo mis cambios? </Text>
              </div>
              <div className="help-tutorial">
                <Text variant="normal" onClick={responsiveVideoScroll}>
                  {videoData ? "Ver vídeo tutorial" : "Ver receta tutorial"}
                </Text>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  };

  return (
    <ProposedChangesStyle>
      <div className="proposal-section-container">
        {dotClickHandler()}
        <div className="proposal-section-header">
          <Text className="proposal-section-heading" variant="titleFont">
            Cambio propuesto
          </Text>
          <Text className="proposal-section-description" variant="normal">
            *No realices los cambios si tienes algún traspaso pendiente
          </Text>
        </div>
        <div className="investment-details-container">
          <div className="fund-details">
            <div className="fund-icon">
              <img src={ImageConstants.walletIcon} alt="x" />
            </div>
            <div className="fund-info">
              <Text className="fund-title" variant="normal">
                Vas a traspasar
              </Text>
              <div className="fund-count">
                <Text variant="titleFont">
                  {numberOfChanges && numberOfChanges > 1
                    ? `${numberOfChanges} fondos`
                    : `${numberOfChanges} fondo`}
                </Text>
              </div>
            </div>
          </div>
          <div className="bank-details">
            <div className="bank-icon">
              <img src={ImageConstants.bankIcon} alt="x" />
            </div>
            <div className="bank-info">
              <Text className="bank-title" variant="normal">
                En tu entidad bancaria
              </Text>

              <div className="bank-img">
                <img className="bank-logo" src={bankData.image} alt="x" />
              </div>
            </div>
          </div>
          <div className="help-details">
            <div className="video-icon">
              <img src={ImageConstants.tutorialIcon} alt="x" />
            </div>
            <div className="help-info">
              <div className="help-title">
                <Text variant="normal">¿Cómo realizo mis cambios? </Text>
              </div>
              <div className="help-tutorial">
                <Text variant="normal" onClick={responsiveVideoScroll}>
                  {videoData ? "Ver vídeo tutorial" : "Ver receta tutorial"}
                </Text>
              </div>
            </div>
          </div>
        </div>
        {responsiveDetailsHandler()}
        {connectData === "manual" && (
          <div className="bought-btn">
            <Button
              value="He realizado los cambios"
              className="btn-div"
              click={popUpClickHandler}
            />
          </div>
        )}
      </div>
    </ProposedChangesStyle>
  );
};
export default ProposedChange;
