import styled from "styled-components";

const InvitedFriendsStyle = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  .invited-friends-container {
    display: flex;

    justify-content: space-between;
    align-items: center;
    .invited-friends-title {
      padding-right: 0.5rem;
      font-weight: bold;
      color: ${(props) => props.theme.colors.fontColor.titleFontColor};
    }
    .total-amount {
      display: flex;
      background-color: ${(props) =>
        props.theme.colors.background.defaultFieldBackground};
      color: ${(props) => props.theme.colors.fontColor.titleFontColor};
      border-radius: 1rem;
      justify-content: space-evenly;
      align-items: center;
      padding: 0.5rem;
      min-width: 8.62rem;
      font-weight: 600;
      .amount-icon {
        height: 1.43rem;
      }
      .reward-container {
        padding-left: 0.3rem;
      }
    }
  }
  .invited-friends-description {
    color: ${(props) => props.theme.colors.fontColor.descriptionFontColor};
    font-weight: 500;
  }
  .invited-friends-data {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    padding-top: 1rem;
    overflow: hidden;
    .amount-details {
      display: flex;
      flex-direction: row;
      background-color: ${(props) =>
        props.theme.colors.background.defaultFieldBackground};
      padding: 0.8125rem 0.625rem;
      border-radius: 1rem;
      width: 100%;
      justify-content: space-around;
      @media (max-width: 1460px) {
        flex-direction: column;
        text-align: center;
        gap: 0.5rem;
      }
      @media (max-width: 1080px) {
        flex-direction: row;
        text-align: initial;
      }
      @media (max-width: 450px) {
        flex-direction: column;
        text-align: center;
        gap: 0.5rem;
      }
      .friend-name {
        width: 60%;
        padding-left: 1rem;
        font-weight: bold;
        color: ${(props) => props.theme.colors.fontColor.titleFontColor};
        @media (max-width: 1460px) {
          width: 100%;
        }
        @media (max-width: 1080px) {
          width: 60%;
        }
        @media (max-width: 450px) {
          width: 100%;
        }
      }
      .oval-icon {
        .cross-icon {
          height: 1.56rem;
          width: 1.56rem;
        }
        .tick-icon {
          height: 1.6rem;
          width: 1.6rem;
        }
      }
      .earned-money {
        width: 20%;
        text-align: end;
        color: ${(props) => props.theme.colors.fontColor.titleFontColor};
        @media (max-width: 1460px) {
          width: 100%;
          text-align: center;
        }
        @media (max-width: 1080px) {
          width: 20%;
        }
        @media (max-width: 450px) {
          width: 100%;
        }
      }
    }
    ::-webkit-scrollbar {
      width: 0.3125rem;

      right: 0.3125rem;
    }
    ::-webkit-scrollbar-track {
      box-shadow: inset 0 0 0.3125rem transparent;
      border-radius: 0.625rem;
      right: 0.3125rem;
    }
    ::-webkit-scrollbar-thumb {
      background: ${(props) =>
        props.theme.colors.background.scrollbarBackground};
      border-radius: 0.625rem;
      right: 0.3125rem;
    }
    ::-webkit-scrollbar-thumb:hover {
      background: ${(props) =>
        props.theme.colors.background.scrollbarHoverbackground};
    }
  }
  .button-container {
    padding-bottom: 1rem;
    .hide {
      display: none;
    }
    .see-all-button {
      width: 100%;
      max-height: 3.25rem;
    }
  }
`;
export default InvitedFriendsStyle;
