import React, { useEffect, useState, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import SingleNewsStyle from "./singleNewsStyle";
import { AlertContext } from "../../../../../common/alertContainer";
import { AuthTokenService } from "../../../../../../services";
import { ContainerContext } from "../../../../../../components/navbarContainer/containerProvider";
import defaultTheme from "../../../../../../theme/theme";
import { getSpecificNews } from "../../newsServices";
import ImageConstants from "../../../../../../constants/imageConstants/image_constants";
import Text from "../../../../../../theme/font";

const SingleNews = () => {
  const theme = defaultTheme;
  const [menuHidden] = useState(true);
  const [singleNewsData, setSingleNewsData] = useState([]);
  const {
    changeMenuStatus,
    changeNavBarStatus,
    titleHandler,
    headerTitleHandler,
    pageNameHandler,
  } = useContext(ContainerContext);
  const { open } = useContext(AlertContext);
  const navigate = useNavigate();
  const { search } = useLocation();
  const newsId = new URLSearchParams(search).get("newsId");

  useEffect(() => {
    changeNavBarStatus(false);
    changeMenuStatus(menuHidden);
    toogleNavBar();
    return () => {
      changeMenuStatus(!menuHidden);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const userId = AuthTokenService.getUserId();
    open({
      alertType: "spinner",
      open: true,
    });
    getSpecificNews(userId, newsId)
      .then((result) => {
        open({
          alertType: "spinner",
          open: false,
        });
        if (result?.data) {
          const SingleNews = result.data.data;
          setSingleNewsData(SingleNews);
        }
      })
      .catch(() => {
        open({
          alertType: "spinner",
          open: false,
        });
        open({
          type: "error",
          title: "Error al enviar datos",
          alertType: "toast",
          open: true,
        });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toogleNavBar = () => {
    titleHandler("");
    headerTitleHandler("");
    pageNameHandler("");
  };

  const onReturnHandler = () => {
    navigate("/noticias");
  };
  const homeReturnHandler = () => {
    navigate("/resumen");
  };

  const linkHandler = (url) => {
    let newLink = url && url.slice(1);
    let attachedLink = `https://micappital.com${newLink}`;
    return attachedLink;
  };

  return (
    <ThemeProvider theme={theme}>
      <SingleNewsStyle>
        <div className="back-icon" onClick={() => onReturnHandler()}>
          <img src={ImageConstants.arrowLeftIcon} alt="x" />
        </div>
        <Text className="newsTitle" variant="extraLarge">
          {singleNewsData.titulo}
        </Text>
        <div className="newsContainer">
          <div className="news">
            <div className="newsInfos">
              <img
                src={ImageConstants.Logo}
                className="logo"
                alt="X"
                onClick={() => homeReturnHandler()}
              />
              <div className="newsWriter">
                <Text className="author" variant="labelFont">
                  {singleNewsData.autor}
                </Text>
                {/* <Text className="designation" variant="smallFont">
                  {newsDataArray[newsId].designation}
                </Text> */}
              </div>
              <div className="newsTime">
                <Text className="timeToRead" variant="labelFont">
                  Time to read
                </Text>
                <Text className="time" variant="smallFont">
                  {singleNewsData.tiempo}
                </Text>
              </div>
            </div>
            <div className="newsImage">
              <img
                src={linkHandler(
                  singleNewsData.urlimagen && singleNewsData.urlimagen
                )}
                alt="x"
                className="newsImg"
              />
            </div>
            <Text className="totalNews" variant="popUpText">
              {singleNewsData.textolargo}
            </Text>
          </div>
        </div>
      </SingleNewsStyle>
    </ThemeProvider>
  );
};

export default SingleNews;
