import styled from "styled-components";

const AllNewsStyle = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding-right: 2rem;
  margin: 2rem 0rem;
  @media (max-width: 1040px) {
    padding-right: 0rem;
  }
  @media (max-width: 450px) {
    margin: 3rem 0rem;
  }

  .newsGridContainer {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 2.5rem;
    @media (max-width: 550px) {
      grid-template-columns: repeat(1, 1fr);
      grid-gap: 3.5rem;
    }
    .newsContainer {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 0.7rem;
      .imageContainer {
        width: 100%;
        .newsImage {
          width: 100%;
          border-radius: 4rem;
          height: 17rem;
          max-width: 25rem;
          @media (max-width: 650px) {
            border-radius: 3rem;
          }
        }
      }
      .newsTitle {
        color: ${(props) => props.theme.colors.fontColor.titleFontColor};
        font-weight: bold;
      }
      .newsDetails {
        display: flex;
        gap: 1rem;
        color: ${(props) => props.theme.colors.fontColor.descriptionFontColor};
        .author {
          font-weight: bold;
        }
      }
      .newsDesc {
        font-style: italic;
        color: ${(props) => props.theme.colors.fontColor.newsFontColor};
        line-height: 1.3rem;
      }
      .buttonContainer {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .button {
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: ${(props) =>
            props.theme.colors.button.background.redButton};
          color: ${(props) => props.theme.colors.button.text.textSecondary};
          min-width: 5.5rem;
          min-height: 2.5rem;
          border-radius: 1rem;
          cursor: pointer;
        }
      }
    }
  }
  .back-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2.1875rem;
    position: fixed;
    top: 2.3rem;
    left: 3rem;
    z-index: 1;
    cursor: pointer;
    @media (max-width: 1040px) {
      display: none;
    }

    img {
      width: 1.5625rem;
    }
  }
`;
export default AllNewsStyle;
