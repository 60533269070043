import { useEffect, useState } from "react";
import Navbar from "../navbar/navbar";
import ContainerStyle from "./containerStyle";
import ImageConstants from "../../constants/imageConstants/image_constants";
import URL_CONSTANTS from "../../constants/urlConstants/urlConstants";
import NewMenu from "../newMenu/newMenu";
import { ThemeProvider } from "styled-components";
import defaultTheme from "../../theme/theme";
import AlertMessage from "../alertmessage/alertMessage";

const Container = ({
  isMenuHide,
  isNavBarHide,
  children,
  title,
  headerTitle,
  pageName,
  profileIcon,
  isAlertHide,
}) => {
  const [currentPath, setCurrentPath] = useState(window.location.pathname);
  const theme = defaultTheme;

  useEffect(() => {
    setCurrentPath(window.location.pathname);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.pathname]);

  const openWhatsapp = () => {
    window.open(URL_CONSTANTS.open_whatsapp, "_blank");
  };

  return (
    <ThemeProvider theme={theme}>
      <ContainerStyle>
        <div className="navbar-container">
          {!isNavBarHide && (
            <Navbar
              title={title}
              profileIcon={profileIcon}
              pageName={pageName}
              headerTitle={headerTitle}
            />
          )}
        </div>
        <div className="content-container">
          <div className="menu-container">
            {!isMenuHide && <NewMenu headerTitle={headerTitle} />}
          </div>
          <div className="main-container">
            <div
              className={
                currentPath === "/editarObjetivo" ||
                currentPath === "/propuestaFondos" ||
                currentPath === "/nuevoObjetivo" ||
                currentPath === "/optimizacion" ||
                currentPath === "/propuesta" ||
                currentPath === "/ecoInvestments"
                  ? "new-content"
                  : "content"
              }
            >
              <div className="alert-message-container">
                {isAlertHide && <AlertMessage />}
              </div>

              {children}
            </div>

            <img
              src={ImageConstants.whatsappFino}
              alt="x"
              className={
                currentPath === "/login" ||
                currentPath === "/" ||
                currentPath === "/password" ||
                currentPath === "/recuperarClaves"
                  ? "no-icon"
                  : "chat-icon"
              }
              onClick={openWhatsapp}
            />
          </div>
        </div>
      </ContainerStyle>
    </ThemeProvider>
  );
};

export default Container;
