import styled from "styled-components";

const ServiceCostStyle = styled.div`
  .service-container {
    padding: 2rem;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    .service-title {
      font-weight: bold;
      color: ${(props) => props.theme.colors.fontColor.titleFontColor};
      @media (max-width: 540px) {
        text-align: center;
      }
    }
    .service-primary-description {
      font-weight: bold;
      color: ${(props) => props.theme.colors.background.secondaryLoadingColor};
      @media (max-width: 540px) {
        text-align: center;
      }
    }
    .service-secondary-description {
      color: ${(props) => props.theme.colors.fontColor.descriptionFontColor};
      font-style: italic;
      @media (max-width: 540px) {
        text-align: center;
      }
    }
    .begin-button-container {
      width: 100%;
      .begin-button {
        width: 100%;
      }
    }
  }
`;
export default ServiceCostStyle;