import styled from "styled-components";

const ServiceSectionStyle = styled.div`
  padding: 2rem 1rem;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  .service-section-header {
    .service-section-heading {
      font-weight: bold;
      color: ${(props) => props.theme.colors.fontColor.titleFontColor};
      @media (max-width: 540px) {
        text-align: center;
      }
    }
    .service-section-description {
      color: ${(props) => props.theme.colors.fontColor.descriptionFontColor};
      @media (max-width: 540px) {
        text-align: center;
      }
    }
  }
  .image-section {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    padding: 3rem 0rem;
    @media (max-width: 380px) {
      gap: 0rem;
      justify-content: space-between;
    }
    .service-img {
      @media (max-width: 570px) {
        width: 3rem !important;
        height: 2rem;
      }
    }
    .bank-icon {
      width: 4.52rem;
    }
    .micappital-zero-icon {
      width: 8.06rem;
    }
    .increase-icon {
      width: 5.21rem;
    }
    .black-arrow {
      height: 0.62rem !important;
      @media (max-width: 570px) {
        width: 3rem;
      }
      @media (max-width: 380px) {
        width: 2rem;
      }
    }
  }
`;
export default ServiceSectionStyle;
