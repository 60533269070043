import styled from "styled-components";

const ObjectiveStyle = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  .nuevoObjetivoTexto {
    color: ${(props) => props.theme.colors.button.background.redButton};
    font-weight: bold;
    margin-top: 0;
  }
  .objetivoContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    position: relative;
    min-width: 16rem;
    min-height: 10rem;
    padding: 1rem 1rem 0.2rem 1rem;
    border-radius: 1rem;
    box-shadow: 0.5rem 0.5rem 1.87rem 0 rgb(11 57 59 / 18%);
    .topText {
      position: absolute;
      top: 0.5rem;
      right: 0.5rem;
      min-width: 4rem;
      width: fit-content;
      min-height: 1.5rem;
      text-align: center;
      border-radius: 0.6rem;
      font-weight: bold;
      padding: 0rem 0.3rem;
    }
    .addObjetiveImg {
      width: 3rem;
    }
    .imageContainer {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      height: 100%;
      min-width: 4rem;
      .objetivoImg {
        width: 5rem;
      }
    }
    .contentsContainer {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      padding-left: 0.5rem;
      gap: 0.3rem;
      .objectiveTitle {
        width: 100%;
        font-weight: bold;
        color: ${(props) => props.theme.colors.fontColor.fieldTitleColor};
      }
      .dateAmountContainer {
        display: flex;
        justify-content: flex-start;
        width: 100%;
        gap: 0.5rem;
        font-style: italic;

        .date {
          background-color: ${(props) =>
            props.theme.colors.fontColor.objectiveFontOpec};
          min-width: 5rem;
          min-height: 1.5rem;
          width: fit-content;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 0.6rem;
          color: ${(props) => props.theme.colors.fontColor.objectiveFontColor};
        }
        .amount {
          background-color: ${(props) =>
            props.theme.colors.fontColor.objectiveFontOpec};
          color: ${(props) => props.theme.colors.fontColor.objectiveFontColor};
          min-width: 5rem;
          width: fit-content;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 0.6rem;
        }
      }
      .savingsContainer {
        display: flex;
        justify-content: flex-start;
        color: ${(props) => props.theme.colors.fontColor.objectiveFontColor};
        min-width: 100%;
        width: fit-content;
        align-items: center;
        border-radius: 0.6rem;
        min-height: 1.7rem;
        font-style: italic;

        .icon {
          display: flex;
          justify-content: center;
          align-items: center;
          padding-left: 0.5rem;
          .iconInside {
            width: 1rem;
          }
        }
        .savingsText {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          padding-left: 0.5rem;
          width: 100%;
        }
      }
      .totalSavings {
        color: ${(props) => props.theme.colors.fontColor.objectiveFontColor};
        width: 100%;
        font-style: italic;
      }
    }
  }
`;
export default ObjectiveStyle;
