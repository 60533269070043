import URL_CONSTANTS from "../../../constants/urlConstants/urlConstants";
import axios from "axios";

export const getLoginToken = (data) => {
  return axios.post(
    process.env.REACT_APP_BASE_URL + URL_CONSTANTS.get_login_tokens,
    data
  );
};
// export const getLoginData = (data) => {
//   return Request.post(URL_CONSTANTS.get_login_data, data);
// };

export const getLoginData = (data) => {
  return axios.post(
    process.env.REACT_APP_BASE_URL + URL_CONSTANTS.get_login_data,
    data
  );
};
