import styled from "styled-components";

const Text = styled.div`
  ${(props) => {
    // eslint-disable-next-line default-case
    switch (props.variant) {
      case "normal":
        return `
            @media(min-width: 320px){
                font-size: 0.75rem;
            }
            @media(min-width: 500px){
                font-size: 0.8rem;
            }
            @media(min-width: 768px){
                font-size: 0.9rem;
            }
            @media(min-width: 1000px){
                font-size: 0.9rem;
            }
            @media(min-width: 1500px){
                font-size: 1rem;
            }
            `;
      case "extraSmall":
        return `
            @media(min-width: 320px){
                font-size: 0.6rem;
            }
            @media(min-width: 500px){
                font-size: 0.6rem;
            }
            @media(min-width: 768px){
                font-size: 0.65rem;
            }
            @media(min-width: 1000px){
                font-size: 0.7rem;
            }
            @media(min-width: 1500px){
                font-size: 0.7rem;
            }
            `;

      case "extraLarge":
        return `
            @media(min-width: 260px){
                font-size: 2.3rem;
            }
            @media(min-width: 320px){
                font-size: 2.7rem;
            }
            @media(min-width: 500px){
                font-size: 2.5rem;
            }
            @media(min-width: 768px){
                font-size: 2.7rem;
            }
            @media(min-width: 1000px){
                font-size: 2.5rem;
            }
            @media(min-width: 1500px){
                font-size: 2.6rem;
            }
            `;
      case "button":
        return `
            @media(min-width: 320px){
                font-size: 1.5rem;
            }
            @media(min-width: 500px){
                font-size: 1.6rem;
            }
            @media(min-width: 768px){
                font-size: 1.7rem;
            }
            @media(min-width: 1000px){
                font-size: 1.8rem;
            }
            @media(min-width: 1500px){
                font-size: 1.8rem;
               
            }
            `;
      case "smallFont":
        return `
                @media(min-width: 320px){
                font-size: 0.7rem;
            }
            @media(min-width: 500px){
                font-size: 0.7rem;
            }
            @media(min-width: 768px){
                font-size: 0.8rem;
            }
            @media(min-width: 1000px){
                font-size: 0.8rem;
            }
            @media(min-width: 1500px){
                font-size: 0.8rem;
            }
            `;
      case "mediumFont":
        return `
                @media(min-width: 320px){
                font-size: 1.4rem;
            }
            @media(min-width: 500px){
                font-size: 1.5rem;
            }
            @media(min-width: 768px){
                font-size: 1.5rem;
            }
            @media(min-width: 1000px){
                font-size: 1.7rem;
            }
            @media(min-width: 1500px){
                font-size: 1.8rem;
            }
          
                `;
      case "titleFont":
        return `
                @media(min-width: 320px){
                font-size: 1.2rem;
            }
            @media(min-width: 500px){
                font-size: 1.2rem;
            }
            @media(min-width: 768px){
                font-size: 1.4rem;
            }
            @media(min-width: 1000px){
                font-size: 1.5rem;
            }
            @media(min-width: 1500px){
                font-size: 1.5rem;
            }
            @media(min-width: 2000px){
                font-size: 1.5rem;
            }
                `;
      case "fieldFont":
        return `
                @media(min-width: 320px){
                font-size: 0.9rem;
            }
            @media(min-width: 500px){
                font-size: 0.9rem;
            }
            @media(min-width: 768px){
                font-size: 0.9rem;
            }
            @media(min-width: 1000px){
                font-size: 1rem;
            }
            @media(min-width: 1500px){
                font-size: 1rem;
            }
            @media(min-width: 2000px){
                font-size: 1rem;
            }
                `;
      case "labelFont":
        return `
                @media(min-width: 320px){
                font-size: 1rem;
            }
           
            @media(min-width: 768px){
                font-size: 1.1rem;
            
                `;
      case "primaryFont":
        return `
                @media(min-width: 320px){
                font-size: 1.2rem;
            }
            @media(min-width: 500px){
                font-size: 1.2rem;
            }
            @media(min-width: 768px){
                font-size: 1.2rem;
            }
            @media(min-width: 1000px){
                font-size: 1.3rem;
            }
            @media(min-width: 1500px){
                font-size: 1.4rem;
            }
            @media(min-width: 2000px){
                font-size: 1.4rem;
            }
                `;
      case "smallerText":
        return `
                @media(min-width: 320px){
                font-size: 0.8rem;
            }
            @media(min-width: 500px){
                font-size: 0.8rem;
            }
            @media(min-width: 768px){
                font-size: 0.9rem;
            }
            @media(min-width: 1000px){
                font-size: 0.9rem;
            }
            @media(min-width: 1500px){
                font-size: 0.9rem;
            }
            @media(min-width: 2000px){
                font-size: 0.9rem;
            }
                `;
      case "largeText":
        return `
                @media(min-width: 320px){
                font-size: 1.6rem;
            }
            @media(min-width: 500px){
                font-size: 1.8rem;
            }
            @media(min-width: 768px){
                font-size: 2rem;
            }
            @media(min-width: 1000px){
                font-size: 2.1rem;
            }
            @media(min-width: 1500px){
                font-size: 2.1rem;
            }
            @media(min-width: 2000px){
                font-size: 2.1rem;
            }`;
      case "popUpText":
        return `
                @media(min-width: 320px){
                font-size: 1.1rem;
            }
            @media(min-width: 500px){
                font-size: 1.2rem;
            }
            @media(min-width: 768px){
                font-size: 1.2rem;
            }
            @media(min-width: 1000px){
                font-size: 1.2rem;
            }
            @media(min-width: 1500px){
                font-size: 1.2rem;
            }
            @media(min-width: 2000px){
                font-size: 1.2rem;
            }`;
      case "doubleXl":
        return `
                @media(min-width: 320px){
                font-size: 2rem;
            }
            @media(min-width: 500px){
                font-size: 3rem;
            }
            @media(min-width: 768px){
                font-size: 5rem;
            }
            @media(min-width: 1000px){
                font-size: 6rem;
            }
            @media(min-width: 1500px){
                font-size: 7rem;
            }
            `;
      case "popUpFont":
        return `
                @media(min-width: 320px){
                font-size: 0.8rem;
            }
            @media(min-width: 500px){
                font-size: 0.9rem;
            }
            @media(min-width: 768px){
                font-size: 1rem;
            }
            @media(min-width: 1000px){
                font-size: 1rem;
            }
           `;
      case "newTitle":
        return `
                @media(min-width: 320px){
                font-size: 2.5rem;
            }
            @media(min-width: 768px){
                font-size: 2.6rem;
            }
            @media(min-width: 1040px){
                font-size: 2.8rem;
            }
            @media(min-width: 1300px){
                font-size: 3rem;
            }
           `;
      case "tableTitle":
        return `
                @media(min-width: 320px){
                font-size: 0.7rem;
            }
            @media(min-width: 768px){
                font-size: 0.9rem;
            }
           
            @media(min-width: 1500px){
                font-size: 1.1rem;
            }
            @media(min-width: 2000px){
                font-size: 1.1rem;
            }
           `;
      case "seeMore":
        return `
                @media(min-width: 320px){
                font-size: 0.5rem;
            }
            @media(min-width: 768px){
                font-size: 0.0.6rem;
            }
            @media(min-width: 1040px){
                font-size: 0.9rem;
            }
          
           `;
      case "tableValue":
        return `
         
            @media(min-width: 320px){
                font-size: 0.7rem;
            }
            @media(min-width: 758px){
                font-size: 0.8rem;
            }
            @media(min-width: 1000px){
                font-size: 0.9rem;
            }
            @media(min-width: 1500px){
                font-size: 1rem;
            }
            `;
      case "smallLabel":
        return `
            @media(min-width: 320px){
                font-size: 0.5rem;
            }
            @media(min-width: 1500px){
                font-size: 0.6rem;
            }
            `;
    }
  }}
  ${(props) => {
    switch (props.type) {
      case "primary":
        return `
            {
                font-family: 'Asap';
            }`;
      default:
        return `
            {
                font-family: 'Asap'
            }
            `;
    }
  }}
`;
export default Text;
