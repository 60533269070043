import styled from "styled-components";

const TutorialPopUpStyle = styled.div`
  .tutorial-container {
    width: 100%;
    background-color: ${(props) =>
      props.theme.colors.background.defaultBackground};
    padding: 2rem;
    border-radius: 1.5625rem;
  }
  .tutorial-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    @media (max-width: 600px) {
      flex-direction: column;
      gap: 2rem;
      align-items: center;
    }
    .tutorial-heading {
      color: ${(props) => props.theme.colors.fontColor.titleFontColor};
      font-weight: bold;
    }
    .save-btn {
      .btn-div{
        height: 2.5rem;
        width: 11.25rem;
      }
    }
  }
`;
export default TutorialPopUpStyle;
