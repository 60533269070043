import styled from "styled-components";

const InviteYourFriendsStyle = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3rem;
  .invite-friends-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    .add-friends-icon {
    }
    .invite-title {
      font-weight: bold;
      color: ${(props) => props.theme.colors.fontColor.descriptionFontColor};
      text-align: center;
    }
    .link-description {
      color: ${(props) => props.theme.colors.fontColor.descriptionFontColor};
      font-weight: 500;
      padding-bottom: 2rem;
      text-align: center;
    }
    .link-container {
      display: flex;
      width: 85%;
      max-width: 40rem;
      background-color: ${(props) =>
        props.theme.colors.background.imageBackground};
      border-radius: 1.5625rem;
      align-items: center;
      justify-content: space-between;
      position: relative;
      .copy-link-icon {
        border-radius: 1.5625rem;
        border: 0.187rem solid
          ${(props) => props.theme.colors.background.primaryCardBackground};
        padding: 0.3125rem;
        cursor: pointer;
        img {
          width: 1.56rem;
          height: 1.12rem;
        }
      }
      .invite-link {
        padding: 0.625rem 0.3125rem 0.625rem 3rem;
        color: ${(props) => props.theme.colors.text.fieldText};
        font-weight: 700;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden !important;
        @media (max-width: 1024px) {
          padding: 0.625rem 0.3125rem 0.625rem 2rem;
        }
        @media (max-width: 621px) {
          padding: 0.625rem 0.3125rem 0.625rem 1rem;
        }
        .link-field {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      .copy-message {
        color: ${(props) => props.theme.colors.fontColor.copiedMessageColor};
        font-weight: 500;
        position: absolute;
        bottom: -1.5rem;
        left: 4.5rem;
      }
    }
  }
  .offer-container {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    gap: 2rem;
    justify-content: center;
    .offer-data-container {
      background-color: ${(props) =>
        props.theme.colors.background.defaultFieldBackground};
      min-height: 16rem;
      border-radius: 3.12rem;
      max-width: 12.5rem;
      min-width: 11.87rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      flex: 0 1 30%;
      padding: 2rem 1rem;
      .offer-icon {
        padding-bottom: 2rem;
        img {
          width: 3rem;
        }
      }
      .offer-title {
        font-weight: 500;
        text-align: center;
        color: ${(props) => props.theme.colors.fontColor.descriptionFontColor};
        padding-bottom: 1.5rem;
      }
      .offer-description {
        font-style: italic;
        text-align: center;
        color: ${(props) => props.theme.colors.fontColor.descriptionFontColor};
        font-weight: 400;
      }
    }
  }
`;

export default InviteYourFriendsStyle;
