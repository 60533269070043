import styled from "styled-components";

const SummaryActionStyle = styled.div`
  width: 100%;
  @media (max-width: 1040px) {
    width: 100%;
    height: 100%;
  }

  .panelResumenContainer {
    display: flex;
    flex-direction: column;
    padding: 1.25rem;
    @media (max-width: 1040px) {
      justify-content: center;
      align-items: center;
      border-radius: 0rem;
      height: 100%;
      background-color: ${(props) =>
        props.theme.colors.background.bodyBackground};
    }
    .swiper-summary-container {
      width: 100%;
      @media (min-width: 1040px) {
        display: none;
      }
      .summary-swiper-inner-container {
        .swiper-slide {
          display: flex;
          flex-direction: column;
          align-items: center;
        }
        .swiper-pagination {
          position: absolute;
          top: 0rem;
          height: fit-content;
        }
      }
    }
    .tituloResumen {
      color: ${(props) => props.theme.colors.fontColor.titleFontColor};
      font-weight: bold;
      margin: 0.625rem;
    }
    .normal-title {
      @media (max-width: 1040px) {
        display: none;
      }
    }
    .responsive-title {
      @media (min-width: 1040px) {
        display: none;
      }
    }
    .subtituloResumen {
      color: ${(props) => props.theme.colors.fontColor.descriptionFontColor};
      margin: 0.625rem;
      @media (max-width: 1040px) {
        text-align: center;
      }
    }

    .alertText {
      color: ${(props) =>
        props.theme.colors.fontColor.titleFontColor} !important;
      font-weight: bold;
      @media (max-width: 1040px) {
        text-align: center;
      }
    }
    .cambiarText {
      color: ${(props) =>
        props.theme.colors.fontColor.titleFontColor} !important;
      text-decoration: underline;
      @media (max-width: 1040px) {
        color: ${(props) =>
          props.theme.colors.button.background.redButton} !important;
      }
    }
    .cambiarText:hover {
      cursor: pointer;
    }
    .imageContainer {
      display: flex;
      @media (max-width: 991px) {
        width: auto;
      }
      @media (max-width: 991px) {
        padding-left: 0 !important;
      }
    }
    .noPaddingLeft {
      padding-left: 0 !important;
    }
    .inforow {
      @media (max-width: 1040px) {
        margin-top: 1.25rem !important;
      }
    }
    .responsive-container {
      @media (max-width: 991px) {
        display: flex;
        flex-wrap: nowrap;
      }
      @media (max-width: 430px) {
        padding: 0.625rem !important;
      }
    }
    .bulletContainer {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      text-align: right;
    }
    .content-container {
      @media (max-width: 1040px) {
        display: none;
      }
      @media (max-width: 991px) {
        width: auto;
      }
      @media (max-width: 400px) {
        padding-right: 0rem !important;
        padding-left: 0rem !important;
      }
    }
    .infoContainer {
      width: 100%;
      text-align: left;
    }
    .width-setter {
      @media (max-width: 632px) {
        width: auto;
      }
      .invest-container {
        display: flex;
        @media (max-width: 400px) {
          flex-direction: column;
        }
      }
      .invest-inner-container {
        display: flex;
        align-items: end;
        padding-left: 0.5rem;
        padding-bottom: 0.3rem;
      }
      .avatar-size {
        font-size: 0rem;
      }
      .word-breaker {
        display: none;
        @media (max-width: 380px) {
          display: inline;
        }
      }
    }
    .responsive-div {
      display: flex;
      flex-direction: column;
      @media (max-width: 462px) {
        padding: 0rem !important;
      }
    }
    .wrap-div {
      @media (max-width: 370px) {
        display: contents;
      }
    }
    .customPadding {
      padding-right: 0rem !important;
    }
    .circulo {
      color: ${(props) => props.theme.colors.background.primaryBackground};
      border-radius: 50%;
      text-align: right;
      padding: 0.625rem;
      width: 1.87rem;
      height: 1.87rem;
      margin-left: calc(50% - 1.625rem);
    }
    .avatarImgResumen {
      width: 2rem;
      object-fit: contain;
    }

    .bigtext {
      color: ${(props) => props.theme.colors.fontColor.descriptionFontColor};
      font-weight: bold;
    }
    .performance-div {
      @media (max-width: 370px) {
        padding: 0rem;
      }
    }
    .textoVideoTurotial {
      color: ${(props) => props.theme.colors.fontColor.titleFontColor};
      font-weight: bold;
      text-decoration: underline;
      @media (max-width: 370px) {
        padding: 0rem;
      }
    }
    .textoVideoTurotial:hover {
      cursor: pointer;
    }
    .button-div {
      display: flex;
      justify-content: center;
      @media (max-width: 1040px) {
        width: 100%;
      }
    }
    .red-button {
      width: 19.37rem;
      height: 2.68rem;
      margin: 1rem 0 0;
      padding: 0.5rem 0.68rem 0.75rem 0.75rem;
      border-radius: 1.34375rem;
      background-color: ${(props) =>
        props.theme.colors.button.background.redButton};
      text-align: center;
      color: ${(props) => props.theme.colors.background.defaultBackground};
      @media (max-width: 1040px) {
        margin: 0rem;
      }
    }
    .redbtn-div {
      width: 100%;
      max-width: 22.5rem;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .red-button:hover {
      cursor: pointer;
    }
    .skip-button {
      text-align: center;
      padding: 1rem 0rem;
      font-weight: bold;
      color: ${(props) => props.theme.colors.fontColor.titleFontColor};
      cursor: pointer;
      text-decoration: underline;
      width: 100%;
      @media (max-width: 1040px) {
        display: none;
      }
    }
    .responsive-investment-container {
      padding-left: 1rem;
      width: 100%;

      .investment-details-container {
        display: flex;
        flex-direction: column;
        gap: 2rem;
        .fund-details {
          display: flex;
          align-items: center;
          width: 100%;
          gap: 1rem;
          @media (max-width: 920px) {
            justify-content: start;
          }
          .circle-icon {
            width: 1.9rem;
            height: 1.9rem;
          }
          .fund-info {
            .fund-title {
              color: ${(props) =>
                props.theme.colors.fontColor.descriptionFontColor};
              font-weight: 500;
            }
            .fund-count {
              color: ${(props) =>
                props.theme.colors.fontColor.descriptionFontColor};
              font-weight: bold;
              display: flex;
              align-items: baseline;
              .fund-field {
                word-break: break-all;
              }
              .cambiarText {
                display: flex;
                align-items: end;
                padding-left: 0.5rem;
              }
            }
          }
        }
        .bank-details {
          display: flex;
          align-items: center;
          width: 100%;
          gap: 1rem;
          @media (max-width: 920px) {
            justify-content: start;
          }
          .circle-icon {
            width: 1.9rem;
            height: 1.9rem;
          }
          .bank-info {
            .bank-title {
              color: ${(props) =>
                props.theme.colors.fontColor.descriptionFontColor};
              font-weight: 500;
            }
            .bank-img {
              display: flex;
              justify-content: flex-start;
            }
          }
        }
        .help-details {
          display: flex;
          align-items: center;
          width: 100%;
          gap: 1rem;
          @media (max-width: 920px) {
            justify-content: start;
          }
          .circle-icon {
            width: 1.9rem;
            height: 1.9rem;
          }
          .help-info {
            .help-title {
              color: ${(props) =>
                props.theme.colors.fontColor.descriptionFontColor};
              font-weight: 500;
            }
            .help-tutorial {
              color: ${(props) => props.theme.colors.fontColor.titleFontColor};
              font-weight: bold;
              cursor: pointer;
            }
          }
        }
      }
    }
    .new-investment-container {
      @media (min-width: 1040px) {
        display: none;
      }
      .investment-details-container {
        display: flex;
        flex-direction: column;
        gap: 2rem;
        .fund-details {
          display: flex;
          align-items: center;
          width: 100%;
          gap: 1rem;
          @media (max-width: 920px) {
            justify-content: start;
          }
          .circle-icon {
            width: 1.9rem;
            height: 1.9rem;
          }
          .fund-info {
            .fund-title {
              color: ${(props) =>
                props.theme.colors.fontColor.descriptionFontColor};
              font-weight: 500;
            }
            .fund-count {
              color: ${(props) =>
                props.theme.colors.fontColor.descriptionFontColor};
              font-weight: bold;
              display: flex;
              align-items: baseline;
              .fund-field {
                word-break: break-all;
              }
              .cambiarText {
                display: flex;
                align-items: end;
                padding-left: 0.5rem;
              }
            }
          }
        }
        .bank-details {
          display: flex;
          align-items: center;
          width: 100%;
          gap: 1rem;
          @media (max-width: 920px) {
            justify-content: start;
          }
          .circle-icon {
            width: 1.9rem;
            height: 1.9rem;
          }
          .bank-info {
            .bank-title {
              color: ${(props) =>
                props.theme.colors.fontColor.descriptionFontColor};
              font-weight: 500;
            }
            .bank-img {
              display: flex;
              justify-content: flex-start;
            }
          }
        }
        .help-details {
          display: flex;
          align-items: center;
          width: 100%;
          gap: 1rem;
          @media (max-width: 920px) {
            justify-content: start;
          }
          .circle-icon {
            width: 1.9rem;
            height: 1.9rem;
          }
          .help-info {
            .help-title {
              color: ${(props) =>
                props.theme.colors.fontColor.descriptionFontColor};
              font-weight: 500;
            }
            .help-tutorial {
              color: ${(props) => props.theme.colors.fontColor.titleFontColor};
              font-weight: bold;
              cursor: pointer;
            }
          }
        }
      }
    }
  }
`;

export default SummaryActionStyle;
