import styled from "styled-components";

const DocumentSectionStyle = styled.div`
  padding: 2rem;
  margin-bottom: 1rem;
  .document-section-heading {
    color: ${(props) => props.theme.colors.fontColor.titleFontColor};
    font-weight: bold;
    margin-bottom: 2rem;
    @media (max-width: 540px) {
      text-align: center;
    }
  }
  .button-container {
    display: flex;
    justify-content: space-between;
    gap: 1rem;

    @media (max-width: 1300px) {
      flex-direction: column;
      gap: 1rem;
      align-items: center;
    }
    .inner-button-container {
      flex: 1;
      .inner-button {
        font-weight: bold;
        width: 100%;
        max-width: 15rem;
        min-width: 12.5rem;
        background-color: ${(props) =>
          props.theme.colors.fontColor.titleFontColor};
      }
    }
  }
`;
export default DocumentSectionStyle;
