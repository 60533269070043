import React, { useEffect, useState, useCallback } from "react";
import { useFormik } from "formik";
import DefineObjectiveStyle from "./defineObjectiveStyle";
import {
  monthArray,
  yearArray,
} from "../../../../../../models/goalSelectionArray";
import NewTextField from "../../../../../../components/euroTextField/euroTextField";
import SelectField from "../../../../../../components/selectField/selectField";
import Text from "../../../../../../theme/font";

const DefineObjective = React.memo(({ changeData, amount, year, month }) => {
  const [userData, setUserData] = useState(amount ? amount : "");
  const [userMonth, setUserMonth] = useState(month ? month : "");
  const [userYear, setUserYear] = useState(year ? year : "");
  const [checkState, setCheckState] = useState("greater");
  const [checkYear, setCheckYear] = useState("");

  const currentMonth = new Date().getMonth() + 1;
  const currentYear = new Date().getFullYear();
  const nextYear = new Date().getFullYear() + 1;

  const filteredYear = yearArray.filter(
    (item) => item.value >= currentYear || item.value === "00"
  );

  useEffect(() => {
    if (userYear !== "") {
      const newFilterArray = yearArray.filter(
        (item) => item.value >= userYear || item.value === "00"
      );
      setCheckYear([...newFilterArray]);
    }
  }, [userYear]);

  useEffect(() => {
    if (amount) {
      setUserData(amount);
    }
    if (month) {
      setUserMonth(month);
    }
    if (year) {
      setUserYear(year);
    }
  }, [amount, month, year]);

  //for setting the initial value when the page load
  const initialValueHandler = (type) => {
    // eslint-disable-next-line default-case
    switch (type) {
      case "amount": {
        if (amount) {
          return amount;
        } else {
          return "";
        }
      }
      case "month": {
        if (month) {
          return month;
        } else {
          return "";
        }
      }
      case "year": {
        if (year) {
          return year;
        } else {
          return "";
        }
      }
    }
  };

  const formik = useFormik({
    initialValues: {
      amount: initialValueHandler("amount"),
      month: initialValueHandler("month"),
      year: initialValueHandler("year"),
    },
  });

  //for changing the values of state and changing the setFieldValue
  const changeHandler = useCallback(
    (values, element) => {
      if (element === "amount") {
        if (values.length < 9) {
          changeData(values, element);
          formik.setFieldValue("amount", values);
        } else {
          const split = values.slice(0, 9);
          changeData(split, element);
          formik.setFieldValue("amount", split);
        }
      } else if (element === "month") {
        changeData(values, element);
        setUserMonth(values);
      } else if (element === "year") {
        if (
          parseInt(values) === currentYear &&
          userMonth &&
          userMonth < currentMonth
        ) {
          setUserMonth(currentMonth);
        }
        changeData(values, element);
        setUserYear(values);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [userMonth, userYear]
  );

  const checkIfNumber = (event) => {
    const regex = new RegExp(
      /(^\d*$)|(Backspace|Tab|Delete|ArrowLeft|ArrowRight)/
    );

    return !event.key.match(regex) && event.preventDefault();
  };

  //it passes a string to a state which will change the options inside select field according to these conditions
  const monthOptionhandler = () => {
    if (parseInt(userYear) === "") {
      setCheckState("greater");
    } else if (parseInt(userYear) === currentYear) {
      setCheckState("equal");
    } else if (parseInt(userYear) > currentYear) {
      setCheckState("greater");
    } else if (parseInt(userYear) < currentYear) {
      setCheckState("less");
    }
  };

  useEffect(() => {
    monthOptionhandler();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userYear, userMonth]);

  return (
    <DefineObjectiveStyle>
      <div className="goal-container">
        <div className="new-goal-title">
          <Text variant="primaryFont">Define tu objetivo</Text>
        </div>
        <form onSubmit={formik.handleSubmit}>
          <div className="field-container">
            <div className="amount-goal">
              <div className="amount-goal-title">
                <Text variant="primaryFont">¿Cuánto cuesta?</Text>
              </div>
              <div className="amount-goal-field">
                <NewTextField
                  value={userData}
                  className="amount-input-field"
                  variant="largeText"
                  onKeyDown={(evt) => checkIfNumber(evt)}
                  name="amount"
                  type="number"
                  onChange={changeHandler}
                  max={9}
                />
              </div>
            </div>
            <div className="amount-goal">
              <div className="amount-goal-title">
                <Text variant="primaryFont">¿Para cuándo?</Text>
              </div>
              <div className="date-field-container">
                <div className="primary-date-field">
                  <SelectField
                    value={userMonth}
                    name="month"
                    id="month-field"
                    data={monthArray}
                    handleChange={(e) => changeHandler(e.target.value, "month")}
                    selected
                    currentValue={currentMonth}
                    check={checkState}
                  />
                </div>
                <div className="secondary-date-field">
                  <SelectField
                    value={userYear}
                    name="year"
                    id="year-field"
                    // if userYear is empty or user year is greater than current year the data will be filteredYear or it will be checkYear
                    data={
                      userYear === "" || userYear >= currentYear
                        ? filteredYear
                        : checkYear
                    }
                    handleChange={(e) => changeHandler(e.target.value, "year")}
                    selected
                    // if userMonth is less than current month, currentValue will be nextYear and if it's not it will be currentYear
                    currentValue={
                      userMonth && userMonth < currentMonth
                        ? nextYear
                        : currentYear
                    }
                    check={checkState}
                  />
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </DefineObjectiveStyle>
  );
});

export default DefineObjective;
