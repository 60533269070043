import { useEffect, useState } from "react";
import ChangeProposalStyle from "./changeProposalStyle";
import ImageConstants from "../../../../constants/imageConstants/image_constants";
import Text from "../../../../theme/font";
import { toast } from "react-toastify";

const ChangeProposal = ({ data }) => {
  const [isCopied, setIsCopied] = useState(8);
  const [changeData, setChangeData] = useState(data && data.cambios);

  useEffect(() => {
    if (data && data.cambios) {
      setChangeData(data.cambios);
    }
  }, [data]);

  const copyToClipboard = (value, index) => {
    const text = value;
    setIsCopied(index);
    navigator.clipboard.writeText(text);
    setTimeout(() => {
      setIsCopied(8);
    }, [1000]);
  };

  const formato = (x) => {
    var regex = /\d+/g;
    var matches = x.match(regex);
    if (matches) {
      var number = new Intl.NumberFormat("de-DE", {
        minimumFractionDigits: 2, //Decimals for returns at the top of the chart
        maximumFractionDigits: 2,
      }).format(x);
      return `${number}€`;
    } else {
      return x;
    }
  };

  const titleHandler = (tipo) => {
    if (tipo === 0) {
      return "Traspaso parcial";
    }
    if (tipo === 1) {
      return "Traspaso total";
    }
    if (tipo === 2) {
      return "Venta";
    }
  };
  const importeHandler = (value) => {
    if (value.tipo === 1) {
      return "Traspaso total";
    }else
    {
      return formato(value.importe);
    }
  };

  const changeRecommendationHandler = () => {
    return (
      changeData &&
      changeData.map((value, index) => {
        return (
          <div className="change-recommendation-container" key={Math.random()}>
            <div className="title-container">
              <Text className="card-title">{titleHandler(value.tipo)}</Text>
            </div>

            <div className="amount-container">
              <Text className="amount-title">Importe</Text>
              <Text variant="mediumFont" className="amount">
                {importeHandler(value)}
                
              </Text>
            </div>
            <div className="amount-details">
              <div className="leaves-details">
                {value.tipo === 0 || value.tipo === 1 ? (
                  <div className="up-arrow"></div>
                ) : null}
                <Text variant="fieldFont" className="leaves-details-title">
                  Sale de
                </Text>
                <Text variant="fieldFont" className="leaves-primary-details">
                  {value.nombreOrigen}
                </Text>
                <div className="copy-section">
                  <Text variant="extraSmall" className="leaves-ISIN">
                    ISIN&nbsp;:
                    <span className="ISIN-number">
                      &nbsp;{value.isinOrigen}
                    </span>
                  </Text>
                  <div
                    className="copy-icon"
                    onClick={() => copyToClipboard(value.isinOrigen, index)}
                  >
                    <img src={ImageConstants.copyISIN} alt="x" />
                  </div>
                  {isCopied === index &&
                    toast.success("ISIN copiado", {
                      position: "top-right",
                      autoClose: 1000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      toastId: "",
                    })}
                </div>
              </div>
              {value.tipo === 0 || value.tipo === 1 ? (
                <div className="enter-details">
                  <Text variant="fieldFont" className="enter-details-title">
                    Entra en
                  </Text>
                  <Text variant="fieldFont" className="enter-primary-details">
                    {value.nombreDestino}
                  </Text>
                  <div className="copy-section">
                    <Text variant="extraSmall" className="enter-ISIN">
                      ISIN&nbsp;:
                      <span className="ISIN-number">
                        &nbsp;{value.isinDestino}
                      </span>
                    </Text>
                    <div
                      className="copy-icon"
                      onClick={() => copyToClipboard(value.isinDestino, index)}
                    >
                      <img src={ImageConstants.copyISIN} alt="x" />
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        );
      })
    );
  };

  return (
    <ChangeProposalStyle>
      <div className="change-proposal-container">
        <div className="change-proposal-icon">
          <img
            className="change-icon"
            alt="x"
            src={ImageConstants.changeIcon}
          />
        </div>
        <Text variant="titleFont" className="change-proposal-heading">
          Te recomendamos hacer cambios
        </Text>
        <Text variant="labelFont" className="change-proposal-description">
          Estos son los cambios que te proponemos para optimizar tu cartera de
          inversión.
        </Text>
        <div className="change-recommendations">
          {changeRecommendationHandler()}
        </div>
      </div>
    </ChangeProposalStyle>
  );
};

export default ChangeProposal;
