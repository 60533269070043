import React, { useState, useEffect, useContext } from "react";
import { ThemeProvider } from "styled-components";
import Button from "../../../../../../components/button/button";
import defaultTheme from "../../../../../../theme/theme";
import NewGoalPanelStyle from "./newGoalPanelStyle";
import Text from "../../../../../../theme/font";
import { AuthTokenService } from "../../../../../../services";
import { AlertContext } from "../../../../../common/alertContainer";
import { getIcons } from "../../goalServices";

const titles = ["ahorro", "casa", "coche", "hijos", "universidad", "viaje"];

const NewGoalPanel = ({ goal, changeData, data, imgSrc }) => {
  const theme = defaultTheme;
  const [goalSelectState, setGoalSelectState] = useState(goal);
  const [imgUrl, setImgUrl] = useState(null);
  const [objectiveIcons, setObjectiveIcons] = useState([]);
  const [keys, setKeys] = useState(data || "");
  const { open } = useContext(AlertContext);

  useEffect(() => {
    setKeys(data);
    const userId = AuthTokenService.getUserId();
    getIcons(userId)
      .then((response) => {
        open({
          alertType: "spinner",
          open: false,
        });
        setObjectiveIcons(response.data.data);
        let totalData = response.data.data;
        let tempKeys = Object.keys(response.data.data);
        let tempArray = [];
        // eslint-disable-next-line array-callback-return
        tempKeys.map((value) => {
          let title = titles.find((e) => {
            return value === e;
          });
          if (title) {
            const tempObj = { key: value, value: totalData[value] };
            tempArray = [...tempArray, tempObj];
          }
        });
        setObjectiveIcons([...tempArray]);
      })
      .catch(() => {
        open({
          alertType: "spinner",
          open: false,
        });
        open({
          type: "error",
          title: "Error al enviar datos",
          alertType: "toast",
          open: true,
        });
      });

    // eslint-disable-next-line default-case
    switch (data) {
      case "coche": {
        setImgUrl(imgSrc);
        break;
      }
      case "ahorro": {
        setImgUrl(imgSrc);
        break;
      }
      case "casa": {
        setImgUrl(imgSrc);
        break;
      }
      case "hijos": {
        setImgUrl(imgSrc);
        break;
      }
      case "universidad": {
        setImgUrl(imgSrc);
        break;
      }
      case "viaje": {
        setImgUrl(imgSrc);
        break;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const changeHandler = (values, element) => {
    setKeys(values);
    changeData(values, element);
  };

  const selectedGoalHandler = (value) => {
    if (keys) {
      if (keys === value) {
        return "goal-box selected-goal-box";
      } else {
        return "goal-box";
      }
    }else{
      return "goal-box"
    }
  };

  const upperCaseHandler = (value) => {
    let changedValue = "";
    if (value) {
      changedValue = value.charAt(0).toUpperCase() + value.slice(1);
      return changedValue;
    } else {
      return changedValue;
    }
  };

  const goalSettings = () => {
    if (goalSelectState === true) {
      return (
        <NewGoalPanelStyle>
          <div className="goal-img-container">
            <div className="change-btn-container">
              <Button
                className="change-btn"
                value="Cambiar objetivo"
                click={() => setGoalSelectState(false)}
              />
            </div>
            <div className="goal-img-panel">
              <img src={imgUrl} alt="x" />
            </div>
          </div>
        </NewGoalPanelStyle>
      );
    } else {
      return (
        <NewGoalPanelStyle>
          <div className="goal-panel-conatiner">
            <div className="goal-panel-inner-container">
              <div className="selection-panel-title">
                <Text variant="primaryFont">Selecciona un objetivo</Text>
              </div>
              <div className="selcection-panel-container">
                <div className="selection-container">
                  {objectiveIcons &&
                    objectiveIcons.length > 0 &&
                    objectiveIcons.map((post) => {
                      return (
                        <div
                          className={selectedGoalHandler(post.key)}
                          key={Math.random()}
                          onClick={() => changeHandler(post.key, "title")}
                        >
                          <div className="goal-icon">
                            <img
                              className="goal-img"
                              src={post.value}
                              alt="x"
                            />
                          </div>
                          <div className="goal-name">{upperCaseHandler(post.key)}</div>
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
          </div>
        </NewGoalPanelStyle>
      );
    }
  };

  return <ThemeProvider theme={theme}>{goalSettings()}</ThemeProvider>;
};

export default NewGoalPanel;
