import { ThemeProvider } from "styled-components";
import defaultTheme from "../../../../theme/theme";
import { sectorIcons } from "../../../../models/defaultPageArray";
import SectorsSectionStyle from "./sectorsSectionStyle";
import Text from "../../../../theme/font";

const SectorsSection = () => {
  const theme = defaultTheme;
  return (
    <ThemeProvider theme={theme}>
      <SectorsSectionStyle>
        <Text variant="titleFont" className="sectors-section-heading">
          Tu dinero invertido en los sectores de mayor impacto.
        </Text>
        <Text
          variant="smallerText"
          type="primary"
          className="sectors-container-description"
        >
          Elaboramos las carteras para que tu inversión ayude a conseguir los
          ODS (Objetivos de Desarrollo Sostenible), siguiendo las directrices de
          la ONU.
        </Text>
        <div className="sector-options">
          <div className="sector-options-container">
            {sectorIcons &&
              sectorIcons.map((data) => {
                return (
                  <div className="sector-icon" key={Math.random()}>
                    <img alt="x" src={data.src} />
                  </div>
                );
              })}
          </div>
        </div>
      </SectorsSectionStyle>
    </ThemeProvider>
  );
};
export default SectorsSection;
