import { useNavigate, useLocation } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import defaultTheme from "../../theme/theme";
import ImageConstants from "../../constants/imageConstants/image_constants";
import NewMenuStyle from "./newMenuStyle";
import Text from "../../theme/font";
import { AuthTokenService } from "../../services";

const NewMenu = ({ closeSideBar, headerTitle }) => {
  const theme = defaultTheme;
  const navigate = useNavigate();
  const userId = AuthTokenService.getUserId();
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const section = query.get("section");

  const navHandler = (value) => {
    navigate(value);
    if (closeSideBar) {
      closeSideBar();
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <NewMenuStyle>
        <div className="logo-container">
          <img
            alt="micappital"
            className="logo-icon"
            src={ImageConstants.newLogo}
          />
        </div>
        <div className="menu-items-container">
          {userId && (
            <div
              className={
                headerTitle &&
                headerTitle === "Inversión" &&
                section !== "common"
                  ? "selected-container"
                  : "not-selected-container"
              }
            >
              <img
                alt="x"
                className="menu-option-icon"
                src={
                  headerTitle &&
                  headerTitle === "Inversión" &&
                  section !== "common"
                    ? ImageConstants.selectedInversion
                    : ImageConstants.inversion
                }
              />
              <Text
                variant="fieldFont"
                className="menu-item"
                onClick={() => navHandler("/resumen")}
              >
                INVERSIÓN
              </Text>
            </div>
          )}

          <div
            className={
              headerTitle && headerTitle === "Seguros"
                ? "selected-container"
                : "not-selected-container"
            }
          >
            <img
              alt="x"
              className="seguro-icon"
              src={
                headerTitle && headerTitle === "Seguros"
                  ? ImageConstants.selectedSeguros
                  : ImageConstants.seguros
              }
            />
            <Text
              variant="fieldFont"
              className="menu-item"
              onClick={() => navHandler("/renderContainer")}
            >
              SEGUROS
            </Text>
          </div>
        </div>
        <div className="settings-container">
          <div
            className={
              section === "common"
                ? "selected-container"
                : "not-selected-container"
            }
          >
            <img
              alt="x"
              className="seguro-icon"
              src={
                section === "common"
                  ? ImageConstants.secondaryMenuIcon
                  : ImageConstants.primaryMenuIcon
              }
            />
            <Text
              variant="fieldFont"
              className="menu-item"
              onClick={() => navHandler(`/ajustes?section=${"common"}`)}
            >
              AJUSTES
            </Text>
          </div>
        </div>
      </NewMenuStyle>
    </ThemeProvider>
  );
};

export default NewMenu;
