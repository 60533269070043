import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { ThemeProvider } from "styled-components";
import { AlertContext } from "../../../common/alertContainer";
import { AuthTokenService } from "../../../../services";
import Button from "../../../../components/button/button";
import defaultTheme from "../../../../theme/theme";
import EcoInvestmentStyle from "./ecoFundInvestmentStyle";
import { postEcoInvestment } from "../defaultScreenServices";
import Text from "../../../../theme/font";
import TextField from "../../../../components/textField/textField";

const EcoFundInvestment = () => {
  const theme = defaultTheme;
  const { open } = useContext(AlertContext);
  const navigate = useNavigate();

  const validate = Yup.object({
    investment: Yup.number()
      .typeError("El importe es necesario")
      .required("El importe es necesario"),
  });

  const changeHandler = (values, setFieldValue) => {
    if (values.length < 9) {
      setFieldValue("investment", values);
    } else {
      const split = values.slice(0, 9);
      setFieldValue("investment", split);
    }
  };

  const checkIfNumber = (event) => {
    const regex = new RegExp(
      /(^\d*$)|(Backspace|Tab|Delete|ArrowLeft|ArrowRight)/
    );

    return !event.key.match(regex) && event.preventDefault();
  };

  const variantHandler = (conditionalValue, resultValue) => {
    let variant = "";
    if (conditionalValue && resultValue) {
      variant = "none";
    } else {
      variant = "outlined";
    }
    return variant;
  };

  const formValidationHandler = (conditionalValue, resultValue) => {
    let result = "";
    if (conditionalValue) {
      result = resultValue;
    }
    return result;
  };

  const fundSubmitHandler = (values) => {
    open({
      alertType: "spinner",
      open: true,
    });
    const investmentData = {
      idCliente: AuthTokenService.getUserId(),
      importe: values.investment,
    };

    postEcoInvestment(investmentData)
      .then((response) => {
        open({
          alertType: "spinner",
          open: false,
        });
        navigate("/ecoInvestments");
      })
      .catch((error) => {
        open({
          alertType: "spinner",
          open: false,
        });
        let message;
        if (error) {
          message = error.response.data.msg;
        } else {
          message = "error";
        }
        open({
          type: "error",
          title: message,
          alertType: "toast",
          open: true,
        });
      });
  };

  return (
    <ThemeProvider theme={theme}>
      <EcoInvestmentStyle>
        <Text
          variant="titleFont"
          type="primary"
          className="eco-investment-heading"
        >
          Comienza tu inversión Eco
        </Text>
        <Text
          variant="normal"
          type="primary"
          className="eco-investment-sub-heading"
        >
          La mejor rentabilidad, siempre <br />
          sostenible.
        </Text>
        <Text
          variant="smallerText"
          type="primary"
          className="eco-investment-description"
        >
          Confirma la cantidad con la que quieres empezar a invertir para que
          podamos buscar los mejores productos para ti y calcular cuanto dinero
          deberías invertir en cada producto
        </Text>

        <Formik
          initialValues={{ investment: "" }}
          onSubmit={(values) => {
            fundSubmitHandler(values);
          }}
          validationSchema={validate}
        >
          {({
            values,
            errors,
            touched,
            handleSubmit,
            submitForm,
            handleChange,
            setFieldValue,
          }) => (
            <Form
              onSubmit={handleSubmit}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  handleSubmit();
                }
              }}
            >
              <div className="eco-investment-amount-container">
                <div className="amount-field">
                  <TextField
                    autoComplete="off"
                    id="eco-investment"
                    placeholder="500€"
                    name="investment"
                    form="primary"
                    type="number"
                    value={values.investment}
                    handleChange={(e) =>
                      changeHandler(e.target.value, setFieldValue)
                    }
                    variant={variantHandler(
                      errors.investment,
                      touched.investment
                    )}
                    helperText={formValidationHandler(
                      touched.investment,
                      errors.investment
                    )}
                    onKeyDown={(evt) => checkIfNumber(evt)}
                  />
                </div>
                <Text
                  variant="labelFont"
                  type="primary"
                  className="button-container"
                >
                  <Button
                    className="invest-button"
                    value="Comenzar"
                    type="submit"
                    click={submitForm}
                  />
                </Text>
              </div>
            </Form>
          )}
        </Formik>
      </EcoInvestmentStyle>
    </ThemeProvider>
  );
};

export default EcoFundInvestment;
