import styled from "styled-components";

const CompaniesSectionStyle = styled.div`
  .companies-section-container {
    padding: 1rem;
    .companies-section-heading {
      font-weight: bold;
      padding: 0rem 0rem 1rem 0.6rem;
      color: ${(props) => props.theme.colors.fontColor.titleFontColor};
      @media (max-width: 540px) {
        text-align: center;
      }
    }
    .companies-section-description {
      text-align: center;
      color: ${(props) => props.theme.colors.fontColor.descriptionFontColor};
    }
    .companies-options-section {
      width: 100%;
      display: flex;
      justify-content: center;
      padding: 3rem 0rem;
      .companies-option-container {
        display: grid;
        grid-template-columns: repeat(8, minmax(4.375rem, 1fr));
        grid-gap: 1.25rem;
        align-items: stretch;
        width: fit-content;
        @media (max-width: 1350px) {
          grid-template-columns: repeat(6, minmax(4.375rem, 1fr));
        }
        @media (max-width: 580px) {
          grid-template-columns: repeat(4, minmax(4.375rem, 1fr));
        }
        @media (max-width: 450px) {
          grid-template-columns: repeat(3, minmax(4.375rem, 1fr));
        }
        .companies-container {
          width: fit-content;
          display: flex;
          justify-content: center;
          img {
            width: 4.3125rem;
          }
        }
      }
    }
  }
`;
export default CompaniesSectionStyle;