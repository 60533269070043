import styled from "styled-components";
import Fondo from "../../../../assets/img/micappitalCortada.png";

const NewContributionStyle = styled.div`
  @media (max-width: 1040px) {
    display: flex;
    justify-content: center;
  }
  .nuevaAportacionContainer {
    min-height: 11rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-image: url(${Fondo});
    background-repeat: no-repeat;
    background-position: right;
    background-size: contain;
    padding: 1.25rem;
    @media (max-width: 1040px) {
      width: 19.37rem;
    }
    @media (max-width: 400px) {
      width: 100%;
    }
  }
  .nuevaAportacionText {
    max-width: 65%;
    color: ${(props) => props.theme.colors.fontColor.titleFontColor};
  }
  .nuevaAportacionText2 {
    font-weight: bold;
    margin-bottom: 1.3rem;
    color: ${(props) => props.theme.colors.fontColor.titleFontColor};
  }
  .buttonContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    .button {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: ${(props) =>
        props.theme.colors.button.background.redButton};
      color: ${(props) => props.theme.colors.button.text.textSecondary};
      min-width: 10rem;
      min-height: 2.5rem;
      border-radius: 4rem;
      margin: 1rem 0rem;
      cursor: pointer;
    }
  }
`;
export default NewContributionStyle;
