export const thumbsContainer = {
  display: "flex",
  justifyContent: "center",
  flexDirection: "row",
  flexWrap: "wrap",
  marginTop: 16,
};

export const thumb = {
  display: "inline-flex",
  width: "10.625rem",
  height: "10.625rem",
  boxSizing: "border-box",
};

export const thumbInner = {
  display: "flex",
  minWidth: 0,
  overflow: "hidden",
  width: "100%",
};

export const img = {
  display: "block",
  width: "100%",
  height: "100%",
  borderRadius: "50%",
};
