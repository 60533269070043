import CompaniesSectionStyle from "./companiesSectionStyle";
import { insuranceCompanies } from "../../../../models/insurancelandingPage";
import Text from "../../../../theme/font";

const CompaniesSection = () => {
  return (
    <CompaniesSectionStyle>
      <div className="companies-section-container">
        <Text variant="titleFont" className="companies-section-heading">
          Trabajamos para mejorar tu póliza en más de 60 compañías
        </Text>
        <Text variant="smallFont" className="companies-section-description">
          Mándanos tus pólizas para que las revisemos, sean de la compañía que sean{" "}
        </Text>
        <div className="companies-options-section">
          <div className="companies-option-container">
            {insuranceCompanies &&
              insuranceCompanies.map((data) => {
                return (
                  <div className="companies-container" key={Math.random()}>
                    <img alt="x" src={data.image} />
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </CompaniesSectionStyle>
  );
};

export default CompaniesSection;
