import React, { useState, useContext, useRef, useEffect } from "react";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import AccountDetailsPopUpStyle from "./accountDetailsPopUpStyle";
import { AlertContext } from "../../alertContainer";
import { AuthTokenService } from "../../../../services";
import Button from "../../../../components/button/button";
import { forgotPasswordSuperCliente } from "../../forgotPassword/forgotPasswordServices";
import { patchDatos, patchGeneralData } from "../accountSettingsServices";
import TextField from "../../../../components/textField/textField";
import Text from "../../../../theme/font";

const AccountDetailsPopUp = React.memo((props) => {
  const formRef = useRef(null);
  const { open } = useContext(AlertContext);
  const [changeData, setChangeData] = useState({});

  //Used to load account details into the form for updating
  useEffect(() => {
    setChangeData(props.datas);
  }, [props.datas]);

  //Used for Formik live validation
  const formValidationHandler = (conditionalValue, resultValue) => {
    let result = "";
    if (conditionalValue) {
      result = resultValue;
    }
    return result;
  };

  //Used for showing error in outline of input field
  const variantHandler = (conditionalValue, resultValue) => {
    let variant = "";
    if ((conditionalValue, resultValue)) {
      variant = "none";
    } else {
      variant = "outlined";
    }
    return variant;
  };

  const validate = Yup.object({
    email: Yup.string()
      .email("El correo electrónico es invalido")
      .required("Correo electrónico requerido"),
  });

  //accessing the handlesubmit
  const componentSubmitHandler = () => {
    formRef.current.handleSubmit();
  };

  //For patching the submitted data
  const submitHandler = (values) => {
    open({
      alertType: "spinner",
      open: true,
    });
    open({
      alertType: "spinner",
      open: true,
    });
    if (props.section === "common") {
      const details = {
        idSuperCliente: AuthTokenService.getIdSuperCliente(),
        nombre: changeData.nombre,
        apellidos: changeData.apellidos,
        dni: changeData.dni,
        correo: values.email,
        telefono: values.phone,
      };
      patchGeneralData(details)
        .then((response) => {
          open({
            alertType: "spinner",
            open: false,
          });
          open({
            type: "success",
            title: response.data.msg,
            alertType: "toast",
            open: true,
          });
          props.submited();
        })
        .catch(() => {
          open({
            alertType: "spinner",
            open: false,
          });
          open({
            type: "error",
            title: "Error al enviar datos",
            alertType: "toast",
            open: true,
          });
        });
    } else {
      const details = {
        idCliente: AuthTokenService.getUserId(),
        nombre: changeData.nombre,
        apellidos: changeData.apellidos,
        dni: changeData.dni,
        correo: values.email,
        telefono: values.phone,
      };
      patchDatos(details)
        .then((response) => {
          open({
            alertType: "spinner",
            open: false,
          });
          open({
            type: "success",
            title: response.data.msg,
            alertType: "toast",
            open: true,
          });
          props.submited();
        })
        .catch(() => {
          open({
            alertType: "spinner",
            open: false,
          });
          open({
            type: "error",
            title: "Error al enviar datos",
            alertType: "toast",
            open: true,
          });
        });
    }
  };

  //preventing alphabets,space.etc from input field
  const checkIfNumber = (event) => {
    const regex = new RegExp(
      /(^(\+)?\d*$)|(Backspace|Tab|Delete|ArrowLeft|ArrowRight)/
    );
    return !event.key.match(regex) && event.preventDefault();
  };

  //for setting the maximum number
  const changeHandler = (values, setFieldValue) => {
    if (values.length < 10) {
      setFieldValue("phone", values);
    } else {
      const split = values.slice(0, 10);
      setFieldValue("phone", split);
    }
  };

  //for submitting the data for changing the password
  const changePasswordHandler = () => {
    const emailData = {
      email: changeData.correo,
    };
    forgotPasswordSuperCliente(emailData)
      .then(() => {
        open({
          alertType: "spinner",
          open: false,
        });
        open({
          type: "success",
          title: "Te hemos enviado un link para resetear tu contraseña",
          alertType: "toast",
          open: true,
        });
        props.submited();
      })
      .catch((error) => {
        open({
          alertType: "spinner",
          open: false,
        });
        open({
          type: "error",
          title: "El correo electrónico no existe",
          alertType: "toast",
          open: true,
        });
      });
  };

  return (
    <AccountDetailsPopUpStyle>
      <div className="accountDetails-container">
        <div className="accountDetails-header">
          <div className="heading">
            <Text variant="titleFont">{props.data.title}</Text>
          </div>
          <div className="header-btn">
            <Button
              className="but-div"
              value="Guardar datos"
              type="submit"
              click={() => componentSubmitHandler()}
            />
          </div>
        </div>
        <Formik
          enableReinitialize
          innerRef={formRef}
          initialValues={{
            email: changeData.correo ? changeData.correo : "",
            phone: changeData.telefono ? changeData.telefono : "",
          }}
          onSubmit={(values) => {
            submitHandler(values);
          }}
          validationSchema={validate}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleSubmit,
            setFieldValue,
          }) => (
            <Form onSubmit={handleSubmit}>
              <div className="form-container">
                <div className="form-field emai-field">
                  <div className="label">Email</div>
                  <TextField
                    autoComplete="new-password"
                    variant={variantHandler(errors.email, touched.email)}
                    name="email"
                    value={values.email}
                    type="text"
                    handleChange={handleChange}
                    placeholder="correo"
                    error={formValidationHandler(
                      touched.email,
                      Boolean(errors.email)
                    )}
                    helperText={formValidationHandler(
                      touched.email,
                      errors.email
                    )}
                    className="field-div"
                  />
                </div>

                <div className="form-field tele-field">
                  <div className="label">Móvil</div>
                  <TextField
                    type="number"
                    name="phone"
                    placeholder=""
                    className="field-div"
                    handleChange={(e) =>
                      changeHandler(e.target.value, setFieldValue, handleChange)
                    }
                    value={values.phone}
                    onKeyDown={(evt) => checkIfNumber(evt)}
                  />
                </div>

                <div className="form-field password-field">
                  <div className="label">Contraseña</div>
                  <div className="password-change-button-container">
                    <div className="password-change-button">
                      <Button
                        value="Cambiar la contraseña"
                        click={() => changePasswordHandler()}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </AccountDetailsPopUpStyle>
  );
});

export default AccountDetailsPopUp;
