import styled from "styled-components";

const NavbarStyle = styled.div`
  padding: 1.5rem 1.7rem 1.5rem 0rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: calc(100% - 12rem);
  float: right;
  @media (max-width: 1040px) {
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 2;
    background: ${(props) => props.theme.colors.background.bodyBackground};
    box-shadow: 0rem 0rem 0.875rem 0rem rgb(11 57 59 / 18%);
    padding: 1.2rem 2rem;
    height: 8.187rem;
  }
  @media (max-width: 540px) {
    padding: 1rem;
  }
  .back-icon-container {
    z-index: 1;
    cursor: pointer;
    flex: 1;
    @media (min-width: 1040px) {
      display: none;
    }
    img {
      width: 1.5625rem;
      height: 1.5625rem;
    }
  }
  #menu {
    height: 1.56rem;
    width: 1.56rem;
    flex: 1;
    padding-top: 0.2rem;
    @media (min-width: 1040px) {
      display: none;
    }
  }
  .navbar-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex: 1;

    .navbar-bottom-section {
      display: flex;
      flex-direction: column;
      width: 100%;
      gap: 1.4rem;
      .navbar-logo-container {
        @media (min-width: 1040px) {
          display: none;
        }
        padding: 0rem 1rem 0rem 0rem;
        .logo-icon {
          width: 3rem;
          @media (max-width: 540px) {
            width: 2.5rem;
          }
        }
      }
      .navbar-section {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex: 1;

        .navbar-title {
          margin: 0px !important;
          font-weight: bold;
          flex: 1;
          text-align: left;
          color: ${(props) =>
            props.theme.colors.fontColor.descriptionFontColor};
          @media (max-width: 1040px) {
            display: none;
          }
        }
        .right-container {
          display: flex;
          gap: 2rem;
          align-items: center;
          @media (max-width: 1040px) {
            gap: 0.5rem;
          }

          .avatar-group {
            display: flex;
            gap: 1rem;
            align-items: center;
            .avatar-img {
              width: 2rem;
              height: 2rem;
              border-radius: 50%;
              object-fit: cover;
            }
            .avatar-text {
              text-transform: uppercase;
              font-weight: 500;
              cursor: pointer;
              .normal-avatar-text {
                color: ${(props) =>
                  props.theme.colors.fontColor.objectiveFontColor};
                @media (max-width: 1040px) {
                  display: none;
                }
              }
              .responsive-avatar-text {
                color: ${(props) =>
                  props.theme.colors.fontColor.objectiveFontColor};
                @media (min-width: 1040px) {
                  display: none;
                }
              }
            }
          }

          .signout {
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0rem 1.37rem 0.125rem 1.37rem;
            min-height: 2rem;
            border-radius: 1.125rem;
            background-color: ${(props) =>
              props.theme.colors.button.background.navabarButtonBackground};

            font-weight: bold;
            text-align: center;
            height: 2.375rem;
            color: ${(props) => props.theme.colors.fontColor.titleFontColor};
            @media (max-width: 1040px) {
              display: none;
            }
            @media (max-width: 921px) {
              padding: 0 0.62rem 0 0.75rem;
            }
          }
          .signout-icon {
            height: 2rem;
            padding-bottom: 0.1rem;
            cursor: pointer;
            @media (min-width: 1040px) {
              display: none;
            }
          }
        }
      }
      .title-option-container {
        .option-container {
          .mySwiper {
            .swiper-wrapper {
              display: flex;
              flex-direction: row;
              align-items: center;
              gap: 2rem;
              font-weight: 500;
              @media (max-width: 1040px) {
                justify-content: space-between;
                gap: 0rem;
              }
              .swiper-title {
                width: fit-content !important;

                .not-selected-option {
                  color: ${(props) =>
                    props.theme.colors.fontColor.objectiveFontColor};
                  cursor: pointer;
                }
                .selected-name {
                  background-color: ${(props) =>
                    props.theme.colors.background.defaultBackground};
                  padding: 0.3rem 1rem;
                  border-radius: 0.5rem;
                  color: ${(props) =>
                    props.theme.colors.fontColor.descriptionFontColor};
                  font-weight: bold;
                  cursor: pointer;
                }
              }
            }
          }
        }
      }
    }
  }
`;
export default NavbarStyle;
