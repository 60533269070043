import styled from "styled-components";

const ContractPopUpStyle = styled.div`
  height: 31.25rem;
  border-radius: 1.5625rem;
  overflow: hidden;
  .contractPopUp-container {
    width: 100%;
    height: 100%;
    border-radius: 1.5625rem;
    background-color: ${(props) =>
      props.theme.colors.background.defaultBackground};
    padding: 3rem 3rem 4rem 3rem;
    @media (max-width: 500px) {
      padding: 3rem 1.5rem 4rem 1.5rem;
    }
    .contractPopUp-header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding-bottom: 2rem;
      @media (max-width: 500px) {
        flex-direction: column;
        gap: 2rem;
        align-items: center;
      }
      .popup-heading {
        color: ${(props) => props.theme.colors.fontColor.titleFontColor};
        font-weight: bold;
      }
    }
    .contractPopUp-content {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      @media (max-width: 830px) {
        width: 100%;
        align-items: center;
      }
      .contract-content {
        font-style: italic;
      }
    }
  }
  .simplebar-scrollbar::before {
    background-color: ${(props) =>
      props.theme.colors.background.primaryCardBackground};
    border-radius: 0.4375rem;
  }

  .simplebar-vertical {
    width: 0.6875rem;
  }
`;
export default ContractPopUpStyle;
