import styled from "styled-components";

const AllInOneSectionStyle = styled.div`
  .section-container {
    padding: 2rem;
    .all-in-one-heading {
      font-weight: bold;
      padding-bottom: 1.5rem;
      color: ${(props) => props.theme.colors.fontColor.titleFontColor};
      @media (max-width: 540px) {
        text-align: center;
      }
    }
    .all-in-one-image-container {
      display: flex;
      justify-content: center;
      .all-in-one-image {
        width: 80%;
      }
    }
    .bottom-text {
      text-align: center;
      margin-top: 2rem;
    }
  }
`;
export default AllInOneSectionStyle;