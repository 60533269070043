import { GoalActionTypes } from "./goalActionType";

const initialState = {
  goalDetails: [],
};

export const detailsReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case GoalActionTypes.SHOW_DETAILS:
      return {
        ...state,
        goalDetails: [...state.goalDetails, ...payload],
      };
    case GoalActionTypes.GET_OBJECTIVE:
      const goalArray = state.goalDetails;
      let objective = null;
      if (goalArray && goalArray.length > 0) {
        // eslint-disable-next-line array-callback-return
        goalArray.map((obj) => {
          if (obj.idObjetivo === payload) {
            objective = obj;
          }
        });
      }
      const dates = objective.periodo.split("-");
      const newData = {month: dates[1], year: dates[0]}
      return {
          ...state,
          selectedObjective:{...objective, ...newData}
      };

    default:
      return state;
  }
};
