import React from "react";

export const DownArrow = React.memo(() => {
  return (
    <svg
      id="Group_86_Copy_2"
      data-name="Group 86 Copy 2"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <circle
        id="Oval_Copy_7"
        data-name="Oval Copy 7"
        cx="12"
        cy="12"
        r="12"
        fill="#00ca90"
      />
      <rect
        id="Rectangle"
        width="10"
        height="1.625"
        rx="0.813"
        transform="translate(7 17.375)"
        fill="#fff"
      />
      <path
        id="Path"
        d="M4.978,10a.7.7,0,0,0,.282-.061,1.061,1.061,0,0,0,.258-.16.861.861,0,0,0,0-1.13L2.748,5.774h7.5a.7.7,0,0,0,.54-.233A.8.8,0,0,0,11,4.988a.785.785,0,0,0-.211-.565.714.714,0,0,0-.54-.221H2.795l2.724-2.85A.769.769,0,0,0,5.73.8.769.769,0,0,0,5.519.246a.717.717,0,0,0-1.08,0L0,5.012,4.438,9.779a1.061,1.061,0,0,0,.258.16A.7.7,0,0,0,4.978,10Z"
        transform="translate(7 16) rotate(-90)"
        fill="#fff"
      />
    </svg>
  );
});

export const UpArrow = React.memo(() => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <g
        id="Group_86_Copy"
        data-name="Group 86 Copy"
        transform="translate(0 24)"
      >
        <circle
          id="Oval_Copy_7"
          data-name="Oval Copy 7"
          cx="12"
          cy="12"
          r="12"
          transform="translate(0 -24)"
          fill="#00ca90"
        />
        <rect
          id="Rectangle"
          width="10"
          height="1.625"
          rx="0.813"
          transform="translate(7 -19)"
          fill="#fff"
        />
        <path
          id="Path"
          d="M4.978-10a.7.7,0,0,1,.282.061,1.061,1.061,0,0,1,.258.16.841.841,0,0,1,.211.565.841.841,0,0,1-.211.565L2.748-5.774h7.5a.7.7,0,0,1,.54.233A.8.8,0,0,1,11-4.988a.785.785,0,0,1-.211.565.714.714,0,0,1-.54.221H2.795l2.724,2.85A.769.769,0,0,1,5.73-.8a.769.769,0,0,1-.211.553A.727.727,0,0,1,4.978,0a.727.727,0,0,1-.54-.246L0-5.012,4.438-9.779a1.061,1.061,0,0,1,.258-.16A.7.7,0,0,1,4.978-10Z"
          transform="translate(7 -16) rotate(90)"
          fill="#fff"
        />
      </g>
    </svg>
  );
});

export const DualArrow = React.memo(() => {
  return (
    <svg
      id="Group_88_Copy"
      data-name="Group 88 Copy"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <circle
        id="Oval_Copy_7"
        data-name="Oval Copy 7"
        cx="12"
        cy="12"
        r="12"
        fill="#00ca90"
      />
      <g id="Group_87" data-name="Group 87" transform="translate(4 6)">
        <path
          id="Path"
          d="M4.023-7.714a.589.589,0,0,1,.228.047.865.865,0,0,1,.209.123.632.632,0,0,1,.171.436.632.632,0,0,1-.171.436L2.22-4.454H8.282a.575.575,0,0,1,.436.18.6.6,0,0,1,.171.426.59.59,0,0,1-.171.436.591.591,0,0,1-.436.171H2.258l2.2,2.2a.578.578,0,0,1,.171.426.578.578,0,0,1-.171.426A.6.6,0,0,1,4.023,0a.6.6,0,0,1-.436-.19L0-3.867,3.587-7.544A.865.865,0,0,1,3.8-7.667.589.589,0,0,1,4.023-7.714Z"
          transform="translate(16 4.286) rotate(180)"
          fill="#fff"
        />
        <path
          id="Path_Copy_6"
          data-name="Path Copy 6"
          d="M4.023,7.714a.589.589,0,0,0,.228-.047.865.865,0,0,0,.209-.123.642.642,0,0,0,0-.872L2.22,4.454H8.282a.575.575,0,0,0,.436-.18.6.6,0,0,0,.171-.426.584.584,0,0,0-.607-.607H2.258l2.2-2.2A.578.578,0,0,0,4.63.616.578.578,0,0,0,4.459.19a.6.6,0,0,0-.873,0L0,3.867,3.587,7.544a.865.865,0,0,0,.209.123A.589.589,0,0,0,4.023,7.714Z"
          fill="#fff"
        />
      </g>
    </svg>
  );
});
