import styled from "styled-components";

const RecoverFormStyle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 1rem;
  .form-heading {
    color: ${(props) => props.theme.colors.fontColor.titleFontColor};
    font-weight: bold;
    width: 100%;
    text-align: center;
  }
  .form-description {
    font-style: italic;
    font-weight: 300;
    text-align: center;
    padding: 2rem 0rem;
  }
  .form-field {
    position: relative;
    min-width: 18.75rem;
    .mail-icon {
      position: absolute;
      z-index: 1;
      top: 0.6rem;
      left: 1rem;
      color: ${(props) => props.theme.colors.icon.fieldIcon};
    }
    
  }
  .description {
    padding: 1rem;
    text-align: center;
    cursor: pointer;
    text-decoration: underline;
  }
  .form-button {
    min-width: 14.31rem;
    .send-button {
      max-width: 14.31rem;
    }
  }
`;
export default RecoverFormStyle;
