import Button from "../../../../components/button/button";
import Text from "../../../../theme/font";
import ServiceCostStyle from "./serviceCostSectionStyle";

const ServiceCostSection = ({ showPopUp }) => {
  return (
    <ServiceCostStyle>
      <div className="service-container">
        <Text variant="titleFont" type="primary" className="service-title">
          ¿Cuánto cuesta el servicio?
        </Text>
        <Text
          variant="normal"
          type="primary"
          className="service-primary-description"
        >
          Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam
          nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat
          volutpat
        </Text>
        <Text
          variant="smallerText"
          type="primary"
          className="service-secondary-description"
        >
          Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam
          nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat
          volutpat
        </Text>
        <Text variant="labelFont" className="begin-button-container">
          <Button
            className="begin-button"
            value="Comenzar"
            click={() => showPopUp(true)}
          />
        </Text>
      </div>
    </ServiceCostStyle>
  );
};

export default ServiceCostSection;
