import React, { useState } from "react";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import ImageConstants from "../../../../constants/imageConstants/image_constants";
import ProcessPopUpChildStyle from "./insuranceProcessPopUpsStyle";
import Text from "../../../../theme/font";
import TextField from "../../../../components/textField/textField";
import SelectField from "../../../../components/selectField/selectField";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import LocationField from "../../../../components/autoLocation/autoLocation";
import moment from "moment";

export const HogarPopUp = React.memo(
  ({ check, dropDownData, submitClick, insuranceId, backButtonClick }) => {
    const regimenDropDownValues = dropDownData && dropDownData.hogar.Regimen;
    const tipoDropDownValues = dropDownData && dropDownData.hogar.tipo;

    const newUserValidate = Yup.object({
      address: Yup.string().required("*Campo necesario"),
      approximate: Yup.string().required("*Campo necesario"),
      regime: Yup.string().required("*Campo necesario"),
      tipo: Yup.string().required("*Campo necesario"),
    });
    const existUservalidate = Yup.object({
      address: Yup.string().required("*Campo necesario"),
      approximate: Yup.string().required("*Campo necesario"),
      regime: Yup.string().required("*Campo necesario"),
      tipo: Yup.string().required("*Campo necesario"),
      date: Yup.string().required("*Campo necesario"),
      approximateCurrent: Yup.string().required("*Campo necesario"),
    });

    const clickHandler = (values) => {
      const newUserSubmit = {
        idSeguro: insuranceId,
        direccion: values.address,
        metros: values.approximate,
        regimen: values.regime,
        tipo: values.tipo,
        observaciones: values.comments,
      };
      const submitData = {
        idSeguro: insuranceId,
        direccion: values.address,
        metros: values.approximate,
        regimen: values.regime,
        tipo: values.tipo,
        fecha_vencimiento_actual: moment(values.date).format("YYYY-MM-DD"),
        prima_actual: values.approximateCurrent,
        vinculado_hipoteca: values.linked,
        observaciones: values.comments,
      };
      submitClick(check === true ? submitData : newUserSubmit);
    };

    //Used for Formik live validation
    const formValidationHandler = (conditionalValue, resultValue) => {
      let result = "";
      if (conditionalValue) {
        result = resultValue;
      }
      return result;
    };

    const checkIfNumber = (event) => {
      const regex = new RegExp(
        /(^\d*$)|(Backspace|Tab|Delete|ArrowLeft|ArrowRight)/
      );
      return !event.key.match(regex) && event.preventDefault();
    };

    const onBlurHandler = (e) => {
      if (e.target.value !== "") {
        e.target.type = "date";
      } else {
        e.target.type = "text";
      }
    };

    return (
      <ProcessPopUpChildStyle>
        <Formik
          initialValues={{
            address: "",
            approximate: "",
            regime: "",
            tipo: "",
            date: "",
            approximateCurrent: "",
            comments: "",
            linked: 0,
          }}
          onSubmit={(values) => {
            clickHandler(values);
          }}
          validationSchema={check ? existUservalidate : newUserValidate}
        >
          {({
            values,
            handleSubmit,
            handleChange,
            setFieldValue,
            submitForm,
            errors,
            touched,
          }) => (
            <Form onSubmit={handleSubmit}>
              <div className="main-popup-container">
                <div className="process-popup-inner-container">
                  <Text variant="titleFont" className="process-heading">
                    Necesitamos más datos de tu seguro de hogar
                  </Text>
                  <div className="form-field">
                    <Text
                      variant="labelFont"
                      className="process-sub-heading margin-bottom"
                    >
                      Datos de la casa
                    </Text>
                    <div className="common-container">
                      <div className="direction-field text-field">
                        <LocationField
                          placeholder="*Dirección"
                          name="address"
                          autoComplete="new-password"
                          handleChange={handleChange}
                          error={formValidationHandler(
                            touched.address,
                            Boolean(errors.address)
                          )}
                          helperText={formValidationHandler(
                            touched.address,
                            errors.address
                          )}
                        />
                      </div>
                      <div className="house-data-field text-field">
                        <TextField
                          form="insurance-field"
                          placeholder="*M2 aproximados"
                          name="approximate"
                          autoComplete="new-password"
                          handleChange={handleChange}
                          error={formValidationHandler(
                            touched.approximate,
                            Boolean(errors.approximate)
                          )}
                          helperText={formValidationHandler(
                            touched.approximate,
                            errors.approximate
                          )}
                          onKeyDown={(evt) => checkIfNumber(evt)}
                        />
                      </div>
                    </div>
                    <div className="common-container">
                      <div className="regime-field text-field">
                        <SelectField
                          variant="primary"
                          data={regimenDropDownValues}
                          value={values.regime}
                          handleChange={(e) =>
                            setFieldValue("regime", e.target.value)
                          }
                          placeholder="*Régimen"
                          name="regime"
                          error={formValidationHandler(
                            touched.regime,
                            Boolean(errors.regime)
                          )}
                          helperText={formValidationHandler(
                            touched.regime,
                            errors.regime
                          )}
                        />
                      </div>
                      <div className="tipo-field text-field">
                        <SelectField
                          variant="primary"
                          placeholder="*Tipo"
                          value={values.tipo}
                          data={tipoDropDownValues}
                          handleChange={(e) =>
                            setFieldValue("tipo", e.target.value)
                          }
                          name="tipo"
                          error={formValidationHandler(
                            touched.tipo,
                            Boolean(errors.tipo)
                          )}
                          helperText={formValidationHandler(
                            touched.tipo,
                            errors.tipo
                          )}
                        />
                      </div>
                    </div>
                    {check && (
                      <div className="margin-bottom">
                        <Text
                          variant="labelFont"
                          className="process-sub-heading margin-bottom"
                        >
                          Datos del seguro actual
                        </Text>
                        <div className="common-container">
                          <div className="due-date-field text-field">
                            <TextField
                              form="insurance-field"
                              placeholder="Fecha de vencimiento"
                              name="date"
                              max="9999-12-31"
                              autoComplete="new-password"
                              handleChange={handleChange}
                              error={formValidationHandler(
                                touched.date,
                                Boolean(errors.date)
                              )}
                              helperText={formValidationHandler(
                                touched.date,
                                errors.date
                              )}
                              onFocus={(e) => (e.target.type = "date")}
                              onBlur={(e) => onBlurHandler(e)}
                            />
                          </div>
                          <div className="approximate-date-field text-field">
                            <TextField
                              form="insurance-field"
                              placeholder="Prima actual aproximada"
                              name="approximateCurrent"
                              autoComplete="new-password"
                              handleChange={handleChange}
                              error={formValidationHandler(
                                touched.approximateCurrent,
                                Boolean(errors.approximateCurrent)
                              )}
                              helperText={formValidationHandler(
                                touched.approximateCurrent,
                                errors.approximateCurrent
                              )}
                              onKeyDown={(evt) => checkIfNumber(evt)}
                            />
                          </div>
                        </div>
                        <div className="checkbox-field">
                          <div className="checkbox-container">
                            <FormControlLabel
                              control={
                                <Checkbox
                                  icon={<RadioButtonUncheckedIcon />}
                                  checkedIcon={<CheckCircleIcon />}
                                  onChange={(e) => setFieldValue("linked", 1)}
                                  value={values.linked}
                                />
                              }
                              label="¿Está vinculado a una hipoteca? (Sólo para la propiedad)"
                              name="isLinked"
                            />
                          </div>
                        </div>
                      </div>
                    )}
                    <Text
                      variant="labelFont"
                      className="process-sub-heading margin-bottom"
                    >
                      Observaciones
                    </Text>
                    <div className="text-area-container">
                      <textarea
                        className="secondary-text-area"
                        placeholder="Observaciones"
                        name="comments"
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </div>
                <div className="bottom-button-container">
                  <Text
                    variant="popUpText"
                    className="return-text"
                    onClick={() => backButtonClick()}
                  >
                    Volver
                  </Text>
                  <div
                    className="continue-icon-button"
                    onClick={() => submitForm()}
                  >
                    <img
                      alt="x"
                      src={ImageConstants.continueIcon}
                      className="continue-icon"
                    />
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </ProcessPopUpChildStyle>
    );
  }
);

export const VidaPopUp = React.memo(
  ({
    check,
    dropDownData,
    submitClick,
    insuranceId,
    initialDatas,
    backButtonClick,
  }) => {
    const [selectvalue, setSelectvalue] = useState("");
    const vidaData = dropDownData.vida.coberturas;
    const informationData = initialDatas && initialDatas;

    const newUserValidate = Yup.object({
      profession: Yup.string().required("*Campo necesario"),
      birthdate: Yup.string().required("*Campo necesario"),
      insureCapital: Yup.string().required("*Campo necesario"),
      coverages: Yup.string().required("*Campo necesario"),
    });
    const existUservalidate = Yup.object({
      profession: Yup.string().required("*Campo necesario"),
      birthdate: Yup.string().required("*Campo necesario"),
      insureCapital: Yup.string().required("*Campo necesario"),
      coverages: Yup.string().required("*Campo necesario"),
      date: Yup.string().required("*Campo necesario"),
      approximatePremium: Yup.string().required("*Campo necesario"),
    });

    const checkIfNumber = (event) => {
      const regex = new RegExp(
        /(^\d*$)|(Backspace|Tab|Delete|ArrowLeft|ArrowRight)/
      );
      return !event.key.match(regex) && event.preventDefault();
    };

    const changeHandler = (e, setFieldValue) => {
      setSelectvalue(e.target.value);
      setFieldValue("coverages", e.target.value);
    };

    const clickHandler = (values) => {
      const newUserSubmit = {
        idSeguro: insuranceId,
        profesion: values.profession,
        fecha_nacimiento_asegurado: moment(values.birthdate).format(
          "YYYY-MM-DD"
        ),
        capital: values.insureCapital,
        coberturas: values.coverages,
        para_mi: values.lifeInsurance,
        vinculado_hipoteca: values.property,
        observaciones: values.comments,
      };
      const submitData = {
        idSeguro: insuranceId,
        para_mi: values.lifeInsurance,
        profesion: values.profession,
        fecha_nacimiento_asegurado: moment(values.birthdate).format(
          "YYYY-MM-DD"
        ),
        capital: values.insureCapital,
        coberturas: values.coverages,
        fecha_vencimiento_actual: moment(values.date).format("YYYY-MM-DD"),
        vinculado_hipoteca: values.property,
        prima_actual: values.approximatePremium,
        observaciones: values.comments,
      };
      submitClick(check === true ? submitData : newUserSubmit);
    };

    //Used for Formik live validation
    const formValidationHandler = (conditionalValue, resultValue) => {
      let result = "";
      if (conditionalValue) {
        result = resultValue;
      }
      return result;
    };

    const onBlurHandler = (e) => {
      if (e.target.value !== "") {
        e.target.type = "date";
      } else {
        e.target.type = "text";
      }
    };
    return (
      <ProcessPopUpChildStyle>
        <Formik
          initialValues={{
            profession: "",
            birthdate: informationData?.fecha_nacimiento
              ? informationData.fecha_nacimiento
              : "",
            insureCapital: "",
            coverages: "",
            approximatePremium: "",
            date: "",
            comments: "",
            lifeInsurance: 0,
            property: 0,
          }}
          onSubmit={(values) => {
            clickHandler(values);
          }}
          validationSchema={
            check === true ? existUservalidate : newUserValidate
          }
        >
          {({
            values,
            handleSubmit,
            handleChange,
            setFieldValue,
            submitForm,
            errors,
            touched,
          }) => (
            <Form onSubmit={handleSubmit}>
              <div className="main-popup-container">
                <div className="process-popup-inner-container">
                  <Text variant="titleFont" className="process-heading">
                    Necesitamos más datos de tu seguro de vida
                  </Text>

                  <div className="form-field">
                    <Text
                      variant="labelFont"
                      className="process-sub-heading margin-bottom"
                    >
                      Datos del asegurado
                    </Text>
                    <div className="checkbox-field margin-bottom">
                      <div className="checkbox-container">
                        <FormControlLabel
                          control={
                            <Checkbox
                              icon={<RadioButtonUncheckedIcon />}
                              checkedIcon={<CheckCircleIcon />}
                              onChange={(e) =>
                                setFieldValue("lifeInsurance", 1)
                              }
                              value={values.lifeInsurance}
                            />
                          }
                          label="El seguro de vida es para mi"
                          name="lifeInsurance"
                        />
                      </div>
                    </div>
                    <div className="common-container">
                      <div className="profession-field text-field">
                        <TextField
                          form="insurance-field"
                          placeholder="*Profesión"
                          name="profession"
                          autoComplete="new-password"
                          handleChange={handleChange}
                          error={formValidationHandler(
                            touched.profession,
                            Boolean(errors.profession)
                          )}
                          helperText={formValidationHandler(
                            touched.profession,
                            errors.profession
                          )}
                        />
                      </div>
                      <div className="year-field text-field">
                        <TextField
                          form="insurance-field"
                          placeholder="Fecha de nacimiento"
                          name="birthdate"
                          max="9999-12-31"
                          autoComplete="new-password"
                          value={values.birthdate}
                          handleChange={handleChange}
                          error={formValidationHandler(
                            touched.birthdate,
                            Boolean(errors.birthdate)
                          )}
                          helperText={formValidationHandler(
                            touched.birthdate,
                            errors.birthdate
                          )}
                          type={values.birthdate ? "date" : "text"}
                          onFocus={(e) => (e.target.type = "date")}
                          onBlur={(e) => onBlurHandler(e)}
                        />
                      </div>
                    </div>
                    <Text
                      variant="labelFont"
                      className="process-sub-heading margin-bottom"
                    >
                      Datos del seguro
                    </Text>
                    <div className="common-container">
                      <div className="capital-field text-field">
                        <TextField
                          form="insurance-field"
                          placeholder="Capital a asegurar"
                          name="insureCapital"
                          autoComplete="new-password"
                          handleChange={handleChange}
                          error={formValidationHandler(
                            touched.insureCapital,
                            Boolean(errors.insureCapital)
                          )}
                          helperText={formValidationHandler(
                            touched.insureCapital,
                            errors.insureCapital
                          )}
                          onKeyDown={(evt) => checkIfNumber(evt)}
                        />
                      </div>
                      <div className="regime-field text-field">
                        <SelectField
                          variant="primary"
                          placeholder="Coberturas"
                          value={selectvalue}
                          handleChange={(e) => changeHandler(e, setFieldValue)}
                          name="coverages"
                          data={vidaData}
                          error={formValidationHandler(
                            touched.coverages,
                            Boolean(errors.coverages)
                          )}
                          helperText={formValidationHandler(
                            touched.coverages,
                            errors.coverages
                          )}
                        />
                      </div>
                    </div>
                    <div className="checkbox-field margin-bottom">
                      <div className="checkbox-container">
                        <FormControlLabel
                          control={
                            <Checkbox
                              icon={<RadioButtonUncheckedIcon />}
                              checkedIcon={<CheckCircleIcon />}
                              onChange={(e) => setFieldValue("property", 1)}
                              value={values.property}
                            />
                          }
                          label="¿Está vinculado a una hipoteca? (Sólo para la propiedad)"
                          name="property"
                        />
                      </div>
                    </div>
                    {check && (
                      <div>
                        <Text
                          variant="labelFont"
                          className="process-sub-heading margin-bottom"
                        >
                          Datos del seguro actual
                        </Text>
                        <div className="common-container">
                          <div className="profession-field text-field">
                            <TextField
                              form="insurance-field"
                              placeholder="Fecha de vencimiento"
                              name="date"
                              autoComplete="new-password"
                              max="9999-12-31"
                              handleChange={handleChange}
                              error={formValidationHandler(
                                touched.date,
                                Boolean(errors.date)
                              )}
                              helperText={formValidationHandler(
                                touched.date,
                                errors.date
                              )}
                              onFocus={(e) => (e.target.type = "date")}
                              onBlur={(e) => onBlurHandler(e)}
                            />
                          </div>
                          <div className="year-field text-field">
                            <TextField
                              form="insurance-field"
                              placeholder="Prima aproximada"
                              name="approximatePremium"
                              autoComplete="new-password"
                              handleChange={handleChange}
                              error={formValidationHandler(
                                touched.approximatePremium,
                                Boolean(errors.approximatePremium)
                              )}
                              helperText={formValidationHandler(
                                touched.approximatePremium,
                                errors.approximatePremium
                              )}
                            />
                          </div>
                        </div>
                      </div>
                    )}
                    <Text
                      variant="labelFont"
                      className="process-sub-heading margin-bottom"
                    >
                      Observaciones
                    </Text>
                    <div className="text-area-container">
                      <textarea
                        className="secondary-text-area"
                        placeholder="Observaciones"
                        name="comments"
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </div>
                <div className="bottom-button-container">
                  <Text
                    variant="popUpText"
                    className="return-text"
                    onClick={() => backButtonClick()}
                  >
                    Volver
                  </Text>
                  <div
                    className="continue-icon-button"
                    onClick={() => submitForm()}
                  >
                    <img
                      alt="x"
                      src={ImageConstants.continueIcon}
                      className="continue-icon"
                    />
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </ProcessPopUpChildStyle>
    );
  }
);

export const SaludPopUp = React.memo(
  ({
    check,
    dropDownData,
    submitClick,
    insuranceId,
    backButtonClick,
    initialDatas,
  }) => {
    const [selectvalue, setSelectvalue] = useState("");
    const saludData = dropDownData.salud.modalidad;
    const informationData = initialDatas && initialDatas;

    const newUserValidate = Yup.object({
      postalCode: Yup.number().required("*Campo necesario"),
      birthdate: Yup.string().required("*Campo necesario"),
      modality: Yup.string().required("*Campo necesario"),
    });
    const existUservalidate = Yup.object({
      postalCode: Yup.number().required("*Campo necesario"),
      birthdate: Yup.string().required("*Campo necesario"),
      modality: Yup.string().required("*Campo necesario"),
      approximate: Yup.string().required("*Campo necesario"),
      dueDate: Yup.string().required("*Campo necesario"),
    });


    const checkIfNumber = (event) => {
      const regex = new RegExp(
        /(^\d*$)|(Backspace|Tab|Delete|ArrowLeft|ArrowRight)/
      );
      return !event.key.match(regex) && event.preventDefault();
    };

    const changeHandler = (e, setFieldValue) => {
      setSelectvalue(e.target.value);
      setFieldValue("modality", e.target.value);
    };
    const onBlurHandler = (e) => {
      if (e.target.value !== "") {
        e.target.type = "date";
      } else {
        e.target.type = "text";
      }
    };
    const clickHandler = (values) => {
      const newUserSubmit = {
        idSeguro: insuranceId,
        codigo_postal: values.postalCode,
        fecha_nacimiento_asegurado: moment(values.birthdate).format(
          "YYYY-MM-DD"
        ),
        incluir_familiares: values.includeFamily,
        modalidad: values.modality,
        quiere_comparativa: values.comparison,
        quiere_dental: values.includeDentalCortage,
        observaciones: values.comments,
      };
      const submitData = {
        idSeguro: insuranceId,
        codigo_postal: values.postalCode,
        fecha_nacimiento_asegurado: moment(values.birthdate).format(
          "YYYY-MM-DD"
        ),
        incluir_familiares: values.includeFamily,
        modalidad: values.modality,
        quiere_comparativa: values.comparison,
        quiere_dental: values.includeDentalCortage,
        fecha_vencimiento_actual: moment(values.dueDate).format("YYYY-MM-DD"),
        prima_actual: values.approximate,
        observaciones: values.comments,
      };
      submitClick(check === true ? submitData : newUserSubmit);
    };
    const formValidationHandler = (conditionalValue, resultValue) => {
      let result = "";
      if (conditionalValue) {
        result = resultValue;
      }
      return result;
    };

    return (
      <ProcessPopUpChildStyle>
        <Formik
          initialValues={{
            postalCode: "",
            birthdate: informationData?.fecha_nacimiento
              ? informationData.fecha_nacimiento
              : "",
            modality: "",
            approximate: "",
            dueDate: "",
            includeFamily: 0,
            comparison: 0,
            includeDentalCortage: 0,
          }}
          onSubmit={(values) => {
            clickHandler(values);
          }}
          validationSchema={
            check === true ? existUservalidate : newUserValidate
          }
        >
          {({
            values,
            handleSubmit,
            handleChange,
            setFieldValue,
            submitForm,
            errors,
            touched,
          }) => (
            <Form onSubmit={handleSubmit}>
              <div className="main-popup-container">
                <div className="process-popup-inner-container">
                  <Text variant="titleFont" className="process-heading">
                    Necesitamos más datos de tu seguro de salud
                  </Text>
                  <div className="form-field">
                    <Text
                      variant="labelFont"
                      className="process-sub-heading margin-bottom"
                    >
                      Datos del asegurado
                    </Text>
                    <div className="common-container">
                      <div className="post-code-field text-field">
                        <TextField
                          form="insurance-field"
                          placeholder="*Código postal"
                          name="postalCode"
                          autoComplete="new-password"
                          handleChange={handleChange}
                          error={formValidationHandler(
                            touched.postalCode,
                            Boolean(errors.postalCode)
                          )}
                          helperText={formValidationHandler(
                            touched.postalCode,
                            errors.postalCode
                          )}
                          onKeyDown={(evt) => checkIfNumber(evt)}
                        />
                      </div>
                      <div className="year-field text-field">
                        <TextField
                          form="insurance-field"
                          placeholder="Fecha de nacimiento"
                          handleChange={handleChange}
                          name="birthdate"
                          max="9999-12-31"
                          autoComplete="new-password"
                          value={values.birthdate}
                          error={formValidationHandler(
                            touched.birthdate,
                            Boolean(errors.birthdate)
                          )}
                          helperText={formValidationHandler(
                            touched.birthdate,
                            errors.birthdate
                          )}
                          onFocus={(e) => (e.target.type = "date")}
                          onBlur={(e) => onBlurHandler(e)}
                          type={values.birthdate ? "date" : "text"}
                        />
                      </div>
                    </div>
                    <div className="checkbox-field margin-bottom">
                      <div className="checkbox-container">
                        <FormControlLabel
                          control={
                            <Checkbox
                              icon={<RadioButtonUncheckedIcon />}
                              checkedIcon={<CheckCircleIcon />}
                              onChange={(e) =>
                                setFieldValue("includeFamily", 1)
                              }
                              value={values.includeFamily}
                            />
                          }
                          label="Quiero incluir a familiares en esta póliza"
                          name="includeFamily"
                        />
                      </div>
                    </div>
                    <Text
                      variant="labelFont"
                      className="process-sub-heading margin-bottom"
                    >
                      Datos del seguro
                    </Text>
                    <div className="common-container">
                      <div className="regime-field text-field">
                        <SelectField
                          variant="primary"
                          placeholder="*Modalidad"
                          value={selectvalue}
                          name="modality"
                          handleChange={(e) => changeHandler(e, setFieldValue)}
                          data={saludData}
                          error={formValidationHandler(
                            touched.modality,
                            Boolean(errors.modality)
                          )}
                          helperText={formValidationHandler(
                            touched.modality,
                            errors.modality
                          )}
                        />
                      </div>
                      <div className="checkbox-container">
                        <FormControlLabel
                          control={
                            <Checkbox
                              icon={<RadioButtonUncheckedIcon />}
                              checkedIcon={<CheckCircleIcon />}
                              onChange={(e) => setFieldValue("comparison", 1)}
                              value={values.comparison}
                            />
                          }
                          label="Quiero una comparativa entre varias opciones"
                          name="comparison"
                        />
                      </div>
                    </div>
                    <div className="checkbox-field margin-bottom">
                      <div className="checkbox-container">
                        <FormControlLabel
                          control={
                            <Checkbox
                              icon={<RadioButtonUncheckedIcon />}
                              checkedIcon={<CheckCircleIcon />}
                              onChange={(e) =>
                                setFieldValue("includeDentalCortage", 1)
                              }
                              value={values.includeDentalCortage}
                            />
                          }
                          label="Incluir coberturas dentales"
                          name="includeDentalCortage"
                        />
                      </div>
                    </div>
                    {check && (
                      <div>
                        <Text
                          variant="labelFont"
                          className="process-sub-heading margin-bottom"
                        >
                          Datos del seguro actual
                        </Text>
                        <div className="common-container">
                          <div className="due-date-field text-field">
                            <TextField
                              form="insurance-field"
                              placeholder="Fecha de vencimiento"
                              name="dueDate"
                              max="9999-12-31"
                              handleChange={handleChange}
                              autoComplete="new-password"
                              error={formValidationHandler(
                                touched.dueDate,
                                Boolean(errors.dueDate)
                              )}
                              helperText={formValidationHandler(
                                touched.dueDate,
                                errors.dueDate
                              )}
                              onKeyDown={(evt) => checkIfNumber(evt)}
                              onFocus={(e) => (e.target.type = "date")}
                              onBlur={(e) => onBlurHandler(e)}
                            />
                          </div>
                          <div className="approximate-date-field text-field">
                            <TextField
                              form="insurance-field"
                              placeholder="Prima aproximada"
                              name="approximate"
                              handleChange={handleChange}
                              autoComplete="new-password"
                              error={formValidationHandler(
                                touched.approximate,
                                Boolean(errors.approximate)
                              )}
                              helperText={formValidationHandler(
                                touched.approximate,
                                errors.approximate
                              )}
                              onKeyDown={(evt) => checkIfNumber(evt)}
                            />
                          </div>
                        </div>
                      </div>
                    )}
                    <Text
                      variant="labelFont"
                      className="process-sub-heading margin-bottom"
                    >
                      Observaciones
                    </Text>
                    <div className="text-area-container">
                      <textarea
                        className="secondary-text-area"
                        placeholder="Observaciones"
                        name="comments"
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </div>
                <div className="bottom-button-container">
                  <Text
                    variant="popUpText"
                    className="return-text"
                    onClick={() => backButtonClick()}
                  >
                    Volver
                  </Text>
                  <div
                    className="continue-icon-button"
                    onClick={() => submitForm()}
                  >
                    <img
                      alt="x"
                      src={ImageConstants.continueIcon}
                      className="continue-icon"
                    />
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </ProcessPopUpChildStyle>
    );
  }
);

export const CompanyPopUp = React.memo(
  ({ check, dropDownData, submitClick, insuranceId, backButtonClick }) => {
    const regimenData = dropDownData && dropDownData.comercio.regimen;
    const tipoData = dropDownData && dropDownData.comercio.tipo;

    const newUserValidate = Yup.object({
      address: Yup.string().required("*Campo necesario"),
      M2approximate: Yup.string().required("*Campo necesario"),
      regime: Yup.string().required("*Campo necesario"),
      tipo: Yup.string().required("*Campo necesario"),
      description: Yup.string().required("*Campo necesario"),
    });

    const existUservalidate = Yup.object({
      address: Yup.string().required("*Campo necesario"),
      M2approximate: Yup.string().required("*Campo necesario"),
      regime: Yup.string().required("*Campo necesario"),
      tipo: Yup.string().required("*Campo necesario"),
      description: Yup.string().required("*Campo necesario"),
      approximate: Yup.string().required("*Campo necesario"),
      dueDate: Yup.string().required("*Campo necesario"),
    });

    const clickHandler = (values) => {
      const newUserSubmit = {
        idSeguro: insuranceId,
        direccion: values.address,
        metros: values.M2approximate,
        regimen: values.regime,
        tipo: values.tipo,
        descripcion_actividad_cnae: values.description,
        observaciones: values.comments,
      };
      const submitData = {
        idSeguro: insuranceId,
        direccion: values.address,
        metros: values.M2approximate,
        regimen: values.regime,
        tipo: values.tipo,
        descripcion_actividad_cnae: values.description,
        fecha_vencimiento_actual: moment(values.dueDate).format("YYYY-MM-DD"),
        prima_actual: values.approximate,
        observaciones: values.comments,
      };
      submitClick(check === true ? submitData : newUserSubmit);
    };
    const onBlurHandler = (e) => {
      if (e.target.value !== "") {
        e.target.type = "date";
      } else {
        e.target.type = "text";
      }
    };
    const formValidationHandler = (conditionalValue, resultValue) => {
      let result = "";
      if (conditionalValue) {
        result = resultValue;
      }
      return result;
    };
    const checkIfNumber = (event) => {
      const regex = new RegExp(
        /(^\d*$)|(Backspace|Tab|Delete|ArrowLeft|ArrowRight)/
      );
      return !event.key.match(regex) && event.preventDefault();
    };
    return (
      <ProcessPopUpChildStyle>
        <Formik
          initialValues={{
            address: "",
            approximate: "",
            regime: "",
            tipo: "",
            M2approximate: "",
            dueDate: "",
            description: "",
          }}
          onSubmit={(values) => {
            clickHandler(values);
          }}
          validationSchema={
            check === true ? existUservalidate : newUserValidate
          }
        >
          {({
            values,
            handleSubmit,
            handleChange,
            setFieldValue,
            submitForm,
            errors,
            touched,
          }) => (
            <Form onSubmit={handleSubmit}>
              <div className="main-popup-container">
                <div className="process-popup-inner-container">
                  <Text variant="titleFont" className="process-heading">
                    Necesitamos más datos de tu seguro de comercio
                  </Text>
                  <div className="form-field">
                    <Text
                      variant="labelFont"
                      className="process-sub-heading margin-bottom"
                    >
                      Datos del comercio
                    </Text>
                    <div className="common-container">
                      <div className="direction-field text-field">
                        <TextField
                          form="insurance-field"
                          placeholder="*Dirección"
                          name="address"
                          autoComplete="new-password"
                          handleChange={handleChange}
                          error={formValidationHandler(
                            touched.address,
                            Boolean(errors.address)
                          )}
                          helperText={formValidationHandler(
                            touched.address,
                            errors.address
                          )}
                        />
                      </div>
                      <div className="house-data-field text-field">
                        <TextField
                          form="insurance-field"
                          placeholder="*M2 aproximados"
                          name="M2approximate"
                          handleChange={handleChange}
                          autoComplete="new-password"
                          error={formValidationHandler(
                            touched.M2approximate,
                            Boolean(errors.M2approximate)
                          )}
                          helperText={formValidationHandler(
                            touched.M2approximate,
                            errors.M2approximate
                          )}
                          onKeyDown={(evt) => checkIfNumber(evt)}
                        />
                      </div>
                    </div>
                    <div className="common-container">
                      <div className="regime-field text-field">
                        <SelectField
                          variant="primary"
                          value={values.regime}
                          handleChange={(e) =>
                            setFieldValue("regime", e.target.value)
                          }
                          placeholder="*Régimen"
                          name="regime"
                          data={regimenData}
                          error={formValidationHandler(
                            touched.regime,
                            Boolean(errors.regime)
                          )}
                          helperText={formValidationHandler(
                            touched.regime,
                            errors.regime
                          )}
                          onKeyDown={(evt) => checkIfNumber(evt)}
                        />
                      </div>
                      <div className="tipo-field text-field">
                        <SelectField
                          variant="primary"
                          placeholder="*Tipo"
                          value={values.tipo}
                          name="tipo"
                          handleChange={(e) =>
                            setFieldValue("tipo", e.target.value)
                          }
                          data={tipoData}
                          error={formValidationHandler(
                            touched.tipo,
                            Boolean(errors.tipo)
                          )}
                          helperText={formValidationHandler(
                            touched.tipo,
                            errors.tipo
                          )}
                        />
                      </div>
                    </div>
                    <div className="common-container">
                      <div className="regime-field text-field">
                        <TextField
                          form="insurance-field"
                          placeholder="*Código y descripción de la actividad"
                          handleChange={handleChange}
                          autoComplete="new-password"
                          name="description"
                          error={formValidationHandler(
                            touched.description,
                            Boolean(errors.description)
                          )}
                          helperText={formValidationHandler(
                            touched.description,
                            errors.description
                          )}
                        />
                      </div>
                    </div>
                    {check && (
                      <div>
                        <Text
                          variant="labelFont"
                          className="process-sub-heading margin-bottom"
                        >
                          Datos del seguro actual
                        </Text>
                        <div className="common-container">
                          <div className="direction-field text-field">
                            <TextField
                              form="insurance-field"
                              placeholder="Fecha de vencimiento"
                              name="dueDate"
                              handleChange={handleChange}
                              autoComplete="new-password"
                              max="9999-12-31"
                              error={formValidationHandler(
                                touched.dueDate,
                                Boolean(errors.dueDate)
                              )}
                              helperText={formValidationHandler(
                                touched.dueDate,
                                errors.dueDate
                              )}
                              onFocus={(e) => (e.target.type = "date")}
                              onBlur={(e) => onBlurHandler(e)}
                            />
                          </div>
                          <div className="house-data-field text-field">
                            <TextField
                              form="insurance-field"
                              placeholder="Prima actual aproximada"
                              name="approximate"
                              handleChange={handleChange}
                              autoComplete="new-password"
                              error={formValidationHandler(
                                touched.approximate,
                                Boolean(errors.approximate)
                              )}
                              helperText={formValidationHandler(
                                touched.approximate,
                                errors.approximate
                              )}
                              onKeyDown={(evt) => checkIfNumber(evt)}
                            />
                          </div>
                        </div>
                      </div>
                    )}
                    <Text
                      variant="labelFont"
                      className="process-sub-heading margin-bottom"
                    >
                      Observaciones
                    </Text>
                    <div className="text-area-container">
                      <textarea
                        className="secondary-text-area"
                        placeholder="Observaciones"
                        name="comments"
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </div>
                <div className="bottom-button-container">
                  <Text
                    variant="popUpText"
                    className="return-text"
                    onClick={() => backButtonClick()}
                  >
                    Volver
                  </Text>
                  <div
                    className="continue-icon-button"
                    onClick={() => submitForm()}
                  >
                    <img
                      alt="x"
                      src={ImageConstants.continueIcon}
                      className="continue-icon"
                    />
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </ProcessPopUpChildStyle>
    );
  }
);

export const CochePopUp = React.memo(
  ({
    check,
    goal,
    dropDownData,
    submitClick,
    insuranceId,
    backButtonClick,
  }) => {
    const newUserValidate = Yup.object({
      modelo: Yup.string().required("*Campo necesario"),
      marca: Yup.string().required("*Campo necesario"),
      date: Yup.string().required("*Campo necesario"),
    });
    const existUservalidate = Yup.object({
      modelo: Yup.string().required("*Campo necesario"),
      marca: Yup.string().required("*Campo necesario"),
      date: Yup.string().required("*Campo necesario"),
      company: Yup.string().required("*Campo necesario"),
      policyNumber: Yup.number().required("*Campo necesario"),
      price: Yup.number().required("*Campo necesario"),
      due: Yup.string().required("*Campo necesario"),
    });
    const [selectvalue, setSelectvalue] = useState("");

    const changeHandler = (e, setFieldValue) => {
      setSelectvalue(e.target.value);
      setFieldValue("modalidad", e.target.value);
      setFieldValue("modalidad", e.target.value);
    };
    const onBlurHandler = (e) => {
      if (e.target.value !== "") {
        e.target.type = "date";
      } else {
        e.target.type = "text";
      }
    };
    const clickHandler = (values) => {
      const newUserSubmit = {
        idSeguro: insuranceId,
        matricula: values.matrícula,
        modelo: values.modelo,
        marca: values.marca,
        coche_nuevo: values.licensePlate,
        fecha_carnet_principal: moment(values.date).format("YYYY-MM-DD"),
        conductores_adicionales: values.register,
        modalidad: values.modalidad,
        exige_comparativa: values.comparison,
        observaciones: values.comments,
      };
      const submitData = {
        idSeguro: insuranceId,
        matricula: values.matrícula,
        modelo: values.modelo,
        marca: values.marca,
        coche_nuevo: values.licensePlate,
        fecha_carnet_principal: moment(values.date).format("YYYY-MM-DD"),
        conductores_adicionales: values.register,
        aseguradora_actual: values.company,
        poliza_actual: values.policyNumber,
        precio_actual: values.price,
        fecha_vencimiento_actual: moment(values.due).format("YYYY-MM-DD"),
        observaciones: values.comments,
      };
      submitClick(check === true ? submitData : newUserSubmit);
    };

    const dropDownValueHandler = (goal) => {
      let cocheData = [];
      if (goal === "coche") {
        cocheData = dropDownData.coche.Modalidad;
      } else {
        cocheData = dropDownData.moto.Modalidad;
      }
      return cocheData;
    };
    const formValidationHandler = (conditionalValue, resultValue) => {
      let result = "";
      if (conditionalValue) {
        result = resultValue;
      }
      return result;
    };
    const checkIfNumber = (event) => {
      const regex = new RegExp(
        /(^\d*$)|(Backspace|Tab|Delete|ArrowLeft|ArrowRight)/
      );
      return !event.key.match(regex) && event.preventDefault();
    };
    return (
      <ProcessPopUpChildStyle>
        <Formik
          initialValues={{
            matrícula: "",
            modelo: "",
            marca: "",
            date: "",
            modalidad: "",
            price: "",
            due: "",
            company: "",
            policyNumber: "",
            comments: "",
            licensePlate: 0,
            register: 0,
            comparison: 0,
          }}
          onSubmit={(values) => {
            clickHandler(values);
          }}
          validationSchema={
            check === true ? existUservalidate : newUserValidate
          }
        >
          {({
            values,
            handleSubmit,
            handleChange,
            setFieldValue,
            submitForm,
            errors,
            touched,
          }) => (
            <Form onSubmit={handleSubmit}>
              <div className="main-popup-container">
                <div className="popup-inner-container">
                  <Text variant="titleFont" className="process-heading">
                    Necesitamos más datos de tu seguro de {goal}
                  </Text>
                  <div className="form-field">
                    <Text
                      variant="labelFont"
                      className="process-sub-heading margin-bottom"
                    >
                      Datos del vehículo
                    </Text>
                    <div className="common-container">
                      <div className="tution-field text-field">
                        <TextField
                          form="insurance-field"
                          placeholder="*Matrícula"
                          name="matrícula"
                          handleChange={handleChange}
                          autoComplete="new-password"
                          error={formValidationHandler(
                            touched.matrícula,
                            Boolean(errors.matrícula)
                          )}
                          helperText={formValidationHandler(
                            touched.matrícula,
                            errors.matrícula
                          )}
                        />
                      </div>
                    </div>
                    <div className="common-container">
                      <div className="model-field text-field">
                        <TextField
                          form="insurance-field"
                          placeholder="*Modelo"
                          name="modelo"
                          handleChange={handleChange}
                          autoComplete="new-password"
                          error={formValidationHandler(
                            touched.modelo,
                            Boolean(errors.modelo)
                          )}
                          helperText={formValidationHandler(
                            touched.modelo,
                            errors.modelo
                          )}
                        />
                      </div>
                      <div className="model-field text-field">
                        <TextField
                          form="insurance-field"
                          placeholder="*Marca"
                          name="marca"
                          handleChange={handleChange}
                          autoComplete="new-password"
                          error={formValidationHandler(
                            touched.marca,
                            Boolean(errors.marca)
                          )}
                          helperText={formValidationHandler(
                            touched.marca,
                            errors.marca
                          )}
                        />
                      </div>
                    </div>
                    <div className="checkbox-field margin-bottom">
                      <div className="checkbox-container">
                        <FormControlLabel
                          control={
                            <Checkbox
                              icon={<RadioButtonUncheckedIcon />}
                              checkedIcon={<CheckCircleIcon />}
                              onChange={(e) => setFieldValue("licensePlate", 1)}
                              value={values.licensePlate}
                            />
                          }
                          label="Es un coche nuevo y no tiene matrícula todavía"
                          name="NoLicensePlate"
                        />
                      </div>
                    </div>
                    <Text
                      variant="labelFont"
                      className="process-sub-heading margin-bottom"
                    >
                      Datos del conductor
                    </Text>
                    <div className="common-container">
                      <div className="date-field text-field">
                        <TextField
                          form="insurance-field"
                          placeholder="*Fecha de c. de conducir principal"
                          name="date"
                          max="9999-12-31"
                          handleChange={handleChange}
                          autoComplete="new-password"
                          onFocus={(e) => (e.target.type = "date")}
                          onBlur={(e) => onBlurHandler(e)}
                          error={formValidationHandler(
                            touched.date,
                            Boolean(errors.date)
                          )}
                          helperText={formValidationHandler(
                            touched.date,
                            errors.date
                          )}
                        />
                      </div>
                      <div className="checkbox-container">
                        <FormControlLabel
                          control={
                            <Checkbox
                              icon={<RadioButtonUncheckedIcon />}
                              checkedIcon={<CheckCircleIcon />}
                              onChange={(e) => setFieldValue("register", 1)}
                              value={values.register}
                            />
                          }
                          label="Existen más conductores que han de darse de alta en esta póliza"
                          name="moreToRegister"
                        />
                      </div>
                    </div>
                    {check && (
                      <div>
                        <Text
                          variant="labelFont"
                          className="process-sub-heading margin-bottom"
                        >
                          Datos del seguro actual
                        </Text>
                        <div className="main-multiple-field-container">
                          <div className="multiple-field-container">
                            <div className="company-field mini-text-field">
                              <TextField
                                form="insurance-field"
                                placeholder="*Compañía"
                                name="company"
                                handleChange={handleChange}
                                autoComplete="new-password"
                                error={formValidationHandler(
                                  touched.company,
                                  Boolean(errors.company)
                                )}
                                helperText={formValidationHandler(
                                  touched.company,
                                  errors.company
                                )}
                              />
                            </div>
                            <div className="policy-number-field mini-text-field">
                              <TextField
                                form="insurance-field"
                                placeholder="*Número de póliza"
                                name="policyNumber"
                                error={formValidationHandler(
                                  touched.policyNumber,
                                  Boolean(errors.policyNumber)
                                )}
                                helperText={formValidationHandler(
                                  touched.policyNumber,
                                  errors.policyNumber
                                )}
                                handleChange={handleChange}
                                autoComplete="new-password"
                                onKeyDown={(evt) => checkIfNumber(evt)}
                              />
                            </div>
                          </div>
                          <div className="multiple-field-container">
                            <div className="price-field mini-text-field">
                              <TextField
                                form="insurance-field"
                                placeholder="Precio aproximado"
                                name="price"
                                autoComplete="new-password"
                                error={formValidationHandler(
                                  touched.price,
                                  Boolean(errors.price)
                                )}
                                helperText={formValidationHandler(
                                  touched.price,
                                  errors.price
                                )}
                                handleChange={handleChange}
                                onKeyDown={(evt) => checkIfNumber(evt)}
                              />
                            </div>
                            <div className="date-field mini-text-field">
                              <TextField
                                form="insurance-field"
                                placeholder="Fecha de vencimiento"
                                max="9999-12-31"
                                name="due"
                                autoComplete="new-password"
                                error={formValidationHandler(
                                  touched.due,
                                  Boolean(errors.due)
                                )}
                                helperText={formValidationHandler(
                                  touched.due,
                                  errors.due
                                )}
                                handleChange={handleChange}
                                onFocus={(e) => (e.target.type = "date")}
                                onBlur={(e) => onBlurHandler(e)}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    {!check && (
                      <div>
                        <Text
                          variant="labelFont"
                          className="process-sub-heading margin-bottom"
                        >
                          Seguro nuevo
                        </Text>
                        <div className="common-container">
                          <div className="Modality-field text-field">
                            <SelectField
                              variant="primary"
                              placeholder="*Modalidad"
                              value={selectvalue}
                              autoComplete="new-password"
                              handleChange={(e) =>
                                changeHandler(e, setFieldValue)
                              }
                              data={dropDownValueHandler()}
                              name="modalidad"
                              error={formValidationHandler(
                                touched.modalidad,
                                Boolean(errors.modalidad)
                              )}
                              helperText={formValidationHandler(
                                touched.modalidad,
                                errors.modalidad
                              )}
                            />
                          </div>
                          <div className="checkbox-container">
                            <FormControlLabel
                              control={
                                <Checkbox
                                  icon={<RadioButtonUncheckedIcon />}
                                  checkedIcon={<CheckCircleIcon />}
                                  onChange={(e) =>
                                    setFieldValue("comparison", 1)
                                  }
                                  value={values.comparison}
                                />
                              }
                              label="Quiero una comparativa entre varias opciones"
                              name="isComparison"
                            />
                          </div>
                        </div>
                      </div>
                    )}
                    <Text
                      variant="labelFont"
                      className="process-sub-heading margin-bottom"
                    >
                      Observaciones
                    </Text>
                    <div className="text-area-container">
                      <textarea
                        className="secondary-text-area"
                        placeholder="Observaciones"
                        name="comments"
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </div>
                <div className="bottom-button-container">
                  <Text
                    variant="popUpText"
                    className="return-text"
                    onClick={() => backButtonClick()}
                  >
                    Volver
                  </Text>
                  <div
                    className="continue-icon-button"
                    onClick={() => submitForm()}
                  >
                    <img
                      alt="x"
                      src={ImageConstants.continueIcon}
                      className="continue-icon"
                    />
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </ProcessPopUpChildStyle>
    );
  }
);

export const CivilPopUp = React.memo(
  ({ check, dropDownData, submitClick, insuranceId, backButtonClick }) => {
    const [selectvalue, setSelectvalue] = useState("");

    const newUserValidate = Yup.object({
      profession: Yup.string().required("*Campo necesario"),
      volumenanual: Yup.number().required("*Campo necesario"),
      coverages: Yup.string().required("*Campo necesario"),
      employees: Yup.number().required("*Campo necesario"),
    });

    const existUservalidate = Yup.object({
      profession: Yup.string().required("*Campo necesario"),
      volumenanual: Yup.number().required("*Campo necesario"),
      coverages: Yup.string().required("*Campo necesario"),
      employees: Yup.number().required("*Campo necesario"),
      approximate: Yup.string().required("*Campo necesario"),
      dueDate: Yup.string().required("*Campo necesario"),
    });
    const changeHandler = (e, setFieldValue) => {
      setSelectvalue(e.target.value);
      setFieldValue("coverages", e.target.value);
    };

    const clickHandler = (values) => {
      const newUserSubmit = {
        idSeguro: insuranceId,
        profesion_actividad: values.profession,
        volumen_facturacion: values.volumenanual,
        coberturas: values.coverages,
        numero_empleados: values.employees,
        para_mi: values.exclusiveInsurance,
        observaciones: values.comments,
      };
      const submitData = {
        idSeguro: insuranceId,
        profesion_actividad: values.profession,
        volumen_facturacion: values.volumenanual,
        para_mi: values.exclusiveInsurance,
        coberturas: values.coverages,
        numero_empleados: values.employees,
        fecha_vencimiento_actual: moment(values.dueDate1).format("YYYY-MM-DD"),
        prima_actual: values.approximate,
        observaciones: values.comments,
      };
      submitClick(check === true ? submitData : newUserSubmit);
    };
    const onBlurHandler = (e) => {
      if (e.target.value !== "") {
        e.target.type = "date";
      } else {
        e.target.type = "text";
      }
    };
    const checkIfNumber = (event) => {
      const regex = new RegExp(
        /(^\d*$)|(Backspace|Tab|Delete|ArrowLeft|ArrowRight)/
      );
      return !event.key.match(regex) && event.preventDefault();
    };
    const civilData = dropDownData.responsabilidad.coberturas;

    const formValidationHandler = (conditionalValue, resultValue) => {
      let result = "";
      if (conditionalValue) {
        result = resultValue;
      }
      return result;
    };

    return (
      <ProcessPopUpChildStyle>
        <Formik
          initialValues={{
            profession: "",
            volumenanual: "",
            coverages: "",
            employees: "",
            approximate: "",
            dueDate: "",
            exclusiveInsurance: 0,
          }}
          onSubmit={(values) => {
            clickHandler(values);
          }}
          validationSchema={
            check === true ? existUservalidate : newUserValidate
          }
        >
          {({
            values,
            handleSubmit,
            handleChange,
            setFieldValue,
            submitForm,
            errors,
            touched,
          }) => (
            <Form onSubmit={handleSubmit}>
              <div className="main-popup-container">
                <div className="popup-inner-container">
                  <Text variant="titleFont" className="process-heading">
                    Necesitamos más datos de tu seguro de R. Civil
                  </Text>
                  <div className="form-field">
                    <Text
                      variant="labelFont"
                      className="process-sub-heading margin-bottom"
                    >
                      Datos del asegurado
                    </Text>
                    <div className="common-container">
                      <div className="direction-field text-field">
                        <TextField
                          form="insurance-field"
                          placeholder="*Profesión / Actividad"
                          name="profession"
                          handleChange={handleChange}
                          autoComplete="new-password"
                          error={formValidationHandler(
                            touched.profession,
                            Boolean(errors.profession)
                          )}
                          helperText={formValidationHandler(
                            touched.profession,
                            errors.profession
                          )}
                        />
                      </div>
                    </div>
                    <Text
                      variant="labelFont"
                      className="process-sub-heading margin-bottom"
                    >
                      Datos del seguro
                    </Text>
                    <div className="common-container">
                      <div className="regime-field text-field">
                        <TextField
                          form="insurance-field"
                          placeholder="*Volumen facturación anual aproximado"
                          type="textarea"
                          name="volumenanual"
                          handleChange={handleChange}
                          autoComplete="new-password"
                          error={formValidationHandler(
                            touched.volumenanual,
                            Boolean(errors.volumenanual)
                          )}
                          helperText={formValidationHandler(
                            touched.volumenanual,
                            errors.volumenanual
                          )}
                          onKeyDown={(evt) => checkIfNumber(evt)}
                        />
                      </div>
                      <div className="tipo-field text-field">
                        <SelectField
                          variant="primary"
                          placeholder="Coberturas"
                          value={selectvalue}
                          handleChange={(e) => changeHandler(e, setFieldValue)}
                          data={civilData}
                          name="coverages"
                          error={formValidationHandler(
                            touched.coverages,
                            Boolean(errors.coverages)
                          )}
                          helperText={formValidationHandler(
                            touched.coverages,
                            errors.coverages
                          )}
                        />
                      </div>
                    </div>
                    <div className="checkbox-field margin-bottom">
                      <div className="checkbox-container">
                        <FormControlLabel
                          control={
                            <Checkbox
                              icon={<RadioButtonUncheckedIcon />}
                              checkedIcon={<CheckCircleIcon />}
                              onChange={(e) =>
                                setFieldValue("exclusiveInsurance", 1)
                              }
                              value={values.exclusiveInsurance}
                            />
                          }
                          label="Es un seguro exclusivamente para mi"
                          name="exclusiveInsurance"
                        />
                      </div>
                    </div>
                    <div className="common-container">
                      <div className="direction-field text-field">
                        <TextField
                          form="insurance-field"
                          placeholder="Número de empleados"
                          name="employees"
                          handleChange={handleChange}
                          autoComplete="new-password"
                          error={formValidationHandler(
                            touched.employees,
                            Boolean(errors.employees)
                          )}
                          helperText={formValidationHandler(
                            touched.employees,
                            errors.employees
                          )}
                          onKeyDown={(evt) => checkIfNumber(evt)}
                        />
                      </div>
                    </div>
                    {check && (
                      <div>
                        <Text
                          variant="labelFont"
                          className="process-sub-heading margin-bottom"
                        >
                          Datos del seguro actual
                        </Text>
                        <div className="common-container">
                          <div className="tution-field text-field">
                            <TextField
                              form="insurance-field"
                              placeholder="Fecha de vencimiento"
                              name="dueDate"
                              handleChange={handleChange}
                              max="9999-12-31"
                              autoComplete="new-password"
                              onFocus={(e) => (e.target.type = "date")}
                              onBlur={(e) => onBlurHandler(e)}
                              error={formValidationHandler(
                                touched.dueDate,
                                Boolean(errors.dueDate)
                              )}
                              helperText={formValidationHandler(
                                touched.dueDate,
                                errors.dueDate
                              )}
                              onKeyDown={(evt) => checkIfNumber(evt)}
                            />
                          </div>
                          <div className="model-field text-field">
                            <TextField
                              form="insurance-field"
                              placeholder="Prima aproximada"
                              name="approximate"
                              handleChange={handleChange}
                              autoComplete="new-password"
                              error={formValidationHandler(
                                touched.approximate,
                                Boolean(errors.approximate)
                              )}
                              helperText={formValidationHandler(
                                touched.approximate,
                                errors.approximate
                              )}
                              onKeyDown={(evt) => checkIfNumber(evt)}
                            />
                          </div>
                        </div>
                      </div>
                    )}
                    <Text
                      variant="labelFont"
                      className="process-sub-heading margin-bottom"
                    >
                      Observaciones
                    </Text>
                    <div className="text-area-container">
                      <textarea
                        className="secondary-text-area"
                        placeholder="Observaciones"
                        name="comments"
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </div>
                <div className="bottom-button-container">
                  <Text
                    variant="popUpText"
                    className="return-text"
                    onClick={() => backButtonClick()}
                  >
                    Volver
                  </Text>
                  <div
                    className="continue-icon-button"
                    onClick={() => submitForm()}
                  >
                    <img
                      alt="x"
                      src={ImageConstants.continueIcon}
                      className="continue-icon"
                    />
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </ProcessPopUpChildStyle>
    );
  }
);

export const InformationPopUp = React.memo(
  ({ submitClick, insuranceId, backButtonClick }) => {
    const clickHandler = (values) => {
      const submitData = {
        idSeguro: insuranceId,
        observaciones: values.comments,
      };
      submitClick(submitData);
    };

    return (
      <ProcessPopUpChildStyle>
        <Formik
          initialValues={{
            comments: "",
          }}
          onSubmit={(values) => {
            clickHandler(values);
          }}
        >
          {({
            values,
            handleSubmit,
            handleChange,
            setFieldValue,
            submitForm,
            errors,
            touched,
          }) => (
            <Form onSubmit={handleSubmit}>
              <div className="main-popup-container">
                <div className="process-popup-inner-container width-container">
                  <Text variant="titleFont" className="process-heading">
                    Necesitamos más datos de tu seguro
                  </Text>
                  <div className="text-area-form-field">
                    <Text
                      variant="labelFont"
                      className="process-sub-heading margin-bottom"
                    >
                      Explícanos un poco qué necesitas
                    </Text>
                    <div className="text-area-container">
                      <textarea
                        className="secondary-text-area large-text-area"
                        placeholder="Observaciones"
                        name="comments"
                        onChange={handleChange}
                      />
                      {errors.comments && touched.comments && (
                        <Text variant="smallFont" className="error-text">
                          *Por favor introduzca un comentario
                        </Text>
                      )}
                    </div>
                  </div>
                </div>
                <div className="bottom-button-container">
                  <Text
                    variant="popUpText"
                    className="return-text"
                    onClick={() => backButtonClick()}
                  >
                    Volver
                  </Text>
                  <div
                    className="continue-icon-button"
                    onClick={() => submitForm()}
                  >
                    <img
                      alt="x"
                      src={ImageConstants.continueIcon}
                      className="continue-icon"
                    />
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </ProcessPopUpChildStyle>
    );
  }
);
