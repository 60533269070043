import React, { useCallback, useEffect } from "react";
import SimpleBar from "simplebar-react";

const ScrollBar = React.memo(
  ({
    className,
    onScroll,
    elementName,
    max_height,
    children,
    bottom,
    isBottom,
  }) => {
    const scrollRef = React.createRef();

    const scrollElementHandler = useCallback((e) => {
      if (
        e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight &&
        isBottom
      ) {
        bottom();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
      if (scrollRef && scrollRef.current) {
        let scrollElem = scrollRef.current.getScrollElement();
        scrollElem.setAttribute("id", elementName);
        scrollElem.setAttribute("name", elementName);
        scrollElem.onscroll = scrollElementHandler;
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
      <SimpleBar
        forceVisible="y"
        autoHide={true}
        style={{ maxHeight: max_height, width: "100%", height: "100%" }}
        ref={scrollRef}
        className={className}
        onScroll={onScroll}
      >
        {children}
      </SimpleBar>
    );
  }
);

export default ScrollBar;
