import styled from "styled-components";

const BankListSectionStyle = styled.div`
  .bank-section-container {
    padding: 2rem 2rem 4rem 2rem;
    .bank-section-heading {
      font-weight: bold;
      padding-left: 1rem;
      padding-bottom: 1rem;
      color: ${(props) => props.theme.colors.fontColor.titleFontColor};
      @media (max-width: 540px) {
        text-align: center;
      }
    }
    .bank-section-description {
      color: ${(props) => props.theme.colors.fontColor.descriptionFontColor};
      padding: 0 2rem;
      @media (max-width: 540px) {
        text-align: center;
      }
    }
    .bank-options-section {
      width: 100%;
      display: flex;
      justify-content: center;
      padding: 3rem 0rem;
      .bank-option-container {
        display: grid;
        grid-template-columns: repeat(8, minmax(4.375rem, 1fr));
        grid-gap: 1.25rem;
        align-items: stretch;
        width: fit-content;
        @media (max-width: 1350px) {
          grid-template-columns: repeat(6, minmax(4.375rem, 1fr));
        }
        @media (max-width: 580px) {
          grid-template-columns: repeat(4, minmax(4.375rem, 1fr));
        }
        @media (max-width: 450px) {
          grid-template-columns: repeat(3, minmax(4.375rem, 1fr));
        }
        .bank-container {
          width: fit-content;
          display: flex;
          justify-content: center;
          img {
            width: 4.3125rem;
          }
        }
      }
    }
  }
`;
export default BankListSectionStyle;