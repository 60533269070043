import styled from "styled-components";

const ContainerStyle = styled.div`
  display: flex;
  width: 100%;
  height: var(--app-height);
  flex-direction: column;
  max-height: fit-content;
  .navbar-container {
    width: 100%;
  }
  .content-container {
    display: flex;
    flex-direction: column;

    .menu-container {
      padding-top: 1.5rem;
      position: fixed;
      width: 12rem;
      top: 0;
      height: 100%;
      @media (max-width: 1040px) {
        display: none;
      }
    }
    .main-container {
      width: 100%;
    }
    .content {
      width: calc(100% - 12rem);
      height: 100%;
      float: right;
      @media (max-width: 1040px) {
        padding: 9rem 1rem 0rem 1rem;
        width: 100%;
      }
    }
    .new-content {
      width: calc(100% - 12rem);
      height: 100%;
      float: right;
      @media (max-width: 1040px) {
        padding: 8.187rem 0rem 0rem 0rem;
        width: 100%;
      }
    }
    .alert-message-container {
      width: 100%;
      padding-right: 1.5rem;
      @media (max-width: 1040px) {
        padding-right: 0rem;
      }
    }
    .chat-icon {
      position: fixed;
      bottom: 1rem;
      right: 1rem;
      height: 3.5rem;
      cursor: pointer;
      padding: 0.2rem;
      z-index: 1;
    }
    .no-icon {
      display: none;
    }
  }
`;
export default ContainerStyle;
