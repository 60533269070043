import { useEffect, useContext, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { AlertContext } from "../../alertContainer";
import { checkLink } from "../forgotPasswordServices";
import { ContainerContext } from "../../../../components/navbarContainer/containerProvider";
import defaultTheme from "../../../../theme/theme";
import LeftContainer from "../leftConainer/leftContainer";
import NewPasswordStyle from "./newPasswordStyle";
import PasswordForm from "./passwordForm/passwordForm";
import { ThemeProvider } from "styled-components";

const NewPassword = () => {
  const theme = defaultTheme;
  const [link, setLink] = useState(false);
  const { open } = useContext(AlertContext);
  const params = useParams();
  const navigate = useNavigate();
  const [menuHidden] = useState(true);
  const { changeMenuStatus, changeNavBarStatus, changeAlertStatus } =
    useContext(ContainerContext);

  useEffect(() => {
    window.scrollTo(0, 0);
    changeMenuStatus(menuHidden);
    changeNavBarStatus(true);
    changeAlertStatus(false);
    return () => {
      changeMenuStatus(!menuHidden);
      changeNavBarStatus(false);
      changeAlertStatus(true);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [changeMenuStatus, changeNavBarStatus]);

  useEffect(() => {
    // setLink(true);
    checkLink({ hash: params.hash })
      .then((response) => {
        const data = response.data.msg;
        setLink(true);
        if (data !== "Token y link válido") {
          navigate("/recuperarClaves");
        }
      })
      .catch(() => {
        navigate("/recuperarClaves");
        open({
          alertType: "spinner",
          open: false,
        });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ThemeProvider theme={theme}>
      {link && (
        <NewPasswordStyle>
          <div className="new-password-container">
            <LeftContainer />
            <div className="password-form-container">
              <PasswordForm />
            </div>
          </div>
        </NewPasswordStyle>
      )}
    </ThemeProvider>
  );
};

export default NewPassword;
