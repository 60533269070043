import styled from "styled-components";

const ProfileBoxStyle = styled.div`
  width: 100%;
  background-color: ${(props) =>
    props.theme.colors.background.defaultBackground};
  border-radius: 1.5625rem;
  padding: 1rem 2rem 2rem 2rem;
  float: right;
  height: fit-content;
  @media (max-width: 1040px) {
    max-width: 28.62rem;
  }

  .profile-title {
    font-weight: bold;
    color: ${(props) => props.theme.colors.fontColor.titleFontColor};
    padding-right: 1rem;
  }
  .profile-picture {
    display: flex;
    justify-content: center;
    padding-bottom: 1rem;
    @media (max-width: 680px) {
      padding-bottom: 0rem;
      padding-top: 0.6rem;
    }
    .edit-div {
      width: 5.1rem;
      height: 5.1rem;
      position: relative;
      .profile-image {
        height: 5.1rem;
        width: 5.1rem;
        border-radius: 50%;
        object-fit: cover;
        @media (max-width: 680px) {
          height: 4.3rem;
          width: 4.3rem;
        }
      }
      .edit-icon {
        position: absolute;
        bottom: 0.2rem;
        left: 3.8rem;
        cursor: pointer;
        @media (max-width: 680px) {
          bottom: 1rem;
          left: 3.1rem;
        }
        img {
          height: 1.25rem;
        }
      }
    }
  }
  .user-details {
    gap: 0.6rem;
    display: flex;
    flex-direction: column;
    .details {
      font-weight: 500;
      background-color: ${(props) =>
        props.theme.colors.background.cardBackground};
      border-radius: 1rem;
      height: 2.8rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-left: 2rem;
      padding-right: 1rem;
      color: ${(props) => props.theme.colors.text.fieldText};
      min-width: 250px;
      @media (max-width: 500px) {
        padding-left: 1rem;
      }
      .details-item {
        font-weight: bold;
        padding-left: 0.5rem;
        word-break: break-word;
        text-align: end;
      }
    }
  }
`;

export default ProfileBoxStyle;
