import { createGlobalStyle } from "styled-components";

export const GlobalStyles = createGlobalStyle`
:root {
    --app-height: 100vh;
}
html{
   
    box-sizing: border-box;
    scroll-behavior: smooth;
    
    @media(min-width: 1600px){
        font-size:100% ;
    }
    @media(min-width: 1750px){
        font-size:110% ;
    }
    @media(min-width: 1900px){
        font-size:120% ;
    }
    @media(min-width: 2050px){
        font-size:130% ;
    }
    @media(min-width: 2200px){
        font-size:140% ;
    }
     @media(min-width: 2350px){
        font-size:150% ;
    }
    @media(min-width: 2600px){
        font-size:160% ;
    }
    @media(min-width: 2750px){
        font-size:170% ;
    }
    @media(min-width: 2900px){
        font-size:180% ;
    }
    @media(min-width: 3050px){
        font-size:190% ;
    }
    @media(min-width: 3200px){
        font-size:200% ;
    }
    @media(min-width: 3350px){
        font-size:210% ;
    }
    @media(min-width: 3500px){
        font-size:220% ;
    }
    @media(min-width: 3650px){
        font-size:230% ;
    }
    @media(min-width: 3800px){
        font-size:240% ;
    }
    @media(min-width: 3950px){
        font-size:250% ;
    }
    @media(min-width: 4100px){
        font-size:260% ;
    }
    @media(min-width: 4250px){
        font-size:270% ;
    }
    @media(min-width: 4400px){
        font-size:280% ;
    }
    @media(min-width: 4650px){
        font-size:290% ;
    }
    @media(min-width: 4800px){
        font-size:300% ;
    }
    @media(min-width: 4950px){
        font-size:310% ;
    }
    @media(min-width: 5100px){
        font-size:320% ;
    }
    @media(min-width: 5250px){
        font-size:330% ;
    }
}

body{
    margin: 0;
    width: 100%;
    height: 100vh;
    background-color: #F2F2F2;
    
    @media not all and (hover:hover) {
        height: var(--app-height);
    }
    .Toastify__toast-icon {
        width: 1rem;
    }
    .Toastify__close-button > svg {
        width: 1rem;
        height: 1rem;
    }
}

.inner-container{
    display: flex;
    justify-content: center;
    width: 100%;
}
.box-container{
    border-radius: 1.56rem;
    background-color: #fff;
}

.panelVideo-padding-container{
    @media(max-width: 600px){
        padding: 0.625rem;
    }
}


.floatRight{
    float: right;
}
.full-width{
    @media(max-width: 1040px){
    width: 100% !important;
}
}

.width-divider{
    width: 100%;
}
.full-height{
height: 100%;
}
.responsive-div-container{
    @media(max-width: 991px){
        display: flex;
        gap: 2rem;
        padding-bottom: 2rem;
        @media(max-width: 620px){
        padding-top: 2rem;
        }
    }
}
`;
