export const Titles = {
  CARD: "Tarjeta",
  ACCOUNT_DETAILS: "Datos de tu cuenta",
  DOCUMENT: "DNI/NIE",
  TEST: "Test idoneidad",
  CONTRACT: "Contrato",
  TUTORIAL: "Tutorial",
  PROFILE_PICTURE: "Imagen de perfil",
  CHOOSE_YOUR_BANK: "Elige tu banco",
  COMMISSION: "Más información",
};
