import styled from "styled-components";

const ImpactSectorsStyle = styled.div`
  .impact-sector-container {
    padding: 3rem;
    @media (max-width: 620px) {
      padding: 1rem;
    }
    .impact-sector-title {
      font-weight: bold;
      color: ${(props) => props.theme.colors.fontColor.titleFontColor};
      @media (max-width: 780px) {
        text-align: center;
      }
    }
    .impact-sector-swiper {
      width: 100%;
      background-color: ${(props) =>
        props.theme.colors.background.inputFieldBackground};
      padding: 0 2rem;
      border-radius: 1.56rem;
      min-height: 6.25rem;
      display: flex;
      align-items: center;
      margin-top: 1rem;
      .arrow-icon {
        cursor: pointer;
        height: 1rem;
      }
      .swiper-initialized {
        min-height: 8.12rem;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 80%;
        .swiper-wrapper {
          .swiper-slide {
            display: flex;
            justify-content: center;

            img {
              cursor: pointer;
            }
          }
        }
      }
    }
    .impact-sector-doughnut-container {
      padding-top: 2rem;
      display: flex;
      justify-content: center;
      .impact-sector-doughnut {
        max-height: 25rem;
        @media (max-width: 500px) {
          height: 18.75rem !important;
          width: 18.75rem !important;
        }
      }
    }
  }
  .swiper-pagination-bullet {
    width: 0.5rem;
    height: 0.5rem;
    margin: 0.25rem;
  }
`;
export default ImpactSectorsStyle;
