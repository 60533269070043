import styled from "styled-components";
import family from "../../../assets/img/seguros/details/family.svg";
const InsuranceListStyle = styled.div`
  padding: 1rem 1.7rem 1rem 0rem;
  @media (max-width: 1200px) {
    padding: 1rem 0rem;
  }
  .process-pop-section {
    .process-popup-wrapper {
      position: fixed;
      z-index: 150;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      .popup-overlay {
        width: 100%;
        height: 100%;
        background-color: ${(props) =>
          props.theme.colors.background.overlayBackground};
        opacity: 0.5;
      }
      .process-popup-container {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        height: auto;
        max-height: 100%;
        overflow: hidden;
        min-width: 70%;
        @media (max-width: 940px) {
          min-width: 85%;
        }
      }
    }
  }
  .insurance-list-container {
    display: flex;
    gap: 2%;
    @media (max-width: 1200px) {
      flex-direction: column;
    }
    .primary-section {
      display: flex;
      flex-direction: column;
      gap: 1.5rem;
      width: calc(100% - 35%);
      @media (max-width: 1200px) {
        width: 100%;
        padding-bottom: 1.5rem;
        padding-right: 2rem;
      }
      @media (max-width: 1040px) {
        padding-right: 0rem;
      }
    }
    .secondary-section {
      width: calc(100% - 67%);
      display: flex;
      flex-direction: column;
      gap: 1.5rem;
      @media (max-width: 1200px) {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      @media (max-width: 540px) {
        justify-content: center;
        align-items: center;
      }
      .process-section {
        @media (max-width: 1200px) {
          max-width: 22.5rem;
        }
        .process-section-container {
          background-image: url(${family});
          background-repeat: no-repeat;
          min-height: 16rem;
          background-position: center;
          background-repeat: no-repeat;
          background-size: cover;
          overflow: hidden;
          display: flex;
          justify-content: space-between;
          flex-direction: column;
          border-radius: 1rem;

          .process-section-header {
            background-color: #fdfdfd90;
            padding: 1rem;
            .process-section-heading {
              color: ${(props) => props.theme.colors.fontColor.titleFontColor};
              font-weight: bold;
              @media (max-width: 540px) {
                text-align: center;
              }
            }
          }
          .image-button-container {
            padding: 1rem;
            .image-button {
              margin: 0.3rem 0rem;
              max-width: 13rem;
              font-weight: bold;
              background-color: ${(props) =>
                props.theme.colors.fontColor.titleFontColor};
              @media (max-width: 540px) {
                max-width: 12rem;
              }
            }
          }
        }
      }
    }
  }
  .closing-popup-container {
    position: absolute;

    top: 50%;

    left: 50%;

    transform: translate(-50%, -50%);
  }
`;
export default InsuranceListStyle;
