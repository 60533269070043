import styled from "styled-components";

const NewGoalInfoStyle = styled.div`
  height: 100%;
  @media (max-width: 990px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .goal-info-container {
    height: 100%;
    padding: 1rem 2rem;
    z-index: -1;
    @media (max-width: 990px) {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
    }
    .optimization-title {
      font-weight: bold;
      color: ${(props) => props.theme.colors.fontColor.titleFontColor};
    }
    .goal-data-container {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      padding-top: 1rem;
      .data-inner-container {
        display: flex;
        gap: 1rem;
        position: relative;
        min-height: 4.875rem;
        align-items: center;
        .goal-img {
          .change-img {
            height: 2.5rem;
          }
        }
        .new-goal-info {
          .new-goal-title {
            font-weight: 500;
          }
          .new-goal-change {
            font-weight: bold;
            word-break: break-word;
          }
          .label {
            color: ${(props) => props.theme.colors.fontColor.errorFontColor};
          }
        }
      }
    }
    .buttons-container {
      padding-top: 1.5rem;
      display: flex;
      gap: 0.5rem;
      @media (max-width: 990px) {
        width: 100%;
      }
      @media (max-width: 400px) {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 1rem;
      }
      .goal-btn {
        width: 49%;
        @media (max-width: 400px) {
          width: 100%;
        }
        .cancel-btn {
          background-color: ${(props) =>
            props.theme.colors.button.background.cancelButton};
          height: 2.5rem;
          color: ${(props) =>
            props.theme.colors.fontColor.descriptionFontColor};
        }
        .guardar-btn {
          height: 2.5rem;
          @media (max-width: 400px) {
            width: 100%;
          }
        }
      }
    }
  }
`;

export default NewGoalInfoStyle;
