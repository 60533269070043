import ImageConstants from "../constants/imageConstants/image_constants";

export const offerArray = [
    {
        src: ImageConstants.primaryOffer,
        title: "50€ para ti",
        content: "Por cada amigo que invites"

    },
    {
        src: ImageConstants.secondaryOffer,
        title: "50€ para tu amigo",
        content: "También obtiene sus 50€"
    },
    {
        src: ImageConstants.teritiaryOffer,
        title: "Ilimitado",
        content: "Puedes utilizar el código las veces que quieras"
    }
];


