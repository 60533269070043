import React, { useEffect, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { ThemeProvider } from "styled-components";
import { AlertContext } from "../../common/alertContainer";
import { AuthTokenService } from "../../../services";
import { ContainerContext } from "../../../components/navbarContainer/containerProvider";
import defaultTheme from "../../../theme/theme";
import FundPopUp from "../home/fundPopUp/fundPopUp";
import FundProposalStyle from "./fundProposalStyle";
import FundRecommendationSection from "./fundRecommendationSection/fundRecommendationSection.jsx";
import ImageConstants from "../../../constants/imageConstants/image_constants";
import { micappitalConnection } from "../home/homeServices";
import OurProposalSection from "./ourProposalSection/ourProposalSection";
import TutorialSection from "./tutorialSection/tutorialSection";
import AlertMessage from "../../../components/alertmessage/alertMessage";

const FundProposal = React.memo(() => {
  const navigate = useNavigate();
  const theme = defaultTheme;
  const [menuHidden] = useState(true);
  const [showPopUp, setShowPopUp] = useState(false);
  const [connectionData, setConnectionData] = useState("");
  const { open } = useContext(AlertContext);
  const {
    changeMenuStatus,
    changeNavBarStatus,
    changeAlertStatus,
    titleHandler,
    headerTitleHandler,
    pageNameHandler,
  } = useContext(ContainerContext);
  const AmountState = useSelector((state) => state.allFunds);
  const fundData = AmountState && AmountState.fundDetails;
  const popUpData = AmountState?.fundDetails?.fondos;

  useEffect(() => {
    window.scrollTo(0, 0);
    changeNavBarStatus(false);
    changeMenuStatus(menuHidden);
    changeAlertStatus(false);
    toogleNavBar();
    if (fundData.length === 0) {
      navigate("/aportaciones");
    }
    return () => {
      changeMenuStatus(!menuHidden);
      changeAlertStatus(true);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const userId = AuthTokenService.getUserId();
    micappitalConnection(userId)
      .then((result) => {
        if (result.statusText === "OK" && result.status === 200) {
          const totalConnectionData = result.data.data.mode;
          setConnectionData(totalConnectionData);
        }
      })
      .catch(() => {
        open({
          type: "error",
          title: "Error al enviar datos",
          alertType: "toast",
          open: true,
        });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //Passing the heading
  const toogleNavBar = () => {
    titleHandler("Nueva inversión");
    headerTitleHandler("");
    pageNameHandler("Aportaciones");
  };

  //For closing the popup
  const popUpCloseHandler = () => {
    setShowPopUp(false);
  };

  //For opening popup
  const popUpOpenHandler = () => {
    setShowPopUp(true);
  };

  //Making the key values same for passing it to the fund popup
  const renamedData =
    popUpData &&
    popUpData.map(({ ISIN, aportar, nombre }) => ({
      ISIN: ISIN,
      valor: aportar,
      nombre: nombre,
    }));

  //To display the fund popup
  const popUpHandler = () => {
    return (
      <div className="popUp-wrpper">
        <div
          className="popUp-overlay"
          onClick={() => popUpCloseHandler()}
        ></div>
        <div className="popUp-container">
          <FundPopUp data={renamedData} closePopUp={popUpCloseHandler} />
        </div>
      </div>
    );
  };

  //Scroll to video when click on the corresponding link
  const scrollToVideo = () => {
    let primary = document.getElementById("primary-container");
    const videoElement = document.getElementById("video-container");
    const windowWidth = window.innerWidth;
    if (windowWidth > 1040) {
      window.scrollTo(0, videoElement.offsetTop);
    } else {
      primary.scrollTo(0, videoElement.offsetTop);
    }
  };

  //Data for the responsive view of the summary section
  const responsiveSummary = [
    {
      title: "Resumen de tu aportación",
      content:
        "¡Bien hecho! Los inversores que aportan a su inversión de manera periódica obtienen, de media, una rentabilidad un 70% mejor que los que no lo hacen",
      icon: ImageConstants.walletIcon,
      number: fundData.fondos && fundData.info.numaportaciones,
    },
    {
      title: "Resumen de tu aportación",
      content:
        "¡Bien hecho! Los inversores que aportan a su inversión de manera periódica obtienen, de media, una rentabilidad un 70% mejor que los que no lo hacen",
      icon: ImageConstants.bankIcon,
      bankIcon: fundData.fondos && fundData.info.banco.image,
    },
    {
      title: "Resumen de tu aportación",
      content:
        "¡Bien hecho! Los inversores que aportan a su inversión de manera periódica obtienen, de media, una rentabilidad un 70% mejor que los que no lo hacen",
      icon: ImageConstants.videoIcon,
      subContent: "¿Cómo realizo las inversiones?",
      videoLink: " Ver vídeo tutorial",
    },
  ];

  return (
    <ThemeProvider theme={theme}>
      <FundProposalStyle>
        <div className="primary-alert-container">
          <AlertMessage />
        </div>
        <div className="recommendation-section">
          {showPopUp && (
            <div className="investment-popup">{popUpHandler()}</div>
          )}
          <div className="back-icon" onClick={() => navigate("/Aportaciones")}>
            <img src={ImageConstants.arrowLeftIcon} alt="x" />
          </div>
          <div className="investment-section" id="primary-container">
            <div className="secondary-alert-container">
              <AlertMessage />
            </div>
            <div className="investment-container">
              <FundRecommendationSection data={fundData} />
              <div className="video-section" id="video-container">
                <TutorialSection data={fundData} />
              </div>
            </div>
          </div>
          <div className="proposal-section">
            <OurProposalSection
              data={fundData}
              click={scrollToVideo}
              popUpClick={popUpOpenHandler}
              connectData={connectionData}
              responsiveData={responsiveSummary}
            />
          </div>
        </div>
      </FundProposalStyle>
    </ThemeProvider>
  );
});

export default FundProposal;
