import styled from "styled-components";

const ContributionsGraphStyle = styled.div`
  width: calc(100% - 36%);
  height: fit-content;
  min-width: 18rem;
  background-color: ${(props) =>
    props.theme.colors.background.defaultBackground};
  border-radius: 1rem;
  padding: 1.5rem;
  @media (max-width: 1040px) {
    width: 100%;
    max-width: 38.75rem;
  }
  .heading {
    font-weight: bold;
    color: ${(props) => props.theme.colors.fontColor.titleFontColor};
    padding: 0rem 0rem 3rem 0rem;
    @media (max-width: 920px) {
      padding: 0rem;
      display: flex;
      justify-content: center;
      margin-bottom: 2rem;
    }
  }
  .graph-div {
    height: 28.1rem;
  }
  .initial-text {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    font-weight: 500;
  }
`;

export default ContributionsGraphStyle;
