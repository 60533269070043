import React, { useState, useEffect } from "react";
import { AuthTokenService } from "../../../../services";
import ImageConstants from "../../../../constants/imageConstants/image_constants";
import ProfileBoxStyle from "./profileBoxStyle";
import Text from "../../../../theme/font";
import List from "../../../../components/list/list";
import ListItem from "../../../../components/listItem/listItem";

const settingsStaticData = [
  { title: "Nombre", apiKey: "nombre", subName: "apellidos" },
  { title: "DNI", apiKey: "dni" },
  { title: "Email", apiKey: "correo" },
  { title: "Móvil", apiKey: "telefono" },
  { title: "Banco", apiKey: "banco" },
  { title: "Riesgo", apiKey: "riesgo" },
];

const ProfileBox = React.memo((props) => {
  // eslint-disable-next-line no-unused-vars
  const [userDetails, setUserDetails] = useState(props.datas);
  const [fundData, setFundData] = useState([]);
  const [userImage, setUserImage] = useState(AuthTokenService.getUserImage());

  useEffect(() => {
    if (props.profileImage) {
      let file = new FileReader();
      file.readAsDataURL(props.profileImage);
      file.onload = () => {
        setUserImage(file.result);
        localStorage.setItem("imagen", file.result);
      };
    }
  }, [props.profileImage]);

  //Used to load data into right profile for showing
  useEffect(() => {
    if (props.datas) {
      setUserDetails(props.datas);
      const combinedData = [];
      if (settingsStaticData) {
        let settingsData = "";
        if (props.section === "common") {
          settingsData = settingsStaticData.filter(
            (post) => post.title !== "Banco" && post.title !== "Riesgo"
          );
        } else {
          // settingsData = settingsStaticData;
          settingsData = settingsStaticData.filter(
            (post) =>
              post.title !== "Móvil" &&
              post.title !== "DNI" &&
              post.title !== "Email"
          );
        }
        // eslint-disable-next-line array-callback-return
        settingsData.map((items) => {
          const dataItems = {
            primary: items.title,
            secondary: props.datas[items.apiKey]
              ? props.datas[items.apiKey]
              : "",
            subData: props.datas[items.subName],
          };
          combinedData.push(dataItems);
        });
        setFundData([...combinedData]);
      }
    }
  }, [props.datas, props.section]);

  return (
    <ProfileBoxStyle>
      <div className="profile-title">
        <Text variant="titleFont">Tu perfil</Text>
      </div>
      <div className="profile-picture">
        <div className="edit-div">
          <img
            className="profile-image"
            src={userImage ? userImage : ImageConstants.AvatarLogo}
            alt="user-icon"
          />
          <div className="edit-icon">
            <img
              src={ImageConstants.edit}
              alt="edit-icon"
              onClick={() => props.click()}
            />
          </div>
        </div>
      </div>
      <List data={fundData} limit={10}>
        <ListItem variant="settings" />
      </List>
    </ProfileBoxStyle>
  );
});

export default ProfileBox;
