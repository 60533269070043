
export const thumbsContainer = {
  display: "flex",
  gap: "1rem",
  justifyContent: "center",
  flexWrap: "wrap",
  marginTop: 16,
  marginBottom: "1rem",
};

export const thumb = {
  display: "inline-flex",
  boxSizing: "border-box",
};

export const thumbInner = {
  display: "flex",
  minWidth: 0,
  overflow: "hidden",
  width: "100%",
};

export const img = {
  display: "block",
  borderRadius: "1.5625rem",
};
