import URL_CONSTANTS from "../../../../constants/urlConstants/urlConstants";
import { Request } from "../../../../services";

export const getNewsList = (data) => {
  return Request.get(`${URL_CONSTANTS.newsList}${data}`);
};

export const getSpecificNews = (data, newsId) => {
  return Request.get(`${URL_CONSTANTS.specificNews}${data}/${newsId}`);
};
