import React, { useState, useRef } from "react";
import { Form } from "react-bootstrap";
import FormControl from "@mui/material/FormControl";
import { makeStyles } from "@material-ui/core/styles";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import DropDown from "./dropDownIcon";
import SelectFieldStyle, { MuiSelectFieldStyle } from "./selectFieldStyle";
import Text from "../../theme/font";

const SelectField = React.memo(
  ({
    data,
    value,
    name,
    handleChange,
    placeholder,
    id,
    currentValue,
    check,
    variant,
    error,
    helperText,
  }) => {
    const [selectSize, setSelectSize] = useState("1");
    const selectRef = useRef(null);
    const [selectStyle, setSelectStyle] = useState("select-field");

    const changeHandler = (e) => {
      setSelectSize("1");
      selectRef.current.blur();
      if (e.target.value !== "00") {
        handleChange(e);
      }
    };

    const focusHandler = () => {
      if (data) {
        if (data.length < 10) {
          setSelectSize(`${data.length}`);
          setSelectStyle("select-field focused");
        } else {
          setSelectSize("10");
          setSelectStyle("select-field focused");
        }
      } else {
        setSelectSize("0");
      }
    };

    const blurHandler = () => {
      setSelectSize("1");
      setSelectStyle("select-field");
    };

    //for setting the value
    const optionHandler = (optionValue) => {
      if (optionValue.value) {
        return optionValue.value;
      } else {
        return optionValue;
      }
    };

    //for setting the options
    const labelHandler = (optionValue) => {
      if (optionValue.label) {
        return optionValue.label;
      } else {
        return optionValue;
      }
    };

    //for disabling the options in select field
    const selectOptionsHandler = (optionValue) => {
      if (name === "month") {
        if (check === "greater") {
          return false;
        } else if (check === "equal") {
          if (optionValue < currentValue && optionValue !== "00") {
            return true;
          } else {
            return false;
          }
        } else if (check === "less") {
          return true;
        }
      } else {
        if (value) {
          if (
            check === "equal" &&
            optionValue <= currentValue &&
            optionValue !== "00"
          ) {
            return true;
          }
        } else if (check === "greater") {
          if (optionValue < currentValue && optionValue !== "00") {
            return true;
          } else {
            return false;
          }
        } else if (check === "equal") {
          if (optionValue <= currentValue && optionValue !== "00") {
            return true;
          } else {
            return false;
          }
        } else if (check === "less") {
          if (optionValue < currentValue && optionValue !== "00") {
            return true;
          } else {
            return false;
          }
        }
      }
    };

    const useStyles = makeStyles({
      select: {
        "& ul": {
          padding: "0rem",
        },
        "& li": {
          padding: "0.3rem 0rem",
          fontSize: "0.8rem",
        },
        borderRadius: "1rem !important",
        maxWidth: "18.75rem !important",
      },
    });

    const classes = useStyles();

    return variant === "primary" ? (
      <MuiSelectFieldStyle>
        <FormControl sx={{ m: 1, minWidth: 120 }}>
          <Select
            value={value}
            onChange={handleChange}
            displayEmpty
            inputProps={{ "aria-label": "Without label" }}
            IconComponent={() => <DropDown />}
            id="mui-select-field"
            MenuProps={{ classes: { paper: classes.select } }}
          >
            <MenuItem
              value=""
              disabled
              sx={{
                textAlign: "center",
                width: "90%",
                marginLeft: "10% !important",
                color: "#707070 !important",
                justifyContent: "flex-start !important",
              }}
            >
              <em>{placeholder}</em>
            </MenuItem>
            {data &&
              data.map((options) => {
                return (
                  <MenuItem
                    value={options}
                    sx={{
                      borderBottom: "1px solid #e0dddd !important",
                      width: "90% !important",
                      marginLeft: "10% !important",
                      color: "#707070 !important",
                      whiteSpace: "break-spaces !important",
                      justifyContent: "flex-start !important",
                    }}
                    key={Math.random()}
                  >
                    {options}
                  </MenuItem>
                );
              })}
          </Select>
        </FormControl>
        {error && (
          <Text variant="extraSmall" className="error-val">
            {helperText}
          </Text>
        )}
      </MuiSelectFieldStyle>
    ) : (
      <SelectFieldStyle id={id} placeholder={placeholder}>
        <Form.Select
          className={selectStyle}
          value={value}
          name={name}
          onChange={(e) => changeHandler(e)}
          htmlSize={selectSize}
          onFocus={() => focusHandler()}
          onBlur={() => blurHandler()}
          ref={selectRef}
        >
          {data &&
            data.length > 0 &&
            data.map((optionValue, index) => {
              return (
                <option
                  key={Math.random()}
                  className="options-field"
                  value={optionHandler(optionValue)}
                  disabled={selectOptionsHandler(optionValue.value)}
                >
                  {labelHandler(optionValue)}
                </option>
              );
            })}
        </Form.Select>
      </SelectFieldStyle>
    );
  }
);

export default SelectField;
