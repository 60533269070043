import styled from "styled-components";

const ProposalStyle = styled.div`
  padding-right: 2rem;
  @media (max-width: 1040px) {
    padding: 0;
    height: calc(var(--app-height) - 8.187rem);
    max-height: 100%;
  }
  .primary-alert-container {
    @media (max-width: 1040px) {
      display: none;
    }
  }
  .main-container {
    margin-right: 0rem;
    margin: 0rem;
    padding-bottom: 1rem;
    display: flex;
    justify-content: center;
    gap: 2%;

    @media (max-width: 1040px) {
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      height: 100%;
      padding: 0rem;
      gap: 0%;
      max-height: fit-content;
    }
  }
  .popUp-wrapper {
    position: fixed;
    z-index: 150;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    .popUp-overlay {
      width: 100%;
      height: 100%;
      background-color: ${(props) =>
        props.theme.colors.background.overlayBackground};
      opacity: 0.5;
    }
    .popUp-container {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      height: auto;
      max-height: 100%;
      overflow: hidden;
      min-width: 50%;
      @media (max-width: 940px) {
        min-width: 85%;
      }
    }
    .investment-popUp-container {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      height: auto;
      width: 80%;
      max-height: 100%;
      overflow: hidden;
      min-width: 50%;
      @media (max-width: 940px) {
        min-width: 85%;
      }
    }
  }
  .primary-section {
    width: calc(100% - 36%);
    @media (max-width: 1040px) {
      overflow: scroll;
      width: 100%;
      overflow-x: hidden;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 1rem;
      min-height: 30%;
      flex: 1 1 100%;
    }
    ::-webkit-scrollbar {
      width: 0.31rem;
      right: 0.31rem;
    }
    ::-webkit-scrollbar-track {
      box-shadow: inset 0 0 0.31rem transparent;
      border-radius: 0.625rem;
      right: 0.31rem;
    }
    ::-webkit-scrollbar-thumb {
      background: ${(props) =>
        props.theme.colors.background.scrollbarBackground};
      border-radius: 0.62rem;
      right: 0.31rem;
    }
    ::-webkit-scrollbar-thumb:hover {
      background: ${(props) =>
        props.theme.colors.background.scrollbarHoverbackground};
    }
    ::-webkit-scrollbar-corner {
      background: red;
    }
  }
  .secondary-alert-container {
    @media (min-width: 1040px) {
      display: none;
    }
  }
  .new-section {
    width: 100%;
  }
  .video-render-container {
    width: 100%;
  }
  .connect-render-container {
    width: 100%;
  }
  .render-container {
    width: 100%;
  }

  .responsive-div {
    @media (max-width: 1040px) {
      display: flex;
      flex-direction: column;
    }
  }
  .spacer {
    margin: 1.25rem 0rem;
  }

  .responsive-spacer {
    @media (max-width: 1040px) {
      margin: 1.25rem 0rem;
    }
  }
  .hidden-container {
    display: none;
  }
  .secondary-section {
    width: calc(100% - 65%);

    @media (max-width: 1040px) {
      width: 100%;
      display: flex;
      justify-content: center;
      background-color: ${(props) =>
        props.theme.colors.background.defaultBackground};
    }
    .summary-actions-container {
      position: fixed;
      width: 29%;
      min-width: 18.75rem;
      @media (max-width: 1040px) {
        width: 100%;
        bottom: 0;
        height: 100%;
        position: unset;
      }
    }
  }
`;
export default ProposalStyle;
