import styled from "styled-components";

const InviteFriendsStyle = styled.div`
  display: flex;
  justify-content: center;
  .invitaAmigosContainer {
    background: ${(props) => props.theme.gradient.inviteFriendsGradient};
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 1.25rem;
    @media (max-width: 1040px) {
      max-width: 19.37rem;
    }
    @media (max-width: 400px) {
      max-width: 100%;
    }
  }
  .inviteFriendsSection {
    display: flex;
  }
  .invitaAmigosMensaje {
    border: 0;
    border-right: 0.187rem solid ${(props) => props.theme.colors.fontColor.titleFontColor};
    padding-right: 0.625rem;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }

  .invitaAmigosMensaje2 {
    padding-left: 0.625rem;
  }
  .invitaAmigosTexto1 {
    font-weight: bold;
    color: ${(props) => props.theme.colors.fontColor.titleFontColor};
    text-align: center;
    margin-bottom: -0.625rem;
  }
  .invitaAmigos {
    font-weight: bold;
    color: ${(props) => props.theme.colors.fontColor.titleFontColor};
    text-align: center;
    margin-bottom: 0;
  }

  .invitaAmigosTexto2 {
    font-weight: bold;
    color: ${(props) => props.theme.colors.fontColor.titleFontColor};
    text-align: left;
    margin-bottom: 0 !important;
  }

  .buttonContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    .button {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: ${(props) =>
        props.theme.colors.button.background.quaternaryButton};
      color: ${(props) => props.theme.colors.fontColor.titleFontColor};
      min-width: 9rem;
      min-height: 2.5rem;
      border-radius: 4rem;
      margin: 1rem 0rem 0rem 0rem;
      cursor: pointer;
    }
  }
`;
export default InviteFriendsStyle;
