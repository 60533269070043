import styled from "styled-components";

const ContributionStyle = styled.div`
  padding-right: 1.5rem;
  @media (max-width: 1040px) {
    padding-right: 0rem;
    padding-bottom: 1rem;
  }
  .contribution-container {
    display: flex;
    gap: 2%;
    width: 100%;
    @media (max-width: 1040px) {
      padding-top: 1rem;
      flex-direction: column;
      align-items: center;
      gap: 2rem;
    }
    .primary-contribution {
      width: calc(100% - 36%);
      min-width: 18rem;
      background-color: ${(props) =>
        props.theme.colors.background.defaultBackground};
      padding: 1.25rem;
      @media (max-width: 1040px) {
        width: 100%;
        max-width: 38.75rem;
      }
      .heading {
        font-weight: bold;
        color: ${(props) => props.theme.colors.fontColor.titleFontColor};

        @media (max-width: 920px) {
          text-align: center;
          padding-bottom: 1rem;
        }
      }
      .add-icon {
        text-align: center;
        img {
          height: 7.2rem;
          cursor: pointer;
          @media (max-width: 450px) {
            height: 5.3rem;
          }
        }
      }
      .description {
        font-weight: bold;
        text-align: center;
        padding-top: 3rem;
        color: ${(props) => props.theme.colors.fontColor.descriptionFontColor};
      }
      .amount-container {
        display: flex;
        justify-content: center;
        padding-top: 2rem;
        .amount-field {
          width: 19.3rem;
          #amount-textField {
            height: 2.8rem;
          }
        }
      }
      .choose-container {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 3.5rem;
        padding: 2rem 0rem;
        .MuiSvgIcon-root {
          font-size: 1.125rem;
        }
        @media (max-width: 440px) {
          gap: 0.5rem;
        }
        .choose-option {
          display: flex;
          align-items: center;

          .option-label {
            color: ${(props) =>
              props.theme.colors.fontColor.descriptionFontColor};
          }
        }
      }
      .choose-option-description {
        text-align: center;
        color: ${(props) => props.theme.colors.fontColor.descriptionFontColor};
      }
      .invest-button-container {
        padding-top: 1.5rem;
        display: flex;
        justify-content: center;
        .investment-button {
          height: 2.7rem;
          width: 19.3rem;
          @media (max-width: 500px) {
            padding: 0.6rem;
          }
        }
        .disable-btn {
          pointer-events: none;
          cursor: not-allowed;
          opacity: 50%;
        }
      }
    }
  }
  .allContributions-container {
    width: 100%;
    gap: 2%;
    padding: 2rem 0rem;
    @media (max-width: 1040px) {
      flex-direction: column;
      align-items: center;
      gap: 2rem;
    }
    @media (max-width: 450px) {
      padding: 2rem 0 0 0;
    }
  }
`;

export default ContributionStyle;
