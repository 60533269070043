import styled from "styled-components";

const NewObjectiveStyle = styled.div`
  padding-right: 2rem;
  @media (max-width: 1040px) {
    padding: 0rem 1rem 1rem 1rem;
  }
  .back-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 2.187rem;
    width: 2.187rem;
    position: fixed;
    top: 1.7rem;
    left: 3rem;
    border-radius: 1.562rem;
    z-index: 1;
    @media (max-width: 1040px) {
      display: none;
    }
    cursor: pointer;
    img {
      width: 1.5625rem;
      height: 1.5625rem;
    }
  }
  .main-goal-container {
    @media (max-width: 450px) {
      padding-top: 1rem;
    }
  }
`;
export default NewObjectiveStyle;
