import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AdviceSection from "./adviceSection/adviceSection";
import { AlertContext } from "../../common/alertContainer";
import { AuthTokenService } from "../../../services";
import { ContainerContext } from "../../../components/navbarContainer/containerProvider";
import { CheckEcoContribution } from "./defaultScreenServices";
import DefaultScreenStyle from "./defaultScreenStyle";
import EcoFundInvestment from "./ecoFundInvestment/ecoFundInvestment";
import SectorsSection from "./sectorsSection/sectorsSection";

const DefaultScreen = () => {
  const [ecoContributionValue, setEcoContributionValue] = useState(true);

  const { titleHandler, pageNameHandler, headerTitleHandler } =
    useContext(ContainerContext);
  const { open } = useContext(AlertContext);
  const navigate = useNavigate();

  useEffect(() => {
    open({
      alertType: "spinner",
      open: true,
    });
    const userId = AuthTokenService.getUserId();
    CheckEcoContribution(userId)
      .then((response) => {
        open({
          alertType: "spinner",
          open: false,
        });
        setEcoContributionValue(response.data.tieneaportacioneco);
        if (response.data.tieneaportacioneco) {
          navigate("/ecoInvestments");
        }
      })
      .catch(() => {
        open({
          alertType: "spinner",
          open: false,
        });
        open({
          type: "error",
          title: "Error al enviar datos",
          alertType: "toast",
          open: true,
        });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    toogleNavBar();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toogleNavBar = () => {
    titleHandler("Micappital Eco");
    pageNameHandler("Eco");
    headerTitleHandler("Inversión");
  };

  return (
    <DefaultScreenStyle>
      {ecoContributionValue === false && (
        <>
          <div className="primary-section">
            <div className="box-container">
              <AdviceSection />
            </div>
            <div className="box-container">
              <SectorsSection />
            </div>
          </div>
          <div className="secondary-section">
            <EcoFundInvestment />
          </div>
        </>
      )}
    </DefaultScreenStyle>
  );
};

export default DefaultScreen;
