import styled from "styled-components";

const AuthoritySectionStyle = styled.div`
  .section-container {
    padding: 2rem 2rem 4rem 2rem;
    .authority-heading {
      font-weight: bold;
      padding-bottom: 1.5rem;
      color: ${(props) => props.theme.colors.fontColor.titleFontColor};
      @media (max-width: 540px) {
        text-align: center;
      }
    }
    .authority-image-container {
      display: flex;
      justify-content: space-between;
      .authority-image {
        width: 40%;
      }
    }
    .bottom-text {
      text-align: left;
      font-style: italic;
    }
  }
`;
export default AuthoritySectionStyle;