import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { AuthTokenService } from "../../../services";
import { AlertContext } from "../../common/alertContainer";
import { CheckEcoContribution } from "../defaultScreen/defaultScreenServices";
import { ContainerContext } from "../../../components/navbarContainer/containerProvider";
import { getControlPanelData } from "./micappitalEcoServices";
import ImpactControlPanel from "./impactControlPanel/impactControlPanel";
import ImpactMap from "./impactMap/impactMap";
import ImpactSectors from "./impactSectors/impactSectors";
import InvestedSection from "./investedSection/investedSection";
import MicappitalEcoStyle from "./micappitalEcoStyle";

const MicappitalEco = () => {
  const { titleHandler, pageNameHandler,headerTitleHandler } = useContext(ContainerContext);
  const { open } = useContext(AlertContext);
  const [ecoData, setEcoData] = useState(null);
  const [ecoEnable, setEcoEnable] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const userId = AuthTokenService.getUserId();
    open({
      alertType: "spinner",
      open: true,
    });
    getControlPanelData(userId)
      .then((response) => {
        if (response && response.data.ecoenabled === true) {
          setEcoEnable(response.data.ecoenabled);
          setEcoData({ ...response.data.data });
        } else {
          CheckEcoContribution(userId)
            .then((response) => {
              if (response.data.tieneaportacioneco) {
                navigate("/ecoInvestments", { replace: true });
              } else {
                navigate("/conoceEco", { replace: true });
              }
            })
            .catch(() => {
              open({
                alertType: "spinner",
                open: false,
              });
              open({
                type: "error",
                title: "Error al enviar datos",
                alertType: "toast",
                open: true,
              });
            });
        }
      })
      .catch(() => {
        open({
          type: "error",
          title: "Error al enviar datos",
          alertType: "toast",
          open: true,
        });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    toogleNavBar();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toogleNavBar = () => {
    titleHandler("Micappital Eco");
    pageNameHandler("Eco");
    headerTitleHandler("Inversión")

  };

  return (
    <MicappitalEcoStyle>
      {ecoEnable && (
        <div className="eco-container">
          <div className="primary-section">
            <ImpactSectors />
            <ImpactMap />
            <InvestedSection />
          </div>
          <div className="secondary-section">
            <ImpactControlPanel data={ecoData} />
          </div>
        </div>
      )}
    </MicappitalEcoStyle>
  );
};

export default MicappitalEco;
