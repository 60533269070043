import React, { Fragment, useEffect, useState, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { AlertContext } from "../../common/alertContainer";
import { AuthTokenService } from "../../../services";
import Changes from "./changes/changes";
import { ContainerContext } from "../../../components/navbarContainer/containerProvider";
import GetEcho from "./getEco/getEco";
import Graph from "./graph/graph";
import GraphObjectives from "./graphObjectives/graphObjectives";
import HomeStyle from "./homeStyle";
import InviteFriends from "./inviteFriends/inviteFriends";
import MicappitalConnect from "./micappitalConnect/micappitalConnect";
import NewContribution from "./newContribution/newContribution";
import Optimization from "./optimization/optimization";
import PanelFunds from "./panelFunds/panelFunds";
import PortfolioData from "./portfolioData/portfolioData";
import Notice from "./news/news";
import Text from "../../../theme/font";
import CloseIcon from "@mui/icons-material/Close";
import {
  getTipoValue,
  micappitalConnection,
  postConnectError,
} from "./homeServices";
import { ThemeProvider } from "styled-components";
import { cargarRecomendacionInicial } from "../proposal/proposalServices";
import defaultTheme from "../../../theme/theme";
import FundPopUp from "./fundPopUp/fundPopUp";

const Home = () => {
  const [showChanges] = useState(false);
  const [tipoValue, setTipoValue] = useState("");
  const [checkTipoValues, setCheckTipovalues] = useState({});
  const [showPopUp, setShowPopUp] = useState(false);
  const [totalFunds, setTotalFunds] = useState("");
  const [connectionData, setConnectionData] = useState([]);
  const { open } = useContext(AlertContext);
  const { titleHandler, pageNameHandler, headerTitleHandler } =
    useContext(ContainerContext);
  const navigate = useNavigate();
  const theme = defaultTheme;
  const userId = AuthTokenService.getUserId();
  const { search } = useLocation();

  useEffect(() => {
    micappitalConnection(userId)
      .then((result) => {
        open({
          alertType: "spinner",
          open: false,
        });
        if (result.statusText === "OK" && result.status === 200) {
          const data = result.data?.data;
          setConnectionData(data);
        }
      })
      .catch(() => {
        open({
          type: "error",
          title: "Error al enviar datos",
          alertType: "toast",
          open: true,
        });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const query = new URLSearchParams(search);
    const errorText = query.get("error");
    if (errorText) {
      const data = {
        idCliente: AuthTokenService.getUserId(),
        error: errorText,
      };
      open({
        alertType: "spinner",
        open: true,
      });
      postConnectError(data)
        .then((response) => {
          open({
            type: "success",
            title: response.data.userMessage,
            alertType: "toast",
            open: true,
          });
          open({
            alertType: "spinner",
            open: false,
          });
        })
        .catch(() => {
          open({
            alertType: "spinner",
            open: false,
          });
          open({
            type: "error",
            title: "Error al enviar datos",
            alertType: "toast",
            open: true,
          });
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    toogleNavBar();

    getTipoValue(userId)
      .then((response) => {
        if (response) {
          let totalData = response.data.mensajes;
          let sortedTotalData = totalData.sort((a, b) => {
            return a.tipo - b.tipo;
          });
          let tipoData = checkTipoValues;
          setTipoValue(sortedTotalData);
          // eslint-disable-next-line array-callback-return
          sortedTotalData.map((value) => {
            tipoData[value.tipo] = true;
          });
          setCheckTipovalues({ ...tipoData });
        }
      })
      .catch(() => {
        open({
          type: "error",
          title: "Error al enviar datos",
          alertType: "toast",
          open: true,
        });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    open({
      alertType: "spinner",
      open: true,
    });
    cargarRecomendacionInicial(userId)
      .then((result) => {
        open({
          alertType: "spinner",
          open: false,
        });
        if (result.statusText === "OK" && result.status === 200) {
          setTotalFunds(result.data.fondos);
        }
      })
      .catch(() => {
        open({
          alertType: "spinner",
          open: false,
        });
        open({
          type: "error",
          title: "Error al enviar datos",
          alertType: "toast",
          open: true,
        });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toogleNavBar = () => {
    titleHandler("Tu Resumen");
    pageNameHandler("Resumen");
    headerTitleHandler("Inversión");
  };

  //redirect to new goal
  const seeChanges = () => {
    navigate("/nuevoObjetivo");
  };

  //for closing the info messages
  const clickHandler = (tipo) => {
    let closeTipoData = checkTipoValues;
    closeTipoData[tipo] = false;
    setCheckTipovalues({ ...closeTipoData });
  };

  //for showing info messages at the top of the page
  const infoMessageHandler = (tipoValue, activo, message) => {
    if (activo === "1") {
      if (checkTipoValues[parseInt(tipoValue)]) {
        return (
          <Text
            variant="primaryFont"
            className={`${tipoValue === "0" && "red-container"} ${
              tipoValue === "1" && "yellow-container"
            } ${tipoValue === "2" && "blue-container"} info-message `}
          >
            {message && message}
            <CloseIcon
              className="close-icon"
              onClick={() => clickHandler(tipoValue)}
            />
          </Text>
        );
      }
    }
  };

  //changing the state for closing the popup
  const popUpCloseHandler = () => {
    setShowPopUp(false);
  };

  const popUpCloseWithReload = () => {
    setShowPopUp(false);
    window.location.reload();
  };

  //changing the state for showing the popup
  const popUpOpenHandler = () => {
    setShowPopUp(true);
  };

  //It contains the popUp
  const popUpHandler = () => {
    return (
      <div className="popUp-wrapper">
        <div
          className="popUp-overlay"
          onClick={() => popUpCloseHandler()}
        ></div>
        <div className="popUp-container">
          <FundPopUp data={totalFunds} closePopUp={popUpCloseWithReload} />
        </div>
      </div>
    );
  };

  const renderHome = () => {
    return (
      <Fragment>
        <div className="row home-container">
          {showPopUp && (
            <div className="investment-popup">{popUpHandler()}</div>
          )}
          <div
            className={
              tipoValue && tipoValue.length > 0
                ? "info-message-container"
                : "no-info-message"
            }
          >
            {tipoValue &&
              tipoValue.map((value) => {
                return (
                  <div key={Math.random()}>
                    {infoMessageHandler(
                      value.tipo,
                      value.activo,
                      value.mensaje
                    )}
                  </div>
                );
              })}
          </div>
          <div className="col-lg-8 home-inner-container home-inner-container-changes">
            <Graph />
            <div className="col-lg-12 responsive-optimization-container">
              <Optimization
                popUpClick={popUpOpenHandler}
                data={connectionData}
              />
            </div>
            <PanelFunds />
            <GraphObjectives click={() => seeChanges()} />
          </div>
          <div className="col-lg-4 home-inner-container">
            <div className="col-lg-12 optimization-container">
              <Optimization
                popUpClick={popUpOpenHandler}
                data={connectionData}
              />
            </div>
            <div className="col-lg-12">
              <MicappitalConnect data={connectionData} />
            </div>
            <div className="col-lg-12">
              <PortfolioData />
            </div>
            <div className="col-lg-12">
              <Notice />
            </div>
            <div className="col-lg-12">
              <NewContribution />
            </div>
            <div className="col-lg-12">
              <InviteFriends />
            </div>
            <div className="col-lg-12">
              <GetEcho />
            </div>
          </div>
        </div>
      </Fragment>
    );
  };

  // eslint-disable-next-line no-lone-blocks
  {
    if (showChanges) {
      return <Changes />;
    } else {
      return (
        <ThemeProvider theme={theme}>
          <HomeStyle>{renderHome()}</HomeStyle>
        </ThemeProvider>
      );
    }
  }
};
export default Home;
