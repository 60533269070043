import styled from "styled-components";

const ProcessPopStyle = styled.div`
  height: 100%;
  .simplebar-scroll-content {
    max-height: inherit !important;
  }
  .simplebar-content-wrapper {
    border-radius: 1.56rem;
  }
  .simplebar-scrollbar {
    width: 0.6rem;
  }
  .simplebar-vertical {
    top: 20px;
    bottom: 20px;
  }
  .simplebar-scrollbar::before {
    background-color: ${(props) =>
      props.theme.colors.background.processPopUpBackground};
    border-radius: 0.4375rem;
  }
  .process-popup {
    background-color: ${(props) =>
      props.theme.colors.background.bodyBackground};
    padding: 2rem 2rem 2.5rem 2rem;
    width: 100%;
    border-radius: 1.56rem;
    position: relative;
    @media (max-width: 440px) {
      padding: 1rem 1rem 1.5rem 1rem;
    }
    .main-step-container {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      margin-bottom: 0.5rem;
      .step-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 8rem;
        @media (max-width: 440px) {
          width: 6rem;
        }
        .step-count {
          font-weight: bold;
        }
        .step-loader {
          width: 100%;
          min-height: 0.7rem;
          border-radius: 1.56rem;
          background-color: ${(props) =>
            props.theme.colors.background.defaultBackground};
          border: 0.125rem solid
            ${(props) => props.theme.colors.background.processPopUpBorder};
          .inner-step-loader {
            height: 100%;
            background-color: ${(props) =>
              props.theme.colors.background.processPopUpBackground};
            border-radius: 1.56rem;
          }
        }
      }
    }
  }
`;
export default ProcessPopStyle;
