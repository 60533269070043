import SupportSectionStyle from "./supportSectionStyle";
import Text from "../../../../theme/font";
import { supportOptionsData } from "../../../../models/landingPageArray";
import Button from "../../../../components/button/button";

const SupportSection = () => {
  return (
    <SupportSectionStyle>
      <Text variant="titleFont" className="support-section-heading">
        Deja que nosotros trabajemos por ti
      </Text>
      <div className="support-section-container">
        {supportOptionsData &&
          supportOptionsData.map((data) => {
            return (
              <div className="support-options-container" key={Math.random()}>
                <div className="option-icon">
                  <img alt="x" src={data.src} />
                </div>
                <Text
                  variant="labelFont"
                  type="primary"
                  className="option-title"
                >
                  {data.title}
                </Text>
                <Text
                  variant="smallerText"
                  type="primary"
                  className="option-description"
                >
                  {data.description}
                </Text>
              </div>
            );
          })}
      </div>
      <div className="bottom-container">
        <Text
          variant="smallerText"
          type="primary"
          className="support-section-description"
        >
          Nosotros te los subimos directamente y con que lo autorices a través
          de la web/app se ejecuta directamente. ¡Lo haremos todo nosotros!
        </Text>
        <div className="button-container">
          <Button className="begin-button" value="Comenzar" />
        </div>
      </div>
    </SupportSectionStyle>
  );
};

export default SupportSection;
