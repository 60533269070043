import { useEffect, useContext, useState } from "react";
import { AlertContext } from "../../alertContainer";
import { AuthTokenService } from "../../../../services";
import CommissionPopUpStyle from "./commissionPopUpStyle";
import { getCommissionData } from "../accountSettingsServices";
import Text from "../../../../theme/font";

const CommissionPopUp = () => {
  const { open } = useContext(AlertContext);
  const [commissionData, setCommissionData] = useState("");
  const [totalData, setTotalData] = useState("");
  const [comissionReales, setComissionReales]=useState("");

  useEffect(() => {
    open({
      alertType: "spinner",
      open: true,
    });
    const userId = AuthTokenService.getUserId();
    getCommissionData(userId)
      .then((response) => {
        open({
          alertType: "spinner",
          open: false,
        });
        setTotalData(response.data && response.data);
        setCommissionData(response?.data?.comisiones);
        setComissionReales(response?.data?.comisionesReales);
      })
      .catch(() => {
        open({
          alertType: "spinner",
          open: false,
        });
        open({
          type: "error",
          title: "Error al enviar datos",
          alertType: "toast",
          open: true,
        });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const formato = (x) => {
    var number = new Intl.NumberFormat("de-DE", {
      minimumFractionDigits: 2, //Decimals for returns at the top of the chart
      maximumFractionDigits: 2,
    }).format(x);
    return number;
  };

  return (
    <CommissionPopUpStyle>
      <div className="commission-popup-container">
        <div className="content-section">
          <Text variant="fieldFont" className="popup-heading">
            Detalle de comisiones
          </Text>
          <Text className="primary-content" variant="fieldFont">
            A continuación te detallamos el coste de todos tus productos de
            inversión. Cómo sabes, <span className="important-text-commission">Micappital no cobra ninguna comisión de los
             bancos ni de los fondos de inversión que recomendamos.</span> Esto nos permite mantener la
            máxima independencia a la hora de recomendarte.
          </Text>
          <Text className="secondary-content" variant="fieldFont">
            La comisión anual de toda tu cartera recomendada por Micappital es:
            {" "}{totalData.comisionTotal}% ({formato(totalData.comisionTotalEuros)}{" "}
            €/año). El coste medio de una inversión similar asesorada a
            través del banco suele ser de: {totalData.comisionTotalBanco}% (
            {formato(totalData.comisionTotalBancoEuros)} €/año).
          </Text>
          <Text className="tertiary-content" variant="fieldFont">
            IMPORTANTE: Las comisiones que mostramos a continuación están
            incluidas en la rentabilidad del fondo, en ningún caso se cobrarán
            en tu cuenta del banco, pero es un dato que tenemos muy en cuenta a
            la hora de elegir los fondos recomendados, ya que afecta a la
            rentabilidad final.
          </Text>
        </div>
        <div className="commission-details-section">
          {commissionData &&
            commissionData.map((values) => {
              return (
                <div className="commission-details-container"  key={Math.random()}>
                  <div className="won-commission">
                    <Text className="won-commission-title" variant="popUpFont">
                      {values.nombre}
                    </Text>
                    {/* <Text className="won-commission-amount" variant="popUpFont">
                      {formato(values.valor)}€
                    </Text> */}
                  </div>
                  <div className="commission">
                    <div className="commission-details">
                      <Text className="commission-title" variant="popUpFont">
                        Comisiones de Micappital:
                      </Text>
                      <Text
                        className="commission-percentage"
                        variant="popUpFont"
                      >
                        0.00%
                      </Text>
                    </div>
                    <Text className="commission-amount" variant="popUpFont">
                      0.00 €/año
                    </Text>
                  </div>
                  <div className="commission">
                    <div className="commission-details">
                      <Text className="commission-title" variant="popUpFont">
                        Comisiones de la gestora:
                      </Text>
                      <Text
                        className="commission-percentage"
                        variant="popUpFont"
                      >
                        {`${formato(values.comision)}%`}
                      </Text>
                    </div>

                    <Text className="commission-amount" variant="popUpFont">
                      {`${formato(values.comisionEuros)} €/año`}
                    </Text>
                  </div>
                </div>
              );
            })}
        </div>
        <div className="total-commission-section">
          <Text variant="popUpFont">Total comisiones</Text>
          <Text variant="popUpFont">{`${formato(
            totalData.comisionTotalEuros
          )}€/año`}</Text>
        </div>
        <div className="content-section">
          <Text className="secondary-content" variant="fieldFont">
            Mostramos las comisiones totales de los fondos en 2022.
            Para este cálculo hemos utilizado el TER aproximado de cada uno de los fondos ponderandolo por el número de días
            y la cantidad que has tenido en cada uno de ellos.
            
          </Text>
        </div>
        <div className="commission-details-section">
          {comissionReales &&
            comissionReales.map((values) => {
              return (
                <div className="commission-details-container"  key={Math.random()}>
                  <div className="won-commission">
                    <Text className="won-commission-title" variant="popUpFont">
                      {values.nombre}
                    </Text>
                    {/* <Text className="won-commission-amount" variant="popUpFont">
                      {formato(values.valor)}€
                    </Text> */}
                  </div>
                  <div className="commission">
                    <div className="commission-details">
                      <Text className="commission-title" variant="popUpFont">
                        Comisiones de Micappital:
                      </Text>
                      <Text
                        className="commission-percentage"
                        variant="popUpFont"
                      >
                        0.00%
                      </Text>
                    </div>
                    <Text className="commission-amount" variant="popUpFont">
                      0.00 € en {totalData.ano}
                    </Text>
                  </div>
                  <div className="commission">
                    <div className="commission-details">
                      <Text className="commission-title" variant="popUpFont">
                        Comisiones de la gestora:
                      </Text>
                      <Text
                        className="commission-percentage"
                        variant="popUpFont"
                      >
                        {`${formato(values.comision)}%`}
                      </Text>
                    </div>

                    <Text className="commission-amount" variant="popUpFont">
                      {`${formato(values.comisionEuros)} € en ${totalData.ano}`}
                    </Text>
                  </div>
                </div>
              );
            })}
        </div>
        <div className="total-commission-section">
          <Text variant="popUpFont">Total comisiones en 2022</Text>
          <Text variant="popUpFont">{`${formato(
            totalData.comisionReal
          )}€`}</Text>
        </div>
        <div className="content-section">
        <Text variant="fieldFont" className="popup-heading">
            ¿Cómo afectan las comisiones en la rentabilidad de tu cartera?
          </Text>
          <Text className="secondary-content" variant="fieldFont">
            
            La rentabilidad de tu cartera se habria incrementado en {formato(totalData.comisionRealTotal)}€ si no hubiese comisiones.
            
            Las comisiones de gestión de los fondos están
            incluidas en la rentabilidad del fondo, en ningún caso se cobrarán
            en tu cuenta del banco.
            Adicionalmente incluimos el coste del servicio de Micappital, que es de {formato(totalData.ultimoCobro)}€ sólo los meses que ganes dinero. 
          </Text>
        </div>
      </div>
    </CommissionPopUpStyle>
  );
};

export default CommissionPopUp;
