import ImageConstants from "../constants/imageConstants/image_constants";

const settingsOptionsDetails = [
  {
    src: ImageConstants.profile,
    title: "Datos de tu cuenta",
    content: "Actualiza los datos de tu cuenta",
    flag: true,
    isBanks: false,
  },
  {
    src: ImageConstants.card,
    title: "Tarjeta",
    content: "Actualiza tu tarjeta",
    flag: true,
    isBanks: false,
    description:
      "Para actualizar los datos de tu tarjeta se te trasladará al servicio RedSys, una vez terminada y cumplimentada la solicitud, los datos de tu tarjeta quedarán guardados.",
  },
  {
    src: ImageConstants.dni,
    title: "DNI/NIE",
    content: "Actualiza tu DNI",
    flag: false,
    isBanks: false,
    description:
      "Es necesario que tengamos los documentos de identidad de nuestros clientes actualizados",
  },
  {
    src: ImageConstants.test,
    title: "Test idoneidad",
    content: "Revisa tu test",
    flag: true,
    isBanks: false,
    description: "Estas son tus respuestas al test de idoneidad",
  },
  {
    src: ImageConstants.contract,
    title: "Contrato",
    content: "Consulta los datos de tu contrato de asesoramiento",
    flag: true,
    isBanks: false,
  },
  //{
  //  src: ImageConstants.tutorial,
  //  title: "Tutorial",
  //  content: "Recuerda las nociones básicas de Micappital",
  //  flag: true,
  //  isBanks: false,

  //},
  // {
  //   src: ImageConstants.bank,
  //   title: "Elige tu banco",
  //   content: "Selecciona entre los bancos que tenemos en la plataforma",
  //   flag: true,
  //   isBanks: true,

  // },
  {
    src: ImageConstants.commission,
    title: "Más información",
    content: "Consulta el detalle y las comisiones de tus fondos",
    flag: true,
    isBanks: false,
  },
];

export default settingsOptionsDetails;
