const defaultTheme = {
  font: {
    fontFamily: "Asap",
  },
  colors: {
    input: {
      color: "#b4b0b0",
    },
    fontColor: {
      defaultFontColor: "#fff",
      descriptionFontColor: "#3F3F3F",
      titleFontColor: "#2C787C",
      titleFontColorOpac: "#2C787C30",
      errorFontColor: "#ff0000",
      fieldTitleColor: "#5a5c69",
      phoneNumberFontColor: "#25d366",
      evolutionFontColor: "#a8d115",
      objectiveFontColor: "#7d7d7d",
      objectiveFontOpec: "#7d7d7d20",
      copiedMessageColor: "#669797",
      disabledFontColor: "#d5dce0",
      newsFontColor: "#626262",
      newsTitle: "#2D2D2D",
      optimizationCardTitle: "#7FEFDC",
      investmentAmountFont: "#4f4a4a",
      negativeGraphValueColor: "#f89292",
      primaryOptimizationText: "#B7B231",
      secondaryOptimizationText: "#D16A6A",
      micappitalDefaultColor: "#7ADCCA",
      darkFont: "#333333",
      primaryTextFieldColor: "#605F5F",
      textFieldFont: "#605f5f",
      renewalLabelFont: "#5f550e",
    },
    background: {
      bodyBackground: "#F2F2F2",
      inputFieldBackground: "#E9F7F8",
      defaultBackground: "#fff",
      imageBackground: "#e9f7f8",
      cardBackground: "#f3fbf9",
      errorBackground: "#ff0000",
      overlayBackground: "#000",
      defaultFieldBackground: "#F4FBFB",
      defaultCardBackground: "#DAF1F2",
      primaryCardBackground: "#30B2B8",
      primaryLoadingColor: "#ddd",
      secondaryLoadingColor: "#00ca90",
      teritiaryLoadingColor: "#ea9922",
      scrollbarBackground: "#0d6efd40",
      scrollbarHoverbackground: "#86b7fe",
      primaryMapLabelColor: "#a8d115",
      secondaryMapLabelColor: "#1c88c8",
      teritiaryMapLabelColor: "#30b2b8",
      optionsCardBackground: "#E9FFFF",
      primaryCardBackgroundOpec: "#30B2B830",
      dateSelectorBackground: "#efefef",
      menuButtonBackground: "#e7faf5",
      primaryInfoMessage: "#67eada",
      secondaryInfoMessage: "#f8d7da",
      teritiaryInfoMessage: "#fff3cd",
      objectiveBackground: "#30b2b820",
      outlineBorderColor: "#707070",
      primaryInsuranceLabelColor: "#E9FFF1",
      secondaryInsuranceLabelColor: "#F7DFDF",
      teritiaryInsuranceLabelColor: "#FDFCCB",
      processPopUpBorder: "#f6f6f6",
      processPopUpBackground: "#00CCCA",
      checkBoxColor: "#00cbc9",
      dropDownBackground: "#f0eded",
      selectFieldBorder: "#e0dddd",
      animationBackground: "#00ccca10",
      renewalLabelBackground: "#f8eba1",
    },
    text: {
      textPrimary: "#fff",
      textSecondary: "#7e7b90",
      fieldText: "#2C787C",
      yelowAlert: "#856404",
      redAlert: "#721c24",
    },
    icon: {
      fieldIcon: "#6BA0A3",
      eyeIcon: "#2C787C",
    },
    button: {
      background: {
        redButton: "#f07e7f",
        blueButton: "#E9F7F8",
        navabarButtonBackground: "#d9f7ef",
        callButtonBackground: "#30b2b833",
        whatsappButtonBackground: "#32d36633",
        teritiaryButton: "#f05759",
        quaternaryButton: " #a0dfd6",
        cancelButton: "#e9f1f1",
      },
      text: {
        textPrimary: "#65C5AC",
        textSecondary: "#fff",
        textTeritiory: "#2C787C",
      },
    },
  },
  boxShadow: {
    imageBoxShadow: "8px 8px 15px 0 rgb(11 57 59 / 15%)",
  },
  gradient: {
    ecoGradient: "linear-gradient(119deg, #b7f3e2 7%, #b7f3e2 95%)",
    goalGradient:
      "linear-gradient(138deg, #b7f3e24d 7%, #00ca904d 97%),linear-gradient(to bottom, #ffffff4d, #ffffff4d)",
    inviteFriendsGradient: "linear-gradient(119deg, #b7f3e2 7%, #b7f3e2 95%)",
  },
};

export default defaultTheme;
