import styled from "styled-components";

const AccountDetailsPopUpStyle = styled.div`
  .accountDetails-container {
    width: 100%;
    border-radius: 1.5625rem;
    background-color: ${(props) =>
      props.theme.colors.background.defaultBackground};
    padding: 2rem 2rem 4rem 2rem;
    @media (max-width: 820px) {
      padding: 2rem 1rem;
    }
    .accountDetails-header {
      display: flex;
      justify-content: space-between;
      @media (max-width: 820px) {
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
      .heading {
        color: ${(props) => props.theme.colors.fontColor.titleFontColor};
        font-weight: bold;
      }
      .header-btn {
        font-weight: bold;
        width: 24%;
        width: 11.2rem;
        min-width: 8.7rem;
        cursor: pointer;
        @media (max-width: 820px) {
          padding-top: 1rem;
        }
        .but-div {
          height: 2.5rem;
        }
      }
    }
    .form-container {
      width: 70%;
      padding-left: 2rem;
      @media (max-width: 820px) {
        width: 100%;
        display: flex;
        flex-flow: column;
        justify-content: center;
        padding-left: 0rem;
      }
      .form-field {
        .label {
          font-weight: bold;
          padding: 1rem 0.5rem;
        }
        .field-div {
          padding-left: 1.5rem;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          padding-right: 0.5rem;
        }
        .eyeOpen-img {
          height: 0.875rem;
        }
        .eyeClosed-img {
          height: 1.125rem;
        }
      }
    }
    .password-change-button-container {
      display: flex;
      justify-content: flex-start;
      margin-top: 1rem;
      @media (max-width: 820px) {
        justify-content: center;
      }
      .password-change-button {
        min-width: 13.75rem;
      }
    }
  }
`;
export default AccountDetailsPopUpStyle;
