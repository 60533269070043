import React from "react";
import LoaderStyle from "./spinnerStyle";
import LoadingGif from "../../../../assets/img/common/loader.gif";

export const LoaderGif = React.memo(({ data }) => {
  return (
    <LoaderStyle>
      <div className="loader_overlay">
        <div className="loader">
          <img src={LoadingGif} className="loaderImg" alt="X" />
        </div>
      </div>
    </LoaderStyle>
  );
});
