import styled from "styled-components";

const EuroTextFieldStyle = styled.div`
  width: 100%;
  .input {
    outline: none;
  }
  .input[contenteditable] {
    display: inline-block;
  }
  .input[contenteditable]:empty::before {
    content: "0";
    display: inline-block;
  }
  .input[contenteditable]:empty:focus::before {
    content: "";
  }
  .error-text {
    color: ${(props) => props.theme.colors.fontColor.errorFontColor};
  }
`;
export default EuroTextFieldStyle;
