import styled from "styled-components";
import plantImage from "../../../../assets/img/plantImage.svg";

const InvestmentBannerStyle = styled.div`
  height: 30rem;
  @media (max-width: 1150px) {
    height: 25rem;
  }
  @media (max-width: 600px) {
    height: 22rem;
  }

  .banner-container {
    background-image: url(${plantImage});
    background-repeat: no-repeat;
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    border-radius: 1.5625rem;
    .title-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 3rem;
      text-align: center;
      flex: 1;
      padding: 0rem 2rem;
      .banner-text{
        font-weight: bold;
        color: ${(props) => props.theme.colors.fontColor.darkFont};
      }
    }
    .text-container {
      margin-bottom: 2rem;
      padding: 0rem 1rem;
      width: 100%;
      display: flex;
      justify-content: center;
      @media (max-width: 480px) {
        margin-bottom: 1rem;
      }

      .banner-button-container {
        width: 100%;
        max-width: 24rem;
        .banner-button {
          width: 100%;
          padding: 0rem 0.2rem;
        }
      }
    }
  }
`;
export default InvestmentBannerStyle;
