import styled from "styled-components";

const InsuranceLetterSectionStyle = styled.div`
  padding: 2rem;
  .appointment-section-heading {
    color: ${(props) => props.theme.colors.fontColor.titleFontColor};
    font-weight: bold;
    @media (max-width: 540px) {
      text-align: center;
    }
  }
  .appointment-section-description {
    padding-left: 1.5rem;
    margin-top: 1rem;
    color: ${(props) => props.theme.colors.fontColor.objectiveFontColor};
    width: 85%;
    @media (max-width: 1040px) {
      width: 100%;
      padding-left: 0rem;
    }
    @media (max-width: 540px) {
      text-align: center;
    }
  }
  .appointment-button-container {
    margin-top: 1rem;
    .download-button {
      max-width: 15rem;
      font-weight: bold;
      background-color: ${(props) =>
        props.theme.colors.fontColor.titleFontColor};
    }
  }
`;
export default InsuranceLetterSectionStyle;
