import { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { AuthTokenService } from "../../../services";
import { AlertContext } from "../../common/alertContainer";
import { ContainerContext } from "../../../components/navbarContainer/containerProvider";
import { getInsuranceList } from "../insuranceList/insuranceListServices";

const RenderContainer = () => {
  const [menuHidden] = useState(true);
  const { open } = useContext(AlertContext);
  const navigate = useNavigate();
  const { changeMenuStatus, changeNavBarStatus, changeAlertStatus } =
    useContext(ContainerContext);
  const idClienteSeguros = AuthTokenService.getIdClienteSeguros();

  useEffect(() => {
    changeNavBarStatus(true);
    changeMenuStatus(menuHidden);
    changeAlertStatus(false);
    return () => {
      changeMenuStatus(!menuHidden);
      changeNavBarStatus(false);
      changeAlertStatus(true);
    };
  });

  useEffect(() => {
    open({
      alertType: "spinner",
      open: true,
    });

    if (!idClienteSeguros) {
      navigate("/landingPage");
    } else {
      getInsuranceList(idClienteSeguros)
        .then((result) => {
          open({
            alertType: "spinner",
            open: false,
          });
          const data = result.data.listaSeguros;
          if (data.length > 0) {
            navigate("/insuranceList");
          } else {
            navigate("/landingPage");
          }
        })
        .catch(() => {
          open({
            alertType: "spinner",
            open: false,
          });
          open({
            type: "error",
            title: "Error al enviar datos",
            alertType: "toast",
            open: true,
          });
        });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return <div></div>;
};

export default RenderContainer;
