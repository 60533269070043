import styled from "styled-components";

const InsuranceReviewStyle = styled.div`
  .insurance-review-container {
    padding: 1rem 1rem 3rem 1rem;
    .insurance-review-heading {
      font-weight: bold;
      padding-left: 0.6rem;
      padding-bottom: 1rem;
      color: ${(props) => props.theme.colors.fontColor.titleFontColor};
      @media (max-width: 540px) {
        text-align: center;
      }
    }
    .insurance-review-option-container {
      display: flex;
      justify-content: space-around;
      gap: 0.5rem;
      flex-wrap: wrap;
      .review-option-container {
        min-width: 11rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        flex: 0 1 23%;
        background-color: ${(props) =>
          props.theme.colors.background.optionsCardBackground};
        padding: 3rem 1rem;
        border-radius: 2.18rem;
        .review-option-icon {
          margin-bottom: 3rem;
          .option-icon {
            width: 2rem;
            height: 2rem;
          }
        }
        .review-option-title {
          font-weight: 600;
          text-align: center;
          margin-bottom: 1rem;
          color: ${(props) =>
            props.theme.colors.fontColor.descriptionFontColor};
        }
        .review-option-description {
          text-align: center;
          color: ${(props) =>
            props.theme.colors.fontColor.descriptionFontColor};
        }
      }
    }
    .bottom-text {
      text-align: center;
      margin-top: 2rem;
    }
  }
`;
export default InsuranceReviewStyle;