import React, { useEffect, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import { AlertContext } from "../../../common/alertContainer";
import { AuthTokenService } from "../../../../services";
import defaultTheme from "../../../../theme/theme";
import { getNewsList } from "./newsServices";
import ImageConstants from "../../../../constants/imageConstants/image_constants";
import NewsStyle from "./newsStyle";
import Text from "../../../../theme/font";

const News = () => {
  const theme = defaultTheme;
  const [newsData, setNewsData] = useState([]);
  const navigate = useNavigate();
  const { open } = useContext(AlertContext);

  useEffect(() => {
    const userId = AuthTokenService.getUserId();
    open({
      alertType: "spinner",
      open: true,
    });
    getNewsList(userId)
      .then((result) => {
        open({
          alertType: "spinner",
          open: false,
        });
        if (result?.data) {
          const totalData = result.data.data;
          const filteredNewsData = totalData.filter((item, index) => index < 2);
          setNewsData(filteredNewsData);
        }
      })
      .catch(() => {
        open({
          alertType: "spinner",
          open: false,
        });
        open({
          type: "error",
          title: "Error al enviar datos",
          alertType: "toast",
          open: true,
        });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const allNewsHandler = () => {
    navigate("/noticias");
  };
  const singleNewsHandler = (link) => {
    //navigate(`/noticia?newsId=${id}`);
    window.open(link, '_blank', 'noopener,noreferrer');
  };

  return (
    <ThemeProvider theme={theme}>
      <NewsStyle>
        <div className="box-container noticeContainer">
          <Text variant="primaryFont" className="noticeTitle">
            Últimas Noticias
          </Text>
          {newsData &&
            newsData.map((data, i) => {
              return (
                <div className="newsList" key={Math.random()}>
                  <div className="notifyImg">
                    <img
                      src={ImageConstants.redCircle}
                      className="notifyIcon"
                      alt="X"
                    />
                  </div>
                  <Text className="newsDescription" variant="labelFont">
                    {data.titulo}
                  </Text>
                  <Text className="updatedTime" variant="normal">
                    {data.tiempo}
                  </Text>
                  <Text
                    className="readMore"
                    variant="normal"
                    onClick={() => singleNewsHandler(data.link)}
                  >
                    leer
                  </Text>
                </div>
              );
            })}
          <Text
            className="viewAll"
            variant="labelFont"
            onClick={allNewsHandler}
          >
            Ver todas
          </Text>
        </div>
      </NewsStyle>
    </ThemeProvider>
  );
};
export default News;
