// import Button from "../../../../components/button/button";
import ProcessSectionStyle from "./processSectionStyle";
import Text from "../../../../theme/font";
// import ImageConstants from "../../../../constants/imageConstants/image_constants";

const ProcessSection = () => {
  // const buttonValueHandler = () => {
  //   return (
  //     <Text className="button-value">
  //       <img src={ImageConstants.callIcon} alt="x" className="call-icon" />
  //       913778777
  //     </Text>
  //   );
  // };

  return (
    <ProcessSectionStyle>
      <Text variant="titleFont" className="process-section-heading">
        Tramitar un parte o siniestro
      </Text>
      <Text className="process-section-description" variant="popUpFont">
        Si necesitas ayuda para tramitar un parte o siniestro, ponte en contacto con nosotros a través del whatsapp y estaremos encantados de ayudarte
      </Text>
      {/* <Text variant="labelFont" className="call-button-container">
        <Button className="download-button" value={buttonValueHandler()} />
      </Text> */}
    </ProcessSectionStyle>
  );
};
export default ProcessSection;
