import { useEffect, useState } from "react";
import Button from "../../../../components/button/button";
import EcoFundProposalStyle from "./ecoFundProposalStyle";
import ImageConstants from "../../../../constants/imageConstants/image_constants";
import Text from "../../../../theme/font";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination } from "swiper";

const EcoFundProposal = ({
  data,
  click,
  popUpClick,
  changePopUpClick,
  connectData,
  responsiveData,
}) => {
  const [amount, setAmount] = useState("");
  const [changedAmount, setChangedAmount] = useState(
    data && data.importeTotal && data.importeTotal
  );

  useEffect(() => {
    if (data && data.info && data.info.inversionTotal) {
      setAmount(data.info.inversionTotal);
    }
    if (data.importeTotal) {
      setChangedAmount(data && data.importeTotal && data.importeTotal);
    }
  }, [data, responsiveData]);

  const bankData = data.info;
  const videoData = data?.info?.banco?.enlacevideo;

  const videoScrollHandler = () => {
    click();
  };

  const popUpClickHandler = () => {
    popUpClick();
  };

  const formatter = (value) =>
    new Intl.NumberFormat("de", {
      style: "currency",
      currency: "EUR",
    }).format(value);

  const linkClickHandler = (title) => {
    if (title === "Cambiar") {
      changePopUpClick();
    } else {
      videoScrollHandler();
    }
  };

  const swiperHandler = () => {
    return (
      <div className="responsive-container">
        <Swiper
          pagination={{
            dynamicBullets: true,
            clickable: true,
          }}
          modules={[Pagination]}
          className="mySwiper"
        >
          {responsiveData &&
            responsiveData.map((values) => {
              return (
                <SwiperSlide>
                  <div className="proposal-section-container"  key={Math.random()}>
                    <div className="proposal-section-header">
                      <div className="proposal-section-heading">
                        <Text variant="titleFont">{values.title}</Text>
                      </div>
                      <div className="proposal-section-description">
                        <Text variant="normal">{values.content}</Text>
                      </div>
                    </div>
                    <div className="responsive-investment-details-container">
                      <div className="fund-details">
                        <div className="fund-icon">
                          <img src={values.imageIcon} alt="x" />
                        </div>
                        <div className="fund-info">
                          <div className="fund-title">
                            <Text variant="normal">
                              {values.secondaryTitle}
                            </Text>
                          </div>
                          {values.bankIcon && (
                            <div className="bank-img">
                              <img src={values.bankIcon} alt="x" />
                            </div>
                          )}
                          <div className="fund-count">
                            {values.amount && !values.changedAmount && (
                              <Text
                                variant="titleFont"
                                className=" total-amount"
                              >
                                {formatter(values.amount)}
                              </Text>
                            )}
                            {values.changedAmount && (
                              <Text
                                variant="titleFont"
                                className="changed-amount"
                              >
                                {formatter(values.changedAmount)}
                              </Text>
                            )}
                            {values.funds && (
                              <Text variant="titleFont" className="fund-counts">
                                {values.funds > 1
                                  ? `${values.funds} fondos`
                                  : `${values.funds} fondo`}
                              </Text>
                            )}
                            {values.linkText && (
                              <span
                                onClick={() => {
                                  linkClickHandler(values.linkText);
                                }}
                                className="cambiarText"
                              >
                                {values.linkText}
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              );
            })}
        </Swiper>
      </div>
    );
  };

  return (
    <EcoFundProposalStyle>
      <div className="proposal-section-container main-container">
        <div className="proposal-section-header">
          <div className="proposal-section-heading">
            <Text variant="titleFont"> Resumen de tu aportación Eco</Text>
          </div>
          <div className="proposal-section-description">
            <Text variant="normal">
              ¡Bien hecho! Los inversores que aportan a su inversión de manera
              periódica obtienen, de media, una rentabilidad un 70% mejor que
              los que no lo hacen
            </Text>
          </div>
        </div>
        <div className="investment-details-container">
          <div className="fund-details">
            <div className="fund-icon">
              <img src={ImageConstants.walletIcon} alt="x" />
            </div>
            <div className="fund-info">
              <div className="fund-title">
                <Text variant="normal">Vas a invertir</Text>
              </div>
              <div className="fund-count">
                {!changedAmount && (
                  <Text variant="titleFont">{formatter(amount)}</Text>
                )}
                {changedAmount && (
                  <Text variant="titleFont">{formatter(changedAmount)}</Text>
                )}
                <span
                  onClick={() => {
                    changePopUpClick();
                  }}
                  className="cambiarText"
                >
                  Cambiar
                </span>
              </div>
            </div>
          </div>
          <div className="fund-details">
            <div className="fund-icon">
              <img src={ImageConstants.walletIcon} alt="x" />
            </div>
            <div className="fund-info">
              <div className="fund-title">
                <Text variant="normal">Vas a aportar a</Text>
              </div>
              <div className="fund-count">
                {bankData && (
                  <Text variant="titleFont">
                    {bankData.numaportaciones > 1
                      ? `${bankData.numaportaciones} fondos`
                      : `${bankData.numaportaciones} fondo`}
                  </Text>
                )}
              </div>
            </div>
          </div>
          <div className="bank-details">
            <div className="bank-icon">
              <img src={ImageConstants.bankIcon} alt="x" />
            </div>
            <div className="bank-info">
              <div className="bank-title">
                <Text variant="normal">En tu entidad bancaria</Text>
              </div>
              {bankData && (
                <div className="bank-img">
                  <img src={bankData.banco.image} alt="x" />
                </div>
              )}
            </div>
          </div>
          <div className="help-details">
            <div className="video-icon">
              <img src={ImageConstants.videoIcon} alt="x" />
            </div>
            <div className="help-info">
              <div className="help-title">
                <Text variant="normal">¿Cómo realizo las inversiones? </Text>
              </div>
              <div className="help-tutorial">
                <Text variant="normal" onClick={videoScrollHandler}>
                  {videoData ? "Ver vídeo tutorial" : "Ver receta tutorial"}
                </Text>
              </div>
            </div>
          </div>
        </div>
      </div>
      {swiperHandler()}
      {connectData === "manual" && (
        <div className="bought-btn">
          <Button
            value="Ya he comprado mis fondos"
            className="btn-div"
            click={popUpClickHandler}
          />
        </div>
      )}
    </EcoFundProposalStyle>
  );
};
export default EcoFundProposal;
