import styled from "styled-components";

const NecessaryActionsStyle = styled.div`
  padding: 1.2rem;
  @media (max-width: 500px) {
    padding: 1.8rem;
          }
  .necessary-actions-container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    .header-container {
      display: flex;
      justify-content: space-between;
      align-items: baseline;
      .necessary-actions-heading {
        font-weight: bold;
        color: ${(props) => props.theme.colors.fontColor.titleFontColor};
      }
      .optimization-data {
        margin-left: 0.3rem;
        color: ${(props) => props.theme.colors.text.fieldText};
        font-weight: bold;
      }
    }
    .necessary-actions-description {
      color: ${(props) => props.theme.colors.fontColor.descriptionFontColor};
      font-weight: bold;
      padding-left: 1rem;
      @media (max-width: 540px) {
        text-align: center;
        padding: 0rem;
      }
    }
    .improvements-button-container {
    }
    .improvements-button {
      background-color: ${(props) =>
        props.theme.colors.fontColor.titleFontColor};
      font-weight: bold;
    }
  }
`;
export default NecessaryActionsStyle;
