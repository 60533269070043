import React, { useEffect, useState, useContext, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { ThemeProvider } from "styled-components";
import { AlertContext } from "../../container/common/alertContainer";
import { AuthTokenService } from "../../services";
import { cargarPosicion } from "../../container/investment/home/homeServices";
import defaultTheme from "../../theme/theme";
import ImageConstants from "../../constants/imageConstants/image_constants";
import NavbarStyle from "./navbarStyle";
import SideBar from "../sidebar/sidebar.jsx";
import Text from "../../theme/font";

const pageOptions = [
  { name: "Propuesta inicial", path: "/propuesta" },
  { name: "Resumen", path: "/resumen" },
  { name: "Aportaciones", path: "/aportaciones" },
  { name: "Eco", path: "/eco" },
  { name: "Amigos", path: "/amigos" },
  { name: "Ajustes", path: `/ajustes?section=${"investment"}` },

];

const secondaryPageOptions = [
  { name: "Resumen", path: "/renderContainer" },
  //{ name: "Autopilot", path: "/micappitalZero" },
];

const Navbar = React.memo((props) => {
  const theme = defaultTheme;
  const [profileImage, setProfileImage] = useState(
    AuthTokenService.getUserImage()
  );
  const [proposal, setProposal] = useState(null);
  const [zIndex, setZIndex] = useState(1);
  const [currentPath, setCurrentPath] = useState(window.location.pathname);
  const { open } = useContext(AlertContext);
  const navigate = useNavigate();

  const swiperRef = useRef(null);
  const firstName = AuthTokenService.getNombre();
  const lastName = AuthTokenService.getApellidos();

  const menuHandler = () => {
    const userId = AuthTokenService.getUserId();
    if (userId) {
      cargarPosicion(userId)
        .then((result) => {
          open({
            alertType: "spinner",
            open: false,
          });
          if (result.statusText === "OK" && result.status === 200) {
            setProposal(result.data.data[0].valor);
          }
        })
        .catch(() => {
          open({
            alertType: "spinner",
            open: false,
          });
          open({
            type: "error",
            title: "Error al enviar datos",
            alertType: "toast",
            open: true,
          });
        });
    }
  };

  useEffect(() => {
    setCurrentPath(window.location.pathname);
    menuHandler();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.pathname]);

  useEffect(() => {
    if (props.profileIcon) {
      let file = new FileReader();
      file.readAsDataURL(props.profileIcon);
      file.onload = () => {
        setProfileImage(file.result);
        localStorage.setItem("imagen", file.result);
      };
    }
  }, [props.profileIcon]);

  //Log out functionality by clearing the token
  const logoutHandler = () => {
    AuthTokenService.clearToken();
    navigate("/login");
  };

  //For showing user name
  const nameHandler = () => {
    let firstLetter = firstName && firstName.charAt(0);
    let lastLetter = lastName && lastName.charAt(0);
    let fullName = firstLetter + lastLetter;
    return fullName;
  };

  const navHandler = () => {
    if (currentPath === "/propuestaFondos") {
      navigate("/Aportaciones");
    } else {
      navigate("/resumen");
    }
  };

  //For controlling the z index of swiper when click on the menu button
  const menuClickHandler = () => {
    if (zIndex === 1) {
      setZIndex(-1);
    } else {
      setTimeout(() => {
        setZIndex(-1);
      }, 200);
    }
  };

  const HamburgerMenuHandler = () => {
    if (
      currentPath === "/editarObjetivo" ||
      currentPath === "/propuestaFondos" ||
      currentPath === "/nuevoObjetivo" ||
      currentPath === "/optimizacion" ||
      currentPath === "/ecoInvestments" ||
      currentPath === "/noticias" ||
      currentPath === "/noticia"
    ) {
      return (
        <div className="back-icon-container" onClick={() => navHandler()}>
          <img src={ImageConstants.arrowLeftIcon} alt="x" />
        </div>
      );
    } else {
      return (
        <div id="menu">
          <SideBar
            click={() => menuClickHandler()}
            headerTitle={props.headerTitle}
          />
        </div>
      );
    }
  };

  const titleClickHandler = (path) => {
    navigate(path);
  };

  const navigationHandler = () => {
    if (props.headerTitle === "Inversión") {
      navigate("/ajustes");
    }
  };

  const breakpoints = {
    320: {
      slidesPerView: 4,
      spaceBetween: 20,
    },
    360: {
      slidesPerView: 4,
      spaceBetween: 30,
    },
    660: {
      slidesPerView: 5,
      spaceBetween: 0,
    },
    1040: {
      slidesPerView: 7,
      spaceBetween: 0,
    },
  };

  // eslint-disable-next-line no-lone-blocks
  {
    const { pageName } = props;

    return (
      <ThemeProvider theme={theme}>
        <NavbarStyle>
          <div className="navbar-container">
            <div className="navbar-bottom-section">
              <div className="navbar-section">
                <div className="navbar-logo-container">
                  <img
                    alt="micappital"
                    className="logo-icon"
                    src={ImageConstants.newLogo}
                  />
                </div>
                {HamburgerMenuHandler()}
                <div className="navbar-title">
                  <Text variant="mediumFont" type="primary">
                    {props.headerTitle}
                  </Text>
                </div>
                <div className="right-container">
                  <div className="avatar-group">
                    <img
                      className="avatar-img"
                      src={
                        profileImage ? profileImage : ImageConstants.AvatarLogo
                      }
                      alt="x"
                    ></img>
                    <div
                      className="avatar-text"
                      onClick={() => {
                        navigationHandler();
                      }}
                    >
                      <Text
                        className="normal-avatar-text"
                        variant="normal"
                        type="primary"
                      >
                        {firstName + " " + lastName}
                      </Text>
                      <Text
                        className="responsive-avatar-text"
                        variant="normal"
                        type="primary"
                      >
                        {nameHandler()}
                      </Text>
                    </div>
                  </div>
                  <div className="signout" onClick={logoutHandler}>
                    <Text variant="fieldFont" type="primary">
                      Cerrar sesión
                    </Text>
                  </div>
                  <img
                    alt="x"
                    src={ImageConstants.offIcon}
                    className="signout-icon"
                    onClick={logoutHandler}
                  />
                </div>
              </div>
              <div className="title-option-container">
                {props.headerTitle === "Seguros" && (
                  <div className="option-container">
                    <Swiper
                      className="mySwiper"
                      breakpoints={breakpoints}
                      spaceBetween={0}
                      loop={false}
                      ref={swiperRef}
                    >
                      {secondaryPageOptions.map((value) => {
                        return (
                          <SwiperSlide
                            className="swiper-title"
                            key={Math.random()}
                          >
                            <Text
                              variant="fieldFont"
                              className={
                                pageName === value.name
                                  ? "selected-name"
                                  : "not-selected-option"
                              }
                              onClick={() => titleClickHandler(value.path)}
                            >
                              {value.name}
                            </Text>
                          </SwiperSlide>
                        );
                      })}
                    </Swiper>
                  </div>
                )}

                {props.headerTitle === "Inversión" && (
                  <div className="option-container">
                    <Swiper
                      className="mySwiper"
                      style={{ zIndex: zIndex }}
                      breakpoints={breakpoints}
                      spaceBetween={0}
                      loop={false}
                      ref={swiperRef}
                    >
                      {/* eslint-disable-next-line array-callback-return */}
                      {pageOptions.map((value, index) => {
                        if (
                          proposal &&
                          ((proposal !== "0" && index !== 0) ||
                            proposal === "0")
                        ) {
                          return (
                            <SwiperSlide
                              className="swiper-title"
                              key={Math.random()}
                            >
                              <Text
                                variant="fieldFont"
                                className={
                                  pageName === value.name
                                    ? "selected-name"
                                    : "not-selected-option"
                                }
                                onClick={() => titleClickHandler(value.path)}
                              >
                                {value.name}
                              </Text>
                            </SwiperSlide>
                          );
                        }
                      })}
                    </Swiper>
                  </div>
                )}
              </div>
            </div>
          </div>
        </NavbarStyle>
      </ThemeProvider>
    );
    // }
  }
});

export default Navbar;
