import styled from "styled-components";

const LandingPageStyle = styled.div`
  padding: 1rem 1.7rem 1rem 0rem;
  @media (max-width: 1040px) {
    padding: 1rem 0rem;
  }
  .process-pop-section {
    .process-popup-wrapper {
      position: fixed;
      z-index: 150;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      .popup-overlay {
        width: 100%;
        height: 100%;
        background-color: ${(props) =>
          props.theme.colors.background.overlayBackground};
        opacity: 0.5;
      }
      .process-popup-container {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        height: auto;
        max-height: 100%;
        overflow: hidden;
        min-width: 70%;
        @media (max-width: 1040px) {
          min-width: 85%;
        }
      }
    }
  }
  .landing-page-container {
    display: flex;
    gap: 2%;
    @media (max-width: 1040px) {
      flex-direction: column;
    }

    .primary-section {
      width: calc(100% - 35%);
      display: flex;
      flex-direction: column;
      gap: 1.5rem;
      @media (max-width: 1040px) {
        width: 100%;
        padding-bottom: 1.5rem;
      }
      .banner-section {
        width: 100%;
      }
    }
    .secondary-section {
      display: flex;
      flex-direction: column;
      gap: 1.5rem;
      width: calc(100% - 67%);
      @media (max-width: 1040px) {
        width: 100%;
        display: flex;
        align-items: center;
      }
      .simulate-section {
        @media (max-width: 1040px) {
          max-width: 22rem;
        }
      }
      .all-in-one-section {
        @media (max-width: 1040px) {
          max-width: 22rem;
        }
      }
    }
  }
  .closing-popup-container {
    position: absolute;

    top: 50%;

    left: 50%;

    transform: translate(-50%, -50%);
  }
`;
export default LandingPageStyle;
