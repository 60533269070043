import LocationFieldStyle from "./autoLocationStyle";
import Text from "../../theme/font";
import ImageConstants from "../../constants/imageConstants/image_constants";

// import React, { Component, useState } from "react";
// import { Map, GoogleApiWrapper, InfoWindow, Marker } from "google-maps-react";

// const mapStyles = {
//   width: "100%",
//   height: "100%",
// };

// const AutoLocation = () => {
//   const [state, setState] = useState();

//   const onMarkerClick = (props, marker, e) =>
//     setState({
//       selectedPlace: props,
//       activeMarker: marker,
//       showingInfoWindow: true,
//     });

//   const onClose = (props) => {
//     if (state.showingInfoWindow) {
//       setState({
//         showingInfoWindow: false,
//         activeMarker: null,
//       });
//     }
//   };

//   return (
//     <Map
//       google={this.props.google}
//       zoom={14}
//       style={mapStyles}
//       initialCenter={{
//         lat: -1.2884,
//         lng: 36.8233,
//       }}
//     >
//       <Marker
//         onClick={onMarkerClick}
//         name={"Kenyatta International Convention Centre"}
//       />
//       <InfoWindow
//         marker={state.activeMarker}
//         visible={state.showingInfoWindow}
//         onClose={onClose}
//       >
//         <div>
//           <h4>{state.selectedPlace.name}</h4>
//         </div>
//       </InfoWindow>
//     </Map>
//   );
// };

// export default GoogleApiWrapper({
//   apiKey: "YOUR_GOOGLE_MAPS_API_KEY_GOES_HERE",
// })(AutoLocation);

const LocationField = ({
  placeholder,
  handleChange,
  name,
  error,
  helperText,
}) => {
  return (
    <LocationFieldStyle>
      <div className="location-field-container">
        <input
          className="input-field"
          placeholder={placeholder}
          onChange={handleChange}
          name={name}
        />
        <div className="icon-container">
          <img alt="x" src={ImageConstants.location} className="location-icon"/>
        </div>
      </div>
      {error && (
        <Text variant="extraSmall">
          <div className="error-val">{helperText}</div>
        </Text>
      )}
    </LocationFieldStyle>
  );
};

export default LocationField;
