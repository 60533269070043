import styled from "styled-components";

const EditGoalStyle = styled.div`
  height: 100%;
  .edit-goal-container {
    display: flex;
    height: 100%;
    @media (max-width: 620px) {
      flex-direction: column;
    }
  }
`;
export default EditGoalStyle;
