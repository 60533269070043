import React, { useEffect, useState, useContext } from "react";
import AddFriendsStyle from "./addFriendsStyle";
import { AlertContext } from "../../common/alertContainer";
import { AuthTokenService } from "../../../services";
import { ContainerContext } from "../../../components/navbarContainer/containerProvider";
import { getFriendsDetails } from "./addFriendsServices";
import InvitedFriends from "./invitedFriends/invitedFriends";
import InviteYourFriends from "./inviteYourFriends/inviteYourFriends";

const AddFriends = React.memo(() => {
  const [friendsDetails, setFriendsDetails] = useState("");
  const { open } = useContext(AlertContext);
  const { titleHandler, pageNameHandler, headerTitleHandler } =
    useContext(ContainerContext);

  useEffect(() => {
    window.scrollTo(0, 0);
    open({
      alertType: "spinner",
      open: true,
    });
    toogleNavBar();

    const userId = AuthTokenService.getUserId();
    getFriendsDetails(userId)
      .then((response) => {
        open({
          alertType: "spinner",
          open: false,
        });
        setFriendsDetails(response.data);
      })
      .catch(() => {
        open({
          alertType: "spinner",
          open: false,
        });
        open({
          type: "error",
          title: "error encontrado",
          alertType: "toast",
          open: true,
        });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toogleNavBar = () => {
    titleHandler("Invita a tus amigos");
    pageNameHandler("Amigos");
    headerTitleHandler("Inversión");
  };

  return (
    <AddFriendsStyle>
      <div className="add-friends-container margin30">
        <div className="invite-section box-container">
          {/* if friendsDetails is there then pass friendsDetails as the data  */}
          <InviteYourFriends data={friendsDetails && friendsDetails} />
        </div>
        <div className="invited-section box-container">
          <InvitedFriends data={friendsDetails && friendsDetails} />
        </div>
      </div>
    </AddFriendsStyle>
  );
});

export default AddFriends;
