import styled from "styled-components";

const ClickPopupStyle = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  .click-popup-container {
    display: flex;
    align-items: center;
    flex-direction: column;
    background-color: ${(props) =>
      props.theme.colors.background.bodyBackground};
    padding: 2rem 1rem 2.5rem 1rem;
    width: 100%;
    border-radius: 1.56rem;
    align-items: center;
    box-shadow: 0.5rem 0.5rem 1.87rem 0 rgb(11 57 59 / 18%);
    @media (max-width: 440px) {
      padding: 1rem 1rem 1.5rem 1rem;
    }
    .click-process-popup-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 1rem;
      max-width: 70%;
      backface-visibility: hidden;
      @media (max-width: 440px) {
        max-width: 100%;
      }
    }
    .click-heading {
      text-align: center;
      font-weight: bold;
      color: ${(props) => props.theme.colors.fontColor.darkFont};
    }
    .click-button-container {
      display: flex;
      width: 100%;
      justify-content: flex-end;
      gap: 1.5rem;
      margin-top: 2rem;
      @media (max-width: 600px) {
        justify-content: center;
      }
      .return-text {
        text-decoration: underline;
        cursor: pointer;
        color: ${(props) => props.theme.colors.fontColor.objectiveFontColor};
      }
      .continue-icons-button {
        min-width: 7rem;
        background-color: ${(props) =>
          props.theme.colors.background.processPopUpBackground};
        border-radius: 1rem;
        display: flex;
        justify-content: center;
        align-items: center;
        color: ${(props) => props.theme.colors.fontColor.defaultFontColor};
        cursor: pointer;
        .continue-icon {
          @media (max-width: 540px) {
            height: 70%;
          }
        }
      }
    }
  }
`;
export default ClickPopupStyle;
