import { insuranceCompanies } from "../../../../models/insurancelandingPage";
import Text from "../../../../theme/font";
import BankListSecionStyle from "./bankListSectionStyle";

const BankListSection = () => {
  return (
    <BankListSecionStyle>
      <div className="bank-section-container">
        <Text variant="mediumFont" className="bank-section-heading">
          ¿Tienes cuenta en uno de estos bancos? Te ayudamos
        </Text>
        <Text variant="smallFont" className="bank-section-description">
          Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam
          nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam Lorem
          ipsum dolor sit amet, consectetuer adipiscing elit, sed diam
        </Text>
        <div className="bank-options-section">
          <div className="bank-option-container">
            {insuranceCompanies &&
              insuranceCompanies.map((data) => {
                return (
                  <div className="bank-container" key={Math.random()}>
                    <img alt="x" src={data.image} />
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </BankListSecionStyle>
  );
};

export default BankListSection;
