import React, { useState, useContext, useEffect } from "react";
import { ThemeProvider } from "styled-components";
import { AlertContext } from "../../../common/alertContainer";
import { AuthTokenService } from "../../../../services";
import { cargarFondos } from "../homeServices";
import defaultTheme from "../../../../theme/theme";
import List from "../../../../components/list/list";
import ListItem from "../../../../components/listItem/listItem";
import Loading from "../../../../components/commonModules/Loading";
import PanelFundsStyle from "./panelFundsStyle";
import Text from "../../../../theme/font";

const PanelFunds = () => {
  const [funds, setFunds] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const [data, setData] = useState([]);
  const { open } = useContext(AlertContext);
  const theme = defaultTheme;
  const [fundData, setFundData] = useState([]);

  useEffect(() => {
    open({
      alertType: "spinner",
      open: true,
    });
    const userId = AuthTokenService.getUserId();
    cargarFondos(userId)
      .then((result) => {
        open({
          alertType: "spinner",
          open: false,
        });
        if (result.statusText === "OK" && result.status === 200) {
          const funds = result.data.data;
          setFunds(funds);
          setIsLoading(false);
        }
      })
      .catch(() => {
        open({
          alertType: "spinner",
          open: false,
        });
        open({
          type: "error",
          title: "Error al enviar datos",
          alertType: "toast",
          open: true,
        });
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const combinedData = [];
    let cleanFunds = [];

    if (funds) {
      // eslint-disable-next-line array-callback-return
      funds.map((items) => {
        if (funds.valor !== 0 || funds.porcentaje !== 0) {
          cleanFunds.push(items);
        }
      });
      setData([...data, ...cleanFunds]);

      // eslint-disable-next-line array-callback-return
      cleanFunds.map((value) => {
        const dataItems = {
          primary: value.Nombre,
          secondary: value.ISIN,
          teritioary: value.valor,
        };
        combinedData.push(dataItems);
      });
      setFundData([...fundData, ...combinedData]);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [funds]);


  const renderHandler = () => {
    if (funds == null || isLoading) {
      return <Loading />;
    } else {
      return (
        <div className="panelFondosContainer ">
          <div className="tituloOptimizacion">
            <Text variant="primaryFont">Tus fondos</Text>
          </div>
          <List data={fundData} limit={5}>
            <ListItem variant="home" showImage={true} />
          </List>
        </div>
      );
    }
  };
  return (
    <ThemeProvider theme={theme}>
      <PanelFundsStyle>{renderHandler()}</PanelFundsStyle>
    </ThemeProvider>
  );
};

export default PanelFunds;
