import React, { useState } from "react";
import { toast } from "react-toastify";
import FundRecommendationSectionStyle from "./fundRecommendationSectionStyle";
import ImageConstants from "../../../../constants/imageConstants/image_constants";
import Text from "../../../../theme/font";

const FundRecommendationSection = (props) => {
  const [isCopied, setIsCopied] = useState(8);
  const totalAmounts = props.data.fondos;
  const frasePrevia = props.data.info.mensaje;

  const copyToClipboard = (value, index) => {
    const text = value;
    setIsCopied(index);
    navigator.clipboard.writeText(text);
    setTimeout(() => {
      setIsCopied(8);
    }, [1000]);
  };

  const formato = (x) => {
    var number = new Intl.NumberFormat("de-DE", {
      minimumFractionDigits: 2, //Decimals for returns at the top of the chart
      maximumFractionDigits: 2,
    }).format(x);
    return `${number}€`;
  };

  const recommendationOptionshandler = (props) => {
    return (
      totalAmounts &&
      totalAmounts.map((post, index) => {
        return (
          <div className="change-recommendation-container" key={Math.random()}>
            <div className="title-container">
              <Text className="card-title">Compra</Text>
            </div>
            <div className="amount-container">
              <Text className="amount-title">Importe</Text>
              <Text variant="mediumFont" className="amount">
                {formato(post.aportar)}
              </Text>
            </div>
            <div className="amount-details">
              <div className="leaves-details">
                <Text variant="fieldFont" className="leaves-primary-details">
                  {post.nombre}
                </Text>
                <div className="copy-section">
                  <Text variant="extraSmall" className="leaves-ISIN">
                    ISIN&nbsp;:
                    <span className="ISIN-number">&nbsp;{post.ISIN}</span>
                  </Text>
                  <div
                    className="copy-icon"
                    onClick={() => copyToClipboard(post.ISIN, index)}
                  >
                    <img src={ImageConstants.copyISIN} alt="x" />
                  </div>
                  {isCopied === index &&
                    toast.success("ISIN copiado", {
                      position: "top-right",
                      autoClose: 1000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      toastId: "",
                    })}
                </div>
              </div>
            </div>
          </div>
        );
      })
    );
  };

  return (
    <FundRecommendationSectionStyle>
      <div className="fund-recommendation_container">
        <div className="add-investment-icon">
          <img className="add-icon" alt="x" src={ImageConstants.changeIcon} />
        </div>
        <div className="primary-description">
          <Text variant="titleFont">
            Te recomendamos aportar en estos fondos
          </Text>
        </div>
        <div className="secondary-description">
          <Text variant="labelFont">
            Estos son los fondos que mejor se adaptan a tus objetivos
          </Text>
        </div>
        <div className="recommendation-options">
          {recommendationOptionshandler()}
        </div>
        <div className="secondary-description">
          <Text variant="labelFont">
            {frasePrevia}
          </Text>
        </div>
      </div>
    </FundRecommendationSectionStyle>
  );
};

export default FundRecommendationSection;
