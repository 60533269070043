import styled from "styled-components";

const SimulateInsuranceStyle = styled.div`
  .simulate-insurance-section {
    .simulate-insurance-section-container {
      padding: 1rem;
      display: flex;
      flex-direction: column;
      gap: 1rem;
      @media (max-width: 1200px) {
        padding: 2rem;
      }
      .simulate-insurance-section-header {
        margin-bottom: 1rem;
        .simulate-insurance-section-heading {
          color: ${(props) => props.theme.colors.fontColor.titleFontColor};
          font-weight: bold;
          @media (max-width: 540px) {
            text-align: center;
          }
        }
      }
      .simulate-button-container {
        .simulate-button {
          background-color: ${(props) =>
            props.theme.colors.fontColor.titleFontColor};
          max-width: 10rem;
          font-weight: bold;
        }
      }
    }
  }
`;
export default SimulateInsuranceStyle;
