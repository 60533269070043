import styled from "styled-components";

const CancelLetterSectionStyle = styled.div`
  padding: 2rem 2rem 1rem 2rem;
  .cancel-section-heading {
    color: ${(props) => props.theme.colors.fontColor.titleFontColor};
    font-weight: bold;
    margin-bottom: 1rem;
    @media (max-width: 540px) {
      text-align: center;
    }
  }
  .cancel-section-description {
    padding-left: 1.5rem;
    color: ${(props) => props.theme.colors.fontColor.objectiveFontColor};
    width: 80%;
    @media (max-width: 1040px) {
      width: 100%;
      padding-left: 0rem;
    }
    @media (max-width: 540px) {
      text-align: center;
    }
  }
  .cancel-button-container {
    margin-top: 2rem;
    .download-button {
      max-width: 14rem;
      font-weight: bold;
      background-color: ${(props) =>
        props.theme.colors.fontColor.titleFontColor};
    }
  }
`;
export default CancelLetterSectionStyle;
