import { useContext, useEffect, useState } from "react";
import { AlertContext } from "../../common/alertContainer";
import { AuthTokenService } from "../../../services";
import ClickInsurance from "../processPopUp/clickPopup/clickPopup";
import { ContainerContext } from "../../../components/navbarContainer/containerProvider";
import {
  getInsuranceList,
  getInsuranceOptimization,
} from "./insuranceListServices";
import InsuranceListStyle from "./insuranceListStyle";
import Insurances from "./insurances/insurances";
import NecessaryActions from "./necessaryActions/necessaryActions";
import ProcessPopUp from "../processPopUp/processPopUp";
import SimulateInsurance from "./simulateOtherInsurance/simulateInsurance";
// import Button from "../../../components/button/button";
// import Text from "../../../theme/font";

const InsuranceList = () => {
  const { changeAlertStatus, pageNameHandler, headerTitleHandler } =
    useContext(ContainerContext);
  const [showPopUp, setShowPopup] = useState(false);
  const [showClosePopup, setShowClosePopup] = useState(false);
  const [insuranceData, setInsuranceData] = useState([]);
  const [optimizationData, setOptimizationData] = useState([]);
  const { open } = useContext(AlertContext);

  useEffect(() => {
    changeAlertStatus(false);
    toogleNavBar();
    return () => {
      changeAlertStatus(true);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    open({
      alertType: "spinner",
      open: true,
    });
    const idClienteSeguros = AuthTokenService.getIdClienteSeguros();
    getInsuranceList(idClienteSeguros)
      .then((result) => {
        open({
          alertType: "spinner",
          open: false,
        });
        const data = result.data;
        setInsuranceData(data);
      })
      .catch(() => {
        open({
          alertType: "spinner",
          open: false,
        });
        open({
          type: "error",
          title: "Error al enviar datos",
          alertType: "toast",
          open: true,
        });
      });
    getInsuranceOptimization(idClienteSeguros)
      .then((result) => {
        open({
          alertType: "spinner",
          open: false,
        });
        const data = result.data.optimizacion;
        setOptimizationData(data);
      })
      .catch(() => {
        open({
          alertType: "spinner",
          open: false,
        });
        open({
          type: "error",
          title: "Error al enviar datos",
          alertType: "toast",
          open: true,
        });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showPopUp]);

  const toogleNavBar = () => {
    pageNameHandler("Resumen");
    headerTitleHandler("Seguros");
  };

  const popupCloseHandler = () => {
    setShowPopup(false);
  };
  //For closing both popups
  const closeAllPopUps = () => {
    setShowClosePopup(false);
    setShowPopup(false);
  };
  //For closing the confirmation popup
  const closePopUpHandler = () => {
    setShowClosePopup(false);
  };

  const confirmPopUpHandler = () => {
    setShowClosePopup(true);
  };
  const processPopupHandler = () => {
    return (
      <div className="process-popup-wrapper">
        <div
          className="popup-overlay"
          onClick={() => confirmPopUpHandler()}
        ></div>
        <div className="process-popup-container">
          {showPopUp && <ProcessPopUp click={popupCloseHandler} />}
          {showClosePopup && (
            <div className="closing-popup-container">
              <ClickInsurance
                closeAllPopup={closeAllPopUps}
                closePopUp={closePopUpHandler}
              />
            </div>
          )}
        </div>
      </div>
    );
  };

  return (
    <InsuranceListStyle>
      {showPopUp && (
        <div className="process-pop-section">{processPopupHandler()}</div>
      )}
      <div className="insurance-list-container">
        <div className="primary-section">
          <div className="insurances-section box-container">
            <Insurances data={insuranceData} />
          </div>
        </div>
        <div className="secondary-section">
          <div className="necessary-section box-container">
            <NecessaryActions data={optimizationData} />
          </div>
          <SimulateInsurance openPopup={setShowPopup} />
          {/* <div className="process-section box-container">
            <div className="process-section-container">
              <div className="process-section-header">
                <Text variant="titleFont" className="process-section-heading">
                  Protege a los tuyos con un seguro de vida de 50.000€
                </Text>
              </div>
              <Text className="image-button-container" variant="labelFont">
                <Button value="Simular por 45€/mes" className="image-button" />
              </Text>
            </div>
          </div> */}
        </div>
      </div>
    </InsuranceListStyle>
  );
};
export default InsuranceList;
