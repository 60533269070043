import styled from "styled-components";

const SetGoalStyle = styled.div`
  height: 100%;
  .set-goal-container {
    display: flex;
    width: 100%;
    height: 100%;
    @media (max-width: 620px) {
      flex-direction: column;
    }
  }
`;
export default SetGoalStyle;
