import styled from "styled-components";

const FundChangePopUpStyle = styled.div`
  .popup-container {
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 100;
  }
  .overlay {
    width: 100vw;
    height: 100vh;
    position: fixed;
    left: 0;
    top: 0;
    background-color: #ccc;
    opacity: 0.3;
    z-index: -10;
  }
  .popup-inner {
    width: 50%;
    max-width: 40.625rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 1.56rem;
    background-color: ${(props) =>
      props.theme.colors.background.defaultBackground};
    padding: 2rem;
    @media (max-width: 820px) {
      padding: 2rem 2rem;
      height: 40%;
      width: 50%;
    }
    @media (max-width: 600px) {
      padding: 2rem 2rem;
      height: 40%;
      width: 80%;
    }
    .accountDetails-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 0.5rem;
      @media (max-width: 820px) {
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
      .heading {
        color: ${(props) => props.theme.colors.fontColor.titleFontColor};
        font-weight: bold;
        @media (max-width: 820px) {
          margin-bottom: 1rem;
        }
      }
    }
    .form-container {
      width: 100%;
      display: flex;
      gap: 0.5rem;
      @media (max-width: 820px) {
        width: 100%;
        display: flex;
        flex-flow: column;
        justify-content: center;
        align-items: center;
      }
      .form-field {
        width: calc(100% - 8.5rem);
        @media (max-width: 820px) {
          width: 100%;
        }
        .label {
          font-weight: bold;
          padding: 0.5rem 0.5rem;
        }
        .field-div {
          padding-left: 1.5rem;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          padding-right: 0.5rem;
        }
      }
      .header-btn {
        font-weight: bold;
        width: 100%;
        max-width: 8rem;
        @media (max-width: 820px) {
          padding-top: 1rem;
        }
        .but-div {
          height: 2.5rem;
          cursor: pointer;
        }
        .disabled-btn {
          pointer-events: none;
          cursor: not-allowed;
          opacity: 50%;
        }
      }
    }
  }
`;
export default FundChangePopUpStyle;
