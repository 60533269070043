import { Request } from "../../../services";
import URL_CONSTANTS from "../../../constants/urlConstants/urlConstants";

// GET DIVERSIFICACION *//

export const cargarDiversificacion = (data) => {
  return Request.get(`${URL_CONSTANTS.get_user_diversification}${data}`);
};

// GET GRAFICO EVOLUCION *//
export const cargarGraficoEvolucion = (data) => {
  return Request.get(`${URL_CONSTANTS.get_user_graphic_evolution}${data}`);
};

//* GET INIITIAL RECOMMENDATION *//
export const cargarRecomendacionInicial = (data) => {
  return Request.get(`${URL_CONSTANTS.get_initial_recommendation}${data}`);
};

// GET VIDEO *//

export const cargarVideo = (data) => {
  return Request.get(`${URL_CONSTANTS.get_video}${data}`);
};

// GET USER NAME *//

export const cargarAjustes = (data) => {
  return Request.get(`${URL_CONSTANTS.get_settings}${data}`);
};

// POST FUND DETAILS *//

export const postFund = (data) => {
  return Request.post(URL_CONSTANTS.post_add_fund, data);
};
// GET BANK DETAILS *//

export const getBankDetails = (data) => {
  return Request.get(`${URL_CONSTANTS.get_total_bank_details}${data}`);
};

//GET MAP DATA//

export const getMapData = (data) => {
  return Request.get(`${URL_CONSTANTS.get_map_data}${data}`);
};

//GET ECO MAP DATA//

export const getEcoMapData = (data) => {
  return Request.get(`${URL_CONSTANTS.get_eco_map_data}${data}`);
};

//GET INVESTMENT DATA
export const getInvestmentDetails = (data) => {
  return Request.get(`${URL_CONSTANTS.get_investment_details}${data}`);
};
