import React, { useEffect, useState } from "react";
import moment from "moment";
import {
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";
import { ThemeProvider } from "styled-components";
import defaultTheme from "../../../../theme/theme";
import ImageConstants from "../../../../constants/imageConstants/image_constants";
import ObjectiveStyle from "./objectiveStyle";
import Text from "../../../../theme/font";
import "react-circular-progressbar/dist/styles.css";

const Objective = ({ objetivo, click }) => {
  const theme = defaultTheme;
  const [path, setPath] = useState("");
  const [color, setColor] = useState("");
  const [wallet, setWallet] = useState("");
  const [text, setText] = useState("");
  const [name, setName] = useState("");

  const converteDate = () => {
    const date = moment(objetivo.periodo).format("MMM YY");
    return date[0].toUpperCase() + date.substring(1);
  };

  const formato = (x, signo = false) => {
    if (x > 0) {
      var number = new Intl.NumberFormat("de-DE", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(x);
      if (signo && x > 0) {
        return "+" + number;
      } else return number;
    } else {
      return x;
    }
  };

  const conditionhandler = () => {
    let newUrl = "";
    if (objetivo.estado === 0) {
      newUrl = objetivo.icono;
      setColor("#F46767");
      setWallet(ImageConstants.walletRed);
      setText(objetivo.texto);
    }
    if (objetivo.estado === 1) {
      newUrl = objetivo.icono;
      setColor("#F2DE68");
      setWallet(ImageConstants.walletYellow);
      setText(objetivo.texto);
    }
    if (objetivo.estado === 2) {
      newUrl = objetivo.icono;
      setColor("#30B2B8");
      setWallet(ImageConstants.walletBlue);
      setText(objetivo.texto);
    }
    if (objetivo.estado === 3) {
      newUrl = objetivo.icono;
      setColor("#30B2B8");
      setWallet(ImageConstants.walletBlue);
      setText(objetivo.texto);
    }
    setPath(newUrl);
  };

  useEffect(() => {
    if (objetivo) {
      setName(objetivo.objetivo);
      conditionhandler();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (objetivo == null) {
    return (
      <ObjectiveStyle>
        <div className="objetivoContainer" onClick={() => click()}>
          <div className="col-3 float">
            <img
              className="addObjetiveImg"
              alt="x"
              src={ImageConstants.addnew}
            ></img>
          </div>
          <div className="col-9 float objetivoTextoContainer">
            <div className="nuevoObjetivoTexto">
              <Text variant="labelFont">Añadir objetivo</Text>
            </div>
          </div>
        </div>
      </ObjectiveStyle>
    );
  } else {
    return (
      <ThemeProvider theme={theme}>
        <ObjectiveStyle>
          <div className="objetivoContainer">
            <Text
              className="topText"
              variant="fieldFont"
              style={{
                backgroundColor: `${color}20`,
                color: `${color}`,
              }}
            >
              {text}
            </Text>
            <div className="imageContainer">
              <CircularProgressbarWithChildren
                value={(objetivo.progreso / objetivo.importe) * 100}
                strokeWidth={10}
                styles={buildStyles({
                  pathColor: color,
                  strokeLinecap: "butt",
                  pathTransitionDuration: 0.5,
                  trailColor: `${color}20`,
                })}
              >
                <img className="objetivoImg" alt="x" src={path}></img>
              </CircularProgressbarWithChildren>
            </div>

            <div className="contentsContainer">
              <Text className="objectiveTitle" variant="labelFont">
                {name &&
                  name.length > 0 &&
                  name[0].toUpperCase() + name.substring(1)}
              </Text>
              <div className="dateAmountContainer">
                <Text className="amount" variant="smallFont">
                  {formato(objetivo.importe)}€
                </Text>
                <Text className="date" variant="smallFont">
                  {converteDate()}
                </Text>
              </div>
              <div
                className="savingsContainer"
                style={{ backgroundColor: `${color}20` }}
              >
                <div className="icon">
                  <img src={wallet} alt="x" className="iconInside" />
                </div>
                <Text className="savingsText" variant="smallFont">
                  {`ahorra ${formato(objetivo.ahorro)}€/mes`}
                </Text>
              </div>
              <Text className="totalSavings" variant="smallFont">
                {`Llevas ${formato(objetivo.progreso)}€ ahorrados`}
              </Text>
            </div>
          </div>
        </ObjectiveStyle>
      </ThemeProvider>
    );
  }
};

export default Objective;
