import { toast } from "react-toastify";

const Toast = ({ title, type }) => {
  const toastHandler = () => {
    switch (type) {
      case "error":
        return toast.error(title, {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          toastId: "",
        });
      case "success":
        return toast.success(title, {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          toastId: "",
        });

      default:
        return 0;
    }
  };
  return toastHandler();
};

export default Toast;
